import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {PaymentMethod} from '../models/payment-method.model';
import {FirestoreService} from './firestore.service';
import {supabase} from './supabase';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService implements OnDestroy {

  private currentPaymentMethod: BehaviorSubject<PaymentMethod> = new BehaviorSubject<PaymentMethod>(null);
  currentPaymentMethod$ = this.currentPaymentMethod.asObservable();
  subscriptions: Subscription = new Subscription();

  private PAYMENTMETHOD_TABLE = 'PaymentMethod';

  private state = new BehaviorSubject<any>({paymentMethods: []});
  sPaymentMethods$: Observable<PaymentMethod[]> = this.state
    .asObservable()
    .pipe(map((state) => state.paymentMethods));

  private paymentMethodSelectedAction = new BehaviorSubject<any>({paymentMethod: null});
  selectedPaymentMethod$: Observable<PaymentMethod> = this.paymentMethodSelectedAction
    .asObservable().pipe((uState) => uState);

  constructor(private firestoreService: FirestoreService) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getPaymentMethods(): Observable<PaymentMethod[]> {
    return this.firestoreService.col$(`payment-methods`);
  }

  getPaymentMethod(uidPaymentMethod: string): void {
    this.subscriptions.add(
      this.firestoreService.doc$(`payment-methods/${uidPaymentMethod}`).subscribe(
        (paymentMethod: PaymentMethod) => {
          this.currentPaymentMethod.next(paymentMethod);
        }
      )
    );
  }

  addPaymentMethod(paymentMethod: PaymentMethod): Promise<any> {
    return this.firestoreService.set(`payment-methods/${paymentMethod.uid}`, paymentMethod);
  }

  updatePaymentMethod(paymentMethod: PaymentMethod): Promise<any> {
    return this.firestoreService.update(`payment-methods/${paymentMethod.uid}`, paymentMethod);
  }

  updateEtat(uidPaymentMethod: string, state): Promise<any> {
    return this.firestoreService.update2(
      `payment-methods/${uidPaymentMethod}/`, {
        available: state
      }
    );
  }

  deletePaymentMethod(data): Promise<any> {
    return this.firestoreService.delete(`payment-methods/${data.uid}`);
  }


  /**
   * SUPABASE
   */

  async getPaymentMethodsWithSupabase(): Promise<void> {
    const {data} = await supabase
      .from(this.PAYMENTMETHOD_TABLE)
      .select('*')
      .order('uid', {ascending: false});

    this.state.next({
      paymentMethods:
        data?.map((paymentMethod: PaymentMethod) => (paymentMethod)) ?? [],
    });
  }

  async getPaymentMethodWithSupabase(uidPaymentMethod: string): Promise<void> {
    const {data} = await supabase
      .from(this.PAYMENTMETHOD_TABLE)
      .select('*')
      .eq('uid', uidPaymentMethod).single();

    this.paymentMethodSelectedAction.next(data ?? null);
  }

  async addPaymentMethodWithSupabase(paymentMethod: PaymentMethod): Promise<any> {
    const {error} = await supabase
      .from(this.PAYMENTMETHOD_TABLE)
      .insert(paymentMethod).single();
    return {error};
  }

  async updatePaymentMethodWithSupabase(paymentMethod: PaymentMethod): Promise<any> {
    const {data, error} = await supabase
      .from(this.PAYMENTMETHOD_TABLE)
      .update(paymentMethod)
      .match({uid: paymentMethod.uid});

    return {data, error};
  }

  async updateEtatWithSupabase(uidPaymentMethod: string, state: boolean): Promise<any> {
    const {data} = await supabase
      .from(this.PAYMENTMETHOD_TABLE)
      .update({available: state, updatedAt: new Date()})
      .match({uid: uidPaymentMethod});


    return data;
  }

  async deletePaymentMethodWithSupabase(uidPaymentMethod: string): Promise<any> {
    const {data} = await supabase
      .from(this.PAYMENTMETHOD_TABLE)
      .update({deletedAt: new Date()})
      .match({uid: uidPaymentMethod});

    console.log('@#$>', data);
    return data;
  }
}
