<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Deliver</h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item">
          <a routerLink="['/delivers']">Delivers</a>
        </li>
        <li class="breadcrumb-item active">
          <strong>Edit Deliver</strong>
        </li>
      </ol>
    </div>
    <div class="col-lg-2"></div>
  </div>
  <div class="row mt-1">
    <a [routerLink]="['/delivers']" class="btn btn-sm btn-primary text-white"><i class="fa fa-chevron-left"></i> Back</a>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox ">
          <div class="ibox-title">
            <h5>Modify <small>Deliver.</small></h5>
          </div>
          <div class="ibox-content">
            <form (ngSubmit)="onSubmit()" [formGroup]="deliverForm">
              <div class="form-group row"><label class="col-sm-2 col-form-label">Name</label>
                <div class="col-sm-10">
                  <div class="input-group m-b">
                    <div class="input-group-prepend">
                      <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                    </div>
                    <input type="text" placeholder="name" class="form-control" id="name" formControlName="name"
                           required="required" minlength="6"
                           [ngClass]="{'is-invalid' : (deliverForm.get('name').touched || deliverForm.get('name').dirty) && !deliverForm.get('name').valid}">
                    <div class="invalid-feedback">
                      <span *ngIf="deliverForm.get('name').errors?.required">Deliver Name is required !</span>
                      <span
                        *ngIf="deliverForm.get('name').errors?.minlength">Valid Deliver Name must be longer than 6 caracters !</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row"><label class="col-sm-2 col-form-label">Description</label>
                <div class="col-sm-10">
                  <div class="input-group m-b">
                    <div class="input-group-prepend">
                      <span class="input-group-addon"><i class="fa fa-italic"></i></span>
                    </div>
                    <input type="text" placeholder="Description" class="form-control" minlength="10"
                           formControlName="description" id="description"
                           [ngClass]="{'is-invalid' : (deliverForm.get('description').touched || deliverForm.get('description').dirty) && !deliverForm.get('description').valid}">
                    <div class="invalid-feedback">
                      <span
                        *ngIf="deliverForm.get('description').errors?.minlength">Valid Deliver description must be longer than 10 caracters !</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row"><label class="col-sm-2 col-form-label">Adresse</label>
                <div class="col-sm-10">
                  <div class="input-group m-b">
                    <div class="input-group-prepend">
                      <span class="input-group-addon"><i class="fa fa-home"></i></span>
                    </div>
                    <input ngx-google-places-autocomplete #placesRef="ngx-places" type="text" placeholder="Adresse"
                           class="form-control" minlength="10" (onAddressChange)="handleAddressChange($event)"
                           formControlName="adresse" id="adresse" required="required"
                           [ngClass]="{'is-invalid' : (deliverForm.get('adresse').touched || deliverForm.get('adresse').dirty) && !deliverForm.get('adresse').valid}">
                    <div class="invalid-feedback">
                        <span *ngIf="deliverForm.get('adresse').errors?.required">Deliver adresse is required !</span>
                      <span
                        *ngIf="deliverForm.get('adresse').errors?.minlength">Valid Deliver description must be longer than 10 caracters !</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row"><label class="col-sm-2 col-form-label">Email</label>
                <div class="col-sm-10">
                  <div class="input-group m-b">
                    <div class="input-group-prepend">
                      <span class="input-group-addon"><i class="fa fa-at"></i></span>
                    </div>
                    <input type="email" placeholder="Email" class="form-control"
                           formControlName="email" id="email" required="required"
                           [ngClass]="{'is-invalid' : (deliverForm.get('email').touched || deliverForm.get('email').dirty) && !deliverForm.get('email').valid}">
                    <div class="invalid-feedback">
                        <span *ngIf="deliverForm.get('email').errors?.required">Deliver email is required !</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row"><label class="col-sm-2 col-form-label">Phone</label>
                <div class="col-sm-10">
                  <div class="input-group m-b">
                    <div class="input-group-prepend">
                      <span class="input-group-addon"><i class="fa fa-phone"></i></span>
                    </div>
                    <input type="text" placeholder="Phone" class="form-control"
                           formControlName="phone" id="phone" required="required"
                           [ngClass]="{'is-invalid' : (deliverForm.get('phone').touched || deliverForm.get('phone').dirty) && !deliverForm.get('phone').valid}">
                    <div class="invalid-feedback">
                        <span *ngIf="deliverForm.get('phone').errors?.required">Deliver phone is required !</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row"><label class="col-sm-2 col-form-label">Pourcentage</label>
                <div class="col-sm-10">
                  <div class="input-group m-b">
                    <div class="input-group-prepend">
                      <span class="input-group-addon"><i class="fa fa-percent"></i></span>
                    </div>
                    <input type="number" placeholder="Pourcentage" class="form-control"
                           formControlName="pourcentage" id="pourcentage" required="required" [min]="0"
                           [ngClass]="{'is-invalid' : (deliverForm.get('pourcentage').touched || deliverForm.get('pourcentage').dirty) && !deliverForm.get('pourcentage').valid}">
                    <div class="invalid-feedback">
                      <span *ngIf="deliverForm.get('pourcentage').errors?.required">Deliver pourcentage is required !</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row"><label class="col-sm-2 col-form-label">Image</label>
                <div class="col-sm-10">
                  <div class="input-group m-b">
                    <div class="input-group-prepend">
                      <span class="input-group-addon"><i class="fa fa-image"></i></span>
                    </div>
                    <input type="file" accept="image/*" placeholder="Price" class="form-control" id="image"
                           formControlName="image" (change)="onUpload($event)"
                           [ngClass]="{'is-invalid' : (deliverForm.get('image').touched || deliverForm.get('image').dirty) && !deliverForm.get('image').valid}">

                    <div class="invalid-feedback">
                      <span *ngIf="deliverForm.get('image').errors?.required">Deliver image is required !</span>
                    </div>
                  </div>
                  <div class="progress">
                    <div class="progress-bar" [style.width.%]="pourcentage" role="progressbar"
                         [attr.aria-valuenow]="pourcentage" aria-valuemin="0" aria-valuemax="100">
                      <span class="text-center" *ngIf="pourcentage > 0">{{pourcentage | number}} %</span>
                    </div>
                  </div>
                  <div class="offset-6" *ngIf="upload">
                    <div class="lds-ring" *ngIf="pourcentage < 100">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hr-line-dashed"></div>

              <div class="form-group row">
                <div class="col-sm-4 col-sm-offset-2">
                  <button class="btn btn-white btn-sm" type="submit">Cancel</button>
                  <button class="btn btn-primary btn-sm" type="submit"
                          [title]="deliverForm.valid ? 'Ajouter Deliver' : 'Disable until valid form data'"
                          [disabled]="!deliverForm.valid">Edit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
