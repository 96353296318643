<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>Product Type</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/groups', uidGroup]">Groupe</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Ajout Type de Produit</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2"></div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/groups', uidGroup]" class="btn btn-sm btn-primary text-white"><i class="fa fa-chevron-left"></i> Back</a>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Ajout <small>Category.</small></h5>
        </div>
        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" [formGroup]="gProductTypeForm">
            <div class="form-group row"><label class="col-sm-2 col-form-label">Product Type</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-random"></i></span>
                  </div>
                  <select class="form-control m-b" name="productType" formControlName="productType"
                          id="productType" required="required"
                          [ngClass]="{'is-invalid' : (gProductTypeForm.get('productType').touched || gProductTypeForm.get('productType').dirty) && !gProductTypeForm.get('productType').valid}">
                    <option [ngValue]="null" disabled>Product Type</option>
                    <option *ngFor="let productType of productTypes" [value]="productType">{{productType}}</option>
                  </select>
                  <div class="invalid-feedback">
                    <span
                      *ngIf="gProductTypeForm.get('productType').errors?.required">Product Type is required !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="hr-line-dashed"></div>

            <div class="form-group row">
              <div class="col-sm-4 col-sm-offset-2">
                <a [routerLink]="['/groups', uidGroup]" class="btn btn-white btn-sm" type="submit">Cancel</a>
                <button  class="btn btn-primary btn-sm" type="submit"
                         [title]="gProductTypeForm.valid ? 'Ajouter Product Type' : 'Disable until valid form data'"
                         [disabled]="!gProductTypeForm.valid ">Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

