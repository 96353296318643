import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../services/notifications.service';
import {UploadService} from '../services/upload.service';
import {DeliverService} from '../services/deliver.service';
import uid from 'uid';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {Address} from '../models/address.model';
import {AddressService} from '../services/address.service';
import {Address as gAddress} from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-deliveradd',
  templateUrl: './deliveradd.component.html',
  styleUrls: ['./deliveradd.component.css']
})
export class DeliveraddComponent implements OnInit, OnDestroy {
  @ViewChild('placesRef', {static: false}) placesRef: GooglePlaceDirective;
  deliverForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  uidStore: string;
  uid;
  image: string;
  pourcentage = 0;
  fieldTextType: boolean;
  subscriptions: Subscription = new Subscription();
  upload = false;
  deliverAddress: Address;

  constructor(private notifyService: NotificationsService,
              private uploadService: UploadService,
              private deliverService: DeliverService,
              private router: Router,
              private addressService: AddressService) {
  }

  ngOnInit(): void {
    this.uid = uid(32);
    this.initForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.deliverForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      adresse: new UntypedFormControl(),
      email: new UntypedFormControl('', [
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        Validators.required
      ]),
      phone: new UntypedFormControl(),
      pourcentage: new UntypedFormControl(),
      description: new UntypedFormControl(),
      image: new UntypedFormControl(),
      password: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    this.deliverService.addDeliver({
      uid: this.uid,
      description: this.deliverForm.get('description').value,
      amountDu: 0,
      amountReceived: 0,
      distanceTraveled: 0,
      paymentPercent: this.deliverForm.get('pourcentage').value,
      uidUser: '',
      // name: this.deliverForm.get('name').value,
      // imageUrl: this.uploadService.images['deliver-images'][0],
      // address: [this.deliverAddress],
      // phoneNumber: this.deliverForm.get('phone').value,
      // birthday: null,
      // emailAddress: this.deliverForm.get('email').value,
      // password: this.deliverForm.get('password').value,
      // deliveriesCount: 0,
      // createdAt: new Date(),
      // updatedAt: null,
      // deletedAt: null,
      // lockedAt: null
    }).then(
      () => {
        this.deliverForm.reset();
        this.uploadService.dropImageList();
        this.router.navigate(['delivers']).then(
          () => {
            this.toastTitle = 'Ajout Fait';
            this.toastMessage = 'Ajout du Deliver fait avec succès !';
            this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
          }
        );
      }
    ).catch(
      () => {
        this.toastTitle = 'Echec de l\'ajout';
        this.toastMessage = 'Echec de l\'ajout du Deliver !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );

  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `delivers/${this.uid}/${this.uid}`, 'deliver-images');
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

  public handleAddressChange(address: gAddress): void {
    this.deliverAddress = this.addressService.handleAddressChange(address);
  }

  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }

}
