<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>Bannière</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/banners']">Bannières</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Modifier Bannière</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2"></div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/banners']" class="btn btn-sm btn-primary text-white"><i class="fa fa-chevron-left"></i> Back</a>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Modifier <small>Bannière.</small></h5>
        </div>
        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" [formGroup]="bannerForm">
            <div class="form-group row"><label class="col-sm-2 col-form-label" for="endAt">End At</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                  </div>
                  <input type="datetime-local" placeholder="End At" class="form-control" id="endAt" formControlName="endAt"
                         required="required" minlength="2"
                         [ngClass]="{'is-invalid' : (bannerForm.get('endAt').touched || bannerForm.get('endAt').dirty) && !bannerForm.get('endAt').valid}">
                  <div class="invalid-feedback">
                    <span *ngIf="bannerForm.get('endAt').errors?.required">Bannière End Date is required !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label class="col-sm-2 col-form-label" for="link">Lien</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-link"></i></span>
                  </div>
                  <input type="text" placeholder="Lien" class="form-control" required
                         formControlName="link" id="link"
                         [ngClass]="{'is-invalid' : (bannerForm.get('link').touched || bannerForm.get('link').dirty) && !bannerForm.get('link').valid}">
                  <div class="invalid-feedback">
                      <span
                        *ngIf="bannerForm.get('link').errors?.required">Valid link is required</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label class="col-sm-2 col-form-label">Image</label>
              <div class="col-sm-10">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-image"></i></span>
                  </div>
                  <input type="file" accept="image/*" placeholder="Image" class="form-control" id="image"
                         formControlName="image" required="required" (change)="onUpload($event)"
                         [ngClass]="{'is-invalid' : (bannerForm.get('image').touched || bannerForm.get('image').dirty) && !bannerForm.get('image').valid}">

                  <div class="invalid-feedback">
                    <span *ngIf="bannerForm.get('image').errors?.required">banner image is required !</span>
                  </div>
                </div>
                <div class="progress">
                  <div class="progress-bar" [style.width.%]="pourcentage" role="progressbar"
                       [attr.aria-valuenow]="pourcentage" aria-valuemin="0" aria-valuemax="100">
                    <span class="text-center" *ngIf="pourcentage > 0">{{pourcentage | number}} %</span>
                  </div>
                </div>
                <div class="offset-6" *ngIf="upload">
                  <div class="lds-ring" *ngIf="pourcentage < 100">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div class="flex-row" *ngIf="this.uploadService.images['banner-images'] !== []">
                  <img *ngFor="let image of this.uploadService.images['banner-images']"
                       class="rounded mx-1 my-1" src="{{image}}" alt="Uploaded_image" height="100">
                </div>
                <div class="flex-row mt-2" *ngIf="banner?.imageUrl !== ''">
                  <img class="rounded mx-1 my-1" src="{{banner?.imageUrl}}" alt="Uploaded_image" height="100">
                </div>
              </div>
            </div>
            <div class="hr-line-dashed"></div>

            <div class="form-group row">
              <div class="col-sm-4 col-sm-offset-2">
                <a [routerLink]="['/banners']" class="btn btn-white btn-sm" type="submit">Cancel</a>
                <button class="btn btn-primary btn-sm" type="submit"
                        [title]="bannerForm.valid ? 'Modifier Bannière' : 'Disable until valid form data'"
                        [disabled]="!bannerForm.valid">Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
