import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {BannersService} from '../services/banners.service';
import {UploadService} from '../services/upload.service';
import {NotificationsService} from '../services/notifications.service';
import uid from 'uid';
import {Banner} from '../models/banner.model';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-banner-add',
  templateUrl: './banner-add.component.html',
  styleUrls: ['./banner-add.component.css']
})
export class BannerAddComponent implements OnInit, OnDestroy {

  bannerForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  pourcentage = 0;
  upload = false;
  uid: string;
  subscriptions: Subscription = new Subscription();
  admin: any;
  constructor(private bannerService: BannersService,
              public uploadService: UploadService,
              private notifyService: NotificationsService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.initForm();
    this.uid = uid(32);
    this.subscriptions.add(
      this.authService.user$.subscribe(
        user => {
          if (user !== null) {
            this.admin = user;
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initForm(): void {
    this.bannerForm = new UntypedFormGroup({
      image: new UntypedFormControl('', Validators.required),
      endAt: new UntypedFormControl('',  Validators.required),
      link: new UntypedFormControl('',  Validators.required)
    });
  }

  onSubmit(): void {
    const banner: Banner = {
      uid: this.uid,
      imageUrl: this.uploadService.images['banner-images'][0],
      link: (this.bannerForm.get('link').value).trim(),
      endAt: new Date(this.bannerForm.get('endAt').value),
      updatedAt: null,
      available: true,
      createdAt: new Date(),
      uidUser: this.admin.uid
    };

    this.bannerService.addBanner(banner).then(
      () => {
        this.toastTitle = 'Ajout Fait';
        this.toastMessage = 'Ajout de la Bannière fait avec succès !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
        this.bannerForm.reset();

        this.uploadService.dropImageList();
      }
    ).catch(
      () => {
        this.toastTitle = 'Echec';
        this.toastMessage = 'Ajout de la Bannière a échoué !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `banners/${this.uid}`, 'banner-images');
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

}
