import { Injectable } from '@angular/core';
import {MetaData} from '../models/metadata.model';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MetaDataValueType} from '../models/metadata-valueType.enum';
import {MetaDataValue} from '../models/metadata-value.model';
import uid from 'uid';
import {ProductMetadata} from '../models/product-meta-data.model';

@Injectable({
  providedIn: 'root'
})
export class MetaDatasService {

  metaDatas: MetaData[] = [];

  constructor() { }

  /**
   * Fill in the metaDatas in a form
   * @param metadata A metadatas Array
   * @param metaDataValuesFormBuilder A metadataValues FormBuilder
   * @param metaDataFormArray The metadata FormArray
   * @return An array containing the value type of the metadata.
   */
  fillMetadatasForm(metadatas: Array<ProductMetadata>, metaDataValuesFormBuilder: UntypedFormBuilder,
                    metaDataFormArray: UntypedFormArray): Array<MetaDataValueType> {
    const metadataValueTypes: Array<MetaDataValueType> = [];
    metadatas.forEach((metaData) => {
      metadataValueTypes.push(metaData.metaData.type);

      const metaDataValuesArray: UntypedFormArray = metaDataValuesFormBuilder.array([]);

      metaData.metaData.metaDataValues.forEach(
        metaDataValue => {
          // Prevents error linking to cases that do not have the quantity attribute
          metaDataValuesArray.push(metaDataValuesFormBuilder.group({
            ...metaDataValue,
            quantity: metaDataValue.quantity === undefined ? 1 : metaDataValue.quantity
          }));
        }
      );

      const metaDataValueGroup: UntypedFormGroup = metaDataValuesFormBuilder.group({
        ...metaData,
        metaDataValues: metaDataValuesArray
      });

      metaDataFormArray.push(metaDataValueGroup);
    });

    return metadataValueTypes;
  }

  /**
   * Set/add a Product Metadatas
   * @param metadatas A metadatas Array
   * @param images A uploaded images( in case there is metaDataValue-images)
   * @return metaDatas The metadatas list
   */
  setMetadatas(metadatas: MetaData[], images: {} = {}): MetaData[] {
    metadatas.map((metadata) => {
      if (metadata.type === 'IMAGE_URL') {
        metadata.metaDataValues.map((values, index) => {
          const imageIndex = `metaDataValue-images-${index}`;
          if (images[imageIndex] !== undefined){
            values.value = images[imageIndex][0];
          }
        });

        this.pushMetadata(uid(12), metadata);
      }
      else {
        this.pushMetadata(uid(12), metadata);
      }
    });
    return this.metaDatas;
  }

  /**
   * Set/add a Metadatas metadataValue
   * @param metadataValues A metadata values Array
   * @return metadataValues A metadata value object
   */
  private setMetadataValue(metadataValues: MetaDataValue[]): MetaDataValue[] {
    const myMetaDataValues: MetaDataValue[] = [];
    metadataValues.map((metadataValue) => {
      myMetaDataValues.push({
        uid: uid(12),
        value: metadataValue.value,
        extraPrice: metadataValue.extraPrice,
        quantity: metadataValue.quantity,
        available: metadataValue.available
      });
    });

    return myMetaDataValues;
  }

  /**
   * Add a Metadata object in Metadatas Array
   * @param metaUid The metadata Uid
   * @param metadata The metaData object
   * @return metaDatas The metadatas list
   */
  private pushMetadata(metaUid: string, metadata: MetaData): void {
    this.metaDatas.push({
      uid: metaUid,
      name: metadata.name,
      description: metadata.description,
      type: metadata.type,
      metaDataValues: this.setMetadataValue(metadata.metaDataValues)
    });
  }
}
