import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {NotificationsService} from '../services/notifications.service';
import {UploadService} from '../services/upload.service';
import {Router} from '@angular/router';
import {AdminsService} from '../services/admins.service';
import uid from 'uid';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {Address as gAddress} from 'ngx-google-places-autocomplete/objects/address';
import {AddressService} from '../services/address.service';
import {User} from '../models/user.model';
import {UserAddress} from '../models/user-address.model';

@Component({
  selector: 'app-admin-add',
  templateUrl: './admin-add.component.html',
  styleUrls: ['./admin-add.component.css']
})
export class AdminAddComponent implements OnInit, OnDestroy {
  @ViewChild('placesRef', {static: false}) placesRef: GooglePlaceDirective;
  adminForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  uid: string;
  subscription: Subscription = new Subscription();
  image: string;
  pourcentage = 0;
  fieldTextType: boolean;
  upload = false;
  adminAddress: UserAddress;

  constructor(private notifyService: NotificationsService,
              private uploadService: UploadService,
              private adminsService: AdminsService,
              private router: Router,
              private addressService: AddressService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.uid = uid(32);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initForm(): void {
    this.adminForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      phone: new UntypedFormControl(),
      avatar: new UntypedFormControl(),
      adresse: new UntypedFormControl(),
      email: new UntypedFormControl('', [
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        Validators.required
      ]),
      password: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    const admin: User = {
      uid: this.uid,
      name: this.adminForm.get('name').value,
      userName: this.adminForm.get('username').value ? this.adminForm.get('username').value : '',
      phoneNumber: this.adminForm.get('phone').value,
      emailAddress: this.adminForm.get('email').value,
      imageUrl: this.uploadService.images['admin-images'][0],
      password: this.adminForm.get('password').value,
      userAddress: [this.adminAddress],
      birthday: null,
      createdAt: new Date(),
      updatedAt: null,
      deletedAt: null,
      lockedAt: null,
    };
    this.adminsService.addAdmin(admin).then(
      (result) => {
        if (result !== undefined && (result['code'] >= 400 && result['code'] < 500)) {
          this.toastTitle = 'Echec de l\'ajout';
          this.toastMessage = `Echec de l'ajout de l'admin: ${result['message']} !`;
          this.notifyService.showError(this.toastMessage, this.toastTitle);
        } else {
          this.adminForm.reset();
          this.adminsService.disableAdmin(admin).then();
          this.uploadService.dropImageList();
          this.router.navigate(['admins']).then(
            () => {
              this.toastTitle = 'Ajout Fait';
              this.toastMessage = `Ajout de l'Admin fait avec succès !`;
              this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
            }
          );
        }
      }
    ).catch(
      () => {
        this.toastTitle = 'Echec de l\'ajout';
        this.toastMessage = `Echec de l'ajout de l'admin !`;
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `admins/${this.uid}`, 'admin-images');
    this.subscription.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

  public handleAddressChange(address: gAddress): void {
    this.adminAddress = {
      uid: uid(32),
      uidUser: this.uid,
      address: this.addressService.handleAddressChange(address),
      uidAddress: uid(32)
    };
  }

  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }

}
