import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../services/notifications.service';
import {UploadService} from '../../services/upload.service';
import {AgentsService} from '../../services/agents.service';
import uid from 'uid';
import {Subscription} from 'rxjs';
import {Store} from '../../models/store.model';
import {StoreService} from '../../services/store.service';
import {Agent} from '../../models/agent.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Address} from '../../models/address.model';
import {AddressService} from '../../services/address.service';
import {Address as gAddress} from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-agent-add',
  templateUrl: './agent-add.component.html',
  styleUrls: ['./agent-add.component.css']
})
export class AgentAddComponent implements OnInit, OnDestroy {

  agentForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  uid: string;
  uidGroup: string;
  subscription: Subscription = new Subscription();
  image: string;
  pourcentage = 0;
  fieldTextType: boolean;
  restorers: Store[] = [];
  userAddress: Address;
  upload = false;

  constructor(private notifyService: NotificationsService,
              private uploadService: UploadService,
              private agentsService: AgentsService,
              private storeService: StoreService,
              private router: Router,
              private route: ActivatedRoute,
              private addressService: AddressService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.uid = uid(32);
    this.subscription.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidGroup = token.get('idgroup');

          this.subscription.add(
            this.storeService.getStores(this.uidGroup).subscribe(
              restorers => {
                if (restorers !== null) {
                  this.restorers = restorers;
                }
              }
            )
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initForm(): void {
    this.agentForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      phone: new UntypedFormControl(),
      avatar: new UntypedFormControl(),
      adresse: new UntypedFormControl(),
      email: new UntypedFormControl('', [
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        Validators.required
      ]),
      password: new UntypedFormControl(),
      uidStore: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    // const agent: Agent = {
    //   uid: this.uid,
    //   name: this.agentForm.get('name').value,
    //   userName: '',
    //   phoneNumber: this.agentForm.get('phone').value,
    //   emailAddress: this.agentForm.get('email').value,
    //   imageUrl: this.uploadService.images['agent-images'][0],
    //   birthday: null,
    //   createdAt: new Date(),
    //   updatedAt: null,
    //   deletedAt: null,
    //   lockedAt: null,
    //   userAddress: [
    //     {
    //       uid: '',
    //       uidUser: '',
    //       uidAddress: ''
    //     }],
    //   // password: this.agentForm.get('password').value,
    //   uidGroup: this.uidGroup,
    //   // address: this.userAddress, // TODO: Must Change This part
    //   // uidStore: this.agentForm.get('uidStore').value,
    // };
    // this.agentsService.addAgent(agent).then(
    //   (result) => {
    //     if ((result !== undefined && result !== null) && (result['code'] >= 400 && result['code'] < 500)) {
    //       this.toastTitle = 'Echec de l\'ajout';
    //       this.toastMessage = `Echec de l'ajout de l'agent: ${result['message']} !`;
    //       this.notifyService.showError(this.toastMessage, this.toastTitle);
    //     } else {
    //       this.agentForm.reset();
    //       this.uploadService.dropImageList();
    //       this.router.navigate(['groups/' + agent.uidGroup]).then(
    //         () => {
    //           this.toastTitle = 'Ajout Fait';
    //           this.toastMessage = `Ajout de l'Agent fait avec succès !`;
    //           this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
    //         }
    //       );
    //     }
    //   }
    // ).catch(
    //   () => {
    //     this.toastTitle = 'Echec de l\'ajout';
    //     this.toastMessage = `Echec de l'ajout de l'agent !`;
    //     this.notifyService.showError(this.toastMessage, this.toastTitle);
    //   }
    // );
  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `groups/${this.uidGroup}/agents/${this.uid}`, 'agent-images');
    this.subscription.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

  public handleAddressChange(address: gAddress): void {
    this.userAddress = this.addressService.handleAddressChange(address);
  }

  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }

}
