import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Order} from '../../../models/order.model';
import {ActivatedRoute} from '@angular/router';
import {Store} from '../../../models/store.model';
import {StoreService} from '../../../services/store.service';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../services/utils.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit, OnDestroy {

  toastTitle: string;
  toastMessage: string;
  @Input() orders: Order[];
  store: Store;
  idGroup: string;
  uidStore: string;
  subscriptions: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,
              private utilsServices: UtilsService,
              private storeService: StoreService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          if (token !== null) {
            this.idGroup = token.get('idgroup');
            this.uidStore = token.get('idStore');

            this.storeService.getStore(this.idGroup, this.uidStore);
            this.subscriptions.add(
              this.storeService.currentStore$.subscribe(
                store => {
                  if (store !== null) {
                    this.store = store;
                  }
                }
              )
            );
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  validateCommande(order): void {
    this.utilsServices.makeOrderValidateCommande(order);
  }

  giveToDeliver(order): void {
    this.utilsServices.makeOrderGiveToDeliver(order);
  }

  readyForShipping(order): void {
    this.utilsServices.makeOrderReadyForShipping(order);
  }

  orderDelivered(order): void {
    this.utilsServices.makeOrderDelivered(order);
  }

  deleteOrder(order: Order): void {
    this.utilsServices.removeOrder(order, this.store);
  }
}
