import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {PromoService} from '../../../services/promo.service';
import {ActivatedRoute} from '@angular/router';
import {PromoCode} from '../../../models/promo-code.model';
import {StoreService} from '../../../services/store.service';

@Component({
    selector: 'app-promos',
    templateUrl: './promos.component.html',
    styleUrls: ['./promos.component.css']
})
export class PromosComponent implements OnInit, OnDestroy {

    @Input() ownerUid: string;
    promoCodes: PromoCode[] = [];
    idStore: string;
    idGroup: string;
    subscriptions: Subscription = new Subscription();

    constructor(private route: ActivatedRoute,
                private promoServices: PromoService,
                private storeService: StoreService) {
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.route.paramMap.subscribe(
                token => {
                    this.idStore = token.get('idStore');
                    this.idGroup = token.get('idgroup');

                    // void this.promoServices.getStorePromoCodesWithSupabase(this.idStore);
                    // this.promoCodes$ = this.promoServices.storePromoCodes$;

                    void this.storeService.getStoreWithSupabase(this.idStore);
                    this.subscriptions.add(
                        this.storeService.selectedStore$.subscribe(
                            (store) => {
                                if (store !== null) {
                                    store.products.map((product) => {
                                        let round = product.productPromoCode.length;
                                        // TODO: Have to solve the bug here with the duplicate values;
                                        product.productPromoCode.map(
                                            (productPromo) => {
                                                this.promoCodes.push(productPromo.promoCode);
                                            });
                                    });
                                }
                            })
                    );
                }
            )
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
