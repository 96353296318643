import { Injectable } from '@angular/core';
import {FirestoreService} from './firestore.service';
import {BehaviorSubject, catchError, Observable, shareReplay, Subscription} from 'rxjs';
import {ProductTag} from '../models/product-tag.model';

@Injectable({
  providedIn: 'root'
})
export class ProductTagService {

  private currentProductTag: BehaviorSubject<ProductTag> = new BehaviorSubject<ProductTag>(null);
  currentProductTag$ = this.currentProductTag.asObservable();
  subscriptions: Subscription = new Subscription();
  handleError: any = '';
  productTags$: Observable<ProductTag[]> = this.firestoreService.col$('product-tags')
    .pipe(
      shareReplay(1),
      catchError(this.handleError)
    );
  constructor(private firestoreService: FirestoreService) { }

  getProductTags(): Observable<ProductTag[]> {
    return this.firestoreService.col$(`product-tags`);
  }

  getProductTag(uidProductTag: string): void {
    this.subscriptions.add(
      this.firestoreService.doc$(`product-tags/${uidProductTag}`).subscribe(
        (productTag: ProductTag) => {
          this.currentProductTag.next(productTag);
        }
      )
    );
  }

  addProductTag(productTag: ProductTag): Promise<any> {
    return this.firestoreService.set(`product-tags/${productTag.uid}`, productTag);
  }

  updateProductTag(productTag: ProductTag): Promise<any> {
    return this.firestoreService.update(`product-tags/${productTag.uid}`, productTag);
  }

  deleteProductTag(uidProductTag: string): Promise<any> {
    return this.firestoreService.delete(`product-tags/${uidProductTag}`);
  }
}
