import {Component, OnDestroy, OnInit} from '@angular/core';
import {StoreService} from '../../services/store.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-paiements',
  templateUrl: './paiements.component.html',
  styleUrls: ['./paiements.component.css']
})
export class PaiementsComponent implements OnInit, OnDestroy {

  paiements: any[] = [];
  amountDu: number;
  idGroup: string;
  subscriptions: Subscription = new Subscription();

  constructor(private storeService: StoreService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.idGroup = token.get('idgroup');

          // this.subscriptions.add(
          //   this.storeService.storeAvecSommeDue(this.idGroup).subscribe(
          //     restorers => {
          //       this.paiements = restorers;
          //       this.amountDu = 0;
          //       restorers.forEach(store => {
          //         if (typeof store.amountDu === 'number') {
          //           this.amountDu += store.amountDu;
          //         }
          //       });
          //     }
          //   )
          // );
        }
      )
    );

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
