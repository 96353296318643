import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {StoreService} from '../../services/store.service';
import {ActivatedRoute} from '@angular/router';
import {Store} from '../../models/store.model';
import {OrderService} from '../../services/order.service';
import {Order} from '../../models/order.model';
import {catchError, from, Observable, of, shareReplay, Subscription} from 'rxjs';
import {groupBy, mergeMap, toArray} from 'rxjs/operators';
import {Chart} from 'chart.js';
import {InvoiceService} from '../../services/invoice.service';
import {Invoice} from '../../models/invoice.model';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {UploadService} from '../../services/upload.service';
import {NotificationsService} from '../../services/notifications.service';
import {Address} from '../../models/address.model';
import {AddressService} from '../../services/address.service';
import {Address as gAddress} from 'ngx-google-places-autocomplete/objects/address';
import {GroupSingleComponent} from '../group-single.component';
import {GroupsService} from '../../services/groups.service';
import {UtilsService} from '../../services/utils.service';
import {RatingStore} from '../../models/rating-store.model';
import {Rating} from '../../models/rating.model';

@Component({
    selector: 'app-store-single',
    templateUrl: './store-single.component.html',
    styleUrls: ['./store-single.component.css']
})
export class StoreSingleComponent implements OnInit, OnDestroy {
    @ViewChild('lineCanvas') lineCanvas;
    @ViewChild('lineCanvas2') lineCanvas2;
    @ViewChild('lineCanvas3') lineCanvas3;
    @ViewChild('doughnutCanvas') doughnutCanvas;

    idStore: string;
    idGroupe: string;
    store: Store;
    paiements: Invoice[] = [];
    orders: Order[] = [];
    dates: Date[] = [];
    validatesDates: Date[] = [];
    declinedDates: Date[] = [];
    allCommandesValeurs: number[] = [];
    validateCommandesValeurs: number[] = [];
    declineCommandesValeurs: number[] = [];
    lineChart: any;
    doughnutChart: any;

    profilForm: UntypedFormGroup;
    emailForm: UntypedFormGroup;
    toastTitle: string;
    toastMessage: string;
    average: number;
    state: boolean;
    ratings: RatingStore[] = [];
    pourcentage = 0;
    refImage = [];
    fieldTextType: boolean;
    storeAddress: Address;
    subscriptions: Subscription = new Subscription();
    ordersValidatedCount = 0;
    ordersCanceledCount = 0;
    ordersDeliveredCount = 0;

    amountOrdersValidated = 0;
    amountOrdersDelivered = 0;
    amountOrdersCanceled = 0;
    ownerUid: string;

    errorMessage = '';
    selectedStore$: Observable<Store> = this.storeService.selectedStore$
        .pipe(
            shareReplay(1),
            catchError((error) => {
                console.log('**** ERRROR=>', error);
                this.errorMessage = error;
                return of(null);
            })
        );

    constructor(private route: ActivatedRoute,
                private storeService: StoreService,
                private groupService: GroupsService,
                private paiementService: InvoiceService,
                private orderService: OrderService,
                private uploadService: UploadService,
                private notifyService: NotificationsService,
                private addressService: AddressService,
                private utilsServices: UtilsService) {
    }

    ngOnInit(): void {
        this.initForm();
        this.subscriptions.add(
            this.route.paramMap.subscribe(
                token => {
                    this.idStore = token.get('idStore');
                    this.idGroupe = token.get('idgroup');
                    void this.storeService.getStoreWithSupabase(this.idStore);

                    this.subscriptions.add(
                        this.selectedStore$.subscribe(
                            store => {
                                if (store !== null && store !== undefined) {
                                    this.store = store;
                                    this.state = store?.available;
                                    store.storeUser.map((user) => {
                                        if (user.role === 'OWNER') {
                                            this.ownerUid = user.uidUser;
                                        }
                                    });

                                    let ratingTotal = 0;
                                    store.ratingStore.map((storeRating) => {
                                        if (storeRating !== null) {
                                            ratingTotal += storeRating.rating.rating;
                                            this.ratings.push(storeRating);
                                        }
                                    });
                                    this.average = ratingTotal / store.ratingStore.length;

                                    this.profilForm.patchValue({
                                        name: store?.name,
                                        description: store?.description,
                                        adresse: `${store?.storeAddress[0]?.address?.street},${store?.storeAddress[0]?.address?.city} ${store?.storeAddress[0]?.address?.state}, ${store?.storeAddress[0]?.address?.country}`,
                                    });
                                    this.emailForm.patchValue({
                                        newEmail: store?.emailAddress
                                    });
                                }
                            }
                        )
                    );

                    let ordersValidatedCount = 0;
                    let ordersCanceledCount = 0;
                    let ordersDeliveredCount = 0;
                    if (this.store?.orders !== null) {
                        from(this.store?.orders).pipe(
                            groupBy(order => order.orderedAt),
                            mergeMap((group) => group.pipe(toArray()))
                        ).subscribe(
                            c => {
                                this.allCommandesValeurs.push(c.length);
                                this.dates.push(c[0].orderedAt);
                            },
                            error => {
                                console.log(error);
                            },
                            () => {
                                this.lineChartMethod();
                            }
                        );

                        from(this.store?.orders).pipe(
                            groupBy(order => order.validatedAt),
                            mergeMap((group) => group.pipe(toArray()))
                        ).subscribe(
                            command => {
                                if (command[0].validatedAt !== null) {
                                    this.validateCommandesValeurs.push(command.length);
                                    this.validatesDates.push(command[0].validatedAt);
                                    ordersValidatedCount += 1;
                                    this.amountOrdersValidated += command[0].totalAmount;
                                }
                            },
                            error => {
                                console.log(error);
                            },
                            () => {
                                this.ordersValidatedCount = ordersValidatedCount;
                                this.lineChartMethod();
                            }
                        );

                        from(this.store?.orders).pipe(
                            groupBy(order => order.declinedAt),
                            mergeMap((group) => group.pipe(toArray()))
                        ).subscribe(
                            command => {
                                if (command[0].declinedAt !== null) {
                                    this.declineCommandesValeurs.push(command.length);
                                    this.declinedDates.push(command[0].declinedAt);
                                    ordersCanceledCount += 1;
                                    this.amountOrdersCanceled += command[0].totalAmount;
                                }
                            },
                            error => {
                                console.log(error);
                            },
                            () => {
                                this.ordersCanceledCount = ordersCanceledCount;
                                this.lineChartMethod();
                                this.doughnutChartMethod();
                            }
                        );

                        from(this.store?.orders).pipe(
                            groupBy(order => order.deliveredAt),
                            mergeMap((group) => group.pipe(toArray()))
                        ).subscribe(
                            command => {
                                if (command[0].deliveredAt !== null) {
                                    ordersDeliveredCount += 1;
                                    this.amountOrdersDelivered += command[0].totalAmount;
                                }
                            },
                            error => {
                                console.log(error);
                            },
                            () => {
                                this.ordersDeliveredCount = ordersDeliveredCount;
                                this.lineChartMethod();
                                this.doughnutChartMethod();
                            }
                        );
                    }

                    this.subscriptions.add(
                        this.paiementService.getStorePaiements(this.idGroupe, this.idStore).subscribe(
                            (paiements) => {
                                if (paiements !== null) {
                                    this.paiements = paiements;
                                }
                            }
                        )
                    );
                }
            )
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    initForm(): void {
        this.profilForm = new UntypedFormGroup({
            name: new UntypedFormControl(),
            description: new UntypedFormControl(),
            adresse: new UntypedFormControl(),
            image: new UntypedFormControl(),
            email: new UntypedFormControl()
        });
        this.emailForm = new UntypedFormGroup({
            newEmail: new UntypedFormControl()
        });
    }

    lineChartMethod(): void {
        this.lineChart = new Chart(this.lineCanvas.nativeElement, {
            type: 'line',
            data: {
                labels: this.dates,
                datasets: [
                    {
                        label: 'Commandes',
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: 'rgba(75,192,192,0.4)',
                        borderColor: 'rgba(75,192,192,1)',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: 'rgba(75,192,192,1)',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                        pointHoverBorderColor: 'rgba(220,220,220,1)',
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: this.allCommandesValeurs,
                        spanGaps: false,
                    }
                ]
            }
        });
        this.lineChart = new Chart(this.lineCanvas2.nativeElement, {
            type: 'line',
            data: {
                labels: this.validatesDates,
                datasets: [
                    {
                        label: 'Validates Commandes',
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: 'rgb(96,192,75,1)',
                        borderColor: 'rgb(96,192,75,1)',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: 'rgb(96,192,75,1)',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: 'rgb(96,192,75,1)',
                        pointHoverBorderColor: 'rgba(220,220,220,1)',
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: this.validateCommandesValeurs,
                        spanGaps: false,
                    }
                ]
            }
        });
        this.lineChart = new Chart(this.lineCanvas3.nativeElement, {
            type: 'line',
            data: {
                labels: this.declinedDates,
                datasets: [
                    {
                        label: 'Denied Commandes',
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: 'rgb(192,75,75,1)',
                        borderColor: 'rgb(192,75,75,1)',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: 'rgb(192,75,98)',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: 'rgb(192,75,75,1)',
                        pointHoverBorderColor: 'rgba(220,220,220,1)',
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: this.declineCommandesValeurs,
                        spanGaps: false,
                    }
                ]
            }
        });
    }

    doughnutChartMethod(): void {
        this.doughnutChart = new Chart(this.doughnutCanvas.nativeElement, {
            type: 'doughnut',
            data: {
                labels: ['Commandes acceptées', 'Commandes refusées', 'Commandes livrées'],
                datasets: [{
                    label: '# of Votes',
                    data: [this.ordersValidatedCount, this.ordersCanceledCount, this.ordersDeliveredCount],
                    backgroundColor: [
                        'rgba(96,192,75, 0.2)',
                        'rgba(192,75,75, 0.2)',
                        'rgba(54, 162, 235, 0.2)'
                    ],
                    hoverBackgroundColor: [
                        'rgba(96,192,75, 0.8)',
                        'rgba(192,75,75, 0.8)',
                        '#36A2EB'
                    ]
                }]
            }
        });
    }

    onSubmit(): void {
        if (this.profilForm.get('image').value !== null) {
            this.uploadService.editStoreImage(this.uploadService.images['store-images'][0], this.store.uid);
            this.updateValues();
        } else {
            this.updateValues();
        }
    }

    updateValues(): void {
        this.store.name = this.profilForm.get('name').value;
        // this.store.storeAddress[0].address = this.storeAddress;
        this.store.description = this.profilForm.get('description').value;
        this.store.updatedAt = new Date();
        this.storeService.updateStoreWithSupabase(this.store).then(
            () => {
                this.toastTitle = 'Modification Faite';
                this.toastMessage = 'Modification du Store faite avec succès !';
                this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
            }
        ).catch(
            () => {
                this.toastTitle = 'Modification Echouée';
                this.toastMessage = 'Modification du Store échouée !';
                this.notifyService.showError(this.toastMessage, this.toastTitle);
            }
        );
    }

    onUpload(event): void {
        this.uploadService.uploadFiles(event, `groups/${this.idGroupe}/stores/${this.idStore}/${this.idStore}`, 'store-images');
        this.subscriptions.add(
            this.uploadService.uploadPercent$.subscribe(
                valeur => {
                    this.pourcentage = valeur;
                }
            )
        );
        this.refImage = this.uploadService.images['store-images'];
    }

    onChange(event): void {
        this.storeService.updateStoreStateWithSupabase(this.idStore, event.currentValue).then(
            () => {
                this.toastTitle = 'Success';
                this.toastMessage = 'Le Store a maintenant changer de disponibilité !';
                this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
            }
        ).catch(
            () => {
                this.toastTitle = 'Error';
                this.toastMessage = 'Le changement de disponibilité a échoué !';
                this.notifyService.showError(this.toastMessage, this.toastTitle);
            }
        );
    }

    resetEmail(): void {
        this.store.emailAddress = this.emailForm.get('newEmail').value;
        this.store.updatedAt = new Date();
        this.storeService.updateStoreWithSupabase(this.store).then(
            () => {
                this.toastTitle = 'Modification Faite';
                this.toastMessage = 'Modification du Mail du Store fait avec succès !';
                this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
                this.emailForm.reset();
                this.uploadService.dropImageList();
            }
        ).catch(
            () => {
                this.toastTitle = 'Echec';
                this.toastMessage = 'Echec de la modification du Mail Store';
                this.notifyService.showError(this.toastMessage, this.toastTitle);
            }
        );
    }

    deleteRating(rating: Rating): void {
        // this.storeService.deleteRating(rating, this.idGroupe, this.store.uid).then(
        //   () => {
        //     // TODO: Have to make CHANGES HERE !
        //
        //     // this.store.ratingCount = this.store.ratingCount - 1;
        //     // this.store.ratingTotal = this.store.ratingTotal - rating.rate;
        //     // this.storeService.updateStore(this.store).then(() => this.notifyService.showSuccess('Rating Updated !', 'Success'));
        //
        //     this.toastTitle = 'Success';
        //     this.toastMessage = `Suppression réussie !`;
        //     this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
        //   }
        // ).catch(
        //   () => {
        //     this.toastTitle = 'Echec';
        //     this.toastMessage = `Suppression échouée !`;
        //     this.notifyService.showError(this.toastMessage, this.toastTitle);
        //   }
        // );
    }

    handleAddressChange(address: gAddress): void {
        this.storeAddress = this.addressService.handleAddressChange(address);
    }

    toggleFieldTextType(): void {
        this.fieldTextType = !this.fieldTextType;
    }

    lockMarchand(store: Store): void {
        const singleGroupObj = new GroupSingleComponent(
            this.groupService,
            this.storeService,
            this.orderService,
            this.notifyService,
            this.route);
        singleGroupObj.lockStore(store);
    }

    unlockMarchand(store: Store): void {
        const singleGroupObj = new GroupSingleComponent(
            this.groupService,
            this.storeService,
            this.orderService,
            this.notifyService,
            this.route);
        singleGroupObj.restoreStore(store);
    }
}
