import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ProductTagService} from '../services/product-tag.service';
import {Subscription} from 'rxjs';
import {ProductTag} from '../models/product-tag.model';
import {NotificationsService} from '../services/notifications.service';

@Component({
  selector: 'app-product-tag',
  templateUrl: './product-tag.component.html',
  styleUrls: ['./product-tag.component.css']
})
export class ProductTagComponent implements OnInit, OnDestroy {

  @Input() productTags: ProductTag[];
  subscription: Subscription = new Subscription();

  constructor(private productTagServices: ProductTagService,
              private notifyService: NotificationsService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  removeProductTag(data: ProductTag): void {
    if (confirm('Etes-vous sure de vouloir supprimer ce Tag ?')){
      this.productTagServices.deleteProductTag(data.uid).then(
        () => {
          this.notifyService.showSuccess('Suppression du Tag fait avec succès !', 'Suppression Faite');
        }
      ).catch(
        () => {
          this.notifyService.showError(`Echec de la suppression du Tag`, 'Echec');
        }
      );
    }
  }

}
