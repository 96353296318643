<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-9">
    <h2>Produits</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/groups', uidGroup, 'stores', uidStore]">Store</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Produit</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-3">
    <div [ngSwitch]="this.state">
      <h4 *ngSwitchCase="true" class="text-navy"><strong>Le produit est disponible</strong></h4>
      <h4 *ngSwitchCase="false" class="text-danger"><strong>Le produit est indisponible</strong></h4>
    </div>
    <bSwitch  [ngModel]="state" [switch-size]="'small'"
             [switch-on-color]="'success'" [switch-off-color]="'danger'"
             [switch-off-text]="'Indisponible'" [switch-on-text]="'Disponible'"
             [switch-base-class]="'bootstrap-switch'" [switch-wrapper-class]="'bootstrap-switch'"
             (changeState)="onChange($event)">
    </bSwitch>
  </div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/groups', uidGroup, 'stores', uidStore]" class="btn btn-sm btn-primary"><i class="fa fa-chevron-left"></i> Back</a>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">

      <div class="ibox product-detail">
        <div class="ibox-content">

          <div class="row">
            <div class="col-md-5">
              <div class="product-images">
                <ngx-slick-carousel class="carousel"
                                    #slickModal="slick-carousel"
                                    [config]="slideConfig">
                  <div ngxSlickItem *ngFor="let image of product?.images" class="slide">
                    <img src="{{image}}" alt="" width="100%">
                  </div>
                </ngx-slick-carousel>
              </div>
            </div>
            <div class="col-md-7">

              <h2 class="font-bold m-b-xs">
                {{product?.name | titlecase}}
              </h2>
              <small>{{this?.product?.categoryProduct | titlecase}}</small>
              <span >
                <a [routerLink]="['/groups', uidGroup, 'stores', uidStore, 'products',product?.uid, 'edit']"
                   data-toggle="tooltip" data-placement="bottom" title="Modifier le produit">
                  <i class="fa fa-pencil ml-2 pr-1"></i></a>
              </span>
<!--              <div class="m-t-md" *ngIf="(product?.promotion === null) || product?.promotion !== null && product?.promotion?.valid === false">-->
<!--                <h2 class="product-main-price">-->
<!--                  {{product?.price | currency: 'USD'}}-->
<!--                  <small class="text-muted">In Stock: {{product?.stock}}</small> </h2>-->
<!--              </div>-->
<!--              <div class="m-t-md" *ngIf="product?.promotion !== null && product?.promotion?.valid === true">-->
<!--                <h2 class="product-main-price text-navy">-->
<!--                  {{product?.price - ((product.price * product?.promotion?.percent)/100) | currency: 'USD'}}-->
<!--                  <br><s class="small text-muted">-->
<!--                    {{product?.price | currency: 'USD'}}-->
<!--                  </s>-->
<!--                  <br><small class="text-muted">In Stock: {{product?.stock}}</small> </h2>-->
<!--              </div>-->
              <hr>

              <h4>Product description</h4>

              <div class="small text-muted">
                {{product?.description | titlecase}}
              </div>
              <br>
<!--              <div class="well">-->
<!--                <h3>Product Specs</h3>-->
<!--                <div *ngFor="let spec of product?.productSpecs | keyvalue">-->
<!--                  <span class="font-weight-bold"> {{spec.key}}</span> : {{spec.value}}-->
<!--                </div>-->
<!--              </div>-->
              <br>
<!--              <div class="well" *ngFor="let metada of product?.metaDatas">-->
<!--                <div *ngIf="metada.type == 'TEXT'">-->
<!--                  <h4>{{metada.name | titlecase}}</h4>-->
<!--                  <div class="inline" *ngFor="let metadaValue of metada?.metaDataValues">-->
<!--                    <span class="simple_tag font-weight-bold">{{metadaValue.value}}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div *ngIf="metada.type == 'SEAT'">-->
<!--                  <h4>{{metada.name | titlecase}}</h4>-->
<!--                  <div class="inline" *ngFor="let metadaValue of metada?.metaDataValues">-->
<!--                    <span class="simple_tag font-weight-bold">{{metadaValue.value}}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div *ngIf="metada.type == 'COLOR'">-->
<!--                  <h4>{{metada.name | titlecase}}</h4>-->
<!--                  <div class="inline" *ngFor="let metadaValue of metada?.metaDataValues">-->
<!--                    <span class="btn btn-circle m-1" style="background-color:{{metadaValue.value}}"></span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div *ngIf="metada.type == 'IMAGE_URL'">-->
<!--                  <h4>{{metada.name | titlecase}}</h4>-->
<!--                  <div class="inline img-thumbnail mx-1" *ngFor="let metadaValue of metada?.metaDataValues">-->
<!--                    <a [href]="metadaValue.value" target="_blank" ><img [src]="metadaValue.value" height="100px"></a>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <dl class="small m-t-md">
                <dt>Ready After</dt>
                <dd>{{product?.readyForShippingAfter | number}} min</dd>
              </dl>
              <h4>Promotion
                <span >
                  <a [routerLink]="['/groups', uidGroup, 'stores', uidStore, 'products',product?.uid, 'addPromotion']" *ngIf="product?.promotion === null"
                     data-toggle="tooltip" data-placement="bottom" title="Add promotion"
                     class="btn btn-primary btn-rounded btn-xs"><i class="fa fa-plus-circle"></i> Ajouter Promotion</a>
                  <a [routerLink]="['/groups', uidGroup, 'stores', uidStore, 'products',product?.uid, 'editPromotion']" *ngIf="product?.promotion !== null"
                     data-toggle="tooltip" data-placement="bottom" title="Modifier promotion"
                     class="btn btn-info btn-rounded btn-xs"><i class="fa fa-pencil"></i> Modifier Promotion</a>
                </span>
              </h4>
              <dl class="row m-t-md small" *ngIf="product?.promotion === null">
                <dt class="col-md-4 text-right">No Promotion</dt>
              </dl>
              <dl class="row m-t-md small" *ngIf="product?.promotion !== null">
                <dt class="col-md-4 text-right">From</dt>
                <dd class="col-md-8">{{product?.promotion?.startedAt.toDate() | date: 'dd-MMMM-yyyy, hh:mm a' || '-'}}</dd>
                <dt class="col-md-4 text-right">to</dt>
                <dd class="col-md-8">{{product?.promotion?.endedAt.toDate() | date: 'dd-MMMM-yyyy, hh:mm a' || '-'}}</dd>
                <dt class="col-md-4 text-right">Percent</dt>
                <dd class="col-md-8">{{product?.promotion?.percent | number}}</dd>
                <dt class="col-md-4 text-right">Status</dt>
                <dd [ngSwitch]="product?.promotion?.valid" class="col-md-8">
                  <span *ngSwitchCase="true" class="badge badge-primary">Active</span>
                  <span *ngSwitchCase="false" class="badge badge-danger">Disable</span>
                </dd>
              </dl>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Rating list</h5>
        </div>
        <div class="ibox-content table-responsive">
          <table class="table table-hover no-margins">
            <thead>
            <tr>
              <th>User</th>
              <th>Date</th>
              <th>Commentaire</th>
              <th>Value</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let rating of ratings">
              <td><img class="img-thumbnail" [src]="rating.userProfileUrl" alt="User_Profil" [width]="50" [height]="50">
                {{this.rating.userDisplayName}}
              </td>
              <td><i class="fa fa-clock-o"></i>
                {{this?.rating?.createdAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}
              </td>
              <td>{{rating.message || '-'}}</td>
              <td class="text-navy"><i class="fa fa-star"></i> {{rating.rating | number}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
