<div class="row">
  <div class="col-lg-12">
    <div class="ibox ">
      <div class="ibox-title">
        <h5>Liste des Produits du Store</h5>
        <div  class="ibox-tools">
          <a class="btn btn-sm btn-success text-white" [routerLink]="['/groups', idGroup, 'stores', idStore, 'products', 'add']">
            <span>Ajouter Produit</span>
          </a>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive" *ngIf="storeProducts$ | async as products">
          <app-product [products]="products"></app-product>
        </div>

      </div>
    </div>
  </div>

</div>
