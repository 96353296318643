import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {OrderService} from '../../../services/order.service';
import {Order} from '../../../models/order.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-orders-servies',
  templateUrl: './orders-servies.component.html',
  styleUrls: ['./orders-servies.component.css']
})
export class OrdersServiesComponent implements OnInit, OnDestroy {

  orders: Order[] = [];
  idStore: string;
  idGroup: string;
  subscriptions: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,
              private orderService: OrderService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.idStore = token.get('idStore');
          this.idGroup = token.get('idgroup');

          this.subscriptions.add(
            this.orderService.getServedOrders(this.idGroup, this.idStore).subscribe(
              orders => {
                if (orders !== null) {
                  this.orders = orders;
                }
              }
            )
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
