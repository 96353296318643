<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-8">
    <h2><strong>Promotion : {{this.promotion?.code}}</strong></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/promos']">Promotions</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Promotion</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-4 p-1 align-content-center"></div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/promos']" class="btn btn-sm btn-primary text-white"><i class="fa fa-chevron-left"></i> Back</a>
</div>
