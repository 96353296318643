import { Component, OnInit, Input } from '@angular/core';
import {Invoice} from '../../models/invoice.model';

@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html',
  styleUrls: ['./paiement.component.css']
})
export class PaiementComponent implements OnInit {

  @Input() paiements: Invoice[];
  @Input() uidGroup: string;
  toastTitle: string;
  toastMessage: string;
  constructor() { }

  ngOnInit(): void {
  }

}
