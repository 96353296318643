<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>Paiements</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/paiements']">Paiements</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Make paiement</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2">
  </div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/paiements']" class="btn btn-sm btn-primary text-white"><i class="fa fa-chevron-left"></i> Back</a>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Make <small> Invoice.</small></h5>
        </div>
        <div class="ibox-content">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-sm-4 offset-1 border-right p-2 px-5">
                <h2 class="no-margins">{{this?.store?.name | titlecase}}</h2>
                <small>Nom du Store</small>
              </div>
              <div class="col-sm-4 offset-1 border-right p-2 px-5">
                <h2 class="no-margins ">{{this?.store?.amountDu}} $</h2>
                <small>Montant Dû</small>
              </div>
            </div>
          </div>
          <hr>
          <br>

          <form (ngSubmit)="onSubmit()" [formGroup]="paiementForm">
            <div class="form-group row"><label class="col-sm-2 col-form-label">montant</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-money"></i></span>
                  </div>
                  <input type="number" placeholder="montant" class="form-control" id="montant" formControlName="montant"
                         required="required" [min]="0"
                         [ngClass]="{'is-invalid' : (paiementForm.get('montant').touched || paiementForm.get('montant').dirty) && !paiementForm.get('montant').valid}">
                  <div class="invalid-feedback">
                    <span *ngIf="paiementForm.get('montant').errors?.required">Category title is required !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label class="col-sm-2 col-form-label">Description</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-italic"></i></span>
                  </div>
                  <input type="text" placeholder="Description" class="form-control" minlength="10"
                         formControlName="description" id="description"
                         [ngClass]="{'is-invalid' : (paiementForm.get('description').touched || paiementForm.get('description').dirty) && !paiementForm.get('description').valid}">
                  <div class="invalid-feedback">
                    <span
                      *ngIf="paiementForm.get('description').errors?.minlength">Valid Explication message must be longer than 10 caracters !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label class="col-sm-2 col-form-label">Image Facture</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-image"></i></span>
                  </div>
                  <input type="file" accept="image/*" placeholder="Price" class="form-control" id="image"
                         formControlName="image" (change)="onUpload($event)"
                         [ngClass]="{'is-invalid' : (paiementForm.get('image').touched || paiementForm.get('image').dirty) && !paiementForm.get('image').valid}">
                  <div class="invalid-feedback">
                    <span *ngIf="paiementForm.get('image').errors?.required">Category image is required !</span>
                  </div>
                </div>
                <div class="progress">
                  <div class="progress-bar" [style.width.%]="pourcentage" role="progressbar"
                       [attr.aria-valuenow]="pourcentage" aria-valuemin="0" aria-valuemax="100">
                    <span class="text-center" *ngIf="pourcentage > 0">{{pourcentage | number}} %</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="hr-line-dashed"></div>

            <div class="form-group row">
              <div class="col-sm-4 col-sm-offset-2">
                <button class="btn btn-white btn-sm" type="submit">Cancel</button>
                <button  class="btn btn-primary btn-sm" type="submit"
                        [title]="paiementForm.valid ? 'Make a Invoice' : 'Disable until valid form data'"
                        [disabled]="!paiementForm.valid"> Payer
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
