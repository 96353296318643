<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>Bannières</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Bannières</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2">

  </div>
</div>

<div class="wrapper wrapper-content animated fadeInRight" *ngIf="banners$ | async as banners">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Liste des Bannière </h5>
          <div  class="ibox-tools">
            <a class="btn btn-sm btn-success text-white" [routerLink]="['/banners/add']">
              <span> Ajouter Bannière</span>
            </a>
          </div>
        </div>
        <div class="ibox-content">
          <div class="table-responsive">
            <app-banner [banners]="banners"></app-banner>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
