import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Group} from '../models/groups.model';
import {catchError, Observable, of, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {NotificationsService} from '../services/notifications.service';
import {GroupsService} from '../services/groups.service';
import {OrderService} from '../services/order.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsComponent implements OnInit, OnDestroy {

  groups: Group[] = [];
  subscription: Subscription = new Subscription();
  errorMessage = '';
  allCurrentOrders$ = this.orderService.allCurrentOrder$
    .pipe(
      catchError((error) => {
        this.errorMessage = error;
        return of(null);
      })
    );

  sGroups$: Observable<Group[]>;

  constructor(private groupService: GroupsService,
              private route: ActivatedRoute,
              private notifyService: NotificationsService,
              private orderService: OrderService) {
    void this.groupService.getGroupsWithSupabase();
    this.sGroups$ = groupService.sGroups$;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.allCurrentOrders$.subscribe(orders => orders.length > 0 ? this.notifyService.soundNotification() : false)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  deleteGroup(uidGroup: string): void {
    if (confirm('Etes-vous sure de vouloir supprimer ce Groupe ?')) {
      this.groupService.deleteGroupWithSupabase(uidGroup).then(
        () => {
          this.notifyService.showSuccess('Suppression du groupe fait avec succès !', 'Notification Faite');
        }, error => {
          this.notifyService.showError(`Echec de la suppression du groupe => ${error}`, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Suppression du groupe Annulée`, 'Echec');
    }
  }

}
