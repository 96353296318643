<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>Produits</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/groups', uidGroup, 'stores', uidStore]">Store</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Modifier Produit</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2"></div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/groups', uidGroup, 'stores', uidStore]" class="btn btn-sm btn-primary"><i class="fa fa-chevron-left"></i> Back</a>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Modifier Produit</h5>
        </div>
        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" [formGroup]="productForm">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group row"><label class="col-sm-2 col-form-label">Nom</label>
                  <div class="col-sm-10">
                    <div class="input-group m-b">
                      <div class="input-group-prepend">
                        <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                      </div>
                      <input type="text" placeholder="Nom" class="form-control" id="title" formControlName="title"
                             required="required" minlength="3"
                             [ngClass]="{'is-invalid' : (productForm.get('title').touched || productForm.get('title').dirty) && !productForm.get('title').valid}">
                      <div class="invalid-feedback">
                        <span *ngIf="productForm.get('title').errors?.required">Product Name is required !</span>
                        <span
                          *ngIf="productForm.get('title').errors?.minlength">Valid Product Name must be longer than 3 characters !</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row"><label class="col-sm-2 col-form-label">Description</label>
                  <div class="col-sm-10">
                    <div class="input-group m-b">
                      <div class="input-group-prepend">
                        <span class="input-group-addon"><i class="fa fa-italic"></i></span>
                      </div>
                      <input type="text" placeholder="Description" class="form-control" minlength="10"
                             formControlName="description" id="description"
                             [ngClass]="{'is-invalid' : (productForm.get('description').touched || productForm.get('description').dirty) && !productForm.get('description').valid}">
                      <div class="invalid-feedback">
                    <span
                      *ngIf="productForm.get('description').errors?.minlength">Valid Product description must be longer than 10 caracters !</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row"><label class="col-sm-2 col-form-label">Price</label>
                  <div class="col-sm-10">
                    <div class="input-group m-b">
                      <div class="input-group-prepend">
                        <span class="input-group-addon"><i class="fa fa-money"></i></span>
                      </div>
                      <input type="number" placeholder="Price" class="form-control" formControlName="price" id="price"
                             required="required" min="0"
                             [ngClass]="{'is-invalid' : (productForm.get('price').touched || productForm.get('price').dirty) && !productForm.get('price').valid}">
                      <div class="invalid-feedback">
                        <span *ngIf="productForm.get('price').errors?.required">Product price is required !</span>
                        <span
                          *ngIf="productForm.get('price').errors?.minlength">Valid Product price must be greater than 0.0 caracters !</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row"><label class="col-sm-2 col-form-label">Stock</label>
                  <div class="col-sm-10">
                    <div class="input-group m-b">
                      <div class="input-group-prepend">
                        <span class="input-group-addon"><i class="fa fa-sort-numeric-asc"></i></span>
                      </div>
                      <input type="number" placeholder="Stock" class="form-control"
                             formControlName="quantity" id="quantity" required="required" min="0"
                             [ngClass]="{'is-invalid' : (productForm.get('quantity').touched || productForm.get('quantity').dirty) && !productForm.get('quantity').valid}">
                      <div class="invalid-feedback">
                        <span *ngIf="productForm.get('quantity').errors?.required">Product stock is required !</span>
                        <span
                          *ngIf="productForm.get('quantity').errors?.minlength">Valid Product stock must be greater than 0.0 !</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row"><label class="col-sm-2 col-form-label">Category</label>
                  <div class="col-sm-10">
                    <div class="input-group m-b">
                      <div class="input-group-prepend">
                        <span class="input-group-addon"><i class="fa fa-random"></i></span>
                      </div>
                      <select class="form-control" name="categoryProduct" formControlName="categoryProduct"
                              id="categoryProduct" required="required"
                              [ngClass]="{'is-invalid' : (productForm.get('categoryProduct').touched || productForm.get('categoryProduct').dirty) && !productForm.get('categoryProduct').valid}">
                        <option [ngValue]="null" disabled>Select Category</option>
                        <option *ngFor="let category of categories" [value]="category.uid">{{category.name | titlecase}}</option>
                      </select>
                      <div class="invalid-feedback">
                        <span *ngIf="productForm.get('categoryProduct').errors?.required">Product Category price is required !</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row"><label class="col-sm-2 col-form-label">Product Type</label>
                  <div class="col-sm-10">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-addon"><i class="fa fa-microchip"></i></span>
                      </div>
                      <select class="form-control m-b" name="productType" formControlName="productType"
                              id="productType" required="required"
                              [ngClass]="{'is-invalid' : (productForm.get('productType').touched || productForm.get('productType').dirty) && !productForm.get('productType').valid}">
                        <option [ngValue]="null" disabled selected>Select Product Type</option>
                        <option *ngFor="let productType of productTypes" [value]="productType">{{productType | titlecase}}</option>
                      </select>
                      <div class="invalid-feedback">
                        <span *ngIf="productForm.get('productType').errors?.required">Product Type is required !</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row"><label class="col-sm-2 col-form-label">Priority</label>
                  <div class="col-sm-10">
                    <div class="input-group m-b">
                      <div class="input-group-prepend">
                        <span class="input-group-addon"><i class="fa fa-sort"></i></span>
                      </div>
                      <input type="number" placeholder="Priority" class="form-control"
                             formControlName="priority" id="priority" required="required" min="0"
                             [ngClass]="{'is-invalid' : (productForm.get('priority').touched || productForm.get('priority').dirty) && !productForm.get('priority').valid}">
                      <div class="invalid-feedback">
                        <span *ngIf="productForm.get('priority').errors?.required">Product priority is required !</span>
                        <span
                          *ngIf="productForm.get('priority').errors?.minlength">Valid Product priority must be greater or equalto 0 !</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row"><label class="col-sm-12 col-form-label">Ready For Shipping After (minute)</label>
                  <div class="col-sm-12">
                    <div class="input-group m-b">
                      <div class="input-group-prepend">
                        <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>
                      </div>
                      <input type="number" placeholder="Ready For Shipping After" class="form-control" formControlName="readyForShippingAfter" id="readyForShippingAfter"
                             required="required" min="0"
                             [ngClass]="{'is-invalid' : (productForm.get('readyForShippingAfter').touched || productForm.get('readyForShippingAfter').dirty) && !productForm.get('readyForShippingAfter').valid}">
                      <div class="invalid-feedback">
                        <span *ngIf="productForm.get('readyForShippingAfter').errors?.required">Product Cooking Time is required !</span>
                        <span
                          *ngIf="productForm.get('readyForShippingAfter').errors?.minlength">Valid Product Cooking Time must be greater than 0.0 caracters !</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row" formGroupName="shippingDetail">
                  <label class="col-sm-12 col-form-label">Product Shipping Price</label>
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-6 mb-2">
                        <label class="col-sm-12 col-form-label">Default Shipping Price</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-addon"><i class="fa fa-dollar"></i></span>
                          </div>
                          <input type="number" placeholder="Default Shipping Price" class="form-control" formControlName="defaultShippingPrice"
                                 id="defaultShippingPrice" min="0">
                        </div>
                      </div>
                      <div class="col-sm-6 mb-2">
                        <label class="col-sm-12 col-form-label">Express Shipping Price</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-addon"><i class="fa fa-dollar"></i></span>
                          </div>
                          <input type="number" placeholder="Express Shipping Price" class="form-control"
                                 formControlName="expressShippingPrice" id="expressShippingPrice" min="0">
                        </div>
                      </div>
                      <div class="col-sm-8 mb-2">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-addon"><i class="fa fa-italic"></i></span>
                          </div>
                          <input type="text" placeholder="Express Shipping Detail" class="form-control" formControlName="expressShippingDetail"
                                 id="expressShippingDetail" minlength="10">
                        </div>
                      </div>
                      <div class="col-sm-4 mt-2">
                        <div><label>
                          <input type="checkbox" placeholder="Free Shipping" class="i-checks"
                                 formControlName="freeShipping" id="freeShipping"> Free Shipping</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="col-md-4 offset-4">
                  <div class="product-images">
                    <ngx-slick-carousel class="carousel"
                                        #slickModal="slick-carousel"
                                        [config]="slideConfig">
                      <div ngxSlickItem *ngFor="let image of product?.images" class="slide">
                        <img src="{{image}}" alt="" width="100%">
                      </div>
                    </ngx-slick-carousel>
                  </div>
                </div>
                <div class="form-group row"><label class="col-sm-12 col-form-label">Image</label>
                  <div class="col-sm-12">
                    <div class="flex-row">
                      <img *ngFor="let image of this.uploadService.images['product-images']"
                           class="rounded mx-1 my-1" src="{{image}}" alt="Uploaded_image" height="50">
                    </div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-addon"><i class="fa fa-image"></i></span>
                      </div>
                      <input type="file" accept="image/*" placeholder="Image" class="form-control" id="image"
                             formControlName="image" multiple (change)="onUpload($event)"
                             [ngClass]="{'is-invalid' : (productForm.get('image').touched || productForm.get('image').dirty) && !productForm.get('image').valid}">
                    </div>
                    <div class="progress">
                      <div class="progress-bar" [style.width.%]="pourcentage" role="progressbar"
                           [attr.aria-valuenow]="pourcentage" aria-valuemin="0" aria-valuemax="100">
                        <span class="text-center" *ngIf="pourcentage > 0">{{pourcentage | number}} %</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row" formGroupName="productDimension">
                  <label class="col-sm-12 col-form-label">Product Dimension</label>
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-4 mb-2">
                        <label class="col-sm-12 col-form-label">Weight</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-addon"><i class="fa fa-anchor"></i></span>
                          </div>
                          <input type="number" placeholder="Weight" class="form-control" formControlName="weight"
                                 id="weight" min="0">
                        </div>
                      </div>
                      <div class="col-sm-4 mb-2">
                        <label class="col-sm-12 col-form-label">Length</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-addon"><i class="fa fa-arrows-h"></i></span>
                          </div>
                          <input type="number" placeholder="Length" class="form-control" formControlName="length"
                                 id="length" min="0">
                        </div>
                      </div>
                      <div class="col-sm-4 mb-2">
                        <label class="col-sm-12 col-form-label">Width</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-addon"><i class="fa fa-text-width"></i></span>
                          </div>
                          <input type="number" placeholder="Width" class="form-control" formControlName="width"
                                 id="width" min="0">
                        </div>
                      </div>
                      <div class="col-sm-4 mb-2">
                        <label class="col-sm-12 col-form-label">Height</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-addon"><i class="fa fa-arrows-v"></i></span>
                          </div>
                          <input type="number" placeholder="Height" class="form-control" formControlName="height"
                                 id="height" min="0">
                        </div>
                      </div>
                      <div class="col-sm-4 mb-2 mt-5">
                        <div><label>
                          <input type="checkbox" placeholder="FragileBox" class="i-checks"
                                 formControlName="fragileBox" id="fragileBox"> Fragile Box</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row"><label class="col-sm-12 col-form-label">Tags</label>
                  <div class="col-sm-12">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-addon"><i class="fa fa-tags"></i></span>
                      </div>
                      <select class="form-control" name="productTag" formControlName="productTag"
                              id="productTag" multiple=""
                              [ngClass]="{'is-invalid' : (productForm.get('productTag').touched || productForm.get('productTag').dirty) && !productForm.get('productTag').valid}">
                        <option [ngValue]="null" disabled>Select Tag</option>
                        <option *ngFor="let productTag of productTags" [value]="productTag.uid" (click)="addTag(productTag.uid)">
                          {{productTag.title | titlecase}}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <!--                    <span *ngIf="productForm.get('productTag').errors?.required">Product Tag is required !</span>-->
                      </div>
                    </div>
                    <div class="tag-list pb-2">
                      <span class="tag-item bg-primary text-white px-1 py-1 mr-1 my-1 rounded" *ngFor="let tag of selectedTagsName">
                        {{tag}} <i class="fa fa-remove" (click)="deleteTag(tag)"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <span class="col-sm-2 input-group-addon"><i class="fa fa-spinner"></i></span>
                  <label class="col-sm-6 col-form-label">Specs</label>
                  <span class="col-sm-4 align-content-md-center">
                    <button type="button" (click)="addProductSpec()" class="btn btn-xs btn-primary">Add Spec</button></span>
                  <div class="col-sm-12">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <table class="table table-borderless" formArrayName="productSpecs">
                          <tr *ngFor="let productSpec of productSpecsArray().controls; let i=index" [formGroupName]="i">
                            <td>
                              Key :<input type="text" formControlName="key" class="form-control">
                            </td>
                            <td>
                              Value:<input type="text" formControlName="valeur" class="form-control">
                            </td>
                            <td>
                              <button (click)="removeProductSpecs(i)" class="btn btn-xs btn-danger">Remove</button>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <span class="col-sm-2 input-group-addon"><i class="fa fa-list"></i></span>
                  <label class="col-sm-6 col-form-label">MetaDatas</label>
                  <span class="col-sm-4 align-content-md-center">
                    <button type="button" (click)="addMetaData()" class="btn btn-xs btn-primary">Add MetaData</button></span>
                  <div class="col-sm-12">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="row" formArrayName="metaDatas">
                          <div class="col-sm-12"
                               *ngFor="let metaData of metaDatasArray().controls; let metadaIndex=index">
                            <div [formGroupName]="metadaIndex">
                              <table>
                                <tr>
                                  <td>
                                    Name :<input type="text" formControlName="name"
                                                 class="form-control" minlength="2">
                                  </td>
                                  <td>
                                    Description:<input type="text" formControlName="description"
                                                       class="form-control" minlength="10">
                                  </td>
                                  <td>
                                    Type:<select type="text" formControlName="type" class="form-control"
                                                 (change)="selectMetaDataValueType($event, metadaIndex)">
                                    <option [ngValue]="null" disabled selected>Select Type</option>
                                    <option *ngFor="let metaDataValueType of metaDataValueTypes"
                                            [value]="metaDataValueType">
                                      {{metaDataValueType}}</option>
                                  </select>
                                  </td>
                                  <td>
                                    <button type="button" (click)="addMetaDataValue(metadaIndex)"
                                            class="btn btn-xs btn-outline-success">Add MetaDataValue</button>
                                  </td>
                                  <td>
                                    <button (click)="removeMetaData(metadaIndex)" class="btn btn-xs btn-danger">Remove</button>
                                  </td>
                                </tr>
                              </table>
                              <div class="col-sm-12 mt-2">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <table class="table table-borderless" formArrayName="metaDataValues">
                                      <tr *ngFor="let metaDataValue of metaDaValuesArray(metadaIndex).controls; let j=index"
                                          [formGroupName]="j" class="border-bottom">
                                        <td *ngIf="selectedMetaDataValueType[metadaIndex] === 'TEXT'">
                                          Value :<input type="text" formControlName="value" class="form-control">
                                        </td>
                                        <td *ngIf="selectedMetaDataValueType[metadaIndex] === 'SEAT'">
                                          Value :<input type="text" formControlName="value" class="form-control">
                                        </td>
                                        <td *ngIf="selectedMetaDataValueType[metadaIndex] === 'COLOR'">
                                          Value :<input type="color" formControlName="value" class="form-control">
                                        </td>
                                        <td *ngIf="selectedMetaDataValueType[metadaIndex] === 'IMAGE_URL'">
                                          Value :<input type="file" accept="image/*"
                                                        formControlName="value" class="form-control"
                                                        (change)="uploadMetaDataValueImg($event, metadaIndex, j, 'metaDataValue-images-'+ j)">
                                          <div class="flex-row">
                                            <img class="rounded mx-1 my-1" src="{{product?.metaDatas[metadaIndex]?.metaDataValues[j]?.value ||
                                            'noimage.png'}}"
                                                 alt="MetaDataValue-image" height="50">
                                          </div>
                                          <div class="progress">
                                            <div class="progress-bar" [style.width.%]="metaDataValueImgPourcentage" role="progressbar"
                                                 [attr.aria-valuenow]="metaDataValueImgPourcentage" aria-valuemin="0" aria-valuemax="100">
                                              <span class="text-center" *ngIf="metaDataValueImgPourcentage > 0">{{metaDataValueImgPourcentage | number}} %</span>
                                            </div>
                                          </div>
                                          <div class="offset-6" *ngIf="metaDataValueImgupload">
                                            <div class="lds-ring" *ngIf="metaDataValueImgPourcentage < 100">
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                            </div>
                                          </div>
                                          <div class="flex-row">
                                            <img *ngFor="let image of this.uploadService.images['metaDataValue-images-'+j]"
                                                 class="rounded mx-1 my-1" src="{{image}}" alt="Uploaded_image" height="50">
                                          </div>
                                        </td>
                                        <td>
                                          Extra-Price :<input type="number" formControlName="extraPrice"
                                                              class="form-control" min="0">
                                        </td>
                                        <td *ngIf="selectedMetaDataValueType[metadaIndex] !== 'IMAGE_URL'">
                                          Quantity :<input type="number" formControlName="quantity"
                                                           class="form-control" min="0">
                                        </td>
                                        <td>
                                          Quantity :<input type="number" formControlName="quantity"
                                                           class="form-control" min="0">
                                        </td>
                                        <td *ngIf="selectedMetaDataValueType[metadaIndex] !== 'IMAGE_URL'">
                                          Available:<input type="checkbox" formControlName="available" class="form-control">
                                        </td>
                                        <td>
                                          <button (click)="removeMetaDataValue(metadaIndex,j)" class="btn btn-xs btn-danger">Remove</button>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="hr-line-dashed"></div>

            <div class="form-group row">
              <div class="col-sm-4 col-sm-offset-2">
                <a class="btn btn-white btn-sm" [routerLink]="['/groups', uidGroup, 'stores', uidStore]">Cancel</a>
                <button class="btn btn-primary btn-sm" type="submit"
                        [title]="productForm.valid ? 'Modify Produit' : 'Disable until valid form data'"
                        [disabled]="!productForm.valid">Modifier
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
