<div class="ibox ">
  <div class="ibox-title">
    <h5>{{idGroup | titlecase}}: Liste des Paiements </h5>
    <div class="ibox-tools">
      <h3>
        <span> Total: <strong>{{amountDu}}</strong></span>
      </h3>
    </div>
  </div>
  <div class="ibox-content">
    <div class="table-responsive">
      <app-paiement [paiements]="paiements" [uidGroup]="idGroup"></app-paiement>
    </div>
  </div>
</div>
