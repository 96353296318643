import {Component, Input, OnInit} from '@angular/core';
import {Category} from '../../../../models/category.model';
import {NotificationsService} from '../../../../services/notifications.service';
import {CategoryProductService} from '../../../../services/category-product.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.css']
})
export class ProductCategoryComponent implements OnInit {

  @Input() categories: Category[];
  toastTitle: string;
  toastMessage: string;
  uidStore: string;
  uidGroup: string;
  subscriptions: Subscription = new Subscription();

  constructor(private categoryService: CategoryProductService,
              private notifyService: NotificationsService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          if (token !== null) {
            this.uidStore = token.get('idStore');
            this.uidGroup = token.get('idgroup');
          }
        }
      )
    );
  }

  deleteCategory(category): void {
    if (confirm('Voulez-vous supprimer ce produit ?')) {
      this.categoryService.deleteCategoryProduct(this.uidGroup, this.uidStore, category).then(
        () => {
          this.toastTitle = 'Success';
          this.toastMessage = 'Supression réussie !';
          this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
        }
      ).catch(
        () => {
          this.toastTitle = 'Echec';
          this.toastMessage = 'Suppression échouée !';
          this.notifyService.showError(this.toastMessage, this.toastTitle);
        }
      );
    } else {
      this.toastTitle = 'Echec';
      this.toastMessage = 'Suppression Annulée !';
      this.notifyService.showInfo(this.toastMessage, this.toastTitle);
    }
  }

}
