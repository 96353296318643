import { Component, OnInit } from '@angular/core';
import {Notification} from '../models/notification.model';

@Component({
  selector: 'app-notification-single',
  templateUrl: './notification-single.component.html',
  styleUrls: ['./notification-single.component.css']
})
export class NotificationSingleComponent implements OnInit {

  notification: Notification;
  constructor() { }

  ngOnInit(): void {
  }

}
