import { Component, OnInit } from '@angular/core';
import {CategoryStoreService} from '../../services/category-store.service';
import {Category} from '../../models/category.model';
import {catchError, Observable, of, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  uidGroup: string;
  subscriptions: Subscription = new Subscription();
  errorMessage = '';
  storeCategories$: Observable<Category[]> = this.categoryStoreService.storeCategories$
    .pipe(
      catchError((error) => {
        this.errorMessage = error;
        return of(null);
      })
    );
  constructor(private categoryStoreService: CategoryStoreService,
              private route: ActivatedRoute) {
    void this.categoryStoreService.getCategoriesStoreWithSupabase();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        idGroup => {
          this.uidGroup = idGroup.get('idgroup');
        }
      )
    );
  }

}
