import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionManagerService} from './permission-manager.service';
import {PermissionType} from '../../models/permission-type.enum';

@Directive({
  selector: '[appIsGranted]'
})
export class IsGrantedDirective {

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private permissionManagerService: PermissionManagerService) { }

  @Input() set appIsGranted(permission: PermissionType){
    this.isGranted(permission);
  }

  private isGranted(permission: PermissionType): void {
    if (this.permissionManagerService.isGranted(permission)){
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
