import {Component, OnDestroy} from '@angular/core';
import {Deliver} from '../models/deliver.model';
import {DeliverService} from '../services/deliver.service';
import {catchError, of, Subscription} from 'rxjs';

@Component({
  selector: 'app-delivers',
  templateUrl: './delivers.component.html',
  styleUrls: ['./delivers.component.css']
})
export class DeliversComponent implements OnDestroy {
  delivers: Deliver[] = [];
  subscriptions: Subscription = new Subscription();
  errorMessage = '';
  delivers$ = this.deliverService.delivers$
    .pipe(
      catchError((error) => {
        this.errorMessage = error;
        return of(null);
      })
    );

  constructor(private deliverService: DeliverService) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
