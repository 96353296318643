import {Injectable} from '@angular/core';
import {Order} from '../models/order.model';
import {FirestoreService} from './firestore.service';
import {catchError, Observable, shareReplay, combineLatest, BehaviorSubject} from 'rxjs';
import {Deliver} from '../models/deliver.model';
import {UserService} from './user.service';
import {map} from 'rxjs/operators';
import {User} from '../models/user.model';
import {supabase} from './supabase';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  handleError: any = '';
  orders$: Observable<Order[]> = this.firestoreService.col$<Order>('orders/')
    .pipe(
      shareReplay(1),
      catchError(this.handleError)
    );
  ordersWithUsers = combineLatest(
    this.orders$,
    this.userServices.users$
  ).pipe(
    map(([orders, users]) => {
      return orders.map((order: Order) => ({
        ...order,
        user: users.find((user: User) => user.uid === order.uidUser)
      } as Order));
    })
  );
  allCurrentOrder$: Observable<Order[]> = this.firestoreService.col$<Order>('orders/',
      query => query
        .where('validatedAt', '==', null)
        .where('declinedAt', '==', null)
        .orderBy('orderedAt', 'desc')
  ).pipe(
    shareReplay(1),
    catchError(this.handleError)
  );
  // allValidatedOrders$: Observable<Order[]> = this.firestoreService.col$('orders/',
  //     query => query
  //       .where('validatedAt', '!=', null)
  //       .where('giveToDeliverAt', '==', null)
  //       .where('declinedAt', '==', null)
  //       .orderBy('validatedAt', 'desc')
  // ).pipe(
  //   shareReplay(1),
  //   catchError(this.handleError)
  // );
  // allDeclinedOrders$: Observable<Order[]> = this.firestoreService.col$('orders/',
  //     query => query
  //       .where('validatedAt', '==', null)
  //       .where('declinedAt', '!=', null)
  //       .orderBy('declinedAt', 'desc')
  // ).pipe(
  //   shareReplay(1),
  //   catchError(this.handleError)
  // );

  private ORDER_TABLE = 'Order';
  private state = new BehaviorSubject<any>({orders: []});
  sOrders$: Observable<Order[]> = this.state
    .asObservable()
    .pipe(map((state) => state.stores));

  private orderSelectedAction = new BehaviorSubject<any>({order: null});
  selectedOrder$: Observable<Order> = this.orderSelectedAction
    .asObservable().pipe((state) => state);

  private storeOrderSelectedAction = new BehaviorSubject<any>({orders: []});
  selectedStoreOrder$: Observable<Order[]> = this.storeOrderSelectedAction
    .asObservable().pipe(map((state) => state.orders));


  constructor(private firestoreService: FirestoreService,
              private userServices: UserService) {
  }

  getServedOrders(uidGroupe: string, uidStore: string): Observable<Order[]> {
    return this.firestoreService.col$(`groups/${uidGroupe}/stores/${uidStore}/orders`,
      query => query
        .where('deliveredAt', '!=', null)
        .orderBy('deliveredAt', 'desc'));
  }

  // getOrderCommands(uidGroupe: string, uidStore: string): Observable<Order[]> {
  //   return this.firestoreService.getOrderEqual$(`groups/${uidGroupe}/stores/${uidStore}/orders`, 'orderedAt');
  // }

  getPreviousOrders(uidGroupe: string, uidStore: string): Observable<Order[]> {
    return this.firestoreService.col$(`groups/${uidGroupe}/stores/${uidStore}/orders`,
      query => query
        .where('validatedAt', '!=', null)
        .where('giveToDeliverAt', '==', null)
        .orderBy('validatedAt', 'desc'));
  }

  getCurrentOrders(uidGroupe: string, uidStore: string): Observable<Order[]> {
    return this.firestoreService.col$(`groups/${uidGroupe}/stores/${uidStore}/orders`,
      query => query
        .where('validatedAt', '==', null)
        .where('declinedAt', '==', null));
  }

  getAllValidatedOrders(): Observable<Order[]> {
    return this.firestoreService.col$(`orders`,
      query => query
        .where('validatedAt', '!=', null)
        .where('giveToDeliverAt', '==', null)
        .where('declinedAt', '==', null)
        .orderBy('validatedAt', 'desc'));
  }

  getGroupAllCurrentOrders(uidGroupe: string): Observable<Order[]> {
    return this.firestoreService.col$(`orders`,
      query => query
        .where('uidGroup', '==', uidGroupe)
        .where('validatedAt', '==', null)
        .where('declinedAt', '==', null)
        .orderBy('orderedAt', 'desc'));
  }

  getAllCurrentOrders(): Observable<Order[]> {
    return this.firestoreService.col$(`orders`,
      query => query
        .where('validatedAt', '==', null)
        .where('declinedAt', '==', null)
        .orderBy('orderedAt', 'desc'));
  }

  getDeclinedOrders(uidGroupe: string, uidStore: string): Observable<Order[]> {
    return this.firestoreService.col$(`groups/${uidGroupe}/stores/${uidStore}/orders`,
      query => query
        .where('validatedAt', '==', null)
        .where('declinedAt', '!=', null)
        .orderBy('declinedAt', 'desc'));
  }

  getOrder(uidOrder: string, uidStore: string, uidGroupe: string): Observable<Order> {
    return this.firestoreService.doc$(`groups/${uidGroupe}/stores/${uidStore}/orders/${uidOrder}`);
  }

  getOrderDeliver(uidDeliver: string, uidOrder: string, uidStore: string, uidGroupe: string): Observable<Deliver> {
    return this.firestoreService.doc$(`groups/${uidGroupe}/stores/${uidStore}/orders/${uidOrder}/delivers/${uidDeliver}`);
  }

  deleteOrder(order: Order, userUid: string, uidGroupe: string): Promise<any> {
    this.firestoreService.delete(`stores/${order.uidStore}/orders/${order.uid}`)
      .then(() => console.log('order deleted'));
    this.firestoreService.delete(`orders/${order.uid}`)
      .then(() => console.log('order deleted'));
    this.firestoreService.delete(`groups/${uidGroupe}/stores/${order.uidStore}/orders/${order.uid}`)
      .then(() => console.log('order deleted'));
    return this.firestoreService.delete(`users/${userUid}/orders/${order.uid}`);
  }

  getDeliverOrderFromStore(order: Order): Observable<Order[]> {
    return this.firestoreService.getEqual$(`delivers/${order.uidDeliver}/orders`, 'uidStore', order.uidStore);
  }

  deleteDeliverOrder(order: Order, deliverId): Promise<any> {
    return this.firestoreService.delete(`delivers/${deliverId}/orders/${order.uid}`);
  }

  updateOrder(order: Order, uidGroupe: string): Promise<any> {
    this.firestoreService.update(
      `stores/${order.uidStore}/orders/${order.uid}`, order
    ).then();
    this.firestoreService.update(
      `orders/${order.uid}`, order
    ).then();
    return this.firestoreService.update(
      `groups/${uidGroupe}/stores/${order.uidStore}/orders/${order.uid}`, order
    );
  }

  /**
   * SUPABASE
   */

  async getOrdersWithSupabase(): Promise<void> {
    const {data} = await supabase
      .from(this.ORDER_TABLE)
      .select('*')
      .is('deletedAt', null)
      .order('createdAt', {ascending: true});

    this.state.next({
      orders:
        data?.map((order: Order) => (order)) ?? [],
    });
  }

  async getStoreOrdersWithSupabase(uidStore: string): Promise<void> {
    const {data} = await supabase
      .from(this.ORDER_TABLE)
      .select('*')
      .is('deletedAt', null)
      .eq('uidStore', uidStore);

    this.storeOrderSelectedAction.next({
      orders:
        data?.map((order: Order) => (order)) ?? [],
    });
  }

  async getOrderWithSupabase(uidOrder: string): Promise<void> {
    const {data} = await supabase
      .from(this.ORDER_TABLE)
      .select(`*`)
      .eq('uid', uidOrder).single();

    this.orderSelectedAction.next(data ?? null);
  }
}
