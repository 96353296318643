<nav class="navbar-default navbar-static-side" role="navigation">
  <div class="sidebar-collapse">
    <ul class="nav metismenu" id="side-menu">
      <li class="nav-header">
        <div class="dropdown profile-element text-center">
          <img class="rounded-circle shadow" [src]="admin?.photoURL || 'logo_blanc.png'" alt="super_admin_img" [width]="80" [height]="80"/>
          <a data-toggle="dropdown" class="dropdown-toggle" href="#">
            <span class="block m-t-xs font-bold">Be Served Admin</span>
            <span class="text-muted text-xs block">{{this?.admin?.email}} <b
              class="caret"></b></span>
          </a>
          <ul class="dropdown-menu animated fadeInRight m-t-xs">
            <li><a class="dropdown-item">Profile</a></li>
          </ul>
        </div>
        <div class="logo-element">
          BeS
        </div>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['/dashboard']"><i class="fa fa-th-large"></i> <span class="nav-label">Dashboard</span></a>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['groups']"><i class="fa fa-cubes"></i> <span class="nav-label">Groups</span></a>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['/delivers']"><i class="fa fa-bar-chart-o"></i> <span class="nav-label">Delivers</span></a>
      </li>
      <li  routerLinkActive="active">
        <a [routerLink]="['/admins']"><i class="fa fa-user-secret"></i> <span class="nav-label">Admin</span></a>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['/users']"><i class="fa fa-users"></i> <span class="nav-label">Utilisateurs</span></a>
      </li>
      <li  routerLinkActive="active">
        <a [routerLink]="['/paymentMethods']"><i class="fa fa-money"></i> <span class="nav-label">Payment Methods</span></a>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['/notifications']"><i class="fa fa-bell"></i> <span class="nav-label">Notifications</span></a>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['/productTags']"><i class="fa fa-tags"></i> <span class="nav-label">Product Tags</span></a>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['/banners']"><i class="fa fa-flag-checkered"></i> <span class="nav-label">Bannières</span></a>
      </li>
    </ul>

  </div>
</nav>
