import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';


type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocumentPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private angularFirestore: AngularFirestore) {
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.angularFirestore.collection<T>(ref, queryFn) : ref;
  }

  doc<T>(ref: DocumentPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.angularFirestore.doc<T>(ref) : ref;
  }

  doc$<T>(ref: DocumentPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      return doc.payload.data() as T;
    }));
  }

  colId$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(map(docs => {
      return docs.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return {id, ...data};
      });
    }));
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(map(docs => {
      return docs.map(a => {
        const data = a.payload.doc.data();
        return {...data};
      });
    }));
  }

  colLimitId$<T>(ref: CollectionPredicate<T>, limit: number): Observable<T[]> {
    return this.col(ref, query => query.limit(limit)).snapshotChanges().pipe(map(docs => {
      return docs.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return {id, ...data};
      });
    }));
  }

  set<T>(ref: DocumentPredicate<T>, data: any): Promise<any> {
    return this.doc(ref).set({
      ...data
    });
  }

  getEqual$<T>(ref: CollectionPredicate<T>, champ: string, valeur: any): Observable<T[]> {
    return this.col(ref, query => query.where(champ, '==', valeur)).snapshotChanges().pipe(map(docs => {
      return docs.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return {id, ...data};
      });
    }));
  }

  // getSign$<T>(ref: CollectionPredicate<T>, champ: string, sign: string, valeur: any): Observable<T[]> {
  //   return this.col(ref, query => query.where(champ, sign, valeur)).snapshotChanges().pipe(map(docs => {
  //     return docs.map(a => {
  //       const data = a.payload.doc.data();
  //       const id = a.payload.doc.id;
  //       return {id, ...data};
  //     });
  //   }));
  // }

  // getOrderEqual$<T>(ref: CollectionPredicate<T>, champ: string): Observable<T[]> {
  //   return this.col(ref, query => query.orderBy(champ)).snapshotChanges().pipe(map(docs => {
  //     return docs.map(a => {
  //       const data = a.payload.doc.data();
  //       // @ts-ignore
  //       if (data?.availableAt != null) {
  //         // @ts-ignore
  //         const availableAt = new Date(data?.availableAt.seconds * 1000);
  //         // @ts-ignore
  //         // tslint:disable-next-line:max-line-length
  //         data?.availableAt = availableAt.getDate().toString() + '-' + (availableAt.getMonth() + 1).toString() + '-' + availableAt.getFullYear().toString();
  //       }
  //       // @ts-ignore
  //       if (data?.deliveredAt != null) {
  //         // @ts-ignore
  //         const deliveredAt = new Date(data?.deliveredAt.seconds * 1000);
  //         // @ts-ignore
  //         // tslint:disable-next-line:max-line-length
  //         data?.deliveredAt = deliveredAt.getDate().toString() + '-' + (deliveredAt.getMonth() + 1).toString() + '-' + deliveredAt.getFullYear().toString();
  //       }
  //       // @ts-ignore
  //       if (data?.validatedAt != null) {
  //         // @ts-ignore
  //         const validatedAt = new Date(data?.validatedAt.seconds * 1000);
  //         // @ts-ignore
  //         // tslint:disable-next-line:max-line-length
  //         data?.validatedAt = validatedAt.getDate().toString() + '-' + (validatedAt.getMonth() + 1).toString() + '-' + validatedAt.getFullYear().toString();
  //       }
  //       // @ts-ignore
  //       if (data?.declinedAt != null) {
  //         // @ts-ignore
  //         const declinedAt = new Date(data?.declinedAt.seconds * 1000);
  //         // @ts-ignore
  //         // tslint:disable-next-line:max-line-length
  //         data?.declinedAt = declinedAt.getDate().toString() + '-' + (declinedAt.getMonth() + 1).toString() + '-' + declinedAt.getFullYear().toString();
  //       }
  //       const id = a.payload.doc.id;
  //       return {id, ...data};
  //     });
  //   }));
  // }

  getValidateEqual$<T>(ref: CollectionPredicate<T>, champ: string, valeur: any): Observable<T[]> {
    return this.col(
      ref, query => query.where(champ, '==', valeur)
        .where('validate', '==', true).orderBy('orderedAt', 'desc')
    ).snapshotChanges().pipe(map(docs => {
      return docs.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return {id, ...data};
      });
    }));
  }

  getNotDeclineEqual$<T>(ref: CollectionPredicate<T>, champ: string, valeur: any): Observable<T[]> {
    return this.col(
      ref, query => query.where(champ, '==', valeur)
        .where('decline', '==', false)
    ).snapshotChanges().pipe(map(docs => {
      return docs.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return {id, ...data};
      });
    }));
  }

  getDeclineEqual$<T>(ref: CollectionPredicate<T>, champ: string, valeur: any): Observable<T[]> {
    return this.col(
      ref, query => query.where(champ, '==', valeur)
        .where('decline', '==', true)
    ).snapshotChanges().pipe(map(docs => {
      return docs.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return {id, ...data};
      });
    }));
  }

  getEqualLimit$<T>(ref: CollectionPredicate<T>, champ: string, valeur: any, limit: number): Observable<T[]> {
    return this.col(ref, query => query.where(champ, '==', valeur).limit(limit)).snapshotChanges().pipe(map(docs => {
      return docs.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return {id, ...data};
      });
    }));
  }

  update<T>(ref: DocumentPredicate<T>, data: any): Promise<any> {
    return this.doc(ref).update({
      ...data
    });
  }

  update2<T>(ref: DocumentPredicate<T>, data: any): Promise<any> {
    return this.doc(ref).update(data);
  }

  delete<T>(ref: DocumentPredicate<T>): Promise<any> {
    return this.doc(ref).delete();
  }
}
