import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NotificationsService} from '../../services/notifications.service';
import {UploadService} from '../../services/upload.service';
import {ActivatedRoute} from '@angular/router';
import {StoreService} from '../../services/store.service';
import {Store} from '../../models/store.model';
import {Invoice} from '../../models/invoice.model';
import uid from 'uid';
import {InvoiceService} from '../../services/invoice.service';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {User} from '../../models/user.model';

@Component({
  selector: 'app-make-paiement',
  templateUrl: './make-paiement.component.html',
  styleUrls: ['./make-paiement.component.css']
})
export class MakePaiementComponent implements OnInit, OnDestroy {

  paiementForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  uidStore: string;
  idGroup: string;
  store: Store;
  pourcentage = 0;
  uid;
  amountDuStore: number;
  first = 0;
  second = 0;
  subscriptions: Subscription = new Subscription();
  admin: User;

  constructor(private notifyService: NotificationsService,
              private storeService: StoreService,
              private paiementService: InvoiceService,
              private uploadService: UploadService,
              private route: ActivatedRoute,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.add(
      this.authService.user$.subscribe(
        admin => {
          this.admin = admin;
        }
      )
    );
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidStore = token.get('id');
          this.idGroup = token.get('idgroup');

          this.storeService.getStore(this.idGroup, this.uidStore);
          this.subscriptions.add(
            this.storeService.currentStore$.subscribe(
              store => {
                if (this.first < 1 || store !== null) {
                  this.store = store;
                  this.amountDuStore = store?.amountDu;
                  this.first++;
                }
              }
            )
          );
        }
      )
    );

    this.uid = uid(32);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.paiementForm = new UntypedFormGroup({
      montant: new UntypedFormControl(),
      description: new UntypedFormControl(),
      image: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    if (this.paiementForm.get('image').value !== null) {
      this.makePayement(this.uidStore, this.uploadService.images[0]);
    } else {
      this.makePayement(this.uidStore, null);
    }
  }


  makePayement(uidStore, image): void {
    // TODO: MAKE CHANGE HERE
    // const paiement: Invoice = {
    //   uid: this.uid,
    //   description: this.paiementForm.get('description').value,
    //   invoiceImageUrl: image,
    //   uidStore,
    //   uidAdmin: this.admin.uid,
    //   amountDu: this.amountDuStore,
    //   amountReceived: this.paiementForm.get('montant').value,
    //   createdAt: new Date()
    // };
    //
    // this.paiementService.createPaiement(this.idGroup, paiement, this.uidStore).then(
    //   () => {
    //     this.toastTitle = 'Success';
    //     this.toastMessage = 'Invoice fait avec succès !';
    //     this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
    //     this.paiementForm.reset();
    //     this.uploadService.dropImageList();
    //
    //     this.storeService.getStore(this.idGroup, this.uidStore);
    //     this.subscriptions.add(
    //       this.storeService.currentStore$.subscribe(
    //         store => {
    //           if (this.second < 1) {
    //             this.store.amountDu = store.amountDu - this.amountDuStore;
    //             this.store.amountReceived += paiement.amountReceived;
    //
    //             this.storeService.updateStore(this.store).then(
    //               () => {
    //                 this.toastTitle = 'Modification Faite';
    //                 this.toastMessage = 'Information du Store Modifier avec succès !';
    //                 this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
    //               }
    //             ).catch(
    //               () => {
    //                 this.toastTitle = 'Modification Echouée';
    //                 this.toastMessage = 'Modification de l\'Information du Store échouée !';
    //                 this.notifyService.showError(this.toastMessage, this.toastTitle);
    //               }
    //             );
    //             this.second++;
    //           }
    //         }
    //       )
    //     );
    //   }
    // ).catch(
    //   err => {
    //     this.toastTitle = 'Echec';
    //     this.toastMessage = 'Invoice échoué ! =>' + err;
    //     this.notifyService.showError(this.toastMessage, this.toastTitle);
    //   }
    // );
  }

  onUpload(event): void {
    this.uploadService.uploadFiles(event, `groups/${this.idGroup}/stores/${this.uidStore}/payments/${this.uid}`, 'payment-images');
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }
}
