import {Component, OnDestroy, OnInit} from '@angular/core';
import {Product} from '../../../models/product.model';
import {Rating} from '../../../models/rating.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ProductService} from '../../../services/product.service';
import {StoreService} from '../../../services/store.service';
import {NotificationsService} from '../../../services/notifications.service';

@Component({
  selector: 'app-product-single',
  templateUrl: './product-single.component.html',
  styleUrls: ['./product-single.component.css']
})
export class ProductSingleComponent implements OnInit, OnDestroy {

  idProduct: string;
  uidStore: string;
  uidGroup: string;
  product: Product;
  ratings: Rating[];
  toastTitle: string;
  toastMessage: string;
  state: boolean;
  subscriptions: Subscription = new Subscription();
  slideConfig = {slidesToShow: 1, slidesToScroll: 1};

  constructor(private route: ActivatedRoute,
              private productService: ProductService,
              private storeService: StoreService,
              private notifyService: NotificationsService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidStore = token.get('idStore');
          this.uidGroup = token.get('idgroup');
          this.idProduct = token.get('id');

          this.subscriptions.add(
            this.productService.getProduct(this.uidGroup, this.uidStore, this.idProduct).subscribe(
              product => {
                if (product !== null) {
                  this.product = product;
                  this.state = product.available;
                }
              }
            )
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onChange(event): void {
    this.productService.updateProductState(this.uidGroup, this.uidStore, this.idProduct, event.currentValue).then(
      () => {
        this.toastTitle = 'Success';
        this.toastMessage = 'Votre Produit a maintenant changer de disponibilité !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {
        this.toastTitle = 'Error';
        this.toastMessage = 'Le changement de disponibilité a échoué !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

}
