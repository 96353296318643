<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
        <h2>Promotion</h2>
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a [routerLink]="['/dashboard']">Dashboard</a>
            </li>
            <li class="breadcrumb-item">
                <a [routerLink]="['/groups', idGroup, 'stores', idStore]">Promotions</a>
            </li>
            <li class="breadcrumb-item active">
                <strong>Ajout Promotion</strong>
            </li>
        </ol>
    </div>
    <div class="col-lg-2"></div>
</div>
<div class="row mt-1">
    <a [routerLink]="['/groups', idGroup, 'stores', idStore]" class="btn btn-sm btn-primary text-white"><i
            class="fa fa-chevron-left"></i> Back</a>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox ">
                <div class="ibox-title">
                    <h5>Ajouter <small>Promotion.</small></h5>
                </div>
                <div class="ibox-content">
                    <form (ngSubmit)="onSubmit()" [formGroup]="promotionForm">
                        <div class="form-group row"><label class="col-sm-2 col-form-label">Code</label>
                            <div class="col-sm-10">
                                <div class="input-group m-b">
                                    <div class="input-group-prepend">
                                        <span class="input-group-addon"><i class="fa fa-code"></i></span>
                                    </div>
                                    <input type="text" placeholder="Code" class="form-control" id="code"
                                           formControlName="code"
                                           required="required" minlength="6"
                                           [ngClass]="{'is-invalid' : (promotionForm.get('code').touched || promotionForm.get('code').dirty) && !promotionForm.get('code').valid}">
                                    <div class="invalid-feedback">
                                        <span *ngIf="promotionForm.get('code').errors?.required">Code-Promo is required !</span>
                                        <span
                                                *ngIf="promotionForm.get('code').errors?.minlength">Valid Code-Promo must be longer than 6 caracters !</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row"><label class="col-sm-2 col-form-label">Debut</label>
                            <div class="col-sm-10">
                                <div class="input-group m-b">
                                    <div class="input-group-prepend">
                                        <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                                    </div>
                                    <input type="datetime-local" placeholder="Started At" class="form-control"
                                           id="startedAt"
                                           formControlName="startedAt" required="required"
                                           [ngClass]="{'is-invalid' : (promotionForm.get('startedAt').touched || promotionForm.get('startedAt').dirty) && !promotionForm.get('startedAt').valid}">
                                    <div class="invalid-feedback">
                                        <span *ngIf="promotionForm.get('startedAt').errors?.required">Start Datetime is required !</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row"><label class="col-sm-2 col-form-label">Fin</label>
                            <div class="col-sm-10">
                                <div class="input-group m-b">
                                    <div class="input-group-prepend">
                                        <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                                    </div>
                                    <input type="datetime-local" placeholder="Ended At" class="form-control"
                                           id="endedAt"
                                           formControlName="endedAt" required="required"
                                           [ngClass]="{'is-invalid' : (promotionForm.get('endedAt').touched || promotionForm.get('endedAt').dirty) && !promotionForm.get('endedAt').valid}">
                                    <div class="invalid-feedback">
                                        <span *ngIf="promotionForm.get('endedAt').errors?.required">End Datetime is required !</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row"><label class="col-sm-2 col-form-label">Percent</label>
                            <div class="col-sm-10">
                                <div class="input-group m-b">
                                    <div class="input-group-prepend">
                                        <span class="input-group-addon"><i class="fa fa-percent"></i></span>
                                    </div>
                                    <input type="number" placeholder="Percent" class="form-control"
                                           formControlName="percent" id="percent" required="required" min="0"
                                           [ngClass]="{'is-invalid' : (promotionForm.get('percent').touched || promotionForm.get('percent').dirty) && !promotionForm.get('percent').valid}">
                                    <div class="invalid-feedback">
                                        <span *ngIf="promotionForm.get('percent').errors?.required">Promotion percent is required !</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row"><label for="recurrent" class="col-sm-2 col-form-label">Recurrent
                            ?</label>
                            <div class="col-sm-2">
                                <div class="input-group m-b">
                                    <div class="input-group-prepend">
                                        <span class="input-group-addon"><i class="fa fa-check"></i></span>
                                    </div>
                                    <input type="checkbox" placeholder="Email" class="form-control"
                                           formControlName="recurrent" id="recurrent"
                                           [ngClass]="{'is-invalid' : (promotionForm.get('recurrent').touched || promotionForm.get('recurrent').dirty) && !promotionForm.get('recurrent').valid}">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row"><label class="col-sm-2 col-form-label">Products</label>
                            <div class="col-sm-10">
                                <div class="input-group m-b">
                                    <div class="input-group-prepend">
                                        <span class="input-group-addon"><i class="fa fa-random"></i></span>
                                    </div>
                                    <select class="form-control m-b" name="products" formControlName="products"
                                            id="products" multiple
                                            [ngClass]="{'is-invalid' : (promotionForm.get('products').touched || promotionForm.get('products').dirty) && !promotionForm.get('products').valid}">
                                        <option [ngValue]="null" disabled>Products</option>
                                        <option *ngFor="let product of products" (click)="addProductUid(product.uid)"
                                                [value]="product.uid">{{product.name | titlecase}}</option>
                                    </select>
                                </div>
                              <div class="tag-list pb-2">
                                <span class="tag-item bg-primary text-white px-1 py-1 mr-1 my-1 rounded" *ngFor="let prod of selectedProductsUid">
                                  {{prod}} <i class="fa fa-remove" (click)="deleteProductUid(prod)"></i>
                                </span>
                              </div>
                            </div>
                        </div>
                        <div class="hr-line-dashed"></div>

                        <div class="form-group row">
                            <div class="col-sm-4 col-sm-offset-2">
                                <a [routerLink]="['/groups', idGroup, 'stores', idStore]" class="btn btn-white btn-sm"
                                   type="submit">Retour</a>
                                <button class="btn btn-primary btn-sm" type="submit"
                                        [title]="promotionForm.valid ? 'Ajouter Promotion' : 'Disable until valid form data'"
                                        [disabled]="!promotionForm.valid">Ajouter
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
