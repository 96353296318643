import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationsService} from '../services/notifications.service';
import {ProductTagService} from '../services/product-tag.service';
import {Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import uid from 'uid';

@Component({
  selector: 'app-add-product-tag',
  templateUrl: './add-product-tag.component.html',
  styleUrls: ['./add-product-tag.component.css']
})
export class AddProductTagComponent implements OnInit, OnDestroy {

  productTagForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  uid: string;
  subscriptions: Subscription = new Subscription();

  constructor(private notifyService: NotificationsService,
              private productTagServices: ProductTagService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.uid = uid(32);
    this.initForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.productTagForm = new UntypedFormGroup({
      title: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    this.productTagServices.addProductTag({
      uid: uid(32),
      uidTag: this.uid,
      tag: {
        uid: this.uid,
        title: this.productTagForm.get('title').value,
        description: this.productTagForm.get('description').value,
        createdAt: new Date(),
        updatedAt: null,
        deletedAt: null
      },
      uidProduct: ''
    }).then(
      () => {
        this.productTagForm.reset();
        this.router.navigate(['productTags']).then(
          () => {
            this.toastTitle = 'Ajout Fait';
            this.toastMessage = 'Ajout du Tag fait avec succès !';
            this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
          }
        );
      }
    ).catch(
      () => {
        this.toastTitle = 'Echec de l\'ajout';
        this.toastMessage = 'Echec de l\'ajout du Tag !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }
}
