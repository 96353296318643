<table datatable class="table table-striped table-bordered table-hover dataTables-example" *ngIf="users?.length != 0">
  <thead>
  <tr>
    <th>Image </th>
    <th>Nom </th>
    <th>Adresse</th>
    <th>Phone</th>
    <th>Email</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let user of users" class="w-100">
    <td><img src="{{user?.imageUrl || 'https://ssl.gstatic.com/accounts/ui/avatar_2x.png'}}" class="img-thumbnail" alt="user_image" style="max-height: 50px;"></td>
    <td><span class="pie">{{user?.name}}</span></td>
    <td>{{user?.address[0]?.street}}, {{user?.address[0]?.city}}, {{user?.address[0]?.country}} </td><!--    todo: restore this place at the end-->
    <td>{{user?.phoneNumber || '-'}}</td>
    <td>{{user?.emailAddress}}</td>
    <td>
      <a [routerLink]="['/users',user?.uid]" class="btn btn-outline-success font-weight-bold mr-2 mb-1"><i class="fa fa-eye pr-1"></i> Voir</a>
     <span >
       <a [routerLink]="['/users',user?.uid, 'edit']"><i class="fa fa-pencil pr-1"></i></a>
      <a (click)="lockUser(user)" *ngIf="user?.deletedAt === null" class="btn btn-outline-warning font-weight-bold mr-2">
        <i class="fa fa-lock"></i> Bloquer</a>
      <a (click)="restoreUser(user)" *ngIf="user?.deletedAt !== null" class="btn btn-outline-dark font-weight-bold mr-2">
        <i class="fa fa-refresh"></i> Restorer</a>
     </span>
      <a  (click)="removeUser(user)"><i class="fa fa-trash text-danger"></i></a>
    </td>
  </tr>
  </tbody>
</table>
<table datatable class="table table-striped table-bordered table-hover dataTables-example" *ngIf="users?.length == 0">
  <thead>
  <tr>
    <th>Image </th>
    <th>Nom </th>
    <th>Adresse</th>
    <th>Phone</th>
    <th>Email</th>
    <th>Action</th>
  </tr>
  </thead>
</table>
