import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AppSecondComponent} from './app-second.component';
import {StoreaddComponent} from './groups/stores/storeadd.component';
import {StoreeditComponent} from './groups/stores/storeedit.component';
import {DeliversComponent} from './delivers/delivers.component';
import {DeliveraddComponent} from './delivers/deliveradd.component';
import {DelivereditComponent} from './delivers/deliveredit.component';
import {LoginComponent} from './login/login.component';
import {StoreSingleComponent} from './groups/stores/store-single.component';
import {DeliverSingleComponent} from './delivers/deliver-single.component';
import {CategoryAddComponent} from './groups/categories/category-add.component';
import {CategoryEditComponent} from './groups/categories/category-edit.component';
import {PaiementsComponent} from './groups/paiements/paiements.component';
import {redirectUnauthorizedTo, redirectLoggedInTo} from '@angular/fire/auth-guard';
import {MakePaiementComponent} from './groups/paiements/make-paiement.component';
import {AgentsComponent} from './groups/agents/agents.component';
import {AgentAddComponent} from './groups/agents/agent-add.component';
import {AgentEditComponent} from './groups/agents/agent-edit.component';
import {AdminsComponent} from './admins/admins.component';
import {AdminAddComponent} from './admins/admin-add.component';
import {AdminEditComponent} from './admins/admin-edit.component';
import {OrderSingleComponent} from './groups/stores/orders/order-single.component';
import {GroupsComponent} from './groups/groups.component';
import {GroupSingleComponent} from './groups/group-single.component';
import {GroupAddComponent} from './groups/group-add.component';
import {GroupEditComponent} from './groups/group-edit.component';
import {OrdersComponent} from './groups/stores/orders/orders.component';
import {RefundComponent} from './groups/stores/orders/refund/refund.component';
import {UsersComponent} from './users/users.component';
import {UserSingleComponent} from './users/user-single.component';
import {PromoaddComponent} from './groups/stores/promos/promoadd.component';
import {PromoSingleComponent} from './groups/stores/promos/promo-single.component';
import {PromoeditComponent} from './groups/stores/promos/promoedit.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {NotificationaddComponent} from './notifications/notificationadd.component';
import {NotificationeditComponent} from './notifications/notificationedit.component';
import {NotificationSingleComponent} from './notifications/notification-single.component';
import {PaymentMethodsComponent} from './payment-methods/payment-methods.component';
import {AddPaymentMethodComponent} from './payment-methods/add-payment-method.component';
import {EditPaymentMethodComponent} from './payment-methods/edit-payment-method.component';
import {ProductaddComponent} from './groups/stores/products/productadd.component';
import {ProductSingleComponent} from './groups/stores/products/product-single.component';
import {ProducteditComponent} from './groups/stores/products/productedit.component';
import {AddPromotionComponent} from './groups/stores/products/add-promotion.component';
import {EditPromotionComponent} from './groups/stores/products/edit-promotion.component';
import {ProductCategoriesComponent} from './groups/stores/products/categories/product-categories.component';
import {ProductCategoryaddComponent} from './groups/stores/products/categories/product-categoryadd.component';
import {ProductCategorySingleComponent} from './groups/stores/products/categories/product-category-single.component';
import {ProductCategoryeditComponent} from './groups/stores/products/categories/product-categoryedit.component';
import {ProductTagsComponent} from './product-tags/product-tags.component';
import {AddProductTagComponent} from './product-tags/add-product-tag.component';
import {EditProductTagComponent} from './product-tags/edit-product-tag.component';
import {AngularFireAuthGuard} from '@angular/fire/compat/auth-guard';
import {BannersComponent} from './banners/banners.component';
import {BannerAddComponent} from './banners/banner-add.component';
import {BannerEditComponent} from './banners/banner-edit.component';
import {BannerSingleComponent} from './banners/banner-single.component';
import {AddProductTypeComponent} from './groups/product-types/addProductType.component';

// const adminOnly = () => hasCustomClaim('coo');
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['dashboard']);

const routes: Routes = [
  {
    path: '',
    component: AppSecondComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin
    },
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'admins',
        children: [
          {
            path: '',
            component: AdminsComponent
          },
          {
            path: 'add',
            component: AdminAddComponent
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                component: AdminsComponent
              },
              {
                path: 'edit',
                component: AdminEditComponent
              }
            ]
          }
        ]
      },
      {
        path: 'banners',
        children: [
          {
            path: '',
            component: BannersComponent
          },
          {
            path: 'add',
            component: BannerAddComponent
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                component: BannerSingleComponent
              },
              {
                path: 'edit',
                component: BannerEditComponent
              }
            ]
          }
        ]
      },
      {
        path: 'paymentMethods',
        children: [
          {
            path: '',
            component: PaymentMethodsComponent
          },
          {
            path: 'add',
            component: AddPaymentMethodComponent
          },
          {
            path: ':id',
            children: [
              {
                path: 'edit',
                component: EditPaymentMethodComponent
              }
            ]
          }
        ]
      },
      {
        path: 'groups',
        children: [
          {
            path: '',
            component: GroupsComponent
          },
          {
            path: 'add',
            component: GroupAddComponent
          },
          {
            path: ':idgroup',
            children: [
              {
                path: '',
                component: GroupSingleComponent
              },
              {
                path: 'edit',
                component: GroupEditComponent
              },
              {
                path: 'stores',
                children: [
                  {
                    path: 'add',
                    component: StoreaddComponent
                  },
                  {
                    path: ':idStore',
                    children: [
                      {
                        path: '',
                        component: StoreSingleComponent
                      },
                      {
                        path: 'edit',
                        component: StoreeditComponent
                      },
                      {
                        path: ':idOrder',
                        children: [
                          {
                            path: '',
                            component: OrderSingleComponent
                          },
                          // {
                          //   path: 'decline',
                          //   component: DeclinedorderComponent  todo: make decline
                          // }
                        ]
                      },
                      {
                        path: 'products',
                        children: [
                          {
                            path: 'add',
                            component: ProductaddComponent
                          },
                          {
                            path: ':id',
                            children: [
                              {
                                path: '',
                                component: ProductSingleComponent
                              },
                              {
                                path: 'edit',
                                component: ProducteditComponent
                              },
                              {
                                path: 'addPromotion',
                                component: AddPromotionComponent
                              },
                              {
                                path: 'editPromotion',
                                component: EditPromotionComponent
                              }
                            ]
                          }
                        ]
                      },
                      {
                        path: 'categories',
                        children: [
                          {
                            path: '',
                            component: ProductCategoriesComponent
                          },
                          {
                            path: 'add',
                            component: ProductCategoryaddComponent
                          },
                          {
                            path: ':id',
                            children: [
                              {
                                path: '',
                                component: ProductCategorySingleComponent
                              },
                              {
                                path: 'edit',
                                component: ProductCategoryeditComponent
                              }
                            ]
                          }
                        ]
                      },
                      {
                        path: 'promos',
                        children: [
                          {
                            path: 'add',
                            component: PromoaddComponent
                          },
                          {
                            path: ':id',
                            children: [
                              {
                                path: '',
                                component: PromoSingleComponent
                              },
                              {
                                path: 'edit',
                                component: PromoeditComponent
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                path: 'categories',
                children: [
                  {
                    path: 'add',
                    component: CategoryAddComponent
                  },
                  {
                    path: ':idCategory',
                    children: [
                      {
                        path: '',
                        component: GroupSingleComponent
                      },
                      {
                        path: 'edit',
                        component: CategoryEditComponent
                      }
                    ]
                  }
                ]
              },
              {
                path: 'product-types',
                children: [
                  {
                    path: 'add',
                    component: AddProductTypeComponent
                  }
                ]
              },
              {
                path: 'paiements',
                children: [
                  {
                    path: '',
                    component: PaiementsComponent
                  },
                  {
                    path: ':id',
                    children: [
                      {
                        path: '',
                        component: MakePaiementComponent
                      },
                      {
                        path: 'make',
                        component: MakePaiementComponent
                      }
                    ]
                  }
                ]
              },
              {
                path: 'agents',
                children: [
                  {
                    path: 'add',
                    component: AgentAddComponent
                  },
                  {
                    path: ':idStore',
                    children: [
                      {
                        path: '',
                        component: AgentsComponent
                      },
                      {
                        path: ':id',
                        children: [
                          {
                            path: '',
                            component: AgentsComponent
                          },
                          {
                            path: 'edit',
                            component: AgentEditComponent
                          }
                        ]
                      }
                    ]
                  },
                ]
              }
            ]
          },
        ]
      },
      {
        path: 'orders',
        children: [
          {
            path: '',
            component: OrdersComponent
          },
          {
            path: ':idStore',
            children: [
              {
                path: '',
                component: OrdersComponent
              },
              {
                path: ':id',
                children: [
                  {
                    path: '',
                    component: OrderSingleComponent
                  },
                  {
                    path: 'refund',
                    component: RefundComponent
                  }
                ]
              }
            ]
          },
        ]
      },
      {
        path: 'delivers',
        children: [
          {
            path: '',
            component: DeliversComponent
          },
          {
            path: 'add',
            component: DeliveraddComponent
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                component: DeliverSingleComponent
              },
              {
                path: 'edit',
                component: DelivereditComponent
              }
            ]
          }
        ]
      },
      {
        path: 'productTags',
        children: [
          {
            path: '',
            component: ProductTagsComponent
          },
          {
            path: 'add',
            component: AddProductTagComponent
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                component: ProductTagsComponent
              },
              {
                path: 'edit',
                component: EditProductTagComponent
              }
            ]
          }
        ]
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            component: UsersComponent
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                component: UserSingleComponent
              }
            ]
          }
        ]
      },
      {
        path: 'notifications',
        children: [
          {
            path: '',
            component: NotificationsComponent
          },
          {
            path: 'add',
            component: NotificationaddComponent
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                component: NotificationSingleComponent
              },
              {
                path: 'edit',
                component: NotificationeditComponent
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectLoggedInToDashboard
    }
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
