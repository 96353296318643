import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {PromoService} from '../../../services/promo.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NotificationsService} from '../../../services/notifications.service';
import {ProductService} from '../../../services/product.service';
import {Product} from '../../../models/product.model';
import {PromoCode} from '../../../models/promo-code.model';

@Component({
    selector: 'app-promoedit',
    templateUrl: './promoedit.component.html',
    styleUrls: ['./promoedit.component.css']
})
export class PromoeditComponent implements OnInit, OnDestroy {

    promotionForm: UntypedFormGroup;
    toastTitle: string;
    toastMessage: string;
    idStore: string;
    idGroup: string;
    idPromo: string;
    promotion: PromoCode;
    subscriptions: Subscription = new Subscription();
    products: Product[];

    constructor(private route: ActivatedRoute,
                private promoServices: PromoService,
                private productService: ProductService,
                private router: Router,
                private notifyService: NotificationsService) {
    }

    ngOnInit(): void {
        this.initForm();
        this.subscriptions.add(
            this.route.paramMap.subscribe(
                token => {
                    if (token !== null) {
                        this.idStore = token.get('idStore');
                        this.idGroup = token.get('idgroup');

                        this.subscriptions.add(
                            this.route.paramMap.subscribe(
                                idPromo => {
                                    this.idPromo = idPromo.get('id');

                                    void this.productService.getStoreProductsWithSupabase(this.idStore);
                                    this.subscriptions.add(
                                        this.productService.storeProducts$.subscribe(
                                            products => {
                                                if (products !== null) {
                                                    this.products = products;
                                                }
                                            }
                                        )
                                    );

                                    void this.promoServices.getPromoCodeWithSupabase(this.idPromo);
                                    this.subscriptions.add(
                                        this.promoServices.selectedPromoCode$.subscribe(
                                            promotion => {
                                                if (promotion !== null) {
                                                    this.promotion = promotion;

                                                    this.promotionForm.patchValue({
                                                        code: promotion.code,
                                                        startedAt: promotion.startedAt,
                                                        endedAt: promotion.endedAt,
                                                        recurrent: promotion.recurrent,
                                                        percent: promotion.percent
                                                    });
                                                }
                                            }
                                        )
                                    );
                                }
                            )
                        );
                    }
                }
            )
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    initForm(): void {
        this.promotionForm = new UntypedFormGroup({
            code: new UntypedFormControl(),
            startedAt: new UntypedFormControl(),
            endedAt: new UntypedFormControl(),
            recurrent: new UntypedFormControl(),
            percent: new UntypedFormControl(),
            products: new UntypedFormControl()
        });
    }

    onSubmit(): void {
        this.promotion.code = this.promotionForm.get('code').value.toUpperCase();
        this.promotion.startedAt = this.promotionForm.get('startedAt').value;
        this.promotion.endedAt = this.promotionForm.get('endedAt').value;
        this.promotion.recurrent = this.promotionForm.get('recurrent').value;
        this.promotion.percent = this.promotionForm.get('percent').value;

        this.promoServices.updatePromoCodeWithSupabase(this.promotion).then(
            () => {
                this.router.navigate(['/groups', this.idGroup, 'stores', this.idStore]).then(
                    () => {
                        this.toastTitle = 'Modification Faite';
                        this.toastMessage = `Modification de la promotion fait avec succès !`;
                        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
                    }
                );
            }
        ).catch(
            (error) => {
                console.log(error);
                this.toastTitle = 'Echec de la modification';
                this.toastMessage = `Echec de modification de la promotion !`;
                this.notifyService.showError(this.toastMessage, this.toastTitle);
            }
        );
    }

}
