import {Component, OnDestroy} from '@angular/core';
import {catchError, Subscription, of} from 'rxjs';
import {BannersService} from '../services/banners.service';
import {ActivatedRoute} from '@angular/router';
import {NotificationsService} from '../services/notifications.service';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.css']
})
export class BannersComponent implements OnDestroy {

  subscription: Subscription = new Subscription();
  errorMessage = '';
  banners$ = this.bannerService.banners$
    .pipe(
      catchError((error) => {
        this.errorMessage = error;
        return of(null);
      })
    );
  constructor(private bannerService: BannersService) { }



  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
