import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(value: string): Date {
    const date = new Date();
    let month: any = date.getMonth() + 1;
    if (month < 10){
      month = '0' + month;
    }else{
      month = month;
    }

    return new Date(date.getFullYear() + '-' + month + '-' + date.getDate() + 'T' + value + ':00Z');
  }

}
