import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from '../models/user.model';
import {Subscription} from 'rxjs';
import {NotificationsService} from '../services/notifications.service';
import {UserService} from '../services/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy {

  @Input() users: User[];
  subscription: Subscription = new Subscription();

  constructor(private userService: UserService,
              private notifyService: NotificationsService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  lockUser(data: User): void {
    if (confirm('Etes-vous sure de vouloir Bloquer cet Utilisateur ?')) {
      this.userService.deleteUser(data).then(
        () => {
          this.notifyService.showSuccess('L\'utilisateur a été bloqué avec succès !', 'Blocage Fait');
        }
      ).catch(
        () => {
          this.notifyService.showError(`L\'utilisateur n'a pas été bloqué `, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Bloquage de l\'utilisateur Annulé`, 'Echec');
    }
  }

  restoreUser(data: User): void {
    if (confirm('Etes-vous sure de vouloir Supprimer cet Utilisateur ?')) {
      this.userService.restoreUser(data).then(
        () => {
          this.notifyService.showSuccess('L\'utilisateur a été Restoré avec succès !', 'Success');
        }
      ).catch(
        () => {
          this.notifyService.showError(`L\'utilisateur n'a pas été Restoré `, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Restoration de l\'utilisateur Annulé`, 'Echec');
    }
  }

  removeUser(data: User): void {
    data.deletedAt = new Date();
    if (confirm('Etes-vous sure de vouloir supprimer cet Utilisateur ?')) {
      this.userService.deleteAUser(data).then(
        () => {
          this.notifyService.showSuccess('Suppression de l\'utilisateur fait avec succès !', 'Suppression Faite');
        }
      ).catch(
        () => {
          this.notifyService.showError(`Echec de la suppression de l\'utilisateur`, 'Echec');
        }
      );
    }
  }

}
