import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NotificationsService} from '../../services/notifications.service';
import {UploadService} from '../../services/upload.service';
import {CategoryStoreService} from '../../services/category-store.service';
import {ActivatedRoute} from '@angular/router';
import {Category} from '../../models/category.model';
import {catchError, Observable, of, shareReplay, Subscription} from 'rxjs';

@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.css']
})
export class CategoryEditComponent implements OnInit, OnDestroy {

  categorieForm: UntypedFormGroup;
  category: Category;
  uidGroup: string;
  uidCategory: string;
  toastTitle: string;
  toastMessage: string;
  uidStore: string;
  pourcentage = 0;
  subscriptions: Subscription = new Subscription();
  upload = false;

  errorMessage = '';
  selectedCategory$: Observable<Category> = this.categoryStoreService.selectedCategory$
    .pipe(
      shareReplay(1),
      catchError((error) => {
        console.log('**** ERRROR=>', error);
        this.errorMessage = error;
        return of(null);
      })
    );

  constructor(private notifyService: NotificationsService,
              private uploadService: UploadService,
              private categoryStoreService: CategoryStoreService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidCategory = token.get('idCategory');
          this.uidGroup = token.get('idgroup');

          void this.categoryStoreService.getCategoryStoreWithSupabase(this.uidCategory)
            .then(() => {
              this.subscriptions.add(
                this.selectedCategory$.subscribe(
                  category => {
                    if (category !== undefined) {
                      this.category = category;
                      this.categorieForm.patchValue({
                        title: category.name,
                        description: category.description
                      });
                    }
                  }
                )
              );
            });


        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.categorieForm = new UntypedFormGroup({
      title: new UntypedFormControl(),
      description: new UntypedFormControl(),
      image: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    if (this.categorieForm.get('image').value !== null) {
      this.category.image = this.uploadService.images['category-images'][0];
      this.updateValues();
    } else {
      this.updateValues();
    }
  }

  updateValues(): void {
    this.category.name = this.categorieForm.get('title').value;
    this.category.description = this.categorieForm.get('description').value;
    this.category.updatedAt = new Date();

    this.categoryStoreService.updateCategoryWithSupabase(this.category)
      .then(() => {
          this.toastTitle = 'Modification Faite';
          this.toastMessage = 'Modification de la category faite avec success !';
          this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
        }
      ).catch(
      () => {
        this.toastTitle = 'Modification Echouée';
        this.toastMessage = 'Modification de la category échouée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      });
  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `supabase/groups/${this.uidGroup}/category-stores/${this.category.uid}/${this.category.uid}`, 'category-images');
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

}
