<table datatable class="table table-striped table-bordered table-hover dataTables-example" *ngIf="productTags?.length != 0">
  <thead>
  <tr>
    <th># </th>
    <th>Nom </th>
    <th>description</th>
    <th>Created At</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let productTag of productTags" class="w-100">
    <td><i class="fa fa-tag"></i></td>
    <td><span class="pie">{{productTag?.title}}</span></td>
    <td>{{productTag.description}}</td>
    <td>{{productTag?.createdAt?.toDate() | date: 'dd-MM-yyyy, hh:mm a'}}</td>
    <td>
      <span >
          <a [routerLink]="['/productTags',productTag?.uid, 'edit']"><i class="fa fa-pencil pr-1"></i></a>
      </span>
      <a  (click)="removeProductTag(productTag)"><i class="fa fa-trash text-danger"></i></a>
    </td>
  </tr>
  </tbody>
</table>
<table datatable class="table table-striped table-bordered table-hover dataTables-example" *ngIf="productTags?.length == 0">
  <thead>
  <tr>
    <th># </th>
    <th>Nom </th>
    <th>description</th>
    <th>Created At</th>
    <th>Action</th>
  </tr>
  </thead>
</table>
