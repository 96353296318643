import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProductTag} from '../models/product-tag.model';
import {catchError, of, Subscription} from 'rxjs';
import {ProductTagService} from '../services/product-tag.service';

@Component({
  selector: 'app-product-tags',
  templateUrl: './product-tags.component.html',
  styleUrls: ['./product-tags.component.css']
})
export class ProductTagsComponent implements OnInit, OnDestroy {

  productTags: ProductTag[] = [];
  subscription: Subscription = new Subscription();
  errorMessage = '';
  productTags$ = this.productTagServices.productTags$
    .pipe(
      catchError((error) => {
        this.errorMessage = error;
        return of(null);
      })
    );

  constructor(private productTagServices: ProductTagService) { }

  ngOnInit(): void {
    // this.subscription.add(
    //   this.productTagServices.getProductTags().subscribe(
    //     productTags => {
    //       if (productTags !== null) {
    //         this.productTags = productTags;
    //       }
    //     }
    // )
    // );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
