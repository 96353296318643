import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Product} from '../../../models/product.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductService} from '../../../services/product.service';
import {NotificationsService} from '../../../services/notifications.service';
import {UtilsService} from '../../../services/utils.service';

@Component({
  selector: 'app-add-promotion',
  templateUrl: './add-promotion.component.html',
  styleUrls: ['./add-promotion.component.css']
})
export class AddPromotionComponent implements OnInit, OnDestroy {

  promotionForm: UntypedFormGroup;
  idProduct: string;
  uidStore: string;
  uidGroup: string;
  product: Product;
  toastTitle: string;
  toastMessage: string;
  subscriptions: Subscription = new Subscription();
  constructor(private route: ActivatedRoute,
              private productService: ProductService,
              private notifyService: NotificationsService,
              private router: Router,
              private utilsServices: UtilsService) { }

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidStore = token.get('idStore');
          this.uidGroup = token.get('idgroup');
          this.idProduct = token.get('id');

          this.subscriptions.add(
            this.productService.getProduct(this.uidGroup, this.uidStore, this.idProduct).subscribe(
              product => {
                if (product !== null) {
                  this.product = product;
                }
              }
            )
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.promotionForm = new UntypedFormGroup({
      startedAt: new UntypedFormControl(),
      endedAt: new UntypedFormControl(),
      percent: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    const product = this.utilsServices.createProductPromotion(this.promotionForm, this.product);
    this.productService.updateProduct(this.uidGroup, this.uidStore, product).then(
      () => {
        this.router.navigate(['/groups', this.uidGroup, 'stores', this.uidStore, 'products', this.idProduct]).then(
          () => {
            this.toastTitle = 'Enregistrement Fait';
            this.toastMessage = 'Creation de la promotion du produit faite avec succès !';
            this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
          }
        );
      }
    ).catch(
      () => {
        this.toastTitle = 'Enregistrement Echouée';
        this.toastMessage = 'Creation de la promotion du produit échouée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

}
