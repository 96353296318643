import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {SoundsService} from 'ngx-sounds';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private toastr: ToastrService,
              private sounds: SoundsService) {
  }

  showSuccess(message, title): void {
    this.toastr.success(message, title);
  }

  showError(message, title): void {
    this.toastr.error(message, title);
  }

  showInfo(message, title): void {
    this.toastr.info(message, title);
  }

  showWarning(message, title): void {
    this.toastr.warning(message, title);
  }

  soundNotification(): void {
    this.sounds.play('assets/my-sound.mp3').subscribe();
  }
}
