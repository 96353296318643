<table datatable class="table table-striped table-bordered table-hover" *ngIf="categories?.length !=0">
  <thead>
  <tr>
    <th>Image</th>
    <th>Nom</th>
    <th>Description</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let category of categories" class="w-100">
    <td><img src="{{category.image}}" class="img-thumbnail" alt="category_image" style="max-height: 50px;"></td>
    <td><span class="pie">{{category.name | titlecase}}</span></td>
    <td>{{category.description}}</td>
    <td>
      <a [routerLink]="['/groups', uidGroup, 'stores', uidStore, 'categories', category?.uid]" data-toggle="tooltip"
         data-placement="bottom" title="Voir les details de la categorie"><i class="fa fa-eye text-navy pr-1"></i></a>
      <span >
        <a [routerLink]="['/groups', uidGroup, 'stores', uidStore, 'categories', category?.uid, 'edit']"
           data-toggle="tooltip" data-placement="bottom" title="Modifier la categorie"><i class="fa fa-pencil pr-1"></i></a>
      </span>

      <span >
        <a (click)="deleteCategory(category)" *ngIf="category.deletedAt == null" data-toggle="tooltip"
           data-placement="bottom" title="Delete Category"><i class="fa fa-trash text-danger"></i></a>
      </span>

    </td>
  </tr>
  </tbody>
</table>
<table datatable class="table table-striped table-bordered table-hover" *ngIf="categories?.length ==0">
  <thead>
  <tr>
    <th>Image</th>
    <th>Nom</th>
    <th>Description</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  </tbody>
</table>
