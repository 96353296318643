<table datatable class="table table-striped table-bordered table-hover" *ngIf="products?.length !== 0">
  <thead>
  <tr>
    <th>image</th>
    <th>Nom</th>
    <th>Description</th>
    <th>Categorie</th>
    <th>Prix</th>
    <th>Priority</th>
    <th>Stock</th>
    <th>Ready For Shipping After</th>
    <th>State</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let product of products" class="w-100">
    <td><img src="{{product.images[0].image.url}}" class="img-thumbnail" alt="product_image" style="max-height: 50px;"></td>
    <td><span class="pie">{{product.name | titlecase}}</span></td>
    <td>{{product.description}}</td>
    <td>{{product?.categoryProduct[0].category.name | titlecase}}</td>
    <td *ngIf="(product.promotion === null) || product.promotion.valid === false">
      {{product.price | currency: 'USD'}}
    </td>
    <td *ngIf="product.promotion !== null && product.promotion.valid === true">
      <span class="text-navy">
        {{product.price - ((product.price * product.promotion.percent)/100) | currency: 'USD'}}
      </span>
      <s class="small text-muted">
        {{product.price | currency: 'USD'}}
      </s>
    </td>
    <td>{{product.priority | number}}</td>
    <td>{{product.quantity | number}}</td>
    <td>{{product.readyForShippingAfter}} min</td>
    <td [ngSwitch]="product.available">
      <span class="label label-primary" *ngSwitchCase="true">Enable</span>
      <span class="label label-danger" *ngSwitchCase="false">Disabled</span>
    </td>
    <td>
      <a [routerLink]="['/groups', idGroupe, 'stores', idStore, 'products', product?.uid]"
         data-toggle="tooltip" data-placement="bottom" title="Voir Detail du produit" class="btn btn-primary btn-xs m-1">
        <i class="fa fa-eye text-white pr-1"></i> Voir</a>
      <span>
        <a [routerLink]="['/groups', idGroupe, 'stores', idStore, 'products',product?.uid, 'edit']"
           class="btn btn-success btn-xs m-0" data-toggle="tooltip" data-placement="bottom" title="Modifier le produit">
          <i class="fa fa-pencil pr-1"></i> Edit</a>
      </span>
      <span>
        <a (click)="duplicateProduct(product)" *ngIf="product.productType === 'BUS_TICKET'"
           class="btn btn-dark btn-xs m-0 text-white m-1" data-toggle="tooltip" data-placement="bottom" title="Dupliquer le produit">
          <i class="fa fa-recycle pr-1"></i> Duplicate</a>
      </span>
      <span>
        <a (click)="deletePlate(product)" *ngIf="product.deletedAt === null" class="m-1" data-toggle="tooltip" data-placement="bottom"
           title="Supprimer le produit"><i class="fa fa-trash text-danger"></i></a>
      </span>

    </td>
  </tr>
  </tbody>
</table>
<table datatable class="table table-striped table-bordered table-hover" *ngIf="products?.length === 0">
  <thead>
  <tr>
    <th>image</th>
    <th>Nom</th>
    <th>Description</th>
    <th>Categorie</th>
    <th>Prix</th>
    <th>Priority</th>
    <th>Quantity</th>
    <th>Ready For Shipping After</th>
    <th>State</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  </tbody>
</table>
