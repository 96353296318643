import { Injectable } from '@angular/core';
import {FirestoreService} from './firestore.service';
import {BehaviorSubject, catchError, Observable, shareReplay, Subscription} from 'rxjs';
import {Banner} from '../models/banner.model';

@Injectable({
  providedIn: 'root'
})
export class BannersService {

  private currentBanner: BehaviorSubject<Banner> = new BehaviorSubject<Banner>(null);
  currentBanner$ = this.currentBanner.asObservable();
  subscriptions: Subscription = new Subscription();
  handleError: any = '';
  banners$: Observable<Banner[]> = this.firestoreService.col$('banners')
    .pipe(
      shareReplay(1),
      catchError(this.handleError)
    );

  constructor(private firestoreService: FirestoreService) { }

  getBanner(uidBanner: string): void {
    this.subscriptions.add(
      this.firestoreService.doc$(`banners/${uidBanner}`).subscribe(
        (banner: Banner) => {
          this.currentBanner.next(banner);
        }
      )
    );
  }

  addBanner(banner: Banner): Promise<any> {
    return this.firestoreService.set(`banners/${banner.uid}`, banner);
  }

  updateBanner(banner: Banner): Promise<any> {
    return this.firestoreService.update(`banners/${banner.uid}`, banner);
  }

  deleteBanner(uidBanner: string): Promise<any> {
    return this.firestoreService.delete(`banners/${uidBanner}`);
  }

  changeBannerState(uidBanner: string, state: boolean): Promise<any> {
    return this.firestoreService.update2(`banners/${uidBanner}`, {available: state});
  }
}
