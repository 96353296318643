import {Component, OnInit} from '@angular/core';
import {AuthService} from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'back-office';
  login = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  onActivate(event): void {
    if (event.router !== undefined) {
      this.login = true;
    } else if (event.router === undefined) {
      this.login = false;
    }
  }
}
