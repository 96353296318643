import {Component, OnDestroy, OnInit} from '@angular/core';
import {GroupsService} from '../services/groups.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {NotificationsService} from '../services/notifications.service';
import {UploadService} from '../services/upload.service';
import {Group} from '../models/groups.model';
import {UtilsService} from '../services/utils.service';

@Component({
  selector: 'app-group-add',
  templateUrl: './group-add.component.html',
  styleUrls: ['./group-add.component.css']
})
export class GroupAddComponent implements OnInit, OnDestroy {

  groupForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  image: string;
  pourcentage = 0;
  upload = false;
  subscriptions: Subscription = new Subscription();

  constructor(private groupeService: GroupsService,
              private notifyService: NotificationsService,
              private uploadService: UploadService,
              private utilsServices: UtilsService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.groupForm = new UntypedFormGroup({
      title: new UntypedFormControl('', Validators.minLength(4)),
      description: new UntypedFormControl('', Validators.minLength(10)),
      avatar: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    const group: Group = {
      uid: this.utilsServices.replaceSpaceByDash(this.groupForm.get('title').value),
      name: (this.groupForm.get('title').value).trim(),
      description: this.groupForm.get('description').value,
      available: false,
      priority: 0,
      createdAt: new Date(),
      updatedAt: null,
      deletedAt: null
    };

    this.groupeService.addGroupWithSupabase(group).then((response: any) => {
      if (response.error === null){
        console.log('Creation Group Succeed');
        this.uploadService.saveImage(this.uploadService.images['group-images'][0], group.uid);

        this.groupForm.reset();
        this.uploadService.dropImageList();
      } else {
        console.log('Creation Group Error =>', response.error);
      }
    });
  }

  onUpload(event, type= 'group-images'): void {
    this.upload = true;
    const uid = this.groupForm.get('title').value.toLocaleLowerCase().replace(' ', '-');
    this.uploadService.uploadFiles(event, `supabase/groups/${uid}/${uid}`, type);
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }
}
