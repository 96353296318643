<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-8">
    <h2><strong>{{store?.name | uppercase}}</strong></h2>
    <p><small class="font-weight-bold">{{store?.emailAddress}}</small></p>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/groups', idGroupe]">Groupe</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Store</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-4 p-1 align-content-center">
    <div class="col-lg-4">
      <a (click)="lockMarchand(store)" class="btn btn-warning btn-md mx-2" *ngIf="store?.lockedAt === null"
         data-toggle="tooltip" data-placement="bottom"
         title="Bloquer Marchand"><i class="fa fa-lock text-white font-weight-bold"> Bloquer Marchand ?</i></a>
      <a (click)="unlockMarchand(store)" class="btn btn-primary btn-md mx-2" *ngIf="store?.lockedAt !== null"
         data-toggle="tooltip" data-placement="bottom"
         title="Debloquer Marchand"><i class="fa fa-unlock text-white font-weight-bold"> Debloquer Marchand ?</i></a>
    </div>
    <div [ngSwitch]="state" class="col-lg-8">
      <h4 *ngSwitchCase="true" class="text-navy"><strong>Le marchand est disponible</strong>
      </h4>
      <h4 *ngSwitchCase="false" class="text-danger"><strong>Le marchand est
        indisponible</strong></h4>
    </div>
    <bSwitch [(ngModel)]="state" [switch-size]="'small'" [switch-label-text]="'Disponibilité'"
             [switch-on-color]="'success'" [switch-off-color]="'danger'"
             [switch-off-text]="'Indisponible'" [switch-on-text]="'Disponible'"
             [switch-base-class]="'bootstrap-switch'" [switch-wrapper-class]="'bootstrap-switch'"
             (changeState)="onChange($event)">
    </bSwitch>
  </div>
</div>

<div class="row mt-1">
  <a [routerLink]="['/groups', idGroupe]" class="btn btn-sm btn-primary"><i class="fa fa-chevron-left"></i> Retour</a>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="tabs-container">
        <ul class="nav nav-tabs">
          <li><a class="nav-link active" data-toggle="tab" href="#tab-profil"> <i class="fa fa-user"></i> Profile</a>
          </li>
          <li><a class="nav-link" data-toggle="tab" href="#tab-dashboard"><i class="fa fa-dashboard"></i> Dashboard</a>
          </li>
          <li><a class="nav-link" data-toggle="tab" href="#tab-commands"><i class="fa fa-shopping-cart"></i>
            Commandes</a></li>
          <li><a class="nav-link" data-toggle="tab" href="#tab-products"><i class="fa fa-bar-chart-o"></i> Produits</a>
          </li>
          <li><a class="nav-link" data-toggle="tab" href="#tab-categories"><i class="fa fa-bars"></i> Categories</a>
          </li>
          <li><a class="nav-link" data-toggle="tab" href="#tab-promotions"><i class="fa fa-random"></i> Promotions</a>
          </li>
          <li><a class="nav-link" data-toggle="tab" href="#tab-paiements"><i class="fa fa-money"></i> Paiements</a></li>
        </ul>
        <div class="tab-content">
          <div id="tab-profil" class="tab-pane active">
            <div class="row">
              <div class="col-md-10 offset-1">
                <div class="tabs-container">
                  <ul class="nav nav-tabs">
                    <li><a class="nav-link active" data-toggle="tab" href="#tab-profile" target="_blank"> <i class="fa fa-user"></i>
                      Modifier Profile</a>
                    </li>
                    <li><a class="nav-link" data-toggle="tab" href="#tab-email"><i class="fa fa-at"></i> Modifier
                      Email</a></li>
                    <li><a class="nav-link" data-toggle="tab" href="#tab-rating"><i class="fa fa-star"></i> Ratings</a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div id="tab-profile" class="tab-pane active">
                      <div class="panel-body">
                        <h4 class="text-center"><strong> Your Profile</strong></h4>
                        <hr>
                        <div class="row">
                          <div class="col-md-12">
                            <form (ngSubmit)="onSubmit()" [formGroup]="profilForm">
                              <div class="offset-4 mb-3">
                                <div class="profile-img">
                                  <img
                                    src="{{this?.refImage[0] || this?.store?.storeImage[0]?.image.url || 'https://ssl.gstatic.com/accounts/ui/avatar_2x.png'}}"
                                    alt="Profil" class="img-thumbnail rounded-circle">
                                </div>
                              </div>

                              <div class="form-group row"><label for="name" class="col-sm-2 col-form-label">Name</label>
                                <div class="col-sm-10">
                                  <div class="input-group m-b">
                                    <div class="input-group-prepend">
                                      <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                                    </div>
                                    <input type="text" placeholder="name" class="form-control" id="name"
                                           formControlName="name" required="required" minlength="6"
                                           [ngClass]="{'is-invalid' : (profilForm.get('name').touched || profilForm.get('name').dirty) && !profilForm.get('name').valid}">
                                    <div class="invalid-feedback">
                                      <span *ngIf="profilForm.get('name').errors?.required">Store Name is required !</span>
                                      <span *ngIf="profilForm.get('name').errors?.minlength">Valid Store Name must be longer than 6 caracters !</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row"><label for="description" class="col-sm-2 col-form-label">Description</label>
                                <div class="col-sm-10">
                                  <div class="input-group m-b">
                                    <div class="input-group-prepend">
                                      <span class="input-group-addon"><i class="fa fa-italic"></i></span>
                                    </div>
                                    <input type="text" placeholder="Description" class="form-control" minlength="10"
                                           formControlName="description" id="description"
                                           [ngClass]="{'is-invalid' : (profilForm.get('description').touched || profilForm.get('description').dirty) && !profilForm.get('description').valid}">
                                    <div class="invalid-feedback">
                                      <span *ngIf="profilForm.get('description').errors?.minlength">Valid Store description must be longer than 10 caracters !</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row"><label for="adresse" class="col-sm-2 col-form-label">Adresse</label>
                                <div class="col-sm-10">
                                  <div class="input-group m-b">
                                    <div class="input-group-prepend">
                                      <span class="input-group-addon"><i class="fa fa-home"></i></span>
                                    </div>
                                    <input ngx-google-places-autocomplete #placesRef="ngx-places"
                                           (onAddressChange)="handleAddressChange($event)" type="text"
                                           placeholder="Adresse" class="form-control" minlength="10"
                                           formControlName="adresse" id="adresse" required="required"
                                           [ngClass]="{'is-invalid' : (profilForm.get('adresse').touched || profilForm.get('adresse').dirty) && !profilForm.get('adresse').valid}">
                                    <div class="invalid-feedback">
                                      <span *ngIf="profilForm.get('adresse').errors?.required">Store adresse is required !</span>
                                      <span *ngIf="profilForm.get('adresse').errors?.minlength">Valid Store description must be longer than 10 caracters !</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row"><label for="image" class="col-sm-2 col-form-label">Image</label>
                                <div class="col-sm-10">
                                  <div class="input-group m-b">
                                    <div class="input-group-prepend">
                                      <span class="input-group-addon"><i class="fa fa-image"></i></span>
                                    </div>
                                    <input type="file" accept="image/*" placeholder="Price" class="form-control"
                                           id="image" formControlName="image" (change)="onUpload($event)"
                                           [ngClass]="{'is-invalid' : (profilForm.get('image').touched || profilForm.get('image').dirty) && !profilForm.get('image').valid}">

                                    <div class="invalid-feedback">
                                      <span *ngIf="profilForm.get('image').errors?.required">Store image is required !</span>
                                    </div>
                                  </div>
                                  <div class="progress">
                                    <div class="progress-bar" [style.width.%]="pourcentage" role="progressbar"
                                         [attr.aria-valuenow]="pourcentage" aria-valuemin="0" aria-valuemax="100">
                                      <span class="text-center" *ngIf="pourcentage > 0">{{pourcentage | number}}%</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="hr-line-dashed"></div>

                              <div class="form-group row">
                                <div class="col-sm-4 col-sm-offset-2">
                                  <a [routerLink]="['/groups',idGroupe]"
                                     class="btn btn-white btn-sm" type="submit">Cancel</a>
                                  <button class="btn btn-primary btn-sm" type="submit"
                                          [title]="profilForm.valid ? 'Modifier Profile' : 'Disable until valid form data'"
                                          [disabled]="!profilForm.valid">Edit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="tab-email" class="tab-pane">
                      <div class="panel-body">
                        <h4 class="text-center"><strong> Change Email</strong></h4>
                        <hr>
                        <div class="row">
                          <div class="col-md-12 text-black">
                            <form (ngSubmit)="resetEmail()" [formGroup]="emailForm">
                              <div class="form-group row"><label for="newEmail" class="col-sm-3 col-form-label">New
                                Email</label>
                                <div class="col-sm-9">
                                  <div class="input-group m-b">
                                    <div class="input-group-prepend">
                                      <span class="input-group-addon"><i class="fa fa-at"></i></span>
                                    </div>
                                    <input type="email" placeholder="New Email" class="form-control"
                                           id="newEmail" email="email" formControlName="newEmail" required="required"
                                           [ngClass]="{'is-invalid' : (emailForm.get('newEmail').touched || emailForm.get('newEmail').dirty) && !emailForm.get('newEmail').valid}">
                                    <div class="invalid-feedback">
                                      <span
                                        *ngIf="emailForm.get('newEmail').errors?.required">Email is required !</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="hr-line-dashed"></div>

                              <div class="form-group row">
                                <div class="col-sm-4 col-sm-offset-2">
                                  <!--                                  <a [routerLink]="['/groups',store?.uidGroup]" class="btn btn-white btn-sm" type="submit">Cancel</a>-->
                                  <button class="btn btn-primary btn-sm" type="submit"
                                          [title]="emailForm.valid ? 'Modifier Email' : 'Disable until valid form data'"
                                          [disabled]="!emailForm.valid">Modify
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="tab-rating" class="tab-pane">
                      <div class="panel-body">
                        <div class="ibox ">
                          <div class="ibox-title">
                            <h5>Rating list</h5>
                            <div class="ibox-tools">
                              <span>Moyen Rating : <strong>{{this?.average || 0}}</strong></span>
                            </div>
                          </div>
                          <div class="ibox-content table-responsive">
                            <table datatable class="table table-striped table-bordered table-hover no-margins"
                                   *ngIf="ratings?.length !==0">
                              <thead>
                              <tr>
                                <th>Date</th>
                                <th>User</th>
                                <th>Commentaire</th>
                                <th>Rate</th>
                                <th>Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let rating of ratings" class="text-center">
                                <td><i
                                  class="fa fa-clock-o"></i> {{rating?.rating.createdAt | date: 'hh:mm a, dd-MM-yyyy'}}
                                </td>
                                <td>{{rating?.rating.user.userName | titlecase}}</td>
                                <td>{{rating?.rating.message}}</td>
                                <td class="bg-white text-black-50"><i class="fa fa-star"
                                                                      style="color: yellow"></i><strong> {{rating?.rating.rating}}</strong>
                                </td>
                                <td><a (click)="deleteRating(rating.rating)" data-toggle="tooltip" data-placement="bottom"
                                       title="Supprimer le rating" class="btn btn-danger btn-xs text-white ml-1 mt-1"><i
                                  class="fa fa-trash text-white pl-1"></i> Remove</a></td>
                              </tr>
                              </tbody>
                              <tfoot>
                              <tr>
                                <th>Date</th>
                                <th>User</th>
                                <th>Commentaire</th>
                                <th>Rate</th>
                                <th>Action</th>
                              </tr>
                              </tfoot>
                            </table>

                            <table datatable class="table table-striped table-bordered table-hover"
                                   *ngIf="ratings?.length === 0">
                              <thead>
                              <tr>
                                <th>Date</th>
                                <th>User</th>
                                <th>Commentaire</th>
                                <th>Rate</th>
                              </tr>
                              </thead>
                              <tbody>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div id="tab-dashboard" class="tab-pane">
            <div class="ibox ">
              <div class="ibox-title">
                <h5>Dashboard du Store </h5>
                <div class="ibox-tools">
                </div>
              </div>
              <div class="ibox-content">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="col-lg-3 d-sm-inline-flex">
                      <div class="ibox">
                        <div class="ibox-title">
                          <span class="label label-success float-right">All</span>
                          <h5>Commandes</h5>
                        </div>
                        <div class="ibox-content">
                          <h1 class="no-margins">{{this?.store?.orders?.length}}</h1>
                          <small>Total orders</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 d-sm-inline-flex">
                      <div class="ibox ">
                        <div class="ibox-title">
                          <span class="label label-info float-right">All</span>
                          <h5>Commandes </h5>
                        </div>
                        <div class="ibox-content">
                          <h1 class="no-margins">{{this?.ordersValidatedCount}}</h1>
                          <small>Commandes acceptées</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 d-sm-inline-flex">
                      <div class="ibox ">
                        <div class="ibox-title">
                          <span class="label label-warning float-right">All</span>
                          <h5>Commandes</h5>
                        </div>
                        <div class="ibox-content">
                          <h1 class="no-margins">{{this?.ordersDeliveredCount}}</h1>
                          <small>Commandes livrées</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 d-sm-inline-flex">
                      <div class="ibox ">
                        <div class="ibox-title">
                          <span class="label label-danger float-right">All</span>
                          <h5>Commandes</h5>
                        </div>
                        <div class="ibox-content">
                          <h1 class="no-margins">{{this?.ordersCanceledCount}}</h1>
                          <small>Commandes refusées</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <ul class="stat-list list-inline text-center">
                      <li class="border-right p-2 px-5">
                        <h2 class="no-margins">{{this?.amountOrdersValidated | currency: 'USD'}}</h2>
                        <small>Total orders acceptées</small>
                      </li>
                      <li class="border-right p-2 px-5">
                        <h2 class="no-margins ">{{this?.amountOrdersDelivered | currency: 'USD'}}</h2>
                        <small>Total Commandes livrées</small>
                      </li>
                      <li class="p-2 px-5">
                        <h2 class="no-margins ">{{this?.amountOrdersCanceled | currency: 'USD'}}</h2>
                        <small>Total orders refusées</small>
                      </li>
                      <hr>
                      <li class="dropdown-divider"></li>
                      <li class="alert alert-warning col-sm-4 px-2 mx-2">
                        <h2 class="no-margins ">{{this?.store?.amountDu | currency: 'USD'}}</h2>
                        <small>Total Montant dû</small>
                      </li>
                      <li class="alert alert-success col-sm-4 px-2 mx-2">
                        <h2 class="no-margins ">{{this?.store?.amountReceived | currency: 'USD'}}</h2>
                        <small>Total Montant Payés</small>
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-12">
                    <div class="col-lg-6 d-sm-inline-flex">
                      <canvas #lineCanvas></canvas>
                    </div>
                    <div class="col-lg-6 d-sm-inline-flex">
                      <canvas #lineCanvas2></canvas>
                    </div>
                    <div class="col-lg-6 d-sm-inline-flex">
                      <canvas #lineCanvas3></canvas>
                    </div>
                    <div class="col-lg-6 d-sm-inline-flex">
                      <canvas #doughnutCanvas></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-commands" class="tab-pane">
            <div class="row">
              <div class="col-md-10 offset-1">

                <div class="tabs-container">
                  <ul class="nav nav-tabs">
                    <li><a class="nav-link active" data-toggle="tab" href="#tab-cmd"> <i class="fa fa-clock-o"></i>
                      Current Commands</a>
                    </li>
                    <li><a class="nav-link" data-toggle="tab" href="#tab-scmd"><i class="fa fa-check-square-o"></i>
                      Served Commands</a></li>
                  </ul>
                  <div class="tab-content">
                    <div id="tab-cmd" class="tab-pane active">
                      <app-orders></app-orders>
                    </div>
                    <div id="tab-scmd" class="tab-pane">
                      <app-orders-servies></app-orders-servies>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-products" class="tab-pane">
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox ">
                  <div class="ibox-title">
                    <h5>Liste des Produits du Store</h5>
                    <div  class="ibox-tools">
                      <a class="btn btn-sm btn-success text-white" [routerLink]="['/groups', idGroupe, 'stores', idStore, 'products', 'add']">
                        <span>Ajouter Produit</span>
                      </a>
                    </div>
                  </div>
                  <div class="ibox-content">
                    <div class="table-responsive">
                      <app-product [products]="store.products"></app-product>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
          <div id="tab-categories" class="tab-pane">
            <app-product-categories></app-product-categories>
          </div>
          <div id="tab-promotions" class="tab-pane">
            <app-promos></app-promos>
          </div>
          <div id="tab-paiements" class="tab-pane">
            <div class="ibox ">
              <div class="ibox-title">
                <h5>Paiements du Store </h5>
                <div class="ibox-tools">
                </div>
              </div>
              <div class="ibox-content">
                <div class="table-responsive">
                  <table datatable class="table table-striped table-bordered table-hover dataTables-example"
                         *ngIf="paiements?.length !== 0">
                    <thead>
                    <tr>
                      <th>Date de Invoice</th>
                      <th>Montant Du</th>
                      <th>Montant Perçu</th>
                      <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let paiement of paiements" class="w-100">
                      <td><span class="pie">{{paiement.createdAt | date: 'dd-MM-yyyy, hh:mm'}}</span></td>
                      <td>{{paiement.amountDu | currency: 'USD'}}</td>
                      <td>{{paiement.amountReceived | currency: 'USD'}}</td>
                      <td><span>{{paiement.description}}</span></td>
                    </tr>
                    </tbody>
                  </table>

                  <table datatable class="table table-striped table-bordered table-hover"
                         *ngIf="paiements?.length === 0">
                    <thead>
                    <tr>
                      <th>Date de Invoice</th>
                      <th>Montant Du</th>
                      <th>Montant Perçu</th>
                      <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
