import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Category} from '../../models/category.model';
import {NotificationsService} from '../../services/notifications.service';
import {UploadService} from '../../services/upload.service';
import {StoreService} from '../../services/store.service';
import {CategoryStoreService} from '../../services/category-store.service';
import {CustomDatePipe} from '../../pipes/custom-date.pipe';
import uid from 'uid';
import {catchError, Observable, of, Subscription} from 'rxjs';
import {GroupsService} from '../../services/groups.service';
import {Group} from '../../models/groups.model';
import {ActivatedRoute} from '@angular/router';
import {Address} from '../../models/address.model';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {Address as gAddress} from 'ngx-google-places-autocomplete/objects/address';
import {AddressService} from '../../services/address.service';
import {Store} from '../../models/store.model';
import {User} from '../../models/user.model';
import {UserService} from '../../services/user.service';
import {StoreAddress} from '../../models/store-address.model';
import {StoreUser} from '../../models/store-user.model';
import {Roles} from '../../models/roles.enum';
import {StoreGroup} from '../../models/store-group.model';
import {StoreCategory} from '../../models/store-category.model';


@Component({
  selector: 'app-storeadd',
  templateUrl: './storeadd.component.html',
  styleUrls: ['./storeadd.component.css']
})
export class StoreaddComponent implements OnInit, OnDestroy {

  @ViewChild('placesRef', {static: false}) placesRef: GooglePlaceDirective;
  storeForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  uidStore: string;
  categories: Category[];
  users: User[];
  groups: Group[];
  idGroup: string;
  uid;
  image: string;
  pourcentage = 0;
  upload = false;
  customDate = new CustomDatePipe();
  storeAddress: Address;
  subscriptions: Subscription = new Subscription();
  fieldTextType: boolean;

  errorMessage = '';
  storeCategories$: Observable<Category[]> = this.categoryStoreService.storeCategories$
    .pipe(
      catchError((error) => {
        this.errorMessage = error;
        return of(null);
      })
    );
  users$: Observable<User[]> = this.userService.sUsers$
    .pipe(
      catchError((error) => {
        this.errorMessage = error;
        return of(null);
      })
    );

  constructor(private notifyService: NotificationsService,
              private uploadService: UploadService,
              private storeService: StoreService,
              private categoryStoreService: CategoryStoreService,
              private groupeService: GroupsService,
              private route: ActivatedRoute,
              private addressService: AddressService,
              private userService: UserService) {
    void this.categoryStoreService.getCategoriesStoreWithSupabase();
    void this.userService.getUsersWithSupabase();
  }

  ngOnInit(): void {
    this.initForm();

    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.idGroup = token.get('idgroup');

          this.subscriptions.add(
            this.storeCategories$.subscribe(
              categoriesStore => {
                this.categories = categoriesStore;
              }
            )
          );
          this.subscriptions.add(
            this.users$.subscribe(
              user => {
                this.users = user;
              }
            )
          );
        }
      )
    );
    this.uid = uid(32);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.storeForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, Validators.min(3)]),
      description: new UntypedFormControl('', [Validators.minLength(10)]),
      adresse: new UntypedFormControl(),
      categoryStore: new UntypedFormControl(),
      user: new UntypedFormControl(),
      image: new UntypedFormControl(),
      email: new UntypedFormControl('', [
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        Validators.required
      ]),
      password: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    const store: Store = {
      uid: this.uid,
      name: (this.storeForm.get('name').value).trim(),
      description: this.storeForm.get('description').value,
      emailAddress: this.storeForm.get('email').value,
      open: false,
      available: false,
      amountDu: 0,
      amountReceived: 0,
      createdAt: new Date(),
      updatedAt: null,
      deletedAt: null,
      lockedAt: new Date(),
      priority: 0
    };
    const storeAdress: StoreAddress = {
      uid: uid(32),
      uidStore: store.uid,
      uidAddress: this.storeAddress.uid
    };
    const storeGroup: StoreGroup = {
      uid: uid(32),
      uidStore: store.uid,
      uidGroup: this.idGroup
    };
    const storeUser: StoreUser = {
      uid: uid(32),
      uidUser: this.storeForm.get('user').value,
      uidStore: store.uid,
      role: Roles.OWNER
    };

    const storeCategory: StoreCategory = {
      uid: uid(32),
      uidStore: store.uid,
      uidCategory: this.storeForm.get('categoryStore').value
    };

    this.storeService.addStoreWithSupabase(store).then((response: any) => {
      if (response.error === null) {
        this.toastTitle = 'Ajout Fait';
        this.toastMessage = 'Ajout du Store fait avec succès !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
        this.uploadService.saveImage(this.uploadService.images['store-images'][0], null, store.uid, null);
        void this.addressService.addAddressWithSupabase(this.storeAddress)
          .then((resp) => {
            if (resp.error === null) {
              void this.storeService.addStoreAddressWithSupabase(storeAdress)
                .then((res) => {
                  if (res.error === null) {
                    this.toastTitle = 'Ajout Fait';
                    this.toastMessage = `Enregistrement de l'adresse du Store fait avec succès !`;
                    this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
                  } else {
                    this.toastTitle = 'Echec';
                    this.toastMessage = `Echec de l'Ajout du Store Address`;
                    this.notifyService.showError(this.toastMessage, this.toastTitle);
                  }
                });
            }
          });

        void this.storeService.addStoreGroupWithSupabase(storeGroup)
          .then((res) => {
            if (res.error === null) {
              this.toastTitle = 'Ajout Fait';
              this.toastMessage = `Enregistrement du Store Group fait avec succès !`;
              this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
            } else {
              this.toastTitle = 'Echec';
              this.toastMessage = `Echec de l'Ajout du Store Group`;
              this.notifyService.showError(this.toastMessage, this.toastTitle);
            }
          });

        void this.userService.addStoreUserWithSupabase(storeUser)
          .then((res) => {
            if (res.error === null) {
              this.toastTitle = 'Ajout Fait';
              this.toastMessage = `Enregistrement du Store User fait avec succès !`;
              this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
            } else {
              this.toastTitle = 'Echec';
              this.toastMessage = `Echec de l'Ajout du Store User`;
              this.notifyService.showError(this.toastMessage, this.toastTitle);
            }
          });

        void this.categoryStoreService.addStoreCategoryWithSupabase(storeCategory)
          .then((res) => {
            if (res.error === null) {
              this.toastTitle = 'Ajout Fait';
              this.toastMessage = `Enregistrement du Store Category fait avec succès !`;
              this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
            } else {
              this.toastTitle = 'Echec';
              this.toastMessage = `Echec de l'Ajout du Store Category`;
              this.notifyService.showError(this.toastMessage, this.toastTitle);
            }
          });

        this.storeForm.reset();
        this.uploadService.dropImageList();
      } else {
        this.toastTitle = 'Echec';
        this.toastMessage = 'Echec de l\'Ajout du Store';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    }).catch(
      () => {
        this.toastTitle = 'Echec';
        this.toastMessage = 'Echec de l\'Ajout du Store';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  public handleAddressChange(address: gAddress): void {
    this.storeAddress = this.addressService.handleAddressChange(address);
  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `supabase/groups/${this.idGroup}/stores/${this.uid}/${this.uid}`, 'store-images');
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }
}
