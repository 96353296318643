<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>Catégorie</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="dashboard">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/groups', uidGroup, 'stores', uidStore]">Catégories des Produits</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Ajouter Catégorie</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2">
  </div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/groups', uidGroup, 'stores', uidStore]"  class="btn btn-sm btn-primary"><i class="fa fa-chevron-left"></i> Retour</a>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Ajouter Catégorie</h5>
        </div>
        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" [formGroup]="categoryForm">
            <div class="form-group row"><label class="col-sm-2 col-form-label">Nom</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                  </div>
                  <input type="text" placeholder="Nom" class="form-control" id="title" formControlName="title"
                         required="required" minlength="6"
                         [ngClass]="{'is-invalid' : (categoryForm.get('title').touched || categoryForm.get('title').dirty) && !categoryForm.get('title').valid}">
                  <div class="invalid-feedback">
                    <span *ngIf="categoryForm.get('title').errors?.required">Category title is required !</span>
                    <span
                      *ngIf="categoryForm.get('title').errors?.minlength">Valid Category title must be longer than 6 caracters !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label class="col-sm-2 col-form-label">Description</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-italic"></i></span>
                  </div>
                  <input type="text" placeholder="Description" class="form-control" minlength="10"
                         formControlName="description" id="description"
                         [ngClass]="{'is-invalid' : (categoryForm.get('description').touched || categoryForm.get('description').dirty) && !categoryForm.get('description').valid}">
                  <div class="invalid-feedback">
                    <span
                      *ngIf="categoryForm.get('description').errors?.minlength">Valid Category description must be longer than 10 caracters !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label class="col-sm-2 col-form-label">Image</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-image"></i></span>
                  </div>
                  <input type="file" accept="image/*" placeholder="Image" class="form-control" id="image"
                         formControlName="image" required="required" (change)="onUpload($event)"
                         [ngClass]="{'is-invalid' : (categoryForm.get('image').touched || categoryForm.get('image').dirty) && !categoryForm.get('image').valid}">
                  <div class="invalid-feedback">
                    <span *ngIf="categoryForm.get('image').errors?.required">Category image is required !</span>
                  </div>
                </div>
                <div class="progress">
                  <div class="progress-bar" [style.width.%]="pourcentage" role="progressbar"
                       [attr.aria-valuenow]="pourcentage" aria-valuemin="0" aria-valuemax="100">
                    <span class="text-center" *ngIf="pourcentage > 0">{{pourcentage | number}} %</span>
                  </div>
                </div>
                <div class="offset-6" *ngIf="upload">
                  <div class="lds-ring" *ngIf="pourcentage < 100">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="hr-line-dashed"></div>

            <div class="form-group row">
              <div class="col-sm-4 col-sm-offset-2">
                <a class="btn btn-white btn-sm" [routerLink]="['/groups', uidGroup, 'stores', uidStore]">Annuler</a>
                <button  class="btn btn-primary btn-sm" type="submit"
                        [title]="categoryForm.valid ? 'Ajouter Categorie' : 'Disable until valid form data'"
                        [disabled]="!categoryForm.valid && pourcentage < 100">Créer
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
