import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Banner} from '../models/banner.model';
import {ActivatedRoute} from '@angular/router';
import {BannersService} from '../services/banners.service';
import {NotificationsService} from '../services/notifications.service';

@Component({
  selector: 'app-banner-single',
  templateUrl: './banner-single.component.html',
  styleUrls: ['./banner-single.component.css']
})
export class BannerSingleComponent implements OnInit, OnDestroy {

  uidBanner: string;
  banner: Banner;
  toastTitle: string;
  toastMessage: string;
  state: boolean;
  subscriptions: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,
              private bannerService: BannersService,
              private notifyService: NotificationsService) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidBanner = token.get('id');

          this.bannerService.getBanner(this.uidBanner);
          this.subscriptions.add(
            this.bannerService.currentBanner$.subscribe(
              banner => {
                if (banner !== null) {
                  this.banner = banner;
                  this.state = banner.available;
                }
              }
            )
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onChange(event): void {
    this.bannerService.changeBannerState(this.uidBanner, event.currentValue).then(
      () => {
        this.toastTitle = 'Success';
        this.toastMessage = 'Status de la bannière changé !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {
        this.toastTitle = 'Success';
        this.toastMessage = 'Supression de la bannière échouée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }
}
