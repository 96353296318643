import {Component} from '@angular/core';
import {User} from '../models/user.model';
import {catchError, Observable, of, Subscription} from 'rxjs';
import {UserService} from '../services/user.service';
import {OrderService} from '../services/order.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent  {

  users: User[] = [];
  subscriptions: Subscription = new Subscription();
  errorMessage = '';
  ordersWithUsers$ = this.orderServices.ordersWithUsers
    .pipe(
      catchError((error) => {
        this.errorMessage = error;
        return of(null);
      })
    );
  users$: Observable<User[]> = this.userService.sUsers$
    .pipe(
      catchError((error) => {
        this.errorMessage = error;
        return of(null);
      })
    );

  constructor(private userService: UserService,
              private orderServices: OrderService) {
    void this.userService.getUsersWithSupabase();
  }

}
