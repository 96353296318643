<div class="row">
  <div class="col-lg-12">
    <div class="ibox ">
      <div class="ibox-title">
        <h5>Liste des Catégories de Produit </h5>
        <div  class="ibox-tools">
          <a class="btn btn-sm btn-success text-white" [routerLink]="['/groups', uidGroup, 'stores', uidStore, 'categories', 'add']">
            <span> Ajouter Catégorie</span>
          </a>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <app-product-category [categories]="categories"></app-product-category>
        </div>

      </div>
    </div>
  </div>

</div>
