import { Component, OnInit } from '@angular/core';
import {GroupProductTypeService} from '../../services/group-product-type.service';
import {ActivatedRoute} from '@angular/router';
import {catchError, Observable, of, Subscription} from 'rxjs';
import {GroupProductType} from '../../models/group-product-type.model';
import {NotificationsService} from '../../services/notifications.service';

@Component({
  selector: 'app-product-types',
  templateUrl: './product-types.component.html',
  styleUrls: ['./product-types.component.css']
})
export class ProductTypesComponent implements OnInit {

  uidGroup: string;
  subscriptions: Subscription = new Subscription();
  toastTitle: string;
  toastMessage: string;
  errorMessage = '';
  groupProductTypes$: Observable<GroupProductType[]> = this.gProductTypeServices.groupProductTypes$
    .pipe(
      catchError((error) => {
        this.errorMessage = error;
        return of(null);
      })
    );
  constructor(private gProductTypeServices: GroupProductTypeService,
              private route: ActivatedRoute,
              private notifyService: NotificationsService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidGroup = token.get('idgroup');
          void this.gProductTypeServices.getGroupProductTypes(this.uidGroup);
        }
      )
    );
  }

  removeGroupProductType(groupProductType: GroupProductType): void {
    if (confirm('Etes-vous sure de vouloir supprimer ce Product Type ? Cette Action est irreversible')) {
      this.gProductTypeServices.deleteGroupProductType(groupProductType.uid).then(
        () => {
          this.notifyService.showSuccess('Suppression du Product Type fait avec succès !', 'Notification Faite');
        }
      ).catch(
        error => {
          this.notifyService.showError(`Echec de la suppression du Product Type => ${error}`, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Suppression du Product Type Annulée`, 'Echec');
    }
  }

}
