import {Component, OnDestroy, OnInit} from '@angular/core';
import {Product} from '../../../models/product.model';
import {ProductService} from '../../../services/product.service';
import {ActivatedRoute} from '@angular/router';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit, OnDestroy {

  storeProducts$: Observable<Product[]>;
  idStore: string;
  idGroup: string;
  subscriptions: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,
              private productService: ProductService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.idStore = token.get('idStore');
          this.idGroup = token.get('idgroup');

          this.productService.getProducts(this.idGroup, this.idStore);

          void this.productService.getStoreProductsWithSupabase(this.idStore);
          this.storeProducts$ = this.productService.storeProducts$;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
