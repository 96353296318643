<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>{{uidGroup | titlecase}}: Agent</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/groups', uidGroup]">Groupe</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Ajout Agent</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2"></div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/groups', uidGroup]" class="btn btn-sm btn-primary text-white"><i class="fa fa-chevron-left"></i> Retour</a>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>{{uidGroup | titlecase}}: Ajout <small>Agent.</small></h5>
        </div>
        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" [formGroup]="agentForm">
            <div class="form-group row"><label for="name" class="col-sm-2 col-form-label">Name</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                  </div>
                  <input type="text" placeholder="name" class="form-control" id="name" formControlName="name"
                         required="required" minlength="6"
                         [ngClass]="{'is-invalid' : (agentForm.get('name').touched || agentForm.get('name').dirty) && !agentForm.get('name').valid}">
                  <div class="invalid-feedback">
                    <span *ngIf="agentForm.get('name').errors?.required">Agent Name is required !</span>
                    <span
                      *ngIf="agentForm.get('name').errors?.minlength">Valid Agent Name must be longer than 6 caracters !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label for="phone" class="col-sm-2 col-form-label">Phone</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-phone"></i></span>
                  </div>
                  <input type="text" placeholder="Phone" class="form-control"
                         formControlName="phone" id="phone" required="required"
                         [ngClass]="{'is-invalid' : (agentForm.get('phone').touched || agentForm.get('phone').dirty) && !agentForm.get('phone').valid}">
                  <div class="invalid-feedback">
                    <span *ngIf="agentForm.get('phone').errors?.required">Agent phone number is required !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label class="col-sm-2 col-form-label">Address</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-home"></i></span>
                  </div>
                  <input ngx-google-places-autocomplete #placesRef="ngx-places"
                         type="text" placeholder="Address" class="form-control"
                         (onAddressChange)="handleAddressChange($event)"
                         minlength="10" formControlName="adresse" id="adresse" required="required"
                         [ngClass]="{'is-invalid' : (agentForm.get('adresse').touched || agentForm.get('adresse').dirty) && !agentForm.get('adresse').valid}">
                  <div class="invalid-feedback">
                                <span
                                  *ngIf="agentForm.get('adresse').errors?.required">Store Address is required !</span>
                    <span
                      *ngIf="agentForm.get('adresse').errors?.minlength">Valid Store Address must be longer than 10 caracters !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label for="email" class="col-sm-2 col-form-label">Email</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-at"></i></span>
                  </div>
                  <input type="email" placeholder="Email" class="form-control"
                         formControlName="email" id="email" required="required"
                         [ngClass]="{'is-invalid' : (agentForm.get('email').touched || agentForm.get('email').dirty) && !agentForm.get('email').valid}">
                  <div class="invalid-feedback">
                    <span *ngIf="agentForm.get('email').errors?.required">Agent email is required !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label for="password" class="col-sm-2 col-form-label">Password</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-asterisk"></i></span>
                  </div>
                  <input type="password" [type]="fieldTextType ? 'text' : 'password'" placeholder="Password"
                         class="form-control"
                         formControlName="password" id="password" required="required"
                         [ngClass]="{'is-invalid' : (agentForm.get('password').touched || agentForm.get('password').dirty) && !agentForm.get('password').valid}">
                  <div class="input-group-append">
                              <span class="input-group-text">
                                <i class="fa" [ngClass]="{'fa-eye': !fieldTextType,'fa-eye-slash': fieldTextType}"
                                   (click)="toggleFieldTextType()"></i></span>
                  </div>
                  <div class="invalid-feedback">
                    <span *ngIf="agentForm.get('password').errors?.required">Agent password is required !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label for="uidStore" class="col-sm-2 col-form-label">Store</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-random"></i></span>
                  </div>
                  <select class="form-control m-b" name="uidStore" formControlName="uidStore"
                          id="uidStore" required="required"
                          [ngClass]="{'is-invalid' : (agentForm.get('uidStore').touched || agentForm.get('uidStore').dirty) && !agentForm.get('uidStore').valid}">
                    <option [ngValue]="null" disabled> Select Store</option>
                    <option *ngFor="let store of restorers" [value]="store.uid">{{store.name}}</option>
                  </select>
                  <div class="invalid-feedback">
                    <span *ngIf="agentForm.get('uidStore').errors?.required">Agent Store is required !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label class="col-sm-2 col-form-label">Avatar</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-image"></i></span>
                  </div>
                  <input type="file" accept="image/*" placeholder="Avatar" class="form-control" id="avatar"
                         formControlName="avatar" required="required" (change)="onUpload($event)"
                         [ngClass]="{'is-invalid' : (agentForm.get('avatar').touched || agentForm.get('avatar').dirty) && !agentForm.get('avatar').valid}">

                  <div class="invalid-feedback">
                    <span *ngIf="agentForm.get('avatar').errors?.required">Agent avatar is required !</span>
                  </div>
                </div>
                <div class="progress">
                  <div class="progress-bar" [style.width.%]="pourcentage" role="progressbar"
                       [attr.aria-valuenow]="pourcentage" aria-valuemin="0" aria-valuemax="100">
                    <span class="text-center" *ngIf="pourcentage > 0">{{pourcentage | number}} %</span>
                  </div>
                </div>
                <div class="offset-6" *ngIf="upload">
                  <div class="lds-ring" *ngIf="pourcentage < 100">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="hr-line-dashed"></div>

            <div class="form-group row">
              <div class="col-sm-4 col-sm-offset-2">
                <a [routerLink]="['/groups', uidGroup]" class="btn btn-white btn-sm" type="submit">Cancel</a>
                <button  class="btn btn-primary btn-sm" type="submit"
                        [title]="agentForm.valid ? 'Ajouter Agent' : 'Disable until valid form data'"
                        [disabled]="!agentForm.valid || pourcentage < 100">Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
