import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../../../models/product.model';
import {Subscription} from 'rxjs';
import {ProductService} from '../../../services/product.service';
import {NotificationsService} from '../../../services/notifications.service';
import {ActivatedRoute} from '@angular/router';
import {PermissionType} from '../../../models/permission-type.enum';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

    @Input() products: Product[];
    toastTitle: string;
    toastMessage: string;
    subscriptions: Subscription = new Subscription();
    idStore: string;
    idGroupe: string;
    permissions: any = {
        create: PermissionType.CREATE,
        update: PermissionType.UPDATE,
        delete: PermissionType.DELETE,
        read: PermissionType.READ,
    };

    constructor(private route: ActivatedRoute,
                private productService: ProductService,
                private notifyService: NotificationsService) {
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.route.paramMap.subscribe(
                token => {
                    this.idStore = token.get('idStore');
                    this.idGroupe = token.get('idgroup');
                }
            )
        );
    }

    deletePlate(product: Product): void {
        if (confirm('Voulez vous supprimer cet article')) {
            this.productService.deleteProduct(this.idGroupe, this.idStore, product).then(
                () => {
                    this.toastTitle = 'Success';
                    this.toastMessage = 'Supression du produit réussie !';
                    this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
                }
            ).catch(
                () => {
                    this.toastTitle = 'Echec';
                    this.toastMessage = 'Suppression du produit échouée !';
                    this.notifyService.showError(this.toastMessage, this.toastTitle);
                }
            );
        }
    }

    duplicateProduct(product: Product): any {
        if (confirm('Voulez vous Dupliquer ce produit')) {
            // Get the full date
            const fullname = product.name.split(', ');
            const getTripDate = fullname[1];

            const depDate = this.productService.nextDate(getTripDate);
            const arrivalDate = this.productService.nextDate(product.productSpec[0].spec.value);

            const newProduct = this.productService.setDuplication(depDate, arrivalDate, fullname, product);

            // this.productService.createProduct(newProduct.uidGroup, newProduct.uidStore, newProduct)
            //   .then(() => {
            //     this.toastTitle = 'Product Duplicated';
            //     this.toastMessage = `Duplication du produit fait avec succès ! ${newProduct.name} created.`;
            //     this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
            //   }).catch(() => {
            //   this.toastTitle = 'Echec Duplication';
            //   this.toastMessage = 'Duplication du produit a échouée !';
            //   this.notifyService.showError(this.toastMessage, this.toastTitle);
            // });
        }
    }
}
