import {Injectable} from '@angular/core';
import {Order} from '../models/order.model';
import {Store} from '../models/store.model';
import {OrderService} from './order.service';
import {NotificationsService} from './notifications.service';
import {UntypedFormGroup} from '@angular/forms';
import {Product} from '../models/product.model';
import {ProductTag} from '../models/product-tag.model';
import {DeliverService} from './deliver.service';
import {Dimension} from '../models/dimension.model';
import {ProductShippingDetail} from '../models/product-shipping-detail.model';
import uid from 'uid';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  toastTitle: string;
  toastMessage: string;
  tags: string[] = [];
  products: string[] = [];
  productShippingDetail: ProductShippingDetail;
  productDimension: Dimension;

  constructor(private orderServices: OrderService,
              private notifyService: NotificationsService,
              private deliverService: DeliverService) { }

  replaceSpaceByDash(word: string): string {
    word = word.trim();
    return word.toLocaleLowerCase().split(' ').join('-');
  }

  replaceSpaceByUnder(word: string): string {
    word = word.trim();
    return word.toLocaleUpperCase().split(' ').join('_');
  }

  removeOrder(order: Order, store: Store): void {
    this.orderServices.deleteOrder(order, order.uidUser, store.storeGroup[0].uidGroup).then(
      () => {
        let totalAmount = 0;
        store.orders.map((singleOrder) => {
          singleOrder.productOrder.map((product) => {
            totalAmount += product.price;
          });
        });

        if (order.validatedAt !== null) {
          if (order.deliveredAt !== null) {
            store.amountDu -= totalAmount;
            this.removeDeliverCmd(order);
          } else if (order.availableAt !== null && order.uidDeliver !== null && order.giveToDeliverAt !== null) {
            store.amountDu -= totalAmount;
            this.removeDeliverCmd(order);
          }

        }

        // this.storeServices.updateStore(store).then(
        //   () => {
        //     this.toastTitle = 'Modification Faite';
        //     this.toastMessage = 'Modification des infos du Store faite avec succès !';
        //     this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
        //   }
        // ).catch(
        //   () => {
        //     this.toastTitle = 'Modification Echouée';
        //     this.toastMessage = 'Modification des infos du Store échouée !';
        //     this.notifyService.showError(this.toastMessage, this.toastTitle);
        //   }
        // );

        this.toastTitle = 'Success';
        this.toastMessage = `Suppression ${order.orderShortCode} réussie!`;
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {
        this.toastTitle = 'Echec';
        this.toastMessage = `Suppression ${order.orderShortCode} échouée !`;
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  removeDeliverCmd(order: Order): void {
    this.orderServices.getDeliverOrderFromStore(order).subscribe(
      result => {
        if (result !== null && result.length > 0) {
          this.orderServices.deleteDeliverOrder(order, order.uidDeliver).then(
            () => {
              this.toastTitle = 'Success';
              this.toastMessage = `Suppression de la commande chez le livreur réussie !`;
              this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
            }
          ).catch(
            () => {
              this.toastTitle = 'Echec';
              this.toastMessage = `Suppression de la commande chez le livreur échouée !`;
              this.notifyService.showError(this.toastMessage, this.toastTitle);
            }
          );
        } else {
          this.toastTitle = 'Echec';
          this.toastMessage = `Pas de livreur correspondant pour cette commande, Suppression de la commande chez le livreur échouée !`;
          this.notifyService.showError(this.toastMessage, this.toastTitle);
        }
      }
    );
  }

  makeOrderDelivered(order: Order): void {
    order.deliveredAt = new Date();
    order.updatedAt = new Date();
    this.orderServices.updateOrder(order, order.store.storeGroup[0].uidGroup).then(
      () => {
        this.toastTitle = 'Success';
        this.toastMessage = 'Commande a été marquée comme Livrée !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {
        this.toastTitle = 'Echec';
        this.toastMessage = 'La Commande n\'a pas été marquée comme Livrée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  makeOrderReadyForShipping(order: Order): void {
    order.availableAt = new Date();
    order.updatedAt = new Date();
    this.orderServices.updateOrder(order, order.store.storeGroup[0].uidGroup).then(
      () => {
        this.toastTitle = 'Success';
        this.toastMessage = 'Commande disponible pour livraison !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {
        this.toastTitle = 'Echec';
        this.toastMessage = 'La Commande n\'a pas été rendu disponible au livreur !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  makeOrderGiveToDeliver(order: Order): void {
    order.giveToDeliverAt = new Date();
    this.orderServices.updateOrder(order, order.store.storeGroup[0].uidGroup).then(
      () => {
        this.toastTitle = 'Success';
        this.toastMessage = 'Commande donnée au livreur !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {
        this.toastTitle = 'Echec';
        this.toastMessage = 'La Commande n\'a pas été donnée au livreur !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  makeOrderValidateCommande(order: Order): void {
    order.validatedAt = new Date();
    order.updatedAt = new Date();
    this.orderServices.updateOrder(order, order.store.storeGroup[0].uidGroup).then(
      () => {
        this.toastTitle = 'Success';
        this.toastMessage = 'Commande validée !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {
        this.toastTitle = 'Echec';
        this.toastMessage = 'Commande non validée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  createProductPromotion(promotionForm: UntypedFormGroup, product: Product): Product {
    const startedAt = new Date(promotionForm.get('startedAt').value);
    const endedAt = new Date(promotionForm.get('endedAt').value);
    const percent = promotionForm.get('percent').value;

    product.promotion = {
      uid: uid(32),
      createdAt: new Date(),
      startedAt,
      endedAt,
      percent,
      valid: false
    };

    product.updatedAt = new Date();

    return product;
  }

  /**
   * Get Store list without undefined values
   * @param stores Store list
   * @return Store List
   */
  getFilteredStores(stores: Store[]): Store[] {
    return stores.filter(
      store => {
        return !(typeof store === 'object' &&
          Object.keys(store).length === 0);
      }
    );
  }

  /**
   * Set/add a Product dimensions object
   * @param productDimension A Product Dimension object
   * @return productDimension The Product Dimension object
   */
  setProductDimension(productDimension: Dimension): Dimension {
    return this.productDimension = {
      uid: uid(32),
      title: productDimension.title,
      weight: productDimension.weight,
      length: productDimension.length,
      width: productDimension.width,
      height: productDimension.height,
      fragileBox: productDimension.fragileBox,
      createdAt: new Date(),
      updatedAt: null,
      deletedAt: null
    };
  }

  /**
   * Nullify/empty a product dimensions object
   * @return productDimension The empty product dimension object
   */
  removeProductDimension(): Dimension {
    return this.productDimension = null;
  }

  /**
   * Set/add a Product Shipping Price object
   * @param productShippingDetail A Product Shipping Price object
   * @return productShippingDetail ProductShippingDetail
   */
  setProductShippingDetail(productShippingDetail: ProductShippingDetail): ProductShippingDetail {
    return this.productShippingDetail = {
      uid: uid(32),
      freeShipping: productShippingDetail.freeShipping,
      defaultShippingPrice: productShippingDetail.defaultShippingPrice,
      expressShippingPrice: productShippingDetail.expressShippingPrice,
      expressShippingDetail: productShippingDetail.expressShippingDetail,
      defaultShippingDetail: productShippingDetail.defaultShippingDetail
    };
  }

  /**
   * Set/add a Product Tag
   * @param uidTag A Tag uid
   * @param tags A Predefined Tags list
   * @return Tag list
   */
  setTag(uidTag: string, tags: ProductTag[]): string[] {
    if (!this.tags.includes(uidTag)) {
      this.tags.push(uidTag);
    }

    return  tags.map(
      productTag => {
        if (this.tags.includes(productTag.uid)) {
          return productTag.tag.title;
        }
      }
    ).filter(productTag => productTag);
  }

  setProduct(uidProduct: string, products: Product[]): string[] {
    if (!this.products.includes(uidProduct)) {
      this.products.push(uidProduct);
    }

    return  products.map(
      product => {
        if (this.products.includes(product.uid)) {
          return product.name;
        }
      }
    ).filter(productTag => productTag);
  }

  /**
   * Set a list of selected tags
   * @param tagsUid A list of Tag uid
   * @param tags A Tag list from firebase
   * @return A list filtered by the name of the selected tags
   */
  selectedTagNames(tagsUid: string[], tags: ProductTag[]): string[] {
    return tags.map(
      productTag => {
        if (tagsUid.includes(productTag.uid)) {
          return productTag.tag.title;
        }
      }
    ).filter(productTag => productTag);
  }

  selectedProdNames(productsUid: string[], products: Product[]): string[] {
    return products.map(
      product => {
        if (productsUid.includes(product.uid)) {
          return product.name;
        }
      }
    ).filter(product => product);
  }

  /**
   * Remove a Product Tag
   * @param tagTitle A Tag title
   * @param tagsList A Reference Tags-list
   * @return Tag list
   */
  removeTag(tagTitle: string, tagsList: ProductTag[]): string[] {
    tagsList.map((theTag) => {
      if (theTag.tag.title === tagTitle) {
        this.tags.map((tag, index) => {
          if (tag === theTag.uid){
            this.tags.splice(index, 1);
          }
        });
      }
    });

    return this.tags;
  }

    removeProductUid(productName: string, products: Product[]): string[] {
        products.map((theProduct) => {
      if (theProduct.name === productName) {
        this.products.map((product, index) => {
          if (product === theProduct.uid){
            this.products.splice(index, 1);
          }
        });
      }
    });

    return this.products;
  }


  /**
   * Method that affect an order to a Deliver
   * @param order The order
   * @param uidDeliver A Deliver Unique ID
   */
  affectOrder(order: Order, uidDeliver: string): void {
    const data = {
      uid: order.uid,
      uidStore: order.uidStore,
      uidGroup: order.store.storeGroup[0].uidGroup
    };
    this.deliverService.affectOrderTo(uidDeliver, data).then(
      () => {
        this.toastTitle = 'Success';
        this.toastMessage = 'Commande affectée au livreur !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {
        this.toastTitle = 'Echec';
        this.toastMessage = 'La Commande n\'a pas été affectée au livreur !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }
}
