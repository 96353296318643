<div *ngIf="selectedDeliver$ | async as deliver">
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-8">
      <h2><strong>{{this.deliver?.name | uppercase}}</strong></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/delivers']">Livreurs</a>
        </li>
        <li class="breadcrumb-item active">
          <strong>Livreur</strong>
        </li>
      </ol>
    </div>
    <div class="col-lg-4 p-1 align-content-center"></div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="tabs-container">
          <ul class="nav nav-tabs">
            <li><a class="nav-link active" data-toggle="tab" href="#profil-tab"> <i class="fa fa-user"></i> Profile</a>
            </li>
            <li><a class="nav-link" data-toggle="tab" href="#dashboard-tab"><i class="fa fa-dashboard"></i> Dashboard</a></li>
            <li><a class="nav-link" data-toggle="tab" href="#order-tab"><i class="fa fa-shopping-cart"></i> Orders</a></li>
            <li><a class="nav-link" data-toggle="tab" href="#payment-tab"><i class="fa fa-money"></i> Paiements</a></li>
          </ul>
          <div class="tab-content">
            <div id="profil-tab" class="tab-pane active">
              <div class="row">
                <div class="col-md-10 offset-1">
                  <div class="tabs-container">
                    <ul class="nav nav-tabs">
                      <li><a class="nav-link active" data-toggle="tab" href="#tab-profile"> <i class="fa fa-user"></i>
                        Modifier Profile</a>
                      </li>
                      <li><a class="nav-link" data-toggle="tab" href="#tab-pwd"><i class="fa fa-asterisk"></i> Modifier
                        Password</a></li>
                      <li><a class="nav-link" data-toggle="tab" href="#tab-email"><i class="fa fa-at"></i> Modifier
                        Email</a></li>
                      <li><a class="nav-link" data-toggle="tab" href="#tab-rating"><i class="fa fa-star"></i> Ratings</a>
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div id="tab-profile" class="tab-pane active">
                        <div class="panel-body">
                          <h4 class="text-center"><strong> Deliver Profile</strong></h4>
                          <hr>
                          <div class="row">
                            <div class="col-md-12">
                              <form (ngSubmit)="onSubmit()" [formGroup]="profilForm">
                                <div class="offset-4 mb-3">
                                  <div class="profile-img">
                                    <img
                                      src="{{refImage || this?.deliver?.imageUrl || 'https://ssl.gstatic.com/accounts/ui/avatar_2x.png'}}"
                                      alt="Profil" class="img-thumbnail rounded-circle">
                                  </div>
                                </div>

                                <div class="form-group row"><label class="col-sm-2 col-form-label">Name</label>
                                  <div class="col-sm-10">
                                    <div class="input-group m-b">
                                      <div class="input-group-prepend">
                                        <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                                      </div>
                                      <input type="text" placeholder="name" class="form-control" id="name"
                                             formControlName="name" required="required" minlength="6"
                                             [ngClass]="{'is-invalid' : (profilForm.get('name').touched || profilForm.get('name').dirty) && !profilForm.get('name').valid}">
                                      <div class="invalid-feedback">
                                      <span
                                        *ngIf="profilForm.get('name').errors?.required">Store Name is required !</span>
                                        <span
                                          *ngIf="profilForm.get('name').errors?.minlength">Valid Store Name must be longer than 6 caracters !</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row"><label class="col-sm-2 col-form-label">Description</label>
                                  <div class="col-sm-10">
                                    <div class="input-group m-b">
                                      <div class="input-group-prepend">
                                        <span class="input-group-addon"><i class="fa fa-italic"></i></span>
                                      </div>
                                      <input type="text" placeholder="Description" class="form-control" minlength="10"
                                             formControlName="description" id="description"
                                             [ngClass]="{'is-invalid' : (profilForm.get('description').touched || profilForm.get('description').dirty) && !profilForm.get('description').valid}">
                                      <div class="invalid-feedback">
                      <span
                        *ngIf="profilForm.get('description').errors?.minlength">Valid Store description must be longer than 10 caracters !</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row"><label class="col-sm-2 col-form-label">Adresse</label>
                                  <div class="col-sm-10">
                                    <div class="input-group m-b">
                                      <div class="input-group-prepend">
                                        <span class="input-group-addon"><i class="fa fa-home"></i></span>
                                      </div>
                                      <input ngx-google-places-autocomplete #placesRef="ngx-places" placeholder="Adresse"
                                             class="form-control" minlength="10" (onAddressChange)="handleAddressChange($event)"
                                             formControlName="adresse" id="adresse" required="required" type="text"
                                             [ngClass]="{'is-invalid' : (profilForm.get('adresse').touched || profilForm.get('adresse').dirty) && !profilForm.get('adresse').valid}">
                                      <div class="invalid-feedback">
                                <span
                                  *ngIf="profilForm.get('adresse').errors?.required">Store adresse is required !</span>
                                        <span
                                          *ngIf="profilForm.get('adresse').errors?.minlength">Valid Store description must be longer than 10 caracters !</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row"><label class="col-sm-2 col-form-label">Image</label>
                                  <div class="col-sm-10">
                                    <div class="input-group m-b">
                                      <div class="input-group-prepend">
                                        <span class="input-group-addon"><i class="fa fa-image"></i></span>
                                      </div>
                                      <input type="file" accept="image/*" placeholder="Price" class="form-control"
                                             id="image"
                                             formControlName="image"
                                             (change)="onUpload($event)"
                                             [ngClass]="{'is-invalid' : (profilForm.get('image').touched || profilForm.get('image').dirty) && !profilForm.get('image').valid}">

                                      <div class="invalid-feedback">
                                <span
                                  *ngIf="profilForm.get('image').errors?.required">Store image is required !</span>
                                      </div>
                                    </div>
                                    <div class="progress">
                                      <div class="progress-bar" [style.width.%]="pourcentage" role="progressbar"
                                           [attr.aria-valuenow]="pourcentage" aria-valuemin="0" aria-valuemax="100">
                                      <span class="text-center" *ngIf="pourcentage > 0">{{pourcentage | number}}
                                        %</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="hr-line-dashed"></div>

                                <div class="form-group row">
                                  <div class="col-sm-4 col-sm-offset-2">
                                    <button class="btn btn-white btn-sm" type="submit">Cancel</button>
                                    <button  class="btn btn-primary btn-sm" type="submit"
                                             [title]="profilForm.valid ? 'Modifier Profile' : 'Disable until valid form data'"
                                             [disabled]="!profilForm.valid">Edit
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="tab-pwd" class="tab-pane">
                        <div class="panel-body">
                          <h4 class="text-center"><strong> Change Password</strong></h4>
                          <hr>
                          <div class="row">
                            <div class="col-md-12 text-black">
                              <form (ngSubmit)="resetPassword()" [formGroup]="passwordForm">
                                <div class="form-group row"><label class="col-sm-3 col-form-label">New Password</label>
                                  <div class="col-sm-9">
                                    <div class="input-group m-b">
                                      <div class="input-group-prepend">
                                        <span class="input-group-addon"><i class="fa fa-asterisk"></i></span>
                                      </div>
                                      <input type="password" [type]="fieldTextType ? 'text' : 'password'"
                                             placeholder="New Password" class="form-control" id="newPassword"
                                             formControlName="newPassword" required="required" minlength="6"
                                             [ngClass]="{'is-invalid' : (passwordForm.get('newPassword').touched || passwordForm.get('newPassword').dirty) && !passwordForm.get('newPassword').valid}">
                                      <div class="input-group-append">
                                      <span class="input-group-text">
                                        <i [ngClass]="{'fa-eye': !fieldTextType,'fa-eye-slash': fieldTextType}"
                                           (click)="toggleFieldTextType()" class="fa"></i>
                                      </span>
                                      </div>
                                      <div class="invalid-feedback">
                                        <span *ngIf="passwordForm.get('newPassword').errors?.required">Password is required !</span>
                                        <span *ngIf="passwordForm.get('newPassword').errors?.minlength">
                                        Valid Password must be longer than 6 caracters !</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="hr-line-dashed"></div>

                                <div class="form-group row">
                                  <div class="col-sm-4 col-sm-offset-2">
                                    <button class="btn btn-white btn-sm" type="submit">Cancel</button>
                                    <button  class="btn btn-primary btn-sm" type="submit"
                                             [title]="passwordForm.valid ? 'Modifier Mot de Passe' : 'Disable until valid form data'"
                                             [disabled]="!passwordForm.valid">Modify
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="tab-email" class="tab-pane">
                        <div class="panel-body">
                          <h4 class="text-center"><strong> Change Email</strong></h4>
                          <hr>
                          <div class="row">
                            <div class="col-md-12 text-black">
                              <form (ngSubmit)="resetEmail()" [formGroup]="emailForm">
                                <div class="form-group row"><label class="col-sm-3 col-form-label">New Email</label>
                                  <div class="col-sm-9">
                                    <div class="input-group m-b">
                                      <div class="input-group-prepend">
                                        <span class="input-group-addon"><i class="fa fa-at"></i></span>
                                      </div>
                                      <input type="email" placeholder="New Email" class="form-control"
                                             id="newEmail" email="email"
                                             formControlName="newEmail"
                                             required="required"
                                             [ngClass]="{'is-invalid' : (emailForm.get('newEmail').touched || emailForm.get('newEmail').dirty) && !emailForm.get('newEmail').valid}">
                                      <div class="invalid-feedback">
                                <span
                                  *ngIf="emailForm.get('newEmail').errors?.required">Email is required !</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="hr-line-dashed"></div>

                                <div class="form-group row">
                                  <div class="col-sm-4 col-sm-offset-2">
                                    <button class="btn btn-white btn-sm" type="submit">Cancel</button>
                                    <button  class="btn btn-primary btn-sm" type="submit"
                                             [title]="emailForm.valid ? 'Modifier Email' : 'Disable until valid form data'"
                                             [disabled]="!emailForm.valid">Modify
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="tab-rating" class="tab-pane">
                        <div class="panel-body">
                          <div class="ibox ">
                            <div class="ibox-title">
                              <h5>Rating list</h5>
                              <div class="ibox-tools">
                                <!--                              <span>Moyen Rating : <strong>{{this?.average || 0}}</strong></span>-->
                              </div>
                            </div>
                            <div class="ibox-content table-responsive">
                              <table datatable class="table table-striped table-bordered table-hover no-margins"
                                     *ngIf="ratings.length !== 0">
                                <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>User</th>
                                  <th>Commentaire</th>
                                  <th>Rate</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let rating of ratings" class="text-center">
                                  <td><i
                                    class="fa fa-clock-o"></i> {{rating.createdAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}
                                  </td>
                                  <td>{{rating.userDisplayName | titlecase}}</td>
                                  <td>{{rating.message}}</td>
                                  <td class="bg-white text-black-50">
                                    <i class="fa fa-star" style="color: yellow"></i><strong> {{rating.rating}}</strong>
                                  </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                  <th>Date</th>
                                  <th>User</th>
                                  <th>Commentaire</th>
                                  <th>Rate</th>
                                </tr>
                                </tfoot>
                              </table>

                              <table datatable class="table table-striped table-bordered table-hover"
                                     *ngIf="ratings.length === 0">
                                <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>User</th>
                                  <th>Commentaire</th>
                                  <th>Rate</th>
                                </tr>
                                </thead>
                                <tbody>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div id="dashboard-tab" class="tab-pane">
              <div class="ibox ">
                <div class="ibox-title">
                  <h5>Dashboard du Livreur </h5>
                  <div class="ibox-tools">
                  </div>
                </div>
                <div class="ibox-content">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="col-lg-6 d-sm-inline-flex">
                        <div class="ibox">
                          <div class="ibox-title">
                            <span class="label label-success float-right">All</span>
                            <h5>Commandes</h5>
                          </div>
                          <div class="ibox-content">
                            <h1 class="no-margins">{{this?.deliver?.deliveriesCount | number}}</h1>
                            <small>Total commandes Livrées</small>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 d-sm-inline-flex">
                        <div class="ibox ">
                          <div class="ibox-title">
                            <span class="label label-info float-right">All</span>
                            <h5>Distance </h5>
                          </div>
                          <div class="ibox-content">
                            <h1 class="no-margins">{{this?.deliver?.distanceTraveled | number}} Mètre (s)</h1>
                            <small>Distance Parcourue</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <ul class="stat-list list-inline text-center">
                        <li class="dropdown-divider"></li>
                        <li class="alert alert-warning col-sm-4 px-2 mx-2">
                          <h2 class="no-margins ">{{this?.deliver?.amountDu | currency: 'USD'}}</h2>
                          <small>Total Montant dû</small>
                        </li>
                        <li class="alert alert-success col-sm-4 px-2 mx-2">
                          <h2 class="no-margins ">{{this?.deliver?.amountReceived | currency: 'USD'}}</h2>
                          <small>Total Montant Payés</small>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="order-tab" class="tab-pane">
              <div class="row">
                <div class="col-md-10 offset-1">
                  <div class="tabs-container">
                    <ul class="nav nav-tabs">
                      <li><a class="nav-link active" data-toggle="tab" href="#tab-served">
                        <i class="fa fa-check-square-o"></i>Served Orders</a>
                      </li>
                      <li><a class="nav-link" data-toggle="tab" href="#tab-pending"> <i class="fa fa-clock-o"></i>
                        Pending Orders</a>
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div id="tab-served" class="tab-pane active">
                        <div class="ibox ">
                          <div class="ibox-title">
                            <h5>Commandes du Livreur </h5>
                            <div class="ibox-tools">
                            </div>
                          </div>
                          <div class="ibox-content">
                            <div class="table-responsive">
                              <table datatable class="table table-striped table-bordered table-hover dataTables-example"
                                     *ngIf="deliver.orders.length !== 0">
                                <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Short-Code</th>
                                  <th>Payment-Code</th>
                                  <th>Nombre des produits</th>
                                  <th>Prix Total</th>
                                  <th><i class="fa fa-envelope"></i></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let order of deliver.orders" class="w-100">
                                  <td>{{this?.order?.availableAt?.toDate() | date: 'yyyy-MMM-dd, hh:mm a'}}</td>
                                  <td>{{order?.orderShortCode}}</td>
                                  <td>{{order?.paymentCode}}</td>
                                  <td>{{order?.productCount}}</td>
                                  <td>{{order?.totalAmount | currency: 'USD'}}</td>
                                  <td
                                    *ngIf="order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt === null"><span
                                    class="text-black-50">Commande en Attente</span></td>
                                  <td *ngIf="order?.messageOfRejection !== '' && order?.declinedAt !== null"><span
                                    class="text-danger">{{order?.messageOfRejection}}</span></td>
                                  <td
                                    *ngIf="order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt === null">
                                    <span class="text-navy">Commande Validée</span>
                                  </td>
                                  <td
                                    *ngIf="order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt !== null && order.giveToDeliverAt === null"><span
                                    class="text-warning"> Prêt pour la livraison à {{this?.order?.availableAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}</span>
                                  </td>
                                  <td
                                    *ngIf="order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt !== null && order.giveToDeliverAt !== null && order.deliveredAt === null">
                        <span class="text-navy"> Remis au livreur à
                          {{this?.order?.giveToDeliverAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}</span>
                                  </td>
                                  <td
                                    *ngIf="order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt !== null && order.giveToDeliverAt !== null && order.deliveredAt !== null">
                        <span class="text-navy"> Livré à
                          {{this?.order?.deliveredAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}</span>
                                  </td>
                                </tr>
                                </tbody>
                              </table>

                              <table datatable class="table table-striped table-bordered table-hover"
                                     *ngIf="deliver.orders.length === 0">
                                <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Short-Code</th>
                                  <th>Payment-Code</th>
                                  <th>Nombre des produits</th>
                                  <th>Prix Total</th>
                                  <th><i class="fa fa-envelope"></i></th>
                                </tr>
                                </thead>
                                <tbody>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="tab-pending" class="tab-pane">
                        <div class="ibox ">
                          <div class="ibox-title">
                            <h5>Commandes en attente </h5>
                            <div class="ibox-tools">
                            </div>
                          </div>
                          <div class="ibox-content">
                            <div class="table-responsive">
                              <table datatable class="table table-striped table-bordered table-hover dataTables-example"
                                     *ngIf="allValidatedOrders.length !== 0">
                                <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Short-Code</th>
                                  <th>Payment-Code</th>
                                  <th>Nombre des plats</th>
                                  <th>Prix Total</th>
                                  <th><i class="fa fa-envelope"></i></th>
                                  <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let order of allValidatedOrders" class="w-100">
                                  <td>{{this?.order?.availableAt?.toDate() | date: 'yyyy-MMM-dd, hh:mm a'}}</td>
                                  <td>{{order?.orderShortCode}}</td>
                                  <td>{{order?.paymentCode}}</td>
                                  <td>{{order?.productCount}}</td>
                                  <td>{{order?.totalAmount | currency: 'USD'}}</td>
                                  <td
                                    *ngIf="order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt === null"><span
                                    class="text-black-50">Commande en Attente</span></td>
                                  <td *ngIf="order?.messageOfRejection !== '' && order?.declinedAt !== null"><span
                                    class="text-danger">{{order?.messageOfRejection}}</span></td>
                                  <td
                                    *ngIf="order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt === null">
                                    <span class="text-navy">Commande Validée</span>
                                  </td>
                                  <td
                                    *ngIf="order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt !== null && order.giveToDeliverAt === null"><span
                                    class="text-warning"> Prêt pour la livraison à {{this?.order?.availableAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}</span>
                                  </td>
                                  <td
                                    *ngIf="order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt !== null && order.giveToDeliverAt !== null && order.deliveredAt === null">
                        <span class="text-navy"> Remis au livreur à
                          {{this?.order?.giveToDeliverAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}</span>
                                  </td>
                                  <td
                                    *ngIf="order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt !== null && order.giveToDeliverAt !== null && order.deliveredAt !== null">
                        <span class="text-navy"> Livré à
                          {{this?.order?.deliveredAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}</span>
                                  </td>
                                  <td>

                                    <a (click)="takeOrder(order)" *ngIf="order.validatedAt !== null && order.availableAt !== null && order.uidDeliver === ''"
                                       data-toggle="tooltip" data-placement="bottom" class="btn btn-default btn-xs text-black-50 mr-1"
                                       title="Give to Deliver"><i class="fa fa-handshake-o text-black-50 pr-1"></i> Affect Order</a>
                                  </td>
                                </tr>
                                </tbody>
                              </table>

                              <table datatable class="table table-striped table-bordered table-hover"
                                     *ngIf="allValidatedOrders.length === 0">
                                <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Code</th>
                                  <th>Nombre des plats</th>
                                  <th>Prix Total</th>
                                  <th><i class="fa fa-envelope"></i></th>
                                  <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="payment-tab" class="tab-pane">
              <div class="ibox ">
                <div class="ibox-title">
                  <h5>Paiements du Livreur </h5>
                  <div class="ibox-tools">
                  </div>
                </div>
                <div class="ibox-content">
                  <div class="table-responsive">
                    <table datatable class="table table-striped table-bordered table-hover dataTables-example"
                           *ngIf="paiements.length !== 0">
                      <thead>
                      <tr>
                        <th>Date de Invoice</th>
                        <th>Montant Dû</th>
                        <th>Montant Perçu</th>
                        <th>Description</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let paiement of paiements" class="w-100">
                        <td><span class="pie">{{paiement.createdAt?.toDate() | date: 'dd-MM-yyyy, hh:mm'}}</span></td>
                        <td>{{paiement.amountDu}} $</td>
                        <td>{{paiement.amountReceived}} $</td>
                        <td><span>{{paiement.description}}</span></td>
                      </tr>
                      </tbody>
                    </table>

                    <table datatable class="table table-striped table-bordered table-hover" *ngIf="paiements.length === 0">
                      <thead>
                      <tr>
                        <th>Date de Invoice</th>
                        <th>Montant Dû</th>
                        <th>Montant Perçu</th>
                        <th>Description</th>
                      </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

