import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Category} from '../../models/category.model';
import {NotificationsService} from '../../services/notifications.service';
import {UploadService} from '../../services/upload.service';
import {StoreService} from '../../services/store.service';
import {ActivatedRoute} from '@angular/router';
import {Store} from '../../models/store.model';
import {CategoryStoreService} from '../../services/category-store.service';
import {catchError, Observable, of, shareReplay, Subscription} from 'rxjs';
import {Address as gAddress} from 'ngx-google-places-autocomplete/objects/address';
import {Address} from '../../models/address.model';
import {AddressService} from '../../services/address.service';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {StoreCategory} from '../../models/store-category.model';
import uid from 'uid';
import {StoreAddress} from '../../models/store-address.model';

@Component({
  selector: 'app-storeedit',
  templateUrl: './storeedit.component.html',
  styleUrls: ['./storeedit.component.css']
})
export class StoreeditComponent implements OnInit, OnDestroy {

  @ViewChild('placesRef', {static: false}) placesRef: GooglePlaceDirective;
  storeForm: UntypedFormGroup;
  store: Store;
  toastTitle: string;
  toastMessage: string;
  categories: Category[];
  uid;
  image: string;
  pourcentage = 0;
  upload = false;
  subscriptions: Subscription = new Subscription();
  idStore: string;
  idGroupe: string;
  storeAddress: Address;

  errorMessage = '';
  store$: Observable<Store> = this.storeService.selectedStore$
    .pipe(
      shareReplay(1),
      catchError(err => {
        console.log('**** ERRROR=>', err);
        this.errorMessage = err;
        return of(null);
      })
    );
  storeCategories$: Observable<Category[]> = this.categoryStoreService.storeCategories$
    .pipe(
      catchError((error) => {
        this.errorMessage = error;
        return of(null);
      })
    );

  constructor(private notifyService: NotificationsService,
              private uploadService: UploadService,
              private storeService: StoreService,
              private categoryStoreService: CategoryStoreService,
              private route: ActivatedRoute,
              private addressService: AddressService) {
    void this.categoryStoreService.getCategoriesStoreWithSupabase();
  }

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.idStore = token.get('idStore');
          this.idGroupe = token.get('idgroup');

          void this.storeService.getStoreWithSupabase(this.idStore).then();
          this.subscriptions.add(
            this.store$.subscribe(
              store => {
                if (store !== null) {
                  this.store = store;
                  this.storeForm.patchValue({
                    name: store.name,
                    description: store.description,
                    adresse: `${store.storeAddress[0].address.street}, ${store.storeAddress[0].address.city}, ${store.storeAddress[0].address.country}`,
                    categoryStore: store.storeCategory[0]?.uidCategory
                  });
                }
              }
            )
          );

          this.subscriptions.add(
            this.storeCategories$.subscribe(
              categories => this.categories = categories
            )
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.storeForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, Validators.min(3)]),
      description: new UntypedFormControl('', [Validators.minLength(10)]),
      adresse: new UntypedFormControl(),
      categoryStore: new UntypedFormControl(),
      image: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    if (this.storeForm.get('image').value !== null) {
      this.uploadService.editStoreImage(this.uploadService.images['store-images'][0], this.store.uid);
      this.updateValues();
    } else {
      this.updateValues();
    }
    this.uploadService.dropImageList();
  }

  updateValues(): void {
    this.store.name = this.storeForm.get('name').value;
    this.store.description = this.storeForm.get('description').value;
    this.store.updatedAt = new Date();

    if (this.store.storeCategory.length === 0){
      const storeCategory: StoreCategory = {
        uid: uid(32),
        uidStore: this.store.uid,
        uidCategory: this.storeForm.get('categoryStore').value
      };
      void this.categoryStoreService.addStoreCategoryWithSupabase(storeCategory)
        .then((response) => {
          if (response.error === null) {
            this.toastTitle = 'Ajout Fait';
            this.toastMessage = `Enregistrement du Store Category fait avec succès !`;
            this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
          } else {
            this.toastTitle = 'Echec';
            this.toastMessage = `Echec de l'Ajout du Store Category`;
            this.notifyService.showError(this.toastMessage, this.toastTitle);
          }
        });
    }

    if (this.store.storeAddress.length === 0){
      const storeAdress: StoreAddress = {
        uid: uid(32),
        uidStore: this.store.uid,
        uidAddress: this.storeAddress.uid
      };
      void this.addressService.addAddressWithSupabase(this.storeAddress)
        .then((res) => {
          if (res.error === null) {
            void this.storeService.addStoreAddressWithSupabase(storeAdress)
              .then((r) => {
                if (r.error === null){
                  this.toastTitle = 'Ajout Fait';
                  this.toastMessage = `Enregistrement de l'adresse fait avec succès !`;
                  this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
                } else{
                  this.toastTitle = 'Echec';
                  this.toastMessage = `Echec de l'Ajout de l'adresse`;
                  this.notifyService.showError(this.toastMessage, this.toastTitle);
                }
              });
          }
        });
    }
    this.storeService.updateStoreWithSupabase(this.store).then(
      () => {
        this.toastTitle = 'Modification Faite';
        this.toastMessage = 'Modification du Store faite avec succès !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      (err) => {
        console.log(err);
        this.toastTitle = 'Modification Echouée';
        this.toastMessage = 'Modification du Store échouée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  public handleAddressChange(address: gAddress): void {
    this.storeAddress = this.addressService.handleAddressChange(address);
  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `supabase/groups/${this.idGroupe}/stores/${this.idStore}/${this.idStore}`, 'store-images');
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

}
