<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-9">
    <h2>Bannière</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/banners']">Bannières</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Bannière</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-3">
    <div [ngSwitch]="this.state">
      <h4 *ngSwitchCase="true" class="text-navy"><strong>La bannière est disponible</strong></h4>
      <h4 *ngSwitchCase="false" class="text-danger"><strong>Le bannière est indisponible</strong></h4>
    </div>
    <bSwitch  [ngModel]="state" [switch-size]="'small'"
             [switch-on-color]="'success'" [switch-off-color]="'danger'"
             [switch-off-text]="'Indisponible'" [switch-on-text]="'Disponible'"
             [switch-base-class]="'bootstrap-switch'" [switch-wrapper-class]="'bootstrap-switch'"
             (changeState)="onChange($event)">
    </bSwitch>
  </div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/banners']" class="btn btn-sm btn-primary"><i class="fa fa-chevron-left"></i> Back</a>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">

      <div class="ibox product-detail">
        <div class="ibox-content">

          <div class="row">
            <div class="col-md-7">
              <div class="product-images">
                <img src="{{banner?.imageUrl}}" alt="" width="100%">
              </div>
            </div>
            <div class="col-md-5">

              <h2 class="font-bold m-b-xs">
                End At: {{banner?.endAt?.toDate() | date: 'dd-MMMM-yyyy, hh:mm a' || '-'}}
              </h2>
              <span >
                <a [routerLink]="['/banners', uidBanner, 'edit']" class="btn btn-success btn-xs"
                   data-toggle="tooltip" data-placement="bottom" title="Modifier la Bannière">
                  <i class="fa fa-pencil ml-2 pr-1"></i> Edit</a>
              </span>
              <div class="m-t-md" [ngSwitch]="banner?.available">
                <h2 class="product-main-price font-weight-bold text-navy" *ngSwitchCase="true">
                  Disponible
                </h2>
                <h2 class="product-main-price font-weight-bold text-danger" *ngSwitchCase="false">
                  Indisponible
                </h2>
              </div>
              <hr>
              <div class="well">
                <span class="font-weight-bold"> Link</span>:
                <a href="{{banner?.link || '#'}}" class="text-success">{{banner?.link || '-'}}</a>
                <br>
                <span class="font-weight-bold"> Creation Date</span>:
                {{banner?.createdAt?.toDate() | date: 'dd-MMMM-yyyy, hh:mm a'}}
                <br>
                <span class="font-weight-bold"> Last modification</span>:
                {{banner?.updatedAt?.toDate() | date: 'dd-MMMM-yyyy, hh:mm a'}}
              </div>
              <br>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
