import {Injectable} from '@angular/core';
import {Store} from '../models/store.model';
import {FirestoreService} from './firestore.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {supabase} from './supabase';
import {StoreImage} from '../models/store-image.model';
import {map} from 'rxjs/operators';
import {StoreAddress} from '../models/store-address.model';
import {StoreGroup} from '../models/store-group.model';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private currentStore: BehaviorSubject<Store> = new BehaviorSubject<Store>(null);
  currentStore$ = this.currentStore.asObservable();
  handlerError: any = '';

  private STORE_TABLE = 'Store';
  private STOREIMAGE_TABLE = 'StoreImage';
  private STOREGROUP_TABLE = 'StoreGroup';
  private STOREADDRESS_TABLE = 'StoreAddress';

  private state = new BehaviorSubject<any>({stores: []});
  stores$: Observable<Store[]> = this.state
    .asObservable()
    .pipe(map((state) => state.stores));

  private storeSelectedAction = new BehaviorSubject<any>({store: null});
  selectedStore$: Observable<Store> = this.storeSelectedAction
    .asObservable().pipe((uState) => uState);

  constructor(private firestoreService: FirestoreService) {
  }

  getStores(uidGroupe: string): Observable<Store[]> {
    return this.firestoreService.col$(`groups/${uidGroupe}/stores`);
  }

  getStore(uidGroupe: string, uidStore: string): void {
    this.firestoreService.doc$(`groups/${uidGroupe}/stores/${uidStore}`).subscribe(
      (store: Store) => {
        this.currentStore.next(store);
      }
    );
  }

  getStoreRatings(uidGroupe: string, uidStore: string): Observable<any[]> {
    return this.firestoreService.col$(`groups/${uidGroupe}/stores/${uidStore}/rating-stores/`);
  }

  // updateStore(store: Store): Promise<any> {
  //   this.firestoreService.update(`stores/${store.uid}`, store).then();
  //   return this.firestoreService.update(`groups/${store.uidGroup}/stores/${store.uid}`, store);
  // }
  //
  // addStore(store: Store): Promise<any> {
  //   this.firestoreService.set(`stores/${store.uid}`, store).then();
  //   return this.firestoreService.set(`groups/${store.uidGroup}/stores/${store.uid}`, store);
  // }

  // storeAvecSommeDue(uidGroupe: string): Observable<Store[]> {
  //   return this.firestoreService.getSign$(`groups/${uidGroupe}/stores`, 'amountDu', '>', 0);
  // }

  updateEtat(uidGroupe: string, uidStore: string, state): Promise<any> {
    this.firestoreService.update2(
      `stores/${uidStore}/`, {
        available: state
      }
    ).then();
    return this.firestoreService.update2(
      `groups/${uidGroupe}/stores/${uidStore}/`, {
        available: state
      }
    );
  }

  lockStore(store: Store): Promise<any> {
    this.firestoreService.update2(`stores/${store.uid}/`, {
      lockedAt: new Date(),
      available: false
    }).then();
    return this.firestoreService.update2(`groups/${store.storeGroup[0].uidGroup}/stores/${store.uid}/`, {
      lockedAt: new Date(),
      available: false
    });
  }

  restoreStore(store: Store): Promise<any> {
    this.firestoreService.update2(`stores/${store.uid}/`, {lockedAt: null}).then();
    return this.firestoreService.update2(`groups/${store.storeGroup[0].uidGroup}/stores/${store.uid}/`, {lockedAt: null});
  }

  deleteStore(uidGroupe: string, uidStore: string): Promise<any> {
    this.firestoreService.update2(
      `stores/${uidStore}/`, {
        deletedAt: Date.now(),
        available: false
      }
    ).then();
    return this.firestoreService.update2(
      `groups/${uidGroupe}/stores/${uidStore}/`, {
        deletedAt: Date.now(),
        available: false
      }
    );
  }

  // removeStore(store: Store, uidGroup: string): Promise<any>{
  //   this.firestoreService.delete(`stores/${store.uid}/`).then();
  //   return this.firestoreService.delete(`groups/${uidGroup}/stores/${store.uid}/`);
  // }
  //
  // deleteRating(rating, uidGroupe: string, uidStore): Promise<any> {
  //   this.firestoreService.delete(`stores/${uidStore}/rating-stores/${rating.uid}`).then();
  //   return this.firestoreService.delete(`groups/${uidGroupe}/stores/${uidStore}/rating-stores/${rating.uid}`);
  // }

  /**
   * SUPABASE
   */

  async getStoresWithSupabase(): Promise<void> {
    const {data} = await supabase
      .from(this.STORE_TABLE)
      .select('*, storeImage:StoreImage(image:Image(url, available)), storeGroup:StoreGroup(group:Group(uid, name))')
      .is('deletedAt', null)
      .order('uid', {ascending: false});

    this.state.next({
      stores:
        data?.map((store: Store) => (store)) ?? [],
    });
  }

  async getStoreWithSupabase(uidStore: string): Promise<void> {
    const {data} = await supabase
      .from(this.STORE_TABLE)
      .select(`
        *,
        storeImage:StoreImage(image:Image(*)),
        storeAddress: StoreAddress(*, address: Address(*)),
        storesGroup:StoreGroup(group:Group(uid, name)),
        storeCategory:StoreCategory(category: Category(*)),
        storeUser:StoreUser(*),
        schedule:StoreSchedule(*),
        orders:Order(*),
        ratingStore: RatingStore(rating:Rating(*)),
        products: Product(
          *,
          images:ImageProduct(image:Image(url, available)),
          promotion: Promotion(*),
          productPromoCode: ProductPromoCode(
            *,
            promoCode: PromoCode(
              *,
              productPromoCode: ProductPromoCode(
                *,
                product: Product(*)
                )
            )),
          categoryProduct: CategoryProduct(*, category: Category(*)))
      `)
      .eq('uid', uidStore).single();

    this.storeSelectedAction.next(data ?? null);
  }

  async addStoreWithSupabase(store: Store): Promise<any> {
    const {error} = await supabase
      .from(this.STORE_TABLE)
      .insert(store).single();
    return {error};
  }

  async addStoreImageWithSupabase(storeImage: StoreImage): Promise<any> {
    const {error} = await supabase
      .from(this.STOREIMAGE_TABLE)
      .insert(storeImage).single();
    return {error};
  }

  async addStoreGroupWithSupabase(storeGroup: StoreGroup): Promise<any> {
    const {error} = await supabase
      .from(this.STOREGROUP_TABLE)
      .insert(storeGroup).single();
    return {error};
  }

  async addStoreAddressWithSupabase(storeAddress: StoreAddress): Promise<any> {
    const {error} = await supabase
      .from(this.STOREADDRESS_TABLE)
      .insert(storeAddress).single();
    return {error};
  }

  async updateStoreWithSupabase(store: Store): Promise<any> {
    const {error} = await supabase
      .from(this.STORE_TABLE)
      .update(store)
      .match({uid: store.uid});
    return {error};
  }

  async updateStoreStateWithSupabase(uidStore: string, state: boolean): Promise<any> {
    const {data} = await supabase
      .from(this.STORE_TABLE)
      .update({available: state})
      .match({uid: uidStore});

    return data;
  }

  async updateStoreImageWithSupabase(storeImage: StoreImage): Promise<any> {
    const {error} = await supabase
      .from(this.STOREIMAGE_TABLE)
      .update(storeImage)
      .match({uid: storeImage.uid});
    return {error};
  }

  async deleteStoreWithSupabase(uidStore: string): Promise<any> {
    const {data} = await supabase
      .from(this.STORE_TABLE)
      .update({deletedAt: new Date()})
      .match({uid: uidStore});

    return data;
  }

  async lockStoreWithSupabase(uidStore: string): Promise<any> {
    const {data} = await supabase
      .from(this.STORE_TABLE)
      .update({lockedAt: new Date(), available: false})
      .match({uid: uidStore});

    return data;
  }

  async restoreStoreWithSupabase(uidStore: string): Promise<any> {
    const {data} = await supabase
      .from(this.STORE_TABLE)
      .update({lockedAt: null, available: false})
      .match({uid: uidStore});

    return data;
  }

  async deleteStoreImageWithSupabase(uidStoreImage: string): Promise<any> {
    const {data} = await supabase
      .from(this.STOREIMAGE_TABLE)
      .delete()
      .match({uid: uidStoreImage});

    return data;
  }
}
