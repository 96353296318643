import {Component, Input} from '@angular/core';
import {Category} from '../../models/category.model';
import {NotificationsService} from '../../services/notifications.service';
import {CategoryStoreService} from '../../services/category-store.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent  {

  @Input() categories: Category[];
  @Input() uidGroup: string;
  toastTitle: string;
  toastMessage: string;

  constructor(private categoryServices: CategoryStoreService,
              private notifyService: NotificationsService) {
  }

  removeCategory(category: Category): void {
    if (confirm('Etes-vous sûre de vouloir supprimer cette catégorie??')) {
      this.categoryServices.deleteCategoryStoreWithSupabase(category.uid)
        .then(
          () => {
            this.toastTitle = 'Success';
            this.toastMessage = `Suppression de la Catégorie réussie !`;
            this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
          }
        ).catch(
        () => {
          this.toastTitle = 'Echec';
          this.toastMessage = `Suppression de la Catégorie échouée !`;
          this.notifyService.showError(this.toastMessage, this.toastTitle);
        }
      );
    } else {
      this.notifyService.showInfo(`Suppression de la Catégorie Annulée`, 'Echec');
    }
  }

}
