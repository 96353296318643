import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notificationadd',
  templateUrl: './notificationadd.component.html',
  styleUrls: ['./notificationadd.component.css']
})
export class NotificationaddComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
