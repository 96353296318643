import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  state = '';
  constructor(private authService: AuthService,
              @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
  }

  logout(): void{
    this.authService.logout();
  }

  collapseNavbar(): void {
    if (this.state === ''){
      this.document.body.classList.add('mini-navbar');
      this.state = 'mini-navbar';
    }else{
      this.document.body.classList.remove('mini-navbar');
      this.state = '';
    }
  }
}
