<div *ngIf="selectedGroup$ | async as groupe">

  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-8">
      <h2><strong>{{this.groupe?.name | uppercase}}</strong></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/groups']">Groupes</a>
        </li>
        <li class="breadcrumb-item active">
          <strong>Groupe</strong>
        </li>
      </ol>
    </div>
    <div class="col-lg-4 p-1 align-content-center">
      <div [ngSwitch]="this.groupe?.available">
        <h4 *ngSwitchCase="true" class="text-navy"><strong>Le Groupe est disponible</strong>
        </h4>
        <h4 *ngSwitchCase="false" class="text-danger"><strong>Le Groupe est indisponible</strong></h4>
      </div>
      <bSwitch [(ngModel)]="groupe.available" [switch-size]="'small'" [switch-label-text]="'Disponibilité'"
               [switch-on-color]="'success'" [switch-off-color]="'danger'"
               [switch-off-text]="'Indisponible'" [switch-on-text]="'Disponible'"
               [switch-base-class]="'bootstrap-switch'" [switch-wrapper-class]="'bootstrap-switch'"
               (changeState)="onChange($event)">
      </bSwitch>
    </div>
  </div>

  <div class="row mt-1">
    <a [routerLink]="['/groups']" class="btn btn-sm btn-primary"><i class="fa fa-chevron-left"></i> Retour</a>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="tabs-container">
          <ul class="nav nav-tabs">
            <li><a class="nav-link active" data-toggle="tab" href="#groups-tab"> <i class="fa fa-shopping-cart"></i>
              Liste des
              {{groupe?.name | titlecase}}</a>
            </li>
            <li><a class="nav-link" data-toggle="tab" href="#dashboard-tab"><i class="fa fa-dashboard"></i>
              Dashboard</a></li>
            <li><a class="nav-link" data-toggle="tab" href="#orders-tab"><i class="fa fa-shopping-cart"></i> Orders
              <span class="label label-inverse float-right ml-1" *ngIf="groupe?.storesGroup?.length !== 0">
                {{groupe?.storesGroup[0].store?.orders?.length}}</span></a></li>
            <li><a class="nav-link" data-toggle="tab" href="#tab-categories"><i class="fa fa-random"></i> Categories</a>
            <li><a class="nav-link" data-toggle="tab" href="#tab-paiements"><i class="fa fa-money"></i> Paiements</a>
            </li>
            <li><a class="nav-link" data-toggle="tab" href="#tab-product-types"><i class="fa fa-random"></i> Product Types</a>
          </li>
            <li><a class="nav-link" data-toggle="tab" href="#tab-agents"><i class="fa fa-users"></i> Agents</a></li>
          </ul>
          <div class="tab-content">
            <div id="groups-tab" class="tab-pane active">
              <div class="ibox ">
                <div class="ibox-title">
                  <h5>{{groupe?.name | titlecase}}: Liste des Marchands</h5>
                  <div class="ibox-tools">
                    <a class="btn btn-sm btn-success text-white" [routerLink]="['/groups', uidGroup, 'stores', 'add']">
                      <span> Ajouter Marchand</span>
                    </a>
                  </div>
                </div>
                <div class="ibox-content">
                  <div class="table-responsive">
                    <table datatable class="table table-striped table-bordered table-hover dataTables-example"
                           *ngIf="groupe?.storesGroup?.length !== 0">
                      <thead>
                      <tr>
                        <th>image</th>
                        <th>Nom</th>
                        <th>Description</th>
                        <th>Adresse</th>
                        <th>Horaires</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let store of groupe?.storesGroup">
                        <td>
                      <span *ngFor="let image of store.store.storeImage">
                        <img src="{{image.image.url}}" class="img-thumbnail" alt="store_image" [height]="30"
                             style="max-height: 35px;">
                      </span>
                        </td>
                        <td><a [routerLink]="['/groups', uidGroup, 'stores', store?.uidStore]"
                               class="text-dark font-weight-bold">{{store.store.name | titlecase}}</a></td>
                        <td>{{store.store.description}}</td>
                        <td>{{store.store.storeAddress[0].address.street}}, {{store.store.storeAddress[0].address.city}} {{store.store.storeAddress[0].address.state}}
                          , {{store.store.storeAddress[0].address.country}}</td>
                        <td *ngIf="store.store.schedule !== null">
                          <p *ngFor="let horaire of store.store.schedule"> {{horaire.day}} : {{horaire.from}}
                            - {{horaire.to}}</p>
                        </td>
                        <td *ngIf="store.store.schedule === null">
                          <span>Pas d'horaire</span>
                        </td>
                        <td>
                          <a [routerLink]="['/groups', uidGroup, 'stores', store?.store?.uid]"
                             class="btn btn-primary btn-xs m-1"><i
                            class="fa fa-eye text-white pr-1"></i> Voir</a>
                          <span>
                        <a [routerLink]="['/groups', uidGroup, 'stores', store?.store?.uid, 'edit']"
                           class="btn btn-success btn-xs m-0"><i
                          class="fa fa-pencil pr-1"></i>Edit</a>
                        <a (click)="lockStore(store.store)" *ngIf="store.store.lockedAt === null"
                           class="btn btn-warning btn-xs m-1 text-white" data-toggle="tooltip" data-placement="bottom"
                           title="Bloquer Marchand"><i class="fa fa-lock text-white"></i> Bloquer</a>
                        <a (click)="restoreStore(store.store)" *ngIf="store.store.lockedAt !== null"
                           class="btn btn-warning btn-xs m-1 text-white" data-toggle="tooltip" data-placement="bottom"
                           title="Restaurer Marchand"><i class="fa fa-refresh text-white"></i> Restaurer</a>
                      </span>
                          <span>
                          <a (click)="removeStore(store.store)" *ngIf="store.store.deletedAt === null" class="m-1"
                             data-toggle="tooltip" data-placement="bottom"
                             title="Supprimer Marchand"><i class="fa fa-trash-o text-danger"></i></a>
                        </span>

                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <table datatable class="table table-striped table-bordered table-hover dataTables-example"
                           *ngIf="groupe?.storesGroup?.length === 0">
                      <thead>
                      <tr>
                        <th>image</th>
                        <th>Nom</th>
                        <th>Description</th>
                        <th>Adresse</th>
                        <th>Horaires</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </table>


                  </div>
                </div>
              </div>
            </div>
            <div id="dashboard-tab" class="tab-pane">
              <div class="ibox ">
                <div class="ibox-title">
                  <h5>Dashboard des Stores </h5>
                  <div class="ibox-tools">
                  </div>
                </div>
                <div class="ibox-content">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="col-lg-6 d-sm-inline-flex">
                        <canvas #barCanvas></canvas>
                      </div>
                      <div class="col-lg-6 d-sm-inline-flex">
                        <canvas #barCanvas2></canvas>
                      </div>
                      <div class="col-lg-6 d-sm-inline-flex">
                        <canvas #barCanvas3></canvas>
                      </div>
                      <div class="col-lg-6 d-sm-inline-flex">
                        <canvas #barCanvas4></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="orders-tab" class="tab-pane">
              <div class="ibox ">
                <div class="ibox-title">
                  <h5>Toutes les Commandes En Attente de Validation</h5>
                  <div class="ibox-tools">
                  </div>
                </div>
                <div class="ibox-content">
                  <div class="table-responsive" >
                    <app-order *ngIf="groupe?.storesGroup?.length !== 0" [orders]="groupe?.storesGroup[0]?.store?.orders"></app-order>
                  </div>
                </div>
              </div>
            </div>
            <div id="tab-categories" class="tab-pane">
              <app-categories></app-categories>
            </div>
            <div id="tab-product-types" class="tab-pane">
              <app-product-types></app-product-types>
            </div>
            <div id="tab-paiements" class="tab-pane">
              <app-paiements></app-paiements>
            </div>
            <div id="tab-agents" class="tab-pane">
              <app-agents-restorers></app-agents-restorers>
            </div>
          </div>
        </div>


      </div>

    </div>
  </div>
</div>
