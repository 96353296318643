import { Injectable } from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import {ProductSpec} from '../models/product-spec.model';

@Injectable({
  providedIn: 'root'
})
export class ProductSpecsService {

  productSpecs: {[key: string]: string} = {};
  constructor() { }

  /**
   * Set/add a Product specification Array_Map
   * @param productSpecs An array of values
   * @return productSpecs The Map(dictionnaires)
   */
  addProductSpecs(productSpecs: any[]): {} {
    productSpecs.map((val) => {
      if (val.key !== '' && val.valeur !== '') {
        this.productSpecs[(val.key).trim()] = (val.valeur).trim();
      }
    });
    return this.productSpecs;
  }

  /**
   * Convert a Product specification Map to Array
   * @param values A Map of values
   * @return productSpecs The Array
   */
  private formProductSpecs(values: {[key: string]: string}): any[]{
    const productSpecs = [];
    for (const [key, valeur] of Object.entries(values)){
      productSpecs.push({key, valeur});
    }
    return productSpecs;
  }

  /**
   * Fill in the productSpecs in a form
   * @param productSpecs A productSpecs Array
   * @param productSpecsFormBuilder A productSpecs FormBuilder
   * @param productSpecsArray The productSpec FormArray
   */
  fillProductSpecsForm(productSpecs: ProductSpec[], productSpecsFormBuilder: UntypedFormBuilder,
                       productSpecsArray: UntypedFormArray): void {
    // this.formProductSpecs(productSpecs).forEach((productSpec) => {
    //   productSpecsArray.push(productSpecsFormBuilder.group(productSpec));
    // });
    productSpecs.forEach((productSpec) => {
      productSpecsArray.push(productSpecsFormBuilder.group(productSpec));
    });
  }
}
