import {Component, OnInit, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {PromoService} from '../../../services/promo.service';
import {NotificationsService} from '../../../services/notifications.service';
import {PromoCode} from '../../../models/promo-code.model';

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.css']
})
export class PromoComponent implements OnInit {

  @Input() promos: PromoCode[];
  idGroup: string;
  subscriptions: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,
              private promotionService: PromoService,
              private notifyService: NotificationsService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.idGroup = token.get('idgroup');
        })
    );
  }

  deletePromotion(promotion: PromoCode): void {
    if (confirm('Etes-vous sure de vouloir supprimer ce Code-Promo ? Cette Action est irreversible')) {

      // TODO: Must work HERE
      // this.promotionService.deletePromotion(this.idGroup, promotion).then(
      //   () => {
      //     this.notifyService.showSuccess('Suppression du Code-Promo fait avec succès !', 'Notification Faite');
      //   }
      // ).catch(
      //   error => {
      //     console.log(error);
      //     this.notifyService.showError(`Echec de la suppression du Code-Promo`, 'Echec');
      //   }
      // );
    } else {
      this.notifyService.showInfo(`Suppression du Code-Promo Annulée`, 'Echec');
    }
  }

}
