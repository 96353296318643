<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>Admin</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/admins']">Admins</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Modifier Admin</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2"></div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/admins']" class="btn btn-sm btn-primary text-white"><i class="fa fa-chevron-left"></i> Retour</a>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="tabs-container">
        <ul class="nav nav-tabs">
          <li><a class="nav-link active" data-toggle="tab" href="#tab-1"><i class="fa fa-user-secret"></i> Admin Info
            Accounts</a>
          <li><a class="nav-link" data-toggle="tab" href="#tab-2"><i class="fa fa-at"></i> Email</a>
          <li><a class="nav-link" data-toggle="tab" href="#tab-3"><i class="fa fa-asterisk"></i> Password</a>
          </li>
        </ul>
        <div class="tab-content">
          <div id="tab-1" class="tab-pane active">
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox ">
                  <div class="ibox-title">
                    <h5>Modifier <small>Admins Info.</small></h5>
                  </div>
                  <div class="ibox-content">
                    <form (ngSubmit)="onSubmit()" [formGroup]="adminForm">
                      <div class="form-group row"><label class="col-sm-2 col-form-label">Name</label>
                        <div class="col-sm-10">
                          <div class="input-group m-b">
                            <div class="input-group-prepend">
                              <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                            </div>
                            <input type="text" placeholder="name" class="form-control" id="name" formControlName="name"
                                   required="required" minlength="6"
                                   [ngClass]="{'is-invalid' : (adminForm.get('name').touched || adminForm.get('name').dirty) && !adminForm.get('name').valid}">
                            <div class="invalid-feedback">
                              <span *ngIf="adminForm.get('name').errors?.required">Admin Name is required !</span>
                              <span
                                *ngIf="adminForm.get('name').errors?.minlength">Valid Admin Name must be longer than 6 caracters !</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row"><label class="col-sm-2 col-form-label">Phone</label>
                        <div class="col-sm-10">
                          <div class="input-group m-b">
                            <div class="input-group-prepend">
                              <span class="input-group-addon"><i class="fa fa-phone"></i></span>
                            </div>
                            <input type="text" placeholder="Phone" class="form-control"
                                   formControlName="phone" id="phone" required="required"
                                   [ngClass]="{'is-invalid' : (adminForm.get('phone').touched || adminForm.get('phone').dirty) && !adminForm.get('phone').valid}">
                            <div class="invalid-feedback">
                              <span
                                *ngIf="adminForm.get('phone').errors?.required">Admin phone number is required !</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row"><label class="col-sm-2 col-form-label">Adresse</label>
                        <div class="col-sm-10">
                          <div class="input-group m-b">
                            <div class="input-group-prepend">
                              <span class="input-group-addon"><i class="fa fa-home"></i></span>
                            </div>
                            <input ngx-google-places-autocomplete #placesRef="ngx-places" type="text" placeholder="Adresse"
                                   class="form-control" minlength="10" (onAddressChange)="handleAddressChange($event)"
                                   formControlName="adresse" id="adresse" required="required"
                                   [ngClass]="{'is-invalid' : (adminForm.get('adresse').touched || adminForm.get('adresse').dirty) && !adminForm.get('adresse').valid}">
                            <div class="invalid-feedback">
                              <span *ngIf="adminForm.get('adresse').errors?.required">Admin adresse is required !</span>
                              <span
                                *ngIf="adminForm.get('adresse').errors?.minlength">Valid Admin description must be longer than 10 caracters !</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row"><label class="col-sm-2 col-form-label">Avatar</label>
                        <div class="col-sm-10">
                          <div class="input-group m-b">
                            <div class="input-group-prepend">
                              <span class="input-group-addon"><i class="fa fa-image"></i></span>
                            </div>
                            <input type="file" accept="image/*" placeholder="Avatar" class="form-control" id="avatar"
                                   formControlName="avatar" (change)="onUpload($event)"
                                   [ngClass]="{'is-invalid' : (adminForm.get('avatar').touched || adminForm.get('avatar').dirty) && !adminForm.get('avatar').valid}">

                            <div class="invalid-feedback">
                              <span *ngIf="adminForm.get('avatar').errors?.required">Admin avatar is required !</span>
                            </div>
                          </div>
                          <div class="progress">
                            <div class="progress-bar" [style.width.%]="pourcentage" role="progressbar"
                                 [attr.aria-valuenow]="pourcentage" aria-valuemin="0" aria-valuemax="100">
                              <span class="text-center" *ngIf="pourcentage > 0">{{pourcentage | number}} %</span>
                            </div>
                          </div>
                          <div class="offset-6" *ngIf="upload">
                            <div class="lds-ring" *ngIf="pourcentage < 100">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="hr-line-dashed"></div>

                      <div class="form-group row">
                        <div class="col-sm-4 col-sm-offset-2">
                          <a [routerLink]="['/admins']" class="btn btn-white btn-sm" type="submit">Cancel</a>
                          <button  class="btn btn-primary btn-sm" type="submit"
                                  [title]="adminForm.valid ? 'Modifier Admin' : 'Disable until valid form data'"
                                  [disabled]="!adminForm.valid">Modifier
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-2" class="tab-pane">
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox ">
                  <div class="ibox-title">
                    <h5>Modifier <small>Email.</small></h5>
                  </div>
                  <div class="ibox-content">
                    <form (ngSubmit)="modifyEmail()" [formGroup]="adminEmailForm">
                      <div class="form-group row"><label class="col-sm-2 col-form-label">Email</label>
                        <div class="col-sm-10">
                          <div class="input-group m-b">
                            <div class="input-group-prepend">
                              <span class="input-group-addon"><i class="fa fa-at"></i></span>
                            </div>
                            <input type="email" placeholder="Email" class="form-control" id="email"
                                   formControlName="email" required="required"
                                   [ngClass]="{'is-invalid' : (adminEmailForm.get('email').touched || adminEmailForm.get('email').dirty) && !adminEmailForm.get('email').valid}">
                            <div class="invalid-feedback">
                              <span
                                *ngIf="adminEmailForm.get('email').errors?.required">Admins's Email is required !</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="hr-line-dashed"></div>

                      <div class="form-group row">
                        <div class="col-sm-4 col-sm-offset-2">
                          <a [routerLink]="['/admins']" class="btn btn-white btn-sm" type="submit">Cancel</a>
                          <button  class="btn btn-primary btn-sm" type="submit"
                                  [title]="adminEmailForm.valid ? 'Modifier Email' : 'Disable until valid form data'"
                                  [disabled]="!adminEmailForm.valid">Modifier Email
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-3" class="tab-pane">
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox ">
                  <div class="ibox-title">
                    <h5>Modifier <small>Email.</small></h5>
                  </div>
                  <div class="ibox-content">
                    <form (ngSubmit)="modifyPassword()" [formGroup]="adminPwdForm">
                      <div class="form-group row"><label class="col-sm-2 col-form-label">New Password</label>
                        <div class="col-sm-10">
                          <div class="input-group m-b">
                            <div class="input-group-prepend">
                              <span class="input-group-addon"><i class="fa fa-asterisk"></i></span>
                            </div>
                            <input type="password" [type]="fieldTextType ? 'text' : 'password'"
                                   placeholder="New Password" class="form-control" id="password"
                                   formControlName="password" required="required"
                                   [ngClass]="{'is-invalid' : (adminPwdForm.get('password').touched || adminPwdForm.get('password').dirty) && !adminPwdForm.get('password').valid}">
                            <div class="input-group-append">
                              <span class="input-group-text">
                                <i class="fa" [ngClass]="{'fa-eye': !fieldTextType,'fa-eye-slash': fieldTextType}"
                                   (click)="toggleFieldTextType()"></i></span>
                            </div>
                            <div class="invalid-feedback">
                              <span
                                *ngIf="adminPwdForm.get('password').errors?.required">Admin's Password is required !</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="hr-line-dashed"></div>

                      <div class="form-group row">
                        <div class="col-sm-4 col-sm-offset-2">
                          <a [routerLink]="['/admins']" class="btn btn-white btn-sm" type="submit">Cancel</a>
                          <button  class="btn btn-primary btn-sm" type="submit"
                                  [title]="adminPwdForm.valid ? 'Modifier Password' : 'Disable until valid form data'"
                                  [disabled]="!adminPwdForm.valid">Modifier Password
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
