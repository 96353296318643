import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AgentsService} from '../../services/agents.service';
import {Agent} from '../../models/agent.model';
import {ActivatedRoute} from '@angular/router';
import {Store} from '../../models/store.model';
import {StoreService} from '../../services/store.service';
import {NotificationsService} from '../../services/notifications.service';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.css']
})
export class AgentsComponent implements OnInit, OnDestroy {

  agents: Agent[] = [];
  store: Store;
  uidStore: string;
  uidGroup: string;
  subscription: Subscription = new Subscription();

  constructor(private agentService: AgentsService,
              private route: ActivatedRoute,
              private storeService: StoreService,
              private notifyService: NotificationsService) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidStore = token.get('idStore');
          this.uidGroup = token.get('idgroup');

          this.subscription.add(
            this.agentService.getAgents(this.uidGroup, this.uidStore).subscribe(
              agents => {
                this.agents = agents;
              }
            )
          );

          this.storeService.getStore(this.uidGroup, this.uidStore);
          this.subscription.add(
            this.storeService.currentStore$.subscribe(
              store => {
                if (store !== null) {
                  this.store = store;
                }
              }
            )
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  lockAgent(agent: Agent): void {
    if (confirm('Etes-vous sure de vouloir Bloquer cet Administrateur ?')) {
      this.agentService.deleteAgent(agent).then(
        () => {
          this.notifyService.showSuccess('Blocage de l\'Agent fait avec succès !', 'Notification Faite');
        }
      ).catch(
        error => {
          this.notifyService.showError(`Echec du Blocage de l\'Agent => ${error}`, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Blocage de l\'Agent Annulée`, 'Echec');
    }
  }

  restoreAgent(agent: Agent): void {
    if (confirm('Etes-vous sure de vouloir Restorer cet Agent ?')) {
      this.agentService.restoreAgent(agent).then(
        () => {
          this.notifyService.showSuccess('L\'Agent a été restauré avec succès !', 'Notification Faite');
        }
      ).catch(
        error => {
          this.notifyService.showError(`Echec l\'Agent n'a pas été restauré=> ${error}`, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Restauration de l\'Agent Annulé`, 'Echec');
    }
  }

  removeAgent(agent: Agent): void {
    if (confirm('Etes-vous sure de vouloir supprimer cet Agent ? Cette Action est irreversible')) {
      this.agentService.deleteA(agent).then(
        () => {
          this.notifyService.showSuccess('Suppression de l\'Agent fait avec succès !', 'Notification Faite');
        }
      ).catch(
        error => {
          this.notifyService.showError(`Echec de la suppression de l\'Agent => ${error}`, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Suppression de l\'Agent Annulée`, 'Echec');
    }
  }

}
