<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>Remboursement</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/stores', this?.idStore]">Supplier</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Remboursement</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2"></div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/stores', this?.idStore]" class="btn btn-sm btn-primary text-white"><i class="fa fa-chevron-left"></i> Retour</a>
</div>


<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Remboursement</h5>
        </div>
        <div class="ibox-content">
          <form (ngSubmit)="refundCommand()" [formGroup]="refundForm">
            <div class="form-group row"><label class="col-sm-2 col-form-label">Date</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                  </div>
                  <input type="date" placeholder="Date" class="form-control"
                         formControlName="date" id="date" required="required"
                         [ngClass]="{'is-invalid' : (refundForm.get('date').touched || refundForm.get('date').dirty) && !refundForm.get('date').valid}">
                  <div class="invalid-feedback">
                    <span
                      *ngIf="refundForm.get('date').errors?.required">Refund date is required !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label class="col-sm-2 col-form-label">Montant</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-money"></i></span>
                  </div>
                  <input type="number" placeholder="Montant" class="form-control"
                         formControlName="amount" id="amount" required="required" [min]="0"
                         [ngClass]="{'is-invalid' : (refundForm.get('amount').touched || refundForm.get('amount').dirty) && !refundForm.get('amount').valid}">
                  <div class="invalid-feedback">
                    <span
                      *ngIf="refundForm.get('amount').errors?.required">Refund amount is required !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label class="col-sm-2 col-form-label">Motif</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
                  </div>
                  <textarea type="text" placeholder="Motif" class="form-control"
                            id="reason" formControlName="reason"
                            required="required" minlength="10"
                            [ngClass]="{'is-invalid' : (refundForm.get('reason').touched || refundForm.get('reason').dirty) && !refundForm.get('reason').valid}">
                  </textarea>
                  <div class="invalid-feedback">
                    <span *ngIf="refundForm.get('reason').errors?.required">Message Of Rejection is required !</span>
                    <span
                      *ngIf="refundForm.get('reason').errors?.minlength">Message Of Rejection must be longer than 10 caracters !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="hr-line-dashed"></div>

            <div class="form-group row">
              <div class="col-sm-4 col-sm-offset-2">
                <a class="btn btn-white btn-sm" [routerLink]="['/stores', this?.idStore]">Retour</a>
                <button class="btn btn-primary btn-sm" type="submit"
                        [title]="refundForm.valid ? 'Ajouter Remboursement' : 'Disable until valid form data'"
                        [disabled]="!refundForm.valid">Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
