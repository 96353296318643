import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, catchError, Observable, shareReplay, Subscription} from 'rxjs';
import {FirestoreService} from './firestore.service';
import {User} from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AdminsService implements OnDestroy {
  private currentAdmin: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  currentAdmin$ = this.currentAdmin.asObservable();
  subscriptions: Subscription = new Subscription();
  handleError: any = '';
  admins$: Observable<User[]> = this.firestoreService.col$('admins/')
    .pipe(
      shareReplay(1),
      catchError(this.handleError)
    );

  constructor(private firestoreService: FirestoreService) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getAdmins(): Observable<User[]> {
    return this.firestoreService.col$(`admins`);
  }

  getAdmin(uidAdmin: string): void {
    this.subscriptions.add(
      this.firestoreService.doc$(`admins/${uidAdmin}`).subscribe(
        (admin: User) => {
          this.currentAdmin.next(admin);
        }
      )
    );
  }

  getAdminUser(uidAdmin: string): Observable<any> {
    return this.firestoreService.doc$(`admins/${uidAdmin}`);
  }

  addAdmin(admin: User): Promise<any> {
    return this.firestoreService.set(`admins/${admin.uid}`, admin);
  }

  updateAdmin(admin: User): Promise<any> {
    return this.firestoreService.update(`admins/${admin.uid}`, admin);
  }

  disableAdmin(admin: User): Promise<any> {
    return this.firestoreService.update2(`admins/${admin.uid}/`, {deletedAt: new Date()});
  }

  restoreAdmin(admin: User): Promise<any> {
    return this.firestoreService.update2(`admins/${admin.uid}/`, {deletedAt: null});
  }

  deleteA(admin: User): Promise<any> {
    return this.firestoreService.delete(`admins/${admin.uid}`);
  }
}
