<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-8">
    <h2>Admins</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/admins']">Admins</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Admins</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2">
    <div *appIsGranted="'CREATE'" class="ibox-tools pt-4">
      <a class="btn btn-sm btn-success text-white" [routerLink]="['/admins/add']">
        <span>Ajouter Admin</span>
      </a>
    </div>
  </div>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="tabs-container">
        <ul class="nav nav-tabs">
          <li><a class="nav-link" data-toggle="tab" href="#actived"><i class="fa fa-user-secret"></i> Active Admins</a></li>
          <li><a class="nav-link" data-toggle="tab" href="#locked"><i class="fa fa-user-secret"></i><i class="fa fa-lock"></i> Locked Admins</a></li>
        </ul>
        <div class="tab-content" *ngIf="admins$ | async as admins">
          <div id="actived" class="tab-pane active">
            <div class="ibox ">
              <div class="ibox-title">
                <h5>Liste des Admins actifs </h5>
              </div>
              <div class="ibox-content bg-light">
                <div class="row">
                  <div class="col-md-4 pb-3" *ngFor="let admin of admins">
                    <div class="ibox-content text-center shadow-sm" style="border-radius: 5px;" *ngIf="admin.deletedAt === null">
                      <h2 style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">{{admin?.name | titlecase}}</h2>
                      <div class="m-b-sm">
                        <img alt="image" class="rounded-circle" src="{{admin?.imageUrl || 'https://ssl.gstatic.com/accounts/ui/avatar_2x.png'}}" style="height: 96px; width: 96px">
                      </div>
                      <p class="font-bold" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">{{admin?.emailAddress}}</p>
                      <p class="font-bold small" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                        {{admin?.address[0].street}}, {{admin?.address[0].city}}, {{admin?.address[0].country}}
                      </p>

                      <div *appIsGranted="'UPDATE'" class="text-center">
                        <a [routerLink]="['/admins', admin?.uid, 'edit']" class="btn btn-xs btn-success"
                           data-toggle="tooltip" data-placement="bottom" title="Modifier Agent    "><i
                          class="fa fa-pencil"></i> Modifier </a>
                        <a (click)="lockAdmin(admin)" *ngIf="admin.deletedAt === null" class="btn btn-warning ml-2 text-white" data-toggle="tooltip" data-placement="bottom"
                           title="Bloquer Agent"><i class="fa fa-lock text-white"></i> Bloquer</a>
                        <a (click)="removeAdmin(admin)" class="ml-2" data-toggle="tooltip" data-placement="bottom"
                           title="Supprimer Agent"><i class="fa fa-trash text-danger"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="middle-box text-center animated fadeInRightBig" *ngIf="admins?.length === 0">
                  <h3 class="font-bold">Pas d'Admin</h3>
                  <div class="error-desc">
                    Désolé aucun Admin Actif disponible pour ce Backoffice !
                    <br/><a [routerLink]="['/dashboard']" class="btn btn-primary m-t">Dashboard</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="locked" class="tab-pane">
            <div class="ibox ">
              <div class="ibox-title">
                <h5>Liste des Admins bloqués </h5>
              </div>
              <div class="ibox-content bg-light">
                <div class="row">
                  <div class="col-md-4 pb-3" *ngFor="let admin of admins">
                    <div class="ibox-content text-center shadow-sm" style="border-radius: 5px;" *ngIf="admin.deletedAt !== null">
                      <h2 style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">{{admin?.name | titlecase}}</h2>
                      <div class="m-b-sm">
                        <img alt="image" class="rounded-circle" src="{{admin?.imageUrl || 'https://ssl.gstatic.com/accounts/ui/avatar_2x.png'}}" style="height: 96px; width: 96px">
                      </div>
                      <p class="font-bold" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">{{admin?.emailAddress}}</p>
                      <p class="font-bold small" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                        {{admin?.address[0].street}}, {{admin?.address[0].city}}, {{admin?.address[0].country}}
                      </p>

                      <div *appIsGranted="'UPDATE'" class="text-center">
                        <a [routerLink]="['/admins', admin?.uid, 'edit']" class="btn btn-xs btn-success"
                           data-toggle="tooltip" data-placement="bottom" title="Modifier Admin"><i
                          class="fa fa-pencil"></i> Modifier </a>
                        <a (click)="restoreAdmin(admin)" *ngIf="admin.deletedAt !== null" class="btn btn-warning ml-2 text-white" data-toggle="tooltip" data-placement="bottom"
                           title="Restaurer Admin"><i class="fa fa-refresh text-white"></i> Restaurer</a>
                        <a (click)="removeAdmin(admin)" class="ml-2" data-toggle="tooltip" data-placement="bottom"
                           title="Supprimer Admin"><i class="fa fa-trash text-danger"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="middle-box text-center animated fadeInRightBig" *ngIf="admins?.length === 0">
                  <h3 class="font-bold">Pas d'Admin</h3>
                  <div class="error-desc">
                    Désolé Aucun Admin bloqués disponible !
                    <br/><a [routerLink]="['/dashboard']" class="btn btn-primary m-t">Dashboard</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

