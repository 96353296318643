<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>Product - Add Promotion ({{product?.name | titlecase}})</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/groups', uidGroup, 'stores', uidStore]">Store</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Product</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2">
  </div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/groups', this.uidGroup, 'stores', this.uidStore, 'products', product?.uid]" class="btn btn-sm btn-primary"><i
    class="fa fa-chevron-left"></i> Back</a>
</div>


<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Create <small>Promotion.</small></h5>
        </div>
        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" [formGroup]="promotionForm">
            <div class="form-group row"><label class="col-sm-2 col-form-label">From</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                  </div>
                  <input type="datetime-local" placeholder="Started At" class="form-control" id="startedAt"
                         formControlName="startedAt" required="required"
                         [ngClass]="{'is-invalid' : (promotionForm.get('startedAt').touched || promotionForm.get('startedAt').dirty) && !promotionForm.get('startedAt').valid}">
                  <div class="invalid-feedback">
                    <span *ngIf="promotionForm.get('startedAt').errors?.required">Start Datetime is required !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label class="col-sm-2 col-form-label">To</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                  </div>
                  <input type="datetime-local" placeholder="Ended At" class="form-control" id="endedAt"
                         formControlName="endedAt" required="required"
                         [ngClass]="{'is-invalid' : (promotionForm.get('endedAt').touched || promotionForm.get('endedAt').dirty) && !promotionForm.get('endedAt').valid}">
                  <div class="invalid-feedback">
                    <span *ngIf="promotionForm.get('endedAt').errors?.required">End Datetime is required !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label class="col-sm-2 col-form-label">Percent</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-percent"></i></span>
                  </div>
                  <input type="number" placeholder="Percent" class="form-control"
                         formControlName="percent" id="percent" required="required" min="0"
                         [ngClass]="{'is-invalid' : (promotionForm.get('percent').touched || promotionForm.get('percent').dirty) && !promotionForm.get('percent').valid}">
                  <div class="invalid-feedback">
                    <span *ngIf="promotionForm.get('percent').errors?.required">Promotion percent is required !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="hr-line-dashed"></div>

            <div class="form-group row">
              <div class="col-sm-4 col-sm-offset-2">
                <a [routerLink]="['/groups', this.uidGroup, 'stores', this.uidStore, 'products', product?.uid]" class="btn btn-white btn-sm"
                   type="submit">Cancel</a>
                <button  class="btn btn-primary btn-sm" type="submit"
                        [title]="promotionForm.valid ? 'Ajouter Promotion' : 'Disable until valid form data'"
                        [disabled]="!promotionForm.valid">Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
