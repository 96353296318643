import {Injectable} from '@angular/core';
import {Address as gAddress} from 'ngx-google-places-autocomplete/objects/address';
import {AddressComponent as gAddressComponent} from 'ngx-google-places-autocomplete/objects/addressComponent';
import {Address} from '../models/address.model';
import uid from 'uid';
import {supabase} from './supabase';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  searchedAddress: Address;
  private ADDRESS_TABLE = 'Address';

  constructor() {
  }

  public handleAddressChange(address: gAddress): Address {
    this.searchedAddress = {
      uid: uid(32),
      latitude: address.geometry.location.lat(),
      longitude: address.geometry.location.lng(),
      street: `${this.findType(address, 'street_number')} ${this.findType(address, 'route')}`,
      city: this.findType(address, 'locality'),
      state: this.findType(address, 'administrative_area_level_1'),
      country: this.findType(address, 'country'),
      utcOffset: address.utc_offset,
      title: address.name
    };
    return this.searchedAddress;
  }

  private findType(addr: gAddress, type: string): string {
    const result: gAddressComponent = addr.address_components
      .find((item: gAddressComponent) => item.types.indexOf(type) >= 0);
    return result ? result.short_name : '';
  }

  async addAddressWithSupabase(address: Address): Promise<any> {
    const {error} = await supabase
      .from(this.ADDRESS_TABLE)
      .insert(address).single();

    return {error};
  }
}
