import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Category} from '../../../models/category.model';
import {Product} from '../../../models/product.model';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoryProductService} from '../../../services/category-product.service';
import {ProductService} from '../../../services/product.service';
import {UploadService} from '../../../services/upload.service';
import {NotificationsService} from '../../../services/notifications.service';
import {Subscription} from 'rxjs';
import {ProductTag} from '../../../models/product-tag.model';
import {MetaDataValueType} from '../../../models/metadata-valueType.enum';
import {MetaDataValue} from '../../../models/metadata-value.model';
import {MetaData} from '../../../models/metadata.model';
import {UtilsService} from '../../../services/utils.service';
import {ProductTagService} from '../../../services/product-tag.service';
import {ProductType} from '../../../models/product-type.model';
import {MetaDatasService} from '../../../services/meta-datas.service';
import {ProductSpecsService} from '../../../services/product-specs.service';
import {PermissionType} from '../../../models/permission-type.enum';
import {Dimension} from '../../../models/dimension.model';
import {ProductShippingDetail} from '../../../models/product-shipping-detail.model';

@Component({
  selector: 'app-productedit',
  templateUrl: './productedit.component.html',
  styleUrls: ['./productedit.component.css']
})
export class ProducteditComponent implements OnInit, OnDestroy {
  productForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  uidStore: string;
  uidGroup: string;
  categories: Category[];
  product: Product;
  uidProduct: string;
  image: string;
  upload = false;
  pourcentage = 0;
  subscriptions: Subscription = new Subscription();
  tags: string[] = [];
  productTags: ProductTag[] = [];
  productShippingPrice: ProductShippingDetail;
  metaDataValueTypes: MetaDataValueType[] = [
    MetaDataValueType.COLOR,
    MetaDataValueType.TEXT,
    MetaDataValueType.IMAGE_URL,
    MetaDataValueType.SEAT
  ];
  productTypes: ProductType[] = [
    ProductType.AIRPLANE_TICKET,
    ProductType.BUS_TICKET,
    ProductType.FOOD,
    ProductType.GOOD,
    ProductType.SERVICE,
    ProductType.EVENT_TICKET
  ];
  metaDataValues: MetaDataValue[] = [];
  metaDatas: MetaData[] = [];
  productDimension: Dimension;
  productSpecs: {[key: string]: string} = {};
  selectedTagsName: string[] = [];
  selectedMetaDataValueType: string[] = [];
  metaDataValueImgPourcentage = 0;
  metaDataValueImgupload = false;
  slideConfig = {slidesToShow: 1, slidesToScroll: 1};
  permissions: any = {
    create: PermissionType.CREATE,
    update: PermissionType.UPDATE,
    read: PermissionType.READ,
    delete: PermissionType.DELETE
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private categoryProductService: CategoryProductService,
              private productService: ProductService,
              public uploadService: UploadService,
              private notifyService: NotificationsService,
              private utilsServices: UtilsService,
              private metadataServices: MetaDatasService,
              private productSpecServices: ProductSpecsService,
              private productTagService: ProductTagService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidStore = token.get('idStore');
          this.uidGroup = token.get('idgroup');
          this.uidProduct = token.get('id');

          this.subscriptions.add(
            this.categoryProductService.getCategoriesProduct(this.uidGroup, this.uidStore).subscribe(
              categories => {
                if (categories !== null) {
                  this.categories = categories;
                }
              }
            )
          );
          this.subscriptions.add(
            this.productTagService.getProductTags().subscribe(
              productTags => {
                if (productTags !== null) {
                  this.productTags = productTags;
                }
              }
            )
          );

          this.subscriptions.add(
            this.productService.getProduct(this.uidGroup, this.uidStore, this.uidProduct).subscribe(
              product => {
                if (product !== null) {
                  this.product = product;
                }
              }
            )
          );
          setTimeout(() => {
            this.patchForm();
          }, 1000);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.productForm = new UntypedFormGroup({
      title: new UntypedFormControl('', [Validators.required, Validators.min(3)]),
      description: new UntypedFormControl('', [Validators.minLength(10)]),
      price: new UntypedFormControl(),
      quantity: new UntypedFormControl(),
      priority: new UntypedFormControl(),
      readyForShippingAfter: new UntypedFormControl(),
      categoryProduct: new UntypedFormControl(),
      productTag: new UntypedFormControl(),
      productType: new UntypedFormControl('', Validators.required),
      productSpecs: this.fb.array([]),
      productDimension: this.fb.group({
        weight: [0, Validators.min(0)],
        length: [0, Validators.min(0)],
        width: [0, Validators.min(0)],
        height: [0, Validators.min(0)],
        fragileBox: false
      }),
      metaDatas: this.fb.array([]),
      shippingDetail: this.fb.group({
        freeShipping: false,
        defaultShippingPrice: [0, Validators.min(0)],
        expressShippingPrice: [0, Validators.min(0)],
        expressShippingDetail: ['', Validators.minLength(10)]
      }),
      image: new UntypedFormControl()
    });
  }

  patchForm(): void {
    this.productForm.patchValue({
      title: this.product.name,
      description: this.product.description,
      price: this.product.price,
      quantity: this.product.quantity,
      priority: this.product.priority,
      readyForShippingAfter: this.product.readyForShippingAfter,
      categoryProduct: this.product.categoryProduct,
      productType: this.product.productType,
      productTag: this.product.tags
    });
    this.productSpecServices
      .fillProductSpecsForm(this.product.productSpec, this.fb, this.productSpecsArray());
    this.setShippingDetails();
    this.setProductDimension();
    this.selectedMetaDataValueType = this.metadataServices
      .fillMetadatasForm(this.product.metaDatas, this.fb, this.metaDatasArray());
    this.selectedTagsName = this.utilsServices.selectedTagNames(
      this.product.tags.map((tag) => {
        return tag.uid;
    }), this.productTags);
  }

  setShippingDetails(): void {
    this.productForm.get('shippingDetail').setValue(this.product.shippingDetail);
  }

  setProductDimension(): void {
    this.productForm.get('productDimension').setValue(this.product.dimension);
  }

  onSubmit(): void {
    if (this.productForm.get('image').value !== null) {
      // TODO: Must verify things here
      // @ts-ignore
      this.product.images = this.uploadService.images['product-images'];
      this.updateValues();
    } else {
      this.updateValues();
    }
  }

  updateValues(): void {
    this.productSpecs = this.productSpecServices.addProductSpecs(this.productForm.get('productSpecs').value);
    this.productDimension = this.utilsServices.setProductDimension(this.productForm.get('productDimension').value);
    this.productShippingPrice = this.utilsServices.setProductShippingDetail(this.productForm.get('shippingDetail').value);
    this.metaDatas = this.metadataServices.setMetadatas(this.productForm.get('metaDatas').value, this.uploadService.images);

    this.product.name = (this.productForm.get('title').value).trim();
    this.product.description = (this.productForm.get('description').value).trim();
    this.product.categoryProduct[0].uidCategory = this.productForm.get('categoryProduct').value;
    this.product.price = this.productForm.get('price').value;
    this.product.quantity = this.productForm.get('quantity').value;
    this.product.priority = this.productForm.get('priority').value;
    this.product.readyForShippingAfter = this.productForm.get('readyForShippingAfter').value;
    this.product.productType = this.productForm.get('productType').value;
    // this.product.productSpec = this.productSpecs;
    this.product.dimension = this.productDimension;
    this.product.shippingDetail = this.productShippingPrice;
    // this.product.metaDatas = this.metaDatas;
    // this.product.tags = this.tags;
    this.product.updatedAt = new Date();


    this.productService.updateProduct(this.uidGroup, this.uidStore, this.product).then(
      () => {
        this.router.navigate(['/groups', this.uidGroup, 'stores', this.uidStore]).then(
          () => {
            this.toastTitle = 'Modification Faite';
            this.toastMessage = 'Modification du produit faite avec succès !';
            this.notifyService.showSuccess(this.toastMessage, this.toastTitle);

            // Empty Arrays and objects
            this.uploadService.dropImageList();
            this.metaDatas = [];
            this.productSpecs = {};
          }
        );
      }
    ).catch(
      () => {
        this.toastTitle = 'Modification Echouée';
        this.toastMessage = 'Modification du produit échouée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  onUpload(event, type = 'product-images'): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `groups/${this.uidGroup}/stores/${this.uidStore}/products/${this.uidProduct}`, type);
    this.uploadService.uploadPercent$.subscribe(
      valeur => {
        this.pourcentage = valeur;
      }
    );
  }

  uploadMetaDataValueImg(event, metaDataIndex: number, metadataValuesIndex: number, type: string): void {
    this.metaDataValueImgupload = true;
    this.uploadService.uploadFiles(event,
      `groups/${this.uidGroup}/stores/${this.uidStore}/products/${this.uidProduct}/metadatas/${metaDataIndex}/metadataValues/${metadataValuesIndex}`, type);
    this.uploadService.uploadPercent$.subscribe(
      value => {
        this.metaDataValueImgPourcentage = value;
      }
    );
  }

  addTag(tag: string): void{
    this.selectedTagsName = this.utilsServices.setTag(tag, this.productTags);
  }

  deleteTag(tag: string): void {
    this.tags = this.utilsServices.removeTag(tag, this.productTags);

    this.selectedTagsName.map((productTag, index) => {
      if (productTag.toLowerCase() === tag.toLowerCase()){
        this.selectedTagsName.splice(index, 1);
      }
    });
  }

  productSpecsArray(): UntypedFormArray {
    return this.productForm.get('productSpecs') as UntypedFormArray;
  }

  initProductSpec(): UntypedFormGroup {
    return this.fb.group({
      key: '',
      valeur: ''
    });
  }

  addProductSpec(): void{
    this.productSpecsArray().push(this.initProductSpec());
  }

  removeProductSpecs(i: number): void {
    this.productSpecsArray().removeAt(i);
  }

  metaDatasArray(): UntypedFormArray {
    return this.productForm.get('metaDatas') as UntypedFormArray;
  }

  initMetaData(): UntypedFormGroup {
    return this.fb.group({
      name: '',
      description: '',
      type: '',
      metaDataValues: this.fb.array([])
    });
  }

  addMetaData(): void {
    this.metaDatasArray().push(this.initMetaData());
  }

  removeMetaData(metadaIndex: number): void {
    this.metaDatasArray().removeAt(metadaIndex);
    this.selectedMetaDataValueType.splice(metadaIndex, 1);
  }

  metaDaValuesArray(metadaIndex: number): UntypedFormArray {
    return this.metaDatasArray().at(metadaIndex).get('metaDataValues') as UntypedFormArray;
  }

  initMetaDataValue(): UntypedFormGroup {
    return this.fb.group({
      value: '',
      extraPrice: [0, Validators.min(0)],
      quantity: [1, Validators.min(0)],
      available: false
    });
  }

  addMetaDataValue(metadaIndex: number): void {
    this.metaDaValuesArray(metadaIndex).push(this.initMetaDataValue());
  }

  removeMetaDataValue(metadaIndex: number, metadaValueIndex: number): void {
    this.metaDaValuesArray(metadaIndex).removeAt(metadaValueIndex);
    if (this.uploadService.images['metaDataValue-images-' + metadaValueIndex] !== undefined) {
      delete this.uploadService.images['metaDataValue-images-' + metadaValueIndex];
    }
  }

  selectMetaDataValueType(event, metadaIndex: number): void{
    this.selectedMetaDataValueType[metadaIndex] = event.target.value;
  }
}
