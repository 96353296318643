<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-8 col-sm-6">
    <h2>Groups</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Groupes</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2 col-sm-6">
    <div class="ibox-tools pt-4">
      <a *appIsGranted="'CREATE'" class="btn btn-sm btn-success text-white" [routerLink]="['/groups/add']">
        <span>Ajouter Groupe</span>
      </a>
    </div>
  </div>
</div><hr>

<div class="wrapper wrapper-content animated fadeInRight" *ngIf="sGroups$ | async as groups">
  <div class="row">
    <div class="col-lg-12">
      <div class="tabs-container" *ngIf="allCurrentOrders$ | async as allCommandsNoValidate">
        <ul class="nav nav-tabs">
          <li><a class="nav-link active" data-toggle="tab" href="#group-tab"><i class="fa fa-cubes"></i> Groupes</a></li>
          <li><a class="nav-link" data-toggle="tab" href="#orders-tab"><i class="fa fa-shopping-cart"></i> Orders <span
            class="label label-inverse float-right ml-1">{{this?.allCommandsNoValidate.length}}</span></a></li>
        </ul>
        <div class="tab-content">
          <div id="group-tab" class="tab-pane active">
            <div class="ibox">
              <div class="ibox-title">
                <h5>Liste des Groupes</h5>
              </div>
              <div class="ibox-content bg-muted">
                <div class="row">
                  <div class="col-md-4 pb-3" *ngFor="let group of groups">
                    <div class="ibox-content text-center shadow-sm" style="border-radius: 5px;">
                      <h2 style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">{{group.name | titlecase}}</h2>
                      <div class="m-b-sm" *ngIf="group.groupImage[0] !== undefined">
                        <img alt="image" class="rounded-circle" *ngIf="group.groupImage[0].image.available" src="{{group.groupImage[0].image.url || 'https://ssl.gstatic.com/accounts/ui/avatar_2x.png'}}" [width]="96" [height]="96">
                      </div>

                      <div class="text-center">
                        <a *appIsGranted="'READ'" [routerLink]="['/groups', group.uid]" class="btn btn-xs btn-primary mr-1"
                           data-toggle="tooltip" data-placement="bottom" title="Voir Groupe"><i
                          class="fa fa-eye"></i> Voir </a>
                        <a *appIsGranted="'UPDATE'" [routerLink]="['/groups', group.uid, 'edit']" class="btn btn-xs btn-white"
                           data-toggle="tooltip" data-placement="bottom" title="Modifier Groupe"><i
                          class="fa fa-pencil"></i> Modifier </a>
                        <a *appIsGranted="'DELETE'" (click)="deleteGroup(group.uid)" class="ml-2" data-toggle="tooltip" data-placement="bottom"
                           title="Supprimer Groupe"><i class="fa fa-trash text-danger"></i></a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="orders-tab" class="tab-pane ">
            <div class="ibox ">
              <div class="ibox-title">
                <h5>Toutes les Commandes En Attente de Validation</h5>
                <div class="ibox-tools">
                </div>
              </div>
              <div class="ibox-content">
                <div class="table-responsive">
                  <app-order [orders]="allCommandsNoValidate"></app-order>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="middle-box text-center animated fadeInRightBig" *ngIf="groups.length === 0">
    <h3 class="font-bold">Pas de Groupe</h3>
    <div class="error-desc">
      Désolé Aucun autre Groupe disponible pour ce Backoffice !
      <br/><a routerLink="/dashboard" class="btn btn-primary m-t">Dashboard</a>
    </div>
  </div>
</div>

