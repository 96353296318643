import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {Deliver} from '../models/deliver.model';
import {DeliverService} from '../services/deliver.service';
import {Subscription} from 'rxjs';
import {NotificationsService} from '../services/notifications.service';

@Component({
  selector: 'app-deliver',
  templateUrl: './deliver.component.html',
  styleUrls: ['./deliver.component.css']
})
export class DeliverComponent implements OnInit, OnDestroy {

  @Input() delivers: Deliver[];
  subscription: Subscription = new Subscription();

  constructor(private deliverService: DeliverService,
              private notifyService: NotificationsService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  lockDeliver(data: Deliver): void {
    if (confirm('Etes-vous sure de vouloir Bloquer ce Livreur ?')) {
      this.deliverService.deleteDeliver(data).then(
        () => {
          this.notifyService.showSuccess('Le livreur a été bloqué avec succès !', 'Blocage Faite');
        }
      ).catch(
        () => {
          this.notifyService.showError(`Livreur n'a pas été bloqué `, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Bloquage du Livreur Annulé`, 'Echec');
    }
  }

  restoreDeliver(data: Deliver): void {
    if (confirm('Etes-vous sure de vouloir Supprimer ce Livreur ?')) {
      this.deliverService.restoreDeliver(data).then(
        () => {
          this.notifyService.showSuccess('Le livreur a été Restoré avec succès !', 'Success');
        }
      ).catch(
        () => {
          this.notifyService.showError(`Livreur n'a pas été Restoré `, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Restoration du Livreur Annulé`, 'Echec');
    }
  }

  removeDeliver(data: Deliver): void {
    data.user.deletedAt = new Date();
    if (confirm('Etes-vous sure de vouloir supprimer ce Livreur ?')) {
      this.deliverService.deleteA(data).then(
        () => {
          this.notifyService.showSuccess('Suppression du Livreur fait avec succès !', 'Suppression Faite');
        }
      ).catch(
        () => {
          this.notifyService.showError(`Echec de la suppression du Livreur`, 'Echec');
        }
      );
    }
  }
}
