import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {NotificationsService} from '../services/notifications.service';
import {UploadService} from '../services/upload.service';
import {Router} from '@angular/router';
import {PaymentMethod} from '../models/payment-method.model';
import {PaymentMethodsService} from '../services/payment-methods.service';
import {UtilsService} from '../services/utils.service';

@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.css']
})
export class AddPaymentMethodComponent implements OnInit, OnDestroy {

  pMethodForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  subscription: Subscription = new Subscription();
  image: string;
  pourcentage = 0;
  upload = false;

  constructor(private notifyService: NotificationsService,
              private uploadService: UploadService,
              private router: Router,
              private paymentMethodServices: PaymentMethodsService,
              private utilsServices: UtilsService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initForm(): void {
    this.pMethodForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      imageUrl: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    const paymentMethod: PaymentMethod = {
      uid: this.utilsServices.replaceSpaceByUnder(this.pMethodForm.get('name').value),
      name: this.pMethodForm.get('name').value,
      imageUrl: this.uploadService.images['payment-images'][0],
      available: false,
      createdAt: new Date(),
      updatedAt: null
    };

    this.paymentMethodServices.addPaymentMethodWithSupabase(paymentMethod)
      .then((response: any) => {
        if (response.error === null) {
          this.pMethodForm.reset();
          this.uploadService.dropImageList();
          void this.router.navigate(['paymentMethods']).then(
            () => {
              this.toastTitle = 'Ajout Fait';
              this.toastMessage = `Ajout de la methode de Paiement fait avec succès !`;
              this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
            }
          );
        }else {
          this.toastTitle = 'Echec de l\'ajout';
          this.toastMessage = `Echec de l'ajout de la methode de Paiement!: ${response.error}`;
          this.notifyService.showError(this.toastMessage, this.toastTitle);
        }
      }).catch((err) => {
      this.toastTitle = 'Echec de l\'ajout';
      this.toastMessage = `Echec de l'ajout de la methode de Paiement!`;
      this.notifyService.showError(this.toastMessage, this.toastTitle);
    });
  }

  onUpload(event): void {
    this.upload = true;
    const uuid = this.utilsServices.replaceSpaceByUnder(this.pMethodForm.get('name').value);
    this.uploadService.uploadFiles(event, `supabase/payment-methods/${uuid}`, 'payment-images');
    this.subscription.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

}
