import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {PromoService} from '../../../services/promo.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import uid from 'uid';
import {NotificationsService} from '../../../services/notifications.service';
import {PromoCode} from '../../../models/promo-code.model';
import {AuthService} from '../../../services/auth.service';
import {ProductService} from '../../../services/product.service';
import {Product} from '../../../models/product.model';
import {ProductPromoCode} from '../../../models/product-promo-code.model';
import {UtilsService} from '../../../services/utils.service';

@Component({
    selector: 'app-promoadd',
    templateUrl: './promoadd.component.html',
    styleUrls: ['./promoadd.component.css']
})
export class PromoaddComponent implements OnInit, OnDestroy {

    promotionForm: UntypedFormGroup;
    toastTitle: string;
    toastMessage: string;
    idStore: string;
    idGroup: string;
    uid: string;
    subscriptions: Subscription = new Subscription();
    admin: any;
    products: Product[];
    prods: string[];
    selectedProductsUid: string[] = []

    constructor(private route: ActivatedRoute,
                private promoServices: PromoService,
                private router: Router,
                private notifyService: NotificationsService,
                private productService: ProductService,
                private authService: AuthService,
                private utilsServices: UtilsService) {
    }

    ngOnInit(): void {
        this.initForm();
        this.uid = uid(32);
        this.subscriptions.add(
            this.route.paramMap.subscribe(
                token => {
                    if (token !== null) {
                        this.idStore = token.get('idStore');
                        this.idGroup = token.get('idgroup');
                    }
                }
            )
        );
        this.subscriptions.add(
            this.authService.user$.subscribe(
                user => {
                    if (user !== null) {
                        this.admin = user;
                    }
                }
            )
        );

        void this.productService.getProductsWithSupabase();
        this.subscriptions.add(
            this.productService.sProducts$.subscribe(
                products => {
                    if (products !== null) {
                        this.products = products;
                    }
                }
            )
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }


    initForm(): void {
        this.promotionForm = new UntypedFormGroup({
            code: new UntypedFormControl(),
            startedAt: new UntypedFormControl(),
            endedAt: new UntypedFormControl(),
            recurrent: new UntypedFormControl(),
            percent: new UntypedFormControl(),
            products: new UntypedFormControl()
        });
    }

    onSubmit(): void {
        const promotion: PromoCode = {
            uid: this.uid,
            createdAt: new Date(),
            uidUser: 'd4pvhpusrefx656k3zszyryxt8z36v5o',
            code: this.promotionForm.get('code').value.toUpperCase(),
            startedAt: this.promotionForm.get('startedAt').value,
            endedAt: this.promotionForm.get('endedAt').value,
            recurrent: this.promotionForm.get('recurrent').value ?? false,
            percent: this.promotionForm.get('percent').value,
            valid: false
        };


        this.promoServices.addPromoCodeWithSupabase(promotion).then(
            (response: any) => {
                if (response.error === null) {
                    this.utilsServices.products.map((uidProduct) => {
                        const productPromoCode: ProductPromoCode = {
                            uid: uid(32),
                            uidPromoCode: this.uid,
                            uidProduct
                        };
                        void this.promoServices.addProductPromoCodeWithSupabase(productPromoCode);
                    });
                    this.promotionForm.reset();
                    this.router.navigate(['/groups', this.idGroup, 'stores', this.idStore]).then(
                        () => {
                            this.toastTitle = 'Ajout Fait';
                            this.toastMessage = `Ajout de la promotion fait avec succès !`;
                            this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
                        }
                    );
                } else {
                  console.log('&^&>', response.error);
                    this.toastTitle = 'Echec de l\'ajout';
                    this.toastMessage = `Echec de l'ajout de promotion !`;
                    this.notifyService.showError(this.toastMessage, this.toastTitle);
                }
            }
        ).catch(
            () => {
                this.toastTitle = 'Echec de l\'ajout';
                this.toastMessage = `Echec de l'ajout de promotion !`;
                this.notifyService.showError(this.toastMessage, this.toastTitle);
            }
        );
    }

    addProductUid(productUid: string): void {
      this.selectedProductsUid = this.utilsServices.setProduct(productUid, this.products);
    }

    deleteProductUid(productName: string): void {
        this.prods = this.utilsServices.removeProductUid(productName, this.products);

        this.selectedProductsUid.map((productUid, index) => {
            if (productUid.toLowerCase() === productUid.toLowerCase()){
                this.selectedProductsUid.splice(index, 1);
            }
        });
    }

}
