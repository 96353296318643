import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Group} from '../models/groups.model';
import {GroupsService} from '../services/groups.service';
import {NotificationsService} from '../services/notifications.service';
import {UploadService} from '../services/upload.service';
import {catchError, Observable, of, shareReplay, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {GroupImage} from '../models/group-image.model';
import {Image} from '../models/image.model';

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.css']
})
export class GroupEditComponent implements OnInit, OnDestroy {

  groupForm: UntypedFormGroup;
  group: Group;
  uidGroup: string;
  toastTitle: string;
  toastMessage: string;
  image: string;
  pourcentage = 0;
  upload = false;
  subscriptions: Subscription = new Subscription();

  errorMessage = '';
  selectedGroup$: Observable<Group> = this.groupeService.selectedGroup$
    .pipe(
      shareReplay(1),
      catchError((error) => {
        console.log('**** ERRROR=>', error);
        this.errorMessage = error;
        return of(null);
      })
    );

  constructor(private groupeService: GroupsService,
              private notifyService: NotificationsService,
              private uploadService: UploadService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidGroup = token.get('idgroup');
          void this.groupeService.getGroupWithSupabase(this.uidGroup)
            .then(() => {
              this.selectedGroup$.subscribe((group) => {
                this.group = group;
                this.groupForm.patchValue({
                  title: group?.name,
                  description: group?.description
                });
              });
            });

        }
      )
    );

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.groupForm = new UntypedFormGroup({
      title: new UntypedFormControl('', Validators.minLength(4)),
      description: new UntypedFormControl('', Validators.minLength(10)),
      avatar: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    if (this.groupForm.get('avatar').value !== null ) {
      this.uploadService.getImageWithSupabase(this.uidGroup).then( (groupImage: GroupImage) => {

        if (groupImage !== null){
          const image: Image = groupImage.image;
          image.url = this.uploadService.images['group-images'][0];
          image.updatedAt = new Date();

          this.uploadService.updateImageWithSupabase(image)
            .then((result: any) => {
              if (result.error === null){
                this.toastTitle = 'Mis à jour Faite';
                this.toastMessage = `Modification de l'image faite avec succès !`;
                this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
              }else {
                this.toastTitle = 'Echec de la Mis à jour';
                this.toastMessage = `Modification de l'image faite Echouée > ${result.error}!`;
                this.notifyService.showError(this.toastMessage, this.toastTitle);
              }
              this.uploadService.dropImageList();
            });
        } else {
          this.uploadService.saveImage(this.uploadService.images['group-images'][0], this.uidGroup);
        }

      });
      this.updateValues();
    } else {
      this.updateValues();
    }
  }

  updateValues(): void {
    this.group.name = this.groupForm.get('title').value;
    this.group.description = this.groupForm.get('description').value;
    this.group.updatedAt = new Date();
    delete this.group.groupImage;

    this.groupeService.updateGroupWithSupabase(this.group)
      .then((response) => {
        if (response.error === null){
          this.toastTitle = 'Modification Faite';
          this.toastMessage = 'Modification du Groupe fait avec succès !';
          this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
        } else {
          this.toastTitle = 'Modification Echouée';
          this.toastMessage = 'Modification du Groupe échouée';
          this.notifyService.showError(this.toastMessage, this.toastTitle);
        }
      });
  }

  onUpload(event, type= 'group-images'): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `supabase/groups/${this.group.uid}/${this.group.uid}`, type);
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

}
