<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>Tag</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/productTags']">Product-Tags</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Ajout Tag</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2"></div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/productTags']" class="btn btn-sm btn-primary text-white"><i class="fa fa-chevron-left"></i> Back</a>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Modifier <small>Tag.</small></h5>
        </div>
        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" [formGroup]="productTagForm">
            <div class="form-group row"><label class="col-sm-2 col-form-label" for="title">Title</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                  </div>
                  <input type="text" placeholder="Title" class="form-control" id="title" formControlName="title"
                         required="required" minlength="2"
                         [ngClass]="{'is-invalid' : (productTagForm.get('title').touched || productTagForm.get('title').dirty) && !productTagForm.get('title').valid}">
                  <div class="invalid-feedback">
                    <span *ngIf="productTagForm.get('title').errors?.required">Tag Title is required !</span>
                    <span
                      *ngIf="productTagForm.get('title').errors?.minlength">Valid Tag Title must be longer than 2 caracters !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label class="col-sm-2 col-form-label" for="description">Description</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-italic"></i></span>
                  </div>
                  <input type="text" placeholder="Description" class="form-control" minlength="10"
                         formControlName="description" id="description"
                         [ngClass]="{'is-invalid' : (productTagForm.get('description').touched || productTagForm.get('description').dirty) && !productTagForm.get('description').valid}">
                  <div class="invalid-feedback">
                      <span
                        *ngIf="productTagForm.get('description').errors?.minlength">Valid Tag description must be longer than 10 caracters !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="hr-line-dashed"></div>

            <div class="form-group row">
              <div class="col-sm-4 col-sm-offset-2">
                <a [routerLink]="['/productTags']" class="btn btn-white btn-sm" type="submit">Cancel</a>
                <button  class="btn btn-primary btn-sm" type="submit"
                        [title]="productTagForm.valid ? 'Modifier Tag' : 'Disable until valid form data'"
                        [disabled]="!productTagForm.valid"> Modify
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
