<div class="ibox ">
  <div class="ibox-title">
    <h5>{{uidGroup | titlecase}}: Liste des Categories</h5>
    <div  class="ibox-tools">
      <a class="btn btn-sm btn-success text-white"
         [routerLink]="['/groups', uidGroup, 'categories', 'add']">
        <span> Ajouter Categorie</span>
      </a>
    </div>
  </div>
  <div class="ibox-content">
    <div class="table-responsive" *ngIf="storeCategories$ | async as categories">
      <app-category [categories]="categories" [uidGroup]="uidGroup"></app-category>
    </div>

  </div>
</div>
