<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>Catégorie</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/groups', idGroup, 'stores', idStore]">Catégorie produit </a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Catégorie</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2">

  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">


  <div class="row m-b-lg m-t-lg">
    <div class="col-md-6">
      <div class="profile-image">
        <img src="{{category?.image}}" class="img-thumbnail m-b-md" alt="Plate_Image">
      </div>
      <div class="profile-info">
        <div class="">
          <div>
            <h2 class="no-margins">
              {{category?.name | titlecase}}
            </h2>
            <h4>{{category?.description | titlecase}}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-2"></div>
  </div>
</div>
