<table datatable class="table table-striped table-bordered table-hover dataTables-example"
       *ngIf="paiements?.length != 0">
  <thead>
  <tr>
    <th>Store</th>
    <th>Montant</th>
    <th>Etat</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let paiement of paiements" class="w-100">
    <td><span class="pie">{{paiement?.name | titlecase}}</span></td>
    <td>{{paiement.amountDu | currency: 'USD'}}</td>
    <td>
      <div [ngSwitch]="paiement.amountDu > 0">
        <span *ngSwitchCase="true" class="badge badge-warning">En Attente</span>
        <span *ngSwitchCase="false" class="badge badge-success">Solved</span>
      </div>
    </td>
    <td>
      <a *ngIf="paiement.amountDu > 0" [routerLink]="['/groups', uidGroup, 'paiements',paiement?.uid, 'make']"
         class="btn btn-primary text-white"><i
        class="fa fa-check text-white"></i> Make Invoice</a>
    </td>
  </tr>
  </tbody>
</table>
