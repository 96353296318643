<!--<div class="wrapper wrapper-content" *ngIf="groups$ | async as groups">-->
  <div class="row">
    <div class="col-lg-4">
      <div class="ibox ">
        <div class="ibox-title">
          <span class="label label-success float-right">Total</span>
          <h5>Groups</h5>
        </div>
        <div class="ibox-content">
          <h1 class="no-margins">{{groups.length | number}}</h1>
          <div class="stat-percent font-bold text-success"><i class="fa fa-bolt"></i></div>
          <small>Total Groups</small>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="ibox ">
        <div class="ibox-title">
          <span class="label label-success float-right">Total</span>
          <h5>Stores</h5>
        </div>
        <div class="ibox-content">
          <h1 class="no-margins">{{totalStores | number}}</h1>
          <div class="stat-percent font-bold text-success"><i class="fa fa-bolt"></i></div>
          <small>Total Store</small>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="ibox ">
        <div class="ibox-title">
          <span class="label label-info float-right">Total</span>
          <h5>Orders</h5>
        </div>
        <div class="ibox-content">
          <h1 class="no-margins">{{this.allOrders.length | number}}</h1>
          <div class="stat-percent font-bold text-info"><i class="fa fa-shopping-cart"></i></div>
          <small>Total orders</small>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="ibox ">
        <div class="ibox-title">
          <span class="label label-primary float-right">Total</span>
          <h5>Incomes</h5>
        </div>
        <div class="ibox-content">
          <h1 class="no-margins">{{this.allValidateOrdersTotal | currency: 'USD'}}</h1>
          <div class="stat-percent font-bold text-navy"><i class="fa fa-level-up"></i></div>
          <small>Total incomes</small>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="ibox ">
        <div class="ibox-title">
          <span class="label label-danger float-right">Total</span>
          <h5>Delined</h5>
        </div>
        <div class="ibox-content">
          <h1 class="no-margins">{{this.allDeclinedOrdersTotal | currency: 'USD'}}</h1>
          <div class="stat-percent font-bold text-danger"><i class="fa fa-level-down"></i></div>
          <small>Total Delined</small>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Orders</h5>
          <div class="ibox-tools">
            <a class="collapse-link">
              <i class="fa fa-chevron-up"></i>
            </a>
            <a class="close-link">
              <i class="fa fa-times"></i>
            </a>
          </div>
        </div>
        <div class="ibox-content">
          <div class="row">
            <div class="col-lg-6 d-sm-inline-flex">
              <canvas #barCanvas2></canvas>
            </div>
            <div class="col-lg-6 d-sm-inline-flex">
              <canvas #barCanvas3></canvas>
            </div>
            <div class="col-lg-6">
              <table class="table table-hover margin bottom">
                <thead>
                <tr class="">
                  <th style="width: 1%" class="text-center">No.</th>
                  <th>Suppliers</th>
                  <th>Groups</th>
                  <th class="text-center">Total Validate</th>
                  <th class="text-center">Total Declined</th>
                  <th class="text-center text-white bg-primary" (click)="toggleTable()"
                      style="cursor: pointer;">
                    <i class="font-weight-bold fa" [ngClass]="{'fa-eye': ordersTableDisplayed,'fa-eye-slash': !ordersTableDisplayed}">
                      {{ordersTableDisplayed ? 'Hide' : 'Show'}}</i>
                  </th>
                </tr>
                </thead>
                <tbody *ngIf="ordersTableDisplayed">
                <tr *ngFor="let store of stores">
                  <td class="text-center">#</td>
                  <td>{{store.name | titlecase}}</td>
                  <td>{{store?.storeGroup[0]?.uidGroup | titlecase}}</td>
                  <td class="text-center"><span class="label label-primary">{{amountOrdersValidated | number}}</span></td>
                  <td colspan="2" class="text-center"><span class="label label-danger">{{amountOrdersCanceled}}</span></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-6 d-sm-inline-flex">
              <canvas #barCanvas></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--</div>-->
