<table datatable class="table table-striped table-bordered table-hover dataTables-example"
       *ngIf="categories?.length !== 0">
  <thead>
  <tr>
    <th>image</th>
    <th>Nom</th>
    <th>Description</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let categorie of categories">
    <td>
      <img src="{{categorie?.image}}" class="img-thumbnail" [alt]="'category_image'" style="max-height: 50px;">
    </td>
    <td>{{categorie?.name | titlecase}}</td>
    <td>{{categorie?.description | titlecase}}</td>
    <td>
      <a class="btn btn-success btn-xs m-0" data-toggle="tooltip" data-placement="bottom" title="Modifier le produit"
         [routerLink]="['/groups', uidGroup, 'categories', categorie?.uid, 'edit']"><i class="fa fa-pencil pr-1"></i> Edit</a>
      <a class="m-1" data-toggle="tooltip" data-placement="bottom" title="Supprimer le produit"
         (click)="removeCategory(categorie, uidGroup)"><i class="fa fa-remove text-danger"></i></a>
    </td>
  </tr>
  </tbody>
</table>
<table datatable class="table table-striped table-bordered table-hover dataTables-example"
       *ngIf="categories?.length === 0">
  <thead>
  <tr>
    <th>image</th>
    <th>Nom</th>
    <th>Description</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  </tbody>
</table>
