import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {BannersService} from '../services/banners.service';
import {UploadService} from '../services/upload.service';
import {NotificationsService} from '../services/notifications.service';
import {Banner} from '../models/banner.model';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-banner-edit',
  templateUrl: './banner-edit.component.html',
  styleUrls: ['./banner-edit.component.css']
})
export class BannerEditComponent implements OnInit {

  bannerForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  pourcentage = 0;
  upload = false;
  uidBanner: string;
  banner: Banner;
  subscriptions: Subscription = new Subscription();
  constructor(private route: ActivatedRoute,
              private router: Router,
              private bannerService: BannersService,
              public uploadService: UploadService,
              private notifyService: NotificationsService) { }

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidBanner = token.get('id');

          this.bannerService.getBanner(this.uidBanner);
          this.subscriptions.add(
            this.bannerService.currentBanner$.subscribe(
              banner => {
                if (banner !== null) {
                  this.banner = banner;
                }
              }
            )
          );
          setTimeout(() => {
            this.patchForm();
          }, 1000);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initForm(): void {
    this.bannerForm = new UntypedFormGroup({
      image: new UntypedFormControl('', Validators.required),
      endAt: new UntypedFormControl('',  Validators.required),
      link: new UntypedFormControl('',  Validators.required)
    });
  }

  onSubmit(): void {
    if (this.bannerForm.get('image').value !== null) {
      this.banner.imageUrl = this.uploadService.images['banner-images'][0];
      this.updateValues();
    } else {
      this.updateValues();
    }
  }

  updateValues(): void {
    this.banner.endAt = new Date(this.bannerForm.get('endAt').value);
    this.banner.link = (this.bannerForm.get('link').value).trim();
    this.banner.updatedAt = new Date();

    this.bannerService.updateBanner(this.banner).then(
      () => {
        this.router.navigate(['banners']).then(
          () => {
            this.toastTitle = 'Modification Faite';
            this.toastMessage = 'Modification de la Bannière faite avec succès !';
            this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
            this.bannerForm.reset();

            this.uploadService.dropImageList();
          }
        );
      }
    ).catch(
      () => {
        this.toastTitle = 'Modification Echouée';
        this.toastMessage = 'Modification de la Bannière a échouée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `banners/${this.uidBanner}`, 'banner-images');
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

  private patchForm(): void {
    this.bannerForm.patchValue({
      endAt: this.banner.endAt,
      link: this.banner.link
    });
  }
}
