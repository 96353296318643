import {PermissionBase} from './permission-base';
import {Roles} from '../models/roles.enum';
import {AdminPermission} from './admin-permission';
import {AgentPermission} from './agent-permission';

export class PermissionsFactory {
  public static instance: PermissionBase;
  private constructor() {
  }

  public static getInstance(): PermissionBase{
    if (this.instance){
      return this.instance;
    }
    else {
      const role = localStorage.getItem('role');
      switch (role) {
        case Roles.ADMIN:
          this.instance = new AdminPermission();
          break;
        case Roles.AGENT:
          this.instance = new AgentPermission();
          break;
        default:
          this.instance = new AgentPermission();
          break;
      }
    }
  }

}
