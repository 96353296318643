import {Component, OnDestroy} from '@angular/core';
import {catchError, of, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {NotificationsService} from '../services/notifications.service';
import {AdminsService} from '../services/admins.service';
import {User} from '../models/user.model';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.css']
})
export class AdminsComponent implements OnDestroy {

  admins: User[] = [];
  subscription: Subscription = new Subscription();
  errorMessage = '';
  admins$ = this.adminService.admins$
    .pipe(
      catchError((error) => {
        this.errorMessage = error;
        return of(null);
      })
    );

  constructor(private adminService: AdminsService,
              private route: ActivatedRoute,
              private notifyService: NotificationsService) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  lockAdmin(admin: User): void {
    if (confirm('Etes-vous sure de vouloir Bloquer cet Administrateur ?')) {
      this.adminService.disableAdmin(admin).then(
        () => {
          this.notifyService.showSuccess(`'L'Administrateur a été bloqué avec succès !'`, 'Notification Faite');
        }
      ).catch(
        error => {
          this.notifyService.showError(`Echec l'Administrateur n'a pas été bloqué=> ${error}`, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Bloquage de l'Administrateur Annulé`, 'Echec');
    }
  }

  restoreAdmin(admin: User): void {
    if (confirm('Etes-vous sure de vouloir Restorer cet Administrateur ?')) {
      this.adminService.restoreAdmin(admin).then(
        () => {
          this.notifyService.showSuccess(`'L'Administrateur a été restauré avec succès !'`, 'Notification Faite');
        }
      ).catch(
        error => {
          this.notifyService.showError(`Echec l'Administrateur n'a pas été restauré=> ${error}`, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Restauration de l'Administrateur Annulé`, 'Echec');
    }
  }

  removeAdmin(admin: User): void {
    if (confirm('Etes-vous sure de vouloir supprimer cet Administrateur ? Cette Action est irreversible')) {
      this.adminService.deleteA(admin).then(
        () => {
          this.notifyService.showSuccess('Suppression de l\'Administrateur fait avec succès !', 'Notification Faite');
        }
      ).catch(
        error => {
          this.notifyService.showError(`Echec de la suppression de l'Administrateur => ${error}`, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Suppression de l'Administrateur Annulée`, 'Echec');
    }
  }
}
