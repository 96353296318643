import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {CategoryProductService} from '../../../../services/category-product.service';
import {NotificationsService} from '../../../../services/notifications.service';
import {UploadService} from '../../../../services/upload.service';
import {Category} from '../../../../models/category.model';

@Component({
  selector: 'app-product-categoryedit',
  templateUrl: './product-categoryedit.component.html',
  styleUrls: ['./product-categoryedit.component.css']
})
export class ProductCategoryeditComponent implements OnInit, OnDestroy {

  categoryForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  uidStore: string;
  uidCategory: string;
  uidGroup: string;
  category: Category;
  image: string;
  pourcentage = 0;
  upload = false;
  subscriptions: Subscription = new Subscription();

  constructor(private notifyService: NotificationsService,
              private categoryProductService: CategoryProductService,
              private uploadService: UploadService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          if (token !== null) {
            this.uidStore = token.get('idStore');
            this.uidGroup = token.get('idgroup');
            this.uidCategory = token.get('id');

            this.categoryProductService.getCategoryProduct(this.uidGroup, this.uidStore, this.uidCategory).subscribe(
              category => {
                if (category !== null) {
                  this.category = category;
                  this.categoryForm.patchValue({
                    title: this.category.name,
                    description: this.category.description
                  });
                }
              }
            );
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.categoryForm = new UntypedFormGroup({
      title: new UntypedFormControl(),
      description: new UntypedFormControl(),
      image: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    if (this.categoryForm.get('image').value !== null) {
      this.category.image = this.uploadService.images['productCategory-images'][0];
      this.updateValues();
    } else {
      this.updateValues();
    }
  }

  updateValues(): void {
    this.category.name = this.categoryForm.get('title').value;
    this.category.description = this.categoryForm.get('description').value;
    this.categoryProductService.updateCategoryProduct(this.category, this.uidStore, this.uidGroup).then(
      () => {
        this.router.navigate(['/categories']).then(
          () => {
            this.toastTitle = 'Modification Faite';
            this.toastMessage = 'Modification de la category faite avec success !';
            this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
          }
        );
      }
    ).catch(
      () => {
        this.toastTitle = 'Modification Echouée';
        this.toastMessage = 'Modification de la category échouée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `groups/${this.uidGroup}/stores/${this.uidStore}/category-products/${this.uidCategory}/${this.uidCategory}`, 'productCategory-images');
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

}
