<div class="row animated fadeInRight">
  <div class="col-lg-12">
    <div class="ibox ">
      <div class="ibox-title">
        <h5>Liste des Promotions</h5>
        <div class="ibox-tools">
          <a class="btn btn-sm btn-success text-white"
             [routerLink]="['/groups', idGroup, 'stores', idStore, 'promos', 'add']"><span> Ajouter Promotion</span></a>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <app-promo [promos]="promoCodes"></app-promo>
        </div>

      </div>
    </div>
  </div>

</div>

