import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Category} from '../../../models/category.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {CategoryProductService} from '../../../services/category-product.service';
import {ProductService} from '../../../services/product.service';
import {UploadService} from '../../../services/upload.service';
import {Product} from '../../../models/product.model';
import {NotificationsService} from '../../../services/notifications.service';
import uid from 'uid';
import {MetaData} from '../../../models/metadata.model';
import {MetaDataValueType} from '../../../models/metadata-valueType.enum';
import {MetaDataValue} from '../../../models/metadata-value.model';
import {UtilsService} from '../../../services/utils.service';
import {ProductTagService} from '../../../services/product-tag.service';
import {ProductTag} from '../../../models/product-tag.model';
import {ProductType} from '../../../models/product-type.model';
import {MetaDatasService} from '../../../services/meta-datas.service';
import {ProductSpecsService} from '../../../services/product-specs.service';
import {Dimension} from '../../../models/dimension.model';
import {ProductShippingDetail} from '../../../models/product-shipping-detail.model';

@Component({
  selector: 'app-productadd',
  templateUrl: './productadd.component.html',
  styleUrls: ['./productadd.component.css']
})
export class ProductaddComponent implements OnInit, OnDestroy {

  productForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  uidStore: string;
  uidGroup: string;
  categories: Category[];
  uid;
  pourcentage = 0;
  upload = false;
  subscriptions: Subscription = new Subscription();
  tags: string[] = [];
  productTags: ProductTag[] = [];
  productShippingPrice: ProductShippingDetail;
  metaDataValueTypes: MetaDataValueType[] = [
    MetaDataValueType.COLOR,
    MetaDataValueType.TEXT,
    MetaDataValueType.IMAGE_URL,
    MetaDataValueType.SEAT
  ];
  productTypes: ProductType[] = [
    ProductType.AIRPLANE_TICKET,
    ProductType.BUS_TICKET,
    ProductType.FOOD,
    ProductType.GOOD,
    ProductType.SERVICE,
    ProductType.EVENT_TICKET
  ];
  metaDataValues: MetaDataValue[] = [];
  metaDatas: MetaData[] = [];
  productDimension: Dimension;
  productSpecs: {[key: string]: string} = {};
  selectedTagsName: string[] = [];
  selectedMetaDataValueType: string[] = [];
  metaDataValueImgPourcentage = 0;
  metaDataValueImgupload = false;

  productDraft: Product = {
    uid: 'productDraft',
    name: 'Likasi-Fugurume, 31 décembre 2022, 08h30',
    createdAt: new Date(),
    updatedAt: null,
    deletedAt: null,
    quantity: 10,
    available: true,
    isDraft: true,
    description: 'Vous pouvez acheter vos billets sur be-served',
    readyForShippingAfter: 0,
    price: 12,
    uidPromotion: 'null-promotion-uid',
    priority: 0,
    uidStore: 'uidStore',
    productType: ProductType.BUS_TICKET,
    tags: null,
    images: [],
    uidDimension: 'uid-dimension',
    dimension: {
      uid: uid(32),
      title: 'Wait...',
      weight: 0,
      length: 0,
      width: 0,
      height: 0,
      fragileBox: false,
      createdAt: new Date(),
      updatedAt: null,
      deletedAt: null
    },
    shippingDetail: {
      uid: uid(32),
      freeShipping: false,
      defaultShippingPrice: 0,
      expressShippingDetail: '',
      expressShippingPrice: 0,
      defaultShippingDetail: ''
    },
    metaDatas: [
      {
        uid: uid(32),
        uidMetaData: uid(32),
        uidProduct: 'productDraft',
        metaData: {
          uid: uid(32),
          name: 'Sièges ',
          description: 'Product MetaData',
          type: MetaDataValueType.SEAT,
          metaDataValues: [
            {
              uid: '',
              value: '7',
              quantity: 1,
              available: true,
              extraPrice: 0
            },
            {
              uid: '',
              value: '12',
              quantity: 1,
              available: true,
              extraPrice: 0
            },
            {
              uid: '',
              value: '14',
              quantity: 1,
              available: true,
              extraPrice: 0
            },
            {
              uid: '',
              value: '20',
              quantity: 1,
              available: true,
              extraPrice: 0
            },
            {
              uid: '',
              value: '24',
              quantity: 1,
              available: true,
              extraPrice: 0
            },
            {
              uid: '',
              value: '25',
              quantity: 1,
              available: true,
              extraPrice: 0
            },
            {
              uid: '',
              value: '26',
              quantity: 1,
              available: true,
              extraPrice: 0
            },
            {
              uid: '',
              value: '28',
              quantity: 1,
              available: true,
              extraPrice: 0
            }
          ]
        },
      },
    ],
    uidShippingDetail: 'uid-Shipping-Detail'
  };

  constructor(private route: ActivatedRoute,
              private categoryProductService: CategoryProductService,
              private productService: ProductService,
              public uploadService: UploadService,
              private notifyService: NotificationsService,
              private utilsServices: UtilsService,
              private metadataServices: MetaDatasService,
              private productSpecServices: ProductSpecsService,
              private productTagService: ProductTagService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.initForm();
    this.uid = uid(32);
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidStore = token.get('idStore');
          this.uidGroup = token.get('idgroup');

          this.subscriptions.add(
            this.categoryProductService.getCategoriesProduct(this.uidGroup, this.uidStore).subscribe(
              categories => {
                if (categories !== null) {
                  this.categories = categories;
                }
              }
            )
          );
        }
      )
    );

    this.subscriptions.add(
      this.productTagService.getProductTags().subscribe(
        productTags => {
          if (productTags !== null) {
            this.productTags = productTags;
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  patchTemplateInForm(): void{
    this.productForm.patchValue({
      title: this.productDraft.name,
      description: this.productDraft.description,
      price: this.productDraft.price,
      quantity: this.productDraft.quantity,
      priority: this.productDraft.priority,
      readyForShippingAfter: this.productDraft.readyForShippingAfter,
      productType: this.productDraft.productType,
    });
    this.productSpecServices.fillProductSpecsForm(this.productDraft.productSpec, this.fb, this.productSpecsArray());
    this.selectedMetaDataValueType = this.metadataServices
      .fillMetadatasForm(this.productDraft.metaDatas, this.fb, this.metaDatasArray());
  }

  initForm(): void {
    this.productForm = new UntypedFormGroup({
      title: new UntypedFormControl('', [Validators.required, Validators.min(3)]),
      description: new UntypedFormControl('', [Validators.minLength(10)]),
      price: new UntypedFormControl(0, Validators.required),
      quantity: new UntypedFormControl(0, Validators.required),
      readyForShippingAfter: new UntypedFormControl(0, Validators.required),
      categoryProduct: new UntypedFormControl(),
      productTag: new UntypedFormControl(),
      productType: new UntypedFormControl('', Validators.required),
      productSpecs: this.fb.array([]),
      productDimension: new UntypedFormGroup({
        weight: new UntypedFormControl(0, Validators.min(0)),
        length: new UntypedFormControl(0, Validators.min(0)),
        width: new UntypedFormControl(0, Validators.min(0)),
        height: new UntypedFormControl(0, Validators.min(0)),
        fragileBox: new UntypedFormControl(false)
      }),
      metaDatas: this.fb.array([]),
      shippingDetail: new UntypedFormGroup({
        freeShipping: new UntypedFormControl(false),
        defaultShippingPrice: new UntypedFormControl(0, Validators.min(0)),
        expressShippingPrice: new UntypedFormControl(0, Validators.min(0)),
        expressShippingDetail: new UntypedFormControl('', Validators.minLength(10)),
      }),
      image: new UntypedFormControl('', Validators.required)
    });
  }

  onSubmit(): void {
    this.productSpecs = this.productSpecServices.addProductSpecs(this.productForm.get('productSpecs').value);
    this.productDimension = this.utilsServices.setProductDimension(this.productForm.get('productDimension').value);
    // this.productShippingPrice = this.utilsServices.setProductShippingPrice(this.productForm.get('shippingDetail').value);
    this.metaDatas = this.metadataServices.setMetadatas(this.productForm.get('metaDatas').value);

    // const produit: Product = {
    //   uid: this.uid,
    //   name: (this.productForm.get('title').value).trim(),
    //   description: (this.productForm.get('description').value).trim(),
    //   uidCategoryProduct: this.productForm.get('categoryProduct').value,
    //   images: this.uploadService.images['product-images'],
    //   price: this.productForm.get('price').value,
    //   readyForShippingAfter: this.productForm.get('readyForShippingAfter').value,
    //   productType: this.productForm.get('productType').value,
    //   available: true,
    //   createdAt: new Date(),
    //   updatedAt: null,
    //   deletedAt: null,
    //   priority: 0,
    //   promotion: null,
    //   quantity: this.productForm.get('quantity').value,
    //   ratingCount: 0,
    //   ratingTotal: 0,
    //   uidGroup: this.uidGroup,
    //   uidStore: this.uidStore,
    //   tags: this.utilsServices.tags,
    //   shippingDetail: this.productShippingPrice,
    //   metaDatas: this.metaDatas,
    //   productSpecs: this.productSpecs,
    //   productDimension: this.productDimension
    // };
    //
    // this.productService.createProduct(this.uidGroup, this.uidStore, produit).then(
    //   () => {
    //     this.toastTitle = 'Ajout Fait';
    //     this.toastMessage = 'Ajout du produit fait avec succès !';
    //     this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
    //     this.productForm.reset();
    //
    //     // Empty Arrays and objects
    //     this.uploadService.dropImageList();
    //     this.metaDatas = [];
    //     this.productSpecs = {};
    //   }
    // ).catch(
    //   () => {
    //     this.toastTitle = 'Echec';
    //     this.toastMessage = 'Ajout du produit a échoué !';
    //     this.notifyService.showError(this.toastMessage, this.toastTitle);
    //   });
  }

  onUpload(event, type = 'product-images'): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `groups/${this.uidGroup}/stores/${this.uidStore}/products/${this.uid}`, type);
    this.uploadService.uploadPercent$.subscribe(
      valeur => {
        this.pourcentage = valeur;
      }
    );
  }
   uploadMetaDataValueImg(event, metaDataIndex: number, metadataValuesIndex: number, type: string): void {
    this.metaDataValueImgupload = true;
    this.uploadService.uploadFiles(event,
      `groups/${this.uidGroup}/stores/${this.uidStore}/products/${this.uid}/metadatas/${metaDataIndex}/metadataValues/${metadataValuesIndex}`, type);
    this.uploadService.uploadPercent$.subscribe(
      value => {
        this.metaDataValueImgPourcentage = value;
      }
    );
   }

  addTag(tag: string): void{
    this.selectedTagsName = this.utilsServices.setTag(tag, this.productTags);
  }

  deleteTag(tag: string): void {
    this.tags = this.utilsServices.removeTag(tag, this.productTags);

    this.selectedTagsName.map((productTag, index) => {
      if (productTag.toLowerCase() === tag.toLowerCase()){
        this.selectedTagsName.splice(index, 1);
      }
    });
  }

  productSpecsArray(): UntypedFormArray {
    return this.productForm.get('productSpecs') as UntypedFormArray;
  }

  initProductSpec(): UntypedFormGroup {
    return this.fb.group({
      key: '',
      valeur: ''
    });
  }

  addProductSpec(): void{
    this.productSpecsArray().push(this.initProductSpec());
  }

  removeProductSpecs(i: number): void {
    this.productSpecsArray().removeAt(i);
  }

  metaDatasArray(): UntypedFormArray {
    return this.productForm.get('metaDatas') as UntypedFormArray;
  }

  initMetaData(): UntypedFormGroup {
    return this.fb.group({
      name: '',
      description: '',
      type: '',
      metaDataValues: this.fb.array([])
    });
  }

  addMetaData(): void {
    this.metaDatasArray().push(this.initMetaData());
  }

  removeMetaData(metadaIndex: number): void {
    this.metaDatasArray().removeAt(metadaIndex);
    this.selectedMetaDataValueType.splice(metadaIndex, 1);
  }

  metaDaValuesArray(metadaIndex: number): UntypedFormArray {
    return this.metaDatasArray().at(metadaIndex).get('metaDataValues') as UntypedFormArray;
  }

  initMetaDataValue(): UntypedFormGroup {
    return this.fb.group({
      value: '',
      extraPrice: [0, Validators.min(0)],
      quantity: [1, Validators.min(0)],
      available: false
    });
  }

  addMetaDataValue(metadaIndex: number): void {
    this.metaDaValuesArray(metadaIndex).push(this.initMetaDataValue());
  }

  removeMetaDataValue(metadaIndex: number, metadaValueIndex: number): void {
    // this.metaDaValuesArray(metadaIndex).removeAt(metadaValueIndex);
    // if (this.uploadService.images['metaDataValue-images-' + metadaValueIndex] !== undefined) {
    //   delete this.uploadService.images['metaDataValue-images-' + metadaValueIndex];
    // }
  }

  selectMetaDataValueType(event, metadaIndex: number): void{
    // this.selectedMetaDataValueType[metadaIndex] = event.target.value;
  }

  initializeDepartSpecs(event): void {
    if (event.target.value === 'BUS_TICKET'){
      this.productSpecs = {
        'dept-date': '',
        'dept-time': '',
        'arrival-date': '',
        'arrival-time': '',
      };
      // this.productSpecServices.fillProductSpecsForm(this.productSpecs, this.fb, this.productSpecsArray());
      // console.log('#>>', this.productForm.get('productSpecs').get('key').value);
      // this.productForm.get('key').value.disabled = true;
    }
  }
  disableInputOnSpecInitialized(event): void {
    // console.log('#>>', event);
  }
}
