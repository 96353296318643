import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {BehaviorSubject, catchError, Observable, shareReplay, Subscription} from 'rxjs';
import {User} from '../models/user.model';
import {Order} from '../models/order.model';
import {map} from 'rxjs/operators';
import {supabase} from './supabase';
import {StoreUser} from '../models/store-user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  currentUser$ = this.currentUser.asObservable();
  subscriptions: Subscription = new Subscription();
  handleError: any = '';
  users$: Observable<User[]> = this.firestoreService.col$('users/')
    .pipe(
      shareReplay(1),
      catchError(this.handleError)
    );

  private USER_TABLE = 'User';
  private state = new BehaviorSubject<any>({users: []});
  sUsers$: Observable<User[]> = this.state
    .asObservable()
    .pipe(map((state) => state.users));

  private userSelectedAction = new BehaviorSubject<any>({user: null});
  selectedUser$: Observable<User> = this.userSelectedAction
    .asObservable().pipe((uState) => uState);

  private STOREUSER_TABLE = 'StoreUser';

  constructor(private firestoreService: FirestoreService) {
  }

  getUsers(): Observable<User[]> {
    return this.firestoreService.col$(`users`);
  }

  getUser(uidUser: string): void {
    this.subscriptions.add(
      this.firestoreService.doc$(`users/${uidUser}`).subscribe(
        (user: User) => {
          this.currentUser.next(user);
        }
      )
    );
  }

  getUserOrders(uidUser: string): Observable<Order[]> {
    return this.firestoreService.col$(`users/${uidUser}/orders`);
  }

  updateUser(user: User): Promise<any> {
    return this.firestoreService.set(`users/${user.uid}`, user);
  }

  deleteUser(user: User): Promise<any> {
    return this.firestoreService.update2(
      `users/${user.uid}`, {
        deletedAt: Date.now()
      }
    );
  }

  restoreUser(user: User): Promise<any> {
    return this.firestoreService.update2(`users/${user.uid}`, {deletedAt: null});
  }

  deleteAUser(user: User): Promise<any> {
    return this.firestoreService.delete(`users/${user.uid}`);
  }

  /**
   * SUPABASE
   */

  async getUsersWithSupabase(): Promise<void> {
    const {data} = await supabase
      .from(this.USER_TABLE)
      .select('*')
      .is('deletedAt', null)
      .order('uid', {ascending: false});

    this.state.next({
      users:
        data?.map((user: User) => (user)) ?? [],
    });
  }

  async getUserWithSupabase(uidUser: string): Promise<void> {
    const {data} = await supabase
      .from(this.USER_TABLE)
      .select(`*`)
      .eq('uid', uidUser).single();

    this.userSelectedAction.next(data ?? null);
  }

  async addUserWithSupabase(user: User): Promise<any> {
    const {error} = await supabase
      .from(this.USER_TABLE)
      .insert(user).single();
    return {error};
  }

  async updateUserWithSupabase(user: User): Promise<any> {
    const {data, error} = await supabase
      .from(this.USER_TABLE)
      .update(user)
      .match({uid: user.uid});

    return {data, error};
  }

  async deleteUserWithSupabase(uidUser: string): Promise<any> {
    const {data} = await supabase
      .from(this.USER_TABLE)
      .update({deletedAt: new Date()})
      .match({uid: uidUser});


    return data;
  }

  async addStoreUserWithSupabase(storeUser: StoreUser): Promise<any> {
    const {error} = await supabase
      .from(this.STOREUSER_TABLE)
      .insert(storeUser).single();

    return {error};
  }
}
