import {Component, OnDestroy, OnInit} from '@angular/core';
import {catchError, Observable, of, Subscription} from 'rxjs';
import {PaymentMethod} from '../models/payment-method.model';
import {PaymentMethodsService} from '../services/payment-methods.service';
import {ActivatedRoute} from '@angular/router';
import {NotificationsService} from '../services/notifications.service';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.css']
})
export class PaymentMethodsComponent implements OnInit, OnDestroy {

  paymentMethods: PaymentMethod[] = [];
  subscription: Subscription = new Subscription();
  toastTitle: string;
  toastMessage: string;
  state: boolean;
  errorMessage = '';
  paymentMethods$: Observable<PaymentMethod[]> = this.paymentMethodsServices.sPaymentMethods$
    .pipe(
      catchError((error) => {
        this.errorMessage = error;
        return of(null);
      })
    );

  constructor(private paymentMethodsServices: PaymentMethodsService,
              private route: ActivatedRoute,
              private notifyService: NotificationsService) {
    void this.paymentMethodsServices.getPaymentMethodsWithSupabase();

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  onChange(event, uidPaymentMethod: string): void {
    this.paymentMethodsServices.updateEtatWithSupabase(uidPaymentMethod, event.currentValue).then(
      () => {
        this.toastTitle = 'Success';
        this.toastMessage = 'La methode de Paiement a maintenant changer de disponibilité !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {
        this.toastTitle = 'Error';
        this.toastMessage = 'Le changement de disponibilité a échoué !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  removePayementMethod(paymentMethod: PaymentMethod): void {
    if (confirm('Etes-vous sure de vouloir supprimer cette methode de Paiement ? Cette Action est irreversible')) {
      this.paymentMethodsServices.deletePaymentMethodWithSupabase(paymentMethod.uid).then(
        () => {
          this.notifyService.showSuccess('Suppression de la methode de Paiement fait avec succès !', 'Notification Faite');
        }
      ).catch(
        error => {
          this.notifyService.showError(`Echec de la suppression de la methode de Paiement => ${error}`, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Suppression de la methode de Paiement Annulée`, 'Echec');
    }
  }

}
