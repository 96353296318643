import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {NotificationsService} from '../services/notifications.service';
import {ProductTagService} from '../services/product-tag.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductTag} from '../models/product-tag.model';

@Component({
  selector: 'app-edit-product-tag',
  templateUrl: './edit-product-tag.component.html',
  styleUrls: ['./edit-product-tag.component.css']
})
export class EditProductTagComponent implements OnInit, OnDestroy {

  productTagForm: UntypedFormGroup;
  productTag: ProductTag;
  toastTitle: string;
  toastMessage: string;
  uidProductTag: string;
  subscriptions: Subscription = new Subscription();

  constructor(private notifyService: NotificationsService,
              private productTagServices: ProductTagService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidProductTag = token.get('id');
          this.productTagServices.getProductTag(this.uidProductTag);
          this.subscriptions.add(
            this.productTagServices.currentProductTag$.subscribe(
              (productTag) => {
                if (productTag !== null) {
                  this.productTag = productTag;

                  this.productTagForm.patchValue({
                    title: productTag?.tag.title,
                    description: productTag?.tag.description
                  });
                }
          }
            )
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.productTagForm = new UntypedFormGroup({
      title: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl()
    });
  }
  onSubmit(): void {
    this.productTag.tag.title = this.productTagForm.get('title').value;
    this.productTag.tag.description = this.productTagForm.get('description').value;
    this.productTag.tag.updatedAt = new Date();
    this.productTagServices.updateProductTag(this.productTag).then(
      () => {
        this.router.navigate(['productTags']).then(
          () => {
            this.toastTitle = 'Modification Fait';
            this.toastMessage = 'Modification du Tag fait avec succès !';
            this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
          }
        );
      }
    ).catch(
      () => {
        this.toastTitle = 'Echec de la modification';
        this.toastMessage = 'Echec de la modification du Tag !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

}
