import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {Category} from '../../../../models/category.model';
import {CategoryProductService} from '../../../../services/category-product.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.css']
})
export class ProductCategoriesComponent implements OnInit, OnDestroy {

  categories: Category[] = [];
  uidStore: string;
  uidGroup: string;
  subscriptions: Subscription = new Subscription();

  constructor(private categoryProductService: CategoryProductService,
              private authService: AuthService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          if (token !== null) {
            this.uidStore = token.get('idStore');
            this.uidGroup = token.get('idgroup');
            this.categoryProductService.getCategoriesProduct(this.uidGroup, this.uidStore).subscribe(
              categories => {
                if (categories !== null){
                  this.categories = categories;
                }
              },
            );
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
