export const environment = {
  production: false,
  firebase : {
    apiKey: 'AIzaSyC4gdTgmUWNXJEU-lgd4GoWgydOjy60lrM',
    authDomain: 'be-served-backend-52bbe.firebaseapp.com',
    databaseURL: 'https://be-served-backend-52bbe.firebaseio.com',
    projectId: 'be-served-backend-52bbe',
    storageBucket: 'be-served-backend-52bbe.appspot.com',
    messagingSenderId: '932414301334',
    appId: '1:932414301334:web:185e8e6e9b61b15e700eb7',
    measurementId: 'G-02BHFHPYFQ'
  },
  firebaseTest : {
    apiKey: 'AIzaSyABOHzmJ9smmuMIGRd2dBZwWrfIq_2RZ-Q',
    authDomain: 'bs-backend-test-21dd2.firebaseapp.com',
    databaseURL: 'https://bs-backend-test-21dd2-default-rtdb.firebaseio.com',
    projectId: 'bs-backend-test-21dd2',
    storageBucket: 'bs-backend-test-21dd2.appspot.com',
    messagingSenderId: '877796200841',
    appId: '1:877796200841:web:97424eef82a6ee0f8a9158'
  },
  supabaseTest : {
    supabaseUrl: 'https://dybdprcgwqsxrgrucdgw.supabase.co',
    supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR5YmRwcmNnd3FzeHJncnVjZGd3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzU2OTIzMzUsImV4cCI6MTk5MTI2ODMzNX0.1b9sT8weGMLcMAIayNUMyHfYzfk1MsCZB1gknh4ov1w'
  }
};
