<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-8">
    <h2>Users</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/users']">Users</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Users</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2">
  </div>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="tabs-container">
        <ul class="nav nav-tabs">
          <li><a class="nav-link active" data-toggle="tab" href="#users-tab"> <i class="fa fa-users"></i> Users</a>
          </li>
          <li><a class="nav-link" data-toggle="tab" href="#number-tab"><i class="fa fa-sort-numeric-asc"></i> Numeros</a></li>
        </ul>
        <div class="tab-content">
          <div id="users-tab" class="tab-pane active">
            <div class="ibox" *ngIf="users$ | async as users">
              <div class="ibox-title">
                <h5>Liste des Utilisateurs ({{users.length}})</h5>
                <div class="ibox-tools">
                </div>
              </div>
              <div class="ibox-content">
                <div class="table-responsive">
                  <app-user [users]="users"></app-user>
                </div>
              </div>
            </div>
          </div>
          <div id="number-tab" class="tab-pane">
            <div class="ibox ">
              <div class="ibox-title">
                <h5>Orders - Phone Numbers  </h5>
                <div class="ibox-tools">
                </div>
              </div>
              <div class="ibox-content">
                <div class="table-responsive">
                  <table datatable class="table table-striped table-bordered table-hover"
                         *ngIf="ordersWithUsers$ | async as orders">
                    <thead>
                    <tr>
                      <th>Phone number</th>
                      <th>Username</th>
                      <th>Order Ref</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let order of orders" class="w-100">
                      <td>{{order?.phoneNumber || order?.user?.phoneNumber || '-'}}</td>
                      <td>
                        <a [routerLink]="['/users', order?.user?.uid]">
                          {{order?.user?.name}}
                        </a>
                      </td>
                      <td>
                        <a [routerLink]="['/groups', order.uidGroup, 'stores', order.uidStore, order.uid]">
                          {{order.orderShortCode}}
                        </a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
