import {Injectable, OnDestroy} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Router} from '@angular/router';
import {NotificationsService} from './notifications.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {PermissionManagerService} from './permissions/permission-manager.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {


  private user = new BehaviorSubject<any | null>(null);
  public user$ = this.user.asObservable();
  private sub: Subscription = new Subscription();

  constructor(private angularFireAuth: AngularFireAuth, private router: Router,
              private notifyService: NotificationsService,
              private userPermission: PermissionManagerService) {
    this.isAuth();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }


  isAuth(): void {
    this.sub.add(this.angularFireAuth.authState.subscribe(
      user => {
        if (user) {
          user.getIdTokenResult().then(
            token => {
              this.userPermission.authAs(token.claims.type);
              if (token.claims.coo === undefined || token.claims.coo === false) {
                this.notifyService.showError(`Vous n'êtes pas autorisé à accèder!`, 'Access Denied');
                this.logout();
              }
            }
          );
          this.user.next(user);
        } else {
          this.router.navigate(['login']).then();
        }
      }
      )
    );
  }

  login(email: string, password: string): void {
    this.angularFireAuth.signInWithEmailAndPassword(email, password).then(
      () => {
        this.isAuth();
        this.router.navigate(['dashboard']).then(
          () => {
            this.notifyService.showSuccess(`Bienvenue sur le Backoffice de Be Served`, 'Connexion Réussie');
          }
        );
      }
    ).catch(
      result => {
        if (result.code === 'auth/wrong-password' || result.code === 'auth/user-not-found') {
          this.notifyService.showError(`Les identifiants sont invalides`, 'Connexion Echouée');
        } else if (result.code === 'auth/network-request-failed') {
          this.notifyService.showWarning(`Veuillez Verifier votre connexion s'il vous plait`, 'Connexion Echouée');
        } else if (result.code === 'auth/user-disabled') {
          this.notifyService.showWarning(`Votre Compte a été bloqué! Veuillez contacter l'administrateur principal s'il vous plait`, 'Connexion Refusée');
        } else if (result.code === 'auth/too-many-requests') {
          this.notifyService.showWarning(`Votre Compte a été bloqué pour des mesures de securité! Veuillez réessayer dans 2 heures s'il vous plait`, 'Connexion Refusée');
        }
      }
    );
  }

  logout(): void {
    this.angularFireAuth.signOut().then(
      () => {
        this.router.navigate(['login']).then(
          () => {
            this.notifyService.showSuccess(`Aurevoir, à Bientôt !`, 'Déconnexion');
          }
        );
      }
    ).catch(
      err => {
        console.log(err);
      }
    );
  }

}
