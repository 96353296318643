<div class="loginColumns animated fadeInDown text-white">
    <div class="row">

      <div class="col-md-6">
        <h2 class="font-bold">Welcome to Be Served</h2>

        <p>
          Perfectly designed and precisely prepared admin theme with over 50 pages with extra new web app views.
        </p>

        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s.
        </p>

        <p>
          When an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </p>

        <p>
          <small>It has survived not only five centuries, but also the leap into electronic typesetting, remaining
            essentially unchanged.</small>
        </p>

      </div>
      <div class="col-md-6">
        <div class="ibox-content">
          <form class="m-t" (ngSubmit)="connexion()" [formGroup]="loginForm">
            <div class="form-group">
              <input type="email" id="email" formControlName="email" class="form-control"
                     placeholder="Email" required="required"
                     [ngClass]="{'is-invalid' : (loginForm.get('email').touched || loginForm.get('email').dirty) && !loginForm.get('email').valid}">
              <div class="invalid-feedback">
                <span *ngIf="loginForm.get('email').errors?.required">Your email is required !</span>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group m-b">
                <input type="password" [type]="fieldTextType ? 'text' : 'password'"  class="form-control" placeholder="Password" required="required"
                       formControlName="password" minlength="6"
                       [ngClass]="{'is-invalid' : (loginForm.get('password').touched || loginForm.get('password').dirty) && !loginForm.get('password').valid}">
                <div class="input-group-append">
              <span class="input-group-text">
                <i class="fa" [ngClass]="{'fa-eye': !fieldTextType,'fa-eye-slash': fieldTextType}"
                   (click)="toggleFieldTextType()"></i></span>
                </div>
                <div class="invalid-feedback">
                  <span *ngIf="loginForm.get('password').errors?.required">Your Password is required !</span>
                  <span
                    *ngIf="loginForm.get('password').errors?.minlength">Valid Password must be longer than 6 caracters !</span>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary block full-width m-b"
                    [title]="loginForm.valid ?  'User LoggedIn' : 'Disable until valid form data'"
                    [disabled]="!loginForm.valid">Login
            </button>
          </form>
          <p class="m-t">
            <small>Be Served Store web app &copy; 2020</small>
          </p>
        </div>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-md-6">
        Copyright Be-Served
      </div>
      <div class="col-md-6 text-right">
        <small>© 2020</small>
      </div>
    </div>
  </div>
