import {Component, OnDestroy, OnInit} from '@angular/core';
import {Order} from '../../../models/order.model';
import {ProductOrder} from '../../../models/product-order.model';
import {Deliver} from '../../../models/deliver.model';
import {ActivatedRoute} from '@angular/router';
import {ProductService} from '../../../services/product.service';
import {OrderService} from '../../../services/order.service';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../services/store.service';
import {Store} from '../../../models/store.model';
import {UtilsService} from '../../../services/utils.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DeliverService} from '../../../services/deliver.service';
import {UserService} from '../../../services/user.service';
import {User} from '../../../models/user.model';
import {InvoiceService} from '../../../services/invoice.service';

@Component({
  selector: 'app-order-single',
  templateUrl: './order-single.component.html',
  styleUrls: ['./order-single.component.css']
})
export class OrderSingleComponent implements OnInit, OnDestroy {

  idCommande: string;
  idStore: string;
  idGroup: string;
  order: Order;
  products: ProductOrder [];
  deliver: Deliver;
  toastTitle: string;
  toastMessage: string;
  subscriptions: Subscription = new Subscription();
  store: Store;
  user: User;
  selectForm: UntypedFormGroup;
  delivers: Deliver[];

  constructor(private route: ActivatedRoute,
              private utilsServices: UtilsService,
              private productService: ProductService,
              private orderService: OrderService,
              private storeService: StoreService,
              private userService: UserService,
              private invoiceService: InvoiceService,
              private deliverService: DeliverService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          if (token !== null) {
            this.idCommande = token.get('idOrder');
            this.idStore = token.get('idStore');
            this.idGroup = token.get('idgroup');
            this.subscriptions.add(
              this.orderService.getOrder(this.idCommande, this.idStore, this.idGroup).subscribe(
                order => {
                  if (order !== null) {
                    this.order = order;
                    if (order.uidDeliver !== '') {
                      this.subscriptions.add(
                        this.orderService
                          .getOrderDeliver(order.uidDeliver, order.uid, order.uidStore, order.store.storeGroup[0].uidGroup).subscribe(
                          deliver => {
                            if (deliver !== null) {
                              this.deliver = deliver;
                            }
                          }
                        )
                      );
                    }

                    this.userService.getUser(this.order.uidUser);
                    this.subscriptions.add(
                      this.userService.currentUser$.subscribe(
                        user => {
                          if (user !== null) {
                            this.user = user;
                          }
                        }
                      )
                    );
                  }
                }
              )
            );

            this.subscriptions.add(
              this.productService.getOrderProducts(this.idGroup, this.idCommande, this.idStore).subscribe(
                products => {
                  if (products !== null) {
                    this.products = products;
                  }
                }
              )
            );

            this.storeService.getStore(this.idGroup, this.idStore);
            this.subscriptions.add(
              this.storeService.currentStore$.subscribe(
                store => {
                  if (store !== null) {
                    this.store = store;
                  }
                }
              )
            );
          }

        }
      )
    );
    this.selectForm = new UntypedFormGroup({
      deliver: new UntypedFormControl('', Validators.required)
    });
    this.subscriptions.add(
      this.deliverService.getDelivers().subscribe(
        delivers => {
          if (delivers !== null){
            this.delivers = delivers;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  validateCommande(order: Order): void {
    this.utilsServices.makeOrderValidateCommande(order);
  }

  giveToDeliver(order: Order): void {
    this.utilsServices.makeOrderGiveToDeliver(order);
  }

  readyForShipping(order: Order): void {
    this.utilsServices.makeOrderReadyForShipping(order);
  }

  orderDelivered(order: Order): void {
    this.utilsServices.makeOrderDelivered(order);
  }

  deleteOrder(order: Order): void {
    this.utilsServices.removeOrder(order, this.store);
  }

  selectDeliver(): void {
    this.utilsServices.affectOrder(this.order, this.selectForm.get('deliver').value);
  }

  generatePDF(myOrder: Order): void {
    this.invoiceService.generateTicketInvoice(myOrder, this.store, this.user, this.products);
  }
}
