import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NotificationsService} from '../../services/notifications.service';
import {UploadService} from '../../services/upload.service';
import {CategoryStoreService} from '../../services/category-store.service';
import uid from 'uid';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Category} from '../../models/category.model';

@Component({
  selector: 'app-category-add',
  templateUrl: './category-add.component.html',
  styleUrls: ['./category-add.component.css']
})
export class CategoryAddComponent implements OnInit, OnDestroy {

  categorieForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  uidGroup: string;
  uid;
  image: string;
  pourcentage = 0;
  upload = false;
  subscriptions: Subscription = new Subscription();

  constructor(private notifyService: NotificationsService,
              private uploadService: UploadService,
              private categoryStoreService: CategoryStoreService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.initForm();
    this.uid = uid(32);
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidGroup = token.get('idgroup');
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.categorieForm = new UntypedFormGroup({
      title: new UntypedFormControl(),
      description: new UntypedFormControl(),
      image: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    const category: Category = {
      uid: this.uid,
      name: this.categorieForm.get('title').value,
      description: this.categorieForm.get('description').value,
      image: this.uploadService.images['category-images'][0],
      createdAt: new Date(),
      available: true,
      updatedAt: null,
      deletedAt: null,
      priority: 0
    };
    this.categoryStoreService.addCategoryWithSupabase(category)
      .then(
        () => {
          this.toastTitle = 'Ajout Fait';
          this.toastMessage = 'Ajout de la categorie fait avec succès !';
          this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
          this.categorieForm.reset();
          this.uploadService.dropImageList();
        }
      ).catch(
      () => {
        this.toastTitle = 'Echec';
        this.toastMessage = 'Echec de l\'Ajout de la categorie';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `supabase/groups/${this.uidGroup}/category-stores/${this.uid}/${this.uid}`, 'category-images');
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }
}
