<div class="ibox ">
  <div class="ibox-title">
    <h5>{{uidGroup | titlecase}}: Liste des Product Types</h5>
    <div  class="ibox-tools">
      <a class="btn btn-sm btn-success text-white"
         [routerLink]="['/groups', uidGroup, 'product-types', 'add']">
        <span> Ajouter Product Type</span>
      </a>
    </div>
  </div>
  <div class="ibox-content">
    <div class="table-responsive" *ngIf="groupProductTypes$ | async as productTypes">
      <table datatable class="table table-striped table-bordered table-hover dataTables-example"
             *ngIf="productTypes?.length !== 0">
        <thead>
        <tr>
          <th>Product Type</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let productType of productTypes">
          <td>{{productType.productType | titlecase}}</td>
          <td>
            <a class="btn btn-sm btn-danger text-white m-1" data-toggle="tooltip" data-placement="bottom" title="Supprimer le product type"
               (click)="removeGroupProductType(productType)"><i class="fa fa-remove"></i> Delete</a>
          </td>
        </tr>
        </tbody>
      </table>
      <table datatable class="table table-striped table-bordered table-hover dataTables-example"
             *ngIf="productTypes?.length === 0">
        <thead>
        <tr>
          <th>Product Type</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
      </table>

    </div>

  </div>
</div>
