<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-8">
    <h2><strong>{{this.user?.name | uppercase}}</strong></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/users']">Utilisateurs</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Utilisateur</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-4 p-1 align-content-center"></div>
</div>


<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="tabs-container">
        <ul class="nav nav-tabs">
          <li><a class="nav-link active" data-toggle="tab" href="#profil-tab"> <i class="fa fa-user"></i> Profile</a>
          </li>
          <li><a class="nav-link" data-toggle="tab" href="#order-tab"><i class="fa fa-shopping-cart"></i> Orders</a></li>
        </ul>
        <div class="tab-content">
          <div id="profil-tab" class="tab-pane active">
            <div class="row">
              <div class="col-md-10 offset-1">
                <div class="panel-body">
                  <h4 class="text-center"><strong> User Profile</strong></h4>
                  <hr>
                  <div class="row">
                    <div class="col-md-12">
                      <form (ngSubmit)="onSubmit()" [formGroup]="profilForm">
                        <div class="offset-4 mb-3">
                          <div class="profile-img">
                            <img
                              src="{{refImage || this?.user?.imageUrl || 'https://ssl.gstatic.com/accounts/ui/avatar_2x.png'}}"
                              alt="Profil" class="img-thumbnail rounded-circle">
                          </div>
                        </div>

                        <div class="form-group row"><label class="col-sm-2 col-form-label">Name</label>
                          <div class="col-sm-10">
                            <div class="input-group m-b">
                              <div class="input-group-prepend">
                                <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                              </div>
                              <input type="text" placeholder="name" class="form-control" id="name"
                                     formControlName="name" required="required" minlength="6"
                                     [ngClass]="{'is-invalid' : (profilForm.get('name').touched || profilForm.get('name').dirty) && !profilForm.get('name').valid}">
                              <div class="invalid-feedback">
                                      <span
                                        *ngIf="profilForm.get('name').errors?.required">Store Name is required !</span>
                                <span
                                  *ngIf="profilForm.get('name').errors?.minlength">Valid Store Name must be longer than 6 caracters !</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row"><label class="col-sm-2 col-form-label">Adresse</label>
                          <div class="col-sm-10">
                            <div class="input-group m-b">
                              <div class="input-group-prepend">
                                <span class="input-group-addon"><i class="fa fa-home"></i></span>
                              </div>
                              <input ngx-google-places-autocomplete #placesRef="ngx-places" placeholder="Adresse"
                                     class="form-control" minlength="10" (onAddressChange)="handleAddressChange($event)"
                                     formControlName="adresse" id="adresse" required="required" type="text"
                                     [ngClass]="{'is-invalid' : (profilForm.get('adresse').touched || profilForm.get('adresse').dirty) && !profilForm.get('adresse').valid}">
                              <div class="invalid-feedback">
                                <span
                                  *ngIf="profilForm.get('adresse').errors?.required">Store adresse is required !</span>
                                <span
                                  *ngIf="profilForm.get('adresse').errors?.minlength">Valid Store description must be longer than 10 caracters !</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row"><label class="col-sm-2 col-form-label">Phone</label>
                          <div class="col-sm-10">
                            <div class="input-group m-b">
                              <div class="input-group-prepend">
                                <span class="input-group-addon"><i class="fa fa-phone"></i></span>
                              </div>
                              <input type="text" placeholder="Phone" class="form-control"
                                     formControlName="phone" id="phone" required="required"
                                     [ngClass]="{'is-invalid' : (profilForm.get('phone').touched || profilForm.get('phone').dirty) && !profilForm.get('phone').valid}">
                              <div class="invalid-feedback">
                                <span *ngIf="profilForm.get('phone').errors?.required">User phone is required !</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row"><label class="col-sm-2 col-form-label">Image</label>
                          <div class="col-sm-10">
                            <div class="input-group m-b">
                              <div class="input-group-prepend">
                                <span class="input-group-addon"><i class="fa fa-image"></i></span>
                              </div>
                              <input type="file" accept="image/*" placeholder="Price" class="form-control"
                                     id="image" formControlName="image"
                                     (change)="onUpload($event)"
                                     [ngClass]="{'is-invalid' : (profilForm.get('image').touched || profilForm.get('image').dirty) && !profilForm.get('image').valid}">

                              <div class="invalid-feedback">
                                <span
                                  *ngIf="profilForm.get('image').errors?.required">Store image is required !</span>
                              </div>
                            </div>
                            <div class="progress">
                              <div class="progress-bar" [style.width.%]="pourcentage" role="progressbar"
                                   [attr.aria-valuenow]="pourcentage" aria-valuemin="0" aria-valuemax="100">
                                      <span class="text-center" *ngIf="pourcentage > 0">{{pourcentage | number}}
                                        %</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="hr-line-dashed"></div>

                        <div class="form-group row">
                          <div class="col-sm-4 col-sm-offset-2">
                            <a [routerLink]="['/users']" class="btn btn-white btn-sm">Cancel</a>
                            <button *appIsGranted="'UPDATE'" class="btn btn-primary btn-sm" type="submit"
                                    [title]="profilForm.valid ? 'Modifier Profile' : 'Disable until valid form data'"
                                    [disabled]="!profilForm.valid">Edit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div id="order-tab" class="tab-pane">
            <div class="ibox ">
              <div class="ibox-title">
                <h5>Commandes de l'Utilisateur </h5>
                <div class="ibox-tools">
                </div>
              </div>
              <div class="ibox-content">
                <div class="table-responsive">
                  <table datatable class="table table-striped table-bordered table-hover dataTables-example"
                         *ngIf="orders.length != 0">
                    <thead>
                    <tr>
                      <th>Date</th>
                      <th>Short-Code</th>
                      <th>Payment-Code</th>
                      <th>Nombre des plats</th>
                      <th>Prix Total</th>
                      <th><i class="fa fa-envelope"></i></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let order of orders" class="w-100">
                      <td>{{this?.order?.orderedAt?.toDate() || this?.order?.createdAt?.toDate() | date: 'yyyy-MMM-dd, hh:mm a'}}</td>
                      <td>{{order?.orderShortCode || '-'}}</td>
                      <td>{{order?.paymentCode || '-'}}</td>
                      <td>{{order?.productCount | number}}</td>
                      <td>{{order?.totalAmount | currency: 'USD'}}</td>
                      <td *ngIf="order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt === null && order.orderedAt === null"><span
                        class="text-black-50 text-warning">In Process...</span></td>
                      <td *ngIf="order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt === null && order.orderedAt !== null"><span
                        class="text-black-50 text-warning">Commande en Attente</span></td>
                      <td *ngIf="order?.messageOfRejection !== '' && order?.declinedAt !== null"><span
                        class="text-danger">{{order?.messageOfRejection}}</span></td>
                      <td
                        *ngIf="order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt === null">
                        <span class="text-navy">Commande Validée</span>
                      </td>
                      <td
                        *ngIf="order?.messageOfRejection == '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt !== null && order.giveToDeliverAt === null"><span
                        class="text-warning"> Prêt pour la livraison à {{this?.order?.availableAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}</span>
                      </td>
                      <td
                        *ngIf="order?.messageOfRejection == '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt !== null && order.giveToDeliverAt !== null && order.deliveredAt === null">
                        <span class="text-navy"> Remis au livreur à
                          {{this?.order?.giveToDeliverAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}</span>
                      </td>
                      <td
                        *ngIf="order?.messageOfRejection == '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt !== null && order.giveToDeliverAt !== null && order.deliveredAt !== null">
                        <span class="text-navy"> Livré à
                          {{this?.order?.deliveredAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>

                  <table datatable class="table table-striped table-bordered table-hover" *ngIf="orders.length == 0">
                    <thead>
                    <tr>
                      <th>Date</th>
                      <th>Code</th>
                      <th>Nombre des plats</th>
                      <th>Prix Total</th>
                      <th><i class="fa fa-envelope"></i></th>
                    </tr>
                    </thead>
                    <tbody>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
