<table datatable class="table table-striped table-bordered table-hover dataTables-example" *ngIf="delivers?.length != 0">
    <thead>
    <tr>
      <th>image </th>
      <th>Nom </th>
      <th>adresse</th>
      <th>phone</th>
      <th>email</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let deliver of delivers" class="w-100">
      <td><img src="{{deliver?.imageUrl || 'https://ssl.gstatic.com/accounts/ui/avatar_2x.png'}}" class="img-thumbnail" alt="deliver_image" style="max-height: 50px;"></td>
      <td><span class="pie">{{deliver?.name}}</span></td>
      <td>{{deliver.address[0]?.street}}, {{deliver.address[0]?.city}}, {{deliver.address[0]?.country}} </td>
      <td>{{deliver?.phoneNumber}}</td>
      <td>{{deliver?.emailAddress}}</td>
      <td>
        <a [routerLink]="['/delivers',deliver?.uid]" class="btn btn-outline-success font-weight-bold mr-2 mb-1"><i class="fa fa-eye pr-1"></i> Voir</a>
        <span *appIsGranted="'UPDATE'">
          <a [routerLink]="['/delivers',deliver?.uid, 'edit']"><i class="fa fa-pencil pr-1"></i></a>
        <a (click)="lockDeliver(deliver)" *ngIf="deliver?.deletedAt === null" class="btn btn-outline-warning font-weight-bold mr-2">
          <i class="fa fa-lock"></i> Bloquer</a>
        <a (click)="restoreDeliver(deliver)" *ngIf="deliver?.deletedAt !== null" class="btn btn-outline-dark font-weight-bold mr-2">
          <i class="fa fa-refresh"></i> Restorer</a>
        </span>
        <a *appIsGranted="'DELETE'" (click)="removeDeliver(deliver)"><i class="fa fa-trash text-danger"></i></a>
      </td>
    </tr>
    </tbody>
  </table>
<table datatable class="table table-striped table-bordered table-hover dataTables-example" *ngIf="delivers?.length == 0">
    <thead>
    <tr>
      <th>image </th>
      <th>Nom </th>
      <th>adresse</th>
      <th>phone</th>
      <th>email</th>
      <th>Action</th>
    </tr>
    </thead>
  </table>
