import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {Product} from '../models/product.model';
import {map} from 'rxjs/operators';
import {Category} from '../models/category.model';
import {ProductOrder} from '../models/product-order.model';
import {Rating} from '../models/rating.model';
import uid from 'uid';
import {supabase} from './supabase';
import {ImageProduct} from '../models/image-product.model';
import {Group} from '../models/groups.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public products: Observable<Product[]>;
  public currentArticle: Observable<any>;


  private PRODUCT_TABLE = 'Product';
  private PRODUCTIMAGE_TABLE = 'ImageProduct';

  private state = new BehaviorSubject<any>({products: []});
  sProducts$: Observable<Product[]> = this.state
    .asObservable()
    .pipe(map((state) => state.products));

  private stateProducts = new BehaviorSubject<any>({products: []});
  storeProducts$: Observable<Product[]> = this.stateProducts
    .asObservable()
    .pipe(map((state) => state.products));

  private productSelectedAction = new BehaviorSubject<any>({product: null});
  selectedProduct$: Observable<Product> = this.productSelectedAction
    .asObservable().pipe((uState) => uState);

  constructor(private firestoreService: FirestoreService) {
  }

  getProducts(uidGroup: string, uidStore: string): void {
    this.products = combineLatest(
      this.firestoreService.col$(`groups/${uidGroup}/stores/${uidStore}/products`),
      this.firestoreService.col$(`groups/${uidGroup}/stores/${uidStore}/category-products`)
    ).pipe(
      map(([products, categories]) =>
        products.map(
          (p: Product) =>
            ({
              ...p,
              categoryProduct: categories.find((c: Category) =>
                // @ts-ignore
                p.uidCategoryProduct === c.uid)?.name
            } as Product)
        )
      )
    );
  }

  getOrderProducts(uidGroup: string, uidCommand: string, uidStore: string): Observable<ProductOrder[]> {
    return this.firestoreService.col$(`groups/${uidGroup}/stores/${uidStore}/orders/${uidCommand}/products`);
  }

  getProductsRatings(uidGroup: string, uidStore: string): Observable<Rating[]> {
    return this.firestoreService.col$(`groups/${uidGroup}/stores/${uidStore}/rating-products`);
  }

  getProduct(uidGroup: string, uidStore: string, uidProduct: string): Observable<Product> {
    return combineLatest(
      this.firestoreService.doc$(`groups/${uidGroup}/stores/${uidStore}/products/${uidProduct}`),
      this.firestoreService.col$(`groups/${uidGroup}/stores/${uidStore}/category-products`)
    ).pipe(
      map(([product, categories]) => {
          return {
            // @ts-ignore
            ...product,
            // @ts-ignore
            categoryProduct: categories.find((category: Category) => category.uid === product.uidCategoryProduct)?.name
          } as Product;
        }
      )
    );
  }

  createProduct(uidGroup: string, uidStore: string, product: Product): Promise<any> {
    this.firestoreService.set(`products/${product.uid}`, product).then();
    this.firestoreService.set(`stores/${uidStore}/products/${product.uid}`, product).then();
    return this.firestoreService.set(`groups/${uidGroup}/stores/${uidStore}/products/${product.uid}`, product);
  }

  updateProduct(uidGroup: string, uidStore: string, product: Product): Promise<any> {
    this.firestoreService.update(`products/${product.uid}`, product).then();
    this.firestoreService.update(`stores/${uidStore}/products/${product.uid}`, product).then();
    return this.firestoreService.update(`groups/${uidGroup}/stores/${uidStore}/products/${product.uid}`, product);
  }

  deleteProduct(uidGroup: string, uidStore: string, product: Product): Promise<any> {
    return this.firestoreService.delete(`groups/${uidGroup}/stores/${uidStore}/products/${product.uid}`);
  }

  updateProductState(uidGroup: string, uidStore: string, uidProduct: string, state: boolean): Promise<any> {
    this.firestoreService.update2(`products/${uidProduct}/`, {available: state}).then();
    this.firestoreService.update2(`stores/${uidStore}/products/${uidProduct}/`, {available: state}).then();
    return this.firestoreService.update2(`groups/${uidGroup}/stores/${uidStore}/products/${uidProduct}/`, {available: state});
  }

  /**
   * Return next Date of a given date string
   * @param actualDate A string of a date
   * @return nextDate The following date string of the given string
   */
  nextDate(actualDate: string): string {
    // convert the date string in date format; with normalize to remove accents
    const convertedDate = new Date(actualDate.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));

    const nextDate = new Date(convertedDate.setDate(convertedDate.getDate() + 1));

    return nextDate.toLocaleString('fr-FR', {year: 'numeric', month: 'long', day: 'numeric'});
  }

  /**
   * Duplicate a product with his next date
   * @param depDate Departure date
   * @param arrivalDate Arrival Date
   * @param fullname The splited product's fullname
   * @param product The product we need to duplicate
   * @return The new product on next Date
   */
  setDuplication(depDate: string, arrivalDate: string, fullname: string[], product: Product): Product {
    fullname[1] = depDate;

    const newName = fullname.join(', ');
    const newProduct: Product = product;

    newProduct.uid = uid(32);
    newProduct.name = newName;
    newProduct.productSpec['dept-date'] = depDate;
    newProduct.productSpec['arrival-date'] = arrivalDate;
    newProduct.createdAt = new Date();

    return newProduct;
  }

  async addProductImageWithSupabase(productImage: ImageProduct): Promise<any> {
    const {error} = await supabase
      .from(this.PRODUCTIMAGE_TABLE)
      .insert(productImage).single();
    return {error};
  }

  async updateProductImageWithSupabase(productImage: ImageProduct): Promise<any> {
    const {error} = await supabase
      .from(this.PRODUCTIMAGE_TABLE)
      .update(productImage)
      .match({uid: productImage.uid});
    return {error};
  }

  async deleteProductImageWithSupabase(uidProductImage: string): Promise<any> {
    const {data} = await supabase
      .from(this.PRODUCTIMAGE_TABLE)
      .delete()
      .match({uid: uidProductImage});

    return data;
  }


  /**
   * SUPABASE
   */

  async getProductsWithSupabase(): Promise<void> {
    const {data} = await supabase
      .from(this.PRODUCT_TABLE)
      .select(`
        *,
        images:ImageProduct(image:Image(url, available)),
        store:Store(*),
        promotion: Promotion(*),
        categoryProduct: CategoryProduct(*, category: Category(*)),
        productPromoCode: ProductPromoCode(*, promoCode: PromoCode(*)),
        ratingProduct: RatingProduct(*, rating: Rating(*)),
        metaDatas: ProductMetaData(*, metaData: MetaData(*))
        `)
      .is('deletedAt', null)
      .order('uid', {ascending: false});

    this.state.next({
      products:
        data?.map((product: Product) => (product)) ?? [],
    });
  }

  async getStoreProductsWithSupabase(uidStore: string): Promise<void> {
    const {data} = await supabase
      .from(this.PRODUCT_TABLE)
      .select(`
        *,
        images:ImageProduct(image:Image(url, available)),
        store:Store(*),
        promotion: Promotion(*),
        categoryProduct: CategoryProduct(*, category: Category(*)),
        productPromoCode: ProductPromoCode(*, promoCode: PromoCode(*)),
        ratingProduct: RatingProduct(*, rating: Rating(*)),
        metaDatas: ProductMetaData(*, metaData: MetaData(*))
        `)
      .is('deletedAt', null)
      .eq('uidStore', uidStore)
      .order('uid', {ascending: false});

    this.stateProducts.next({
      products:
        data?.map((product: Product) => (product)) ?? [],
    });
  }

  async getproductWithSupabase(uidProduct: string): Promise<void> {
    const {data} = await supabase
      .from(this.PRODUCT_TABLE)
      .select(`
        *,
        images:ImageProduct(image:Image(url, available)),
        store:Store(*),
        promotion: Promotion(*),
        categoryProduct: CategoryProduct(*, category: Category(*)),
        productPromoCode: ProductPromoCode(*, promoCode: PromoCode(*)),
        ratingProduct: RatingProduct(*, rating: Rating(*)),
        metaDatas: ProductMetadata(*, metaData: MetaData(*))
        `)
      .eq('uid', uidProduct).single();
    this.productSelectedAction.next(data ?? null);
  }
}
