import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {catchError, from, Observable, of, shareReplay, Subscription} from 'rxjs';
import {GroupsService} from '../services/groups.service';
import {NotificationsService} from '../services/notifications.service';
import {ActivatedRoute} from '@angular/router';
import {Group} from '../models/groups.model';
import {Store} from '../models/store.model';
import {StoreService} from '../services/store.service';
import {groupBy, mergeMap, toArray} from 'rxjs/operators';
import {Chart} from 'chart.js';
import {OrderService} from '../services/order.service';

@Component({
  selector: 'app-group-single',
  templateUrl: './group-single.component.html',
  styleUrls: ['./group-single.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupSingleComponent implements OnInit, OnDestroy {

  @ViewChild('barCanvas') barCanvas;
  @ViewChild('barCanvas2') barCanvas2;
  @ViewChild('barCanvas3') barCanvas3;
  @ViewChild('barCanvas4') barCanvas4;

  groupe: Group;
  uidGroup: string;
  state: boolean;
  stores: Store[] = [];
  storesName: string[] = [];
  allStoreCommandesValeurs: number[] = [];
  validateStoreCommandesValeurs: number[] = [];
  declineStoreCommandesValeurs: number[] = [];
  toastTitle: string;
  toastMessage: string;
  subscription: Subscription = new Subscription();
  barChart: any;
  allStoreObservableCount = 0;
  amountDu: number;

  errorMessage = '';
  selectedGroup$: Observable<Group> = this.groupService.selectedGroup$
    .pipe(
      shareReplay(1),
      catchError((error) => {
        console.log('**** ERRROR=>', error);
        this.errorMessage = error;
        return of(null);
      })
    );

  constructor(private groupService: GroupsService,
              private storeService: StoreService,
              private orderService: OrderService,
              private notifyService: NotificationsService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.route.paramMap.subscribe(
        idGroup => {
          this.uidGroup = idGroup.get('idgroup');
          void this.groupService.getGroupWithSupabase(this.uidGroup);

          this.subscription.add(
            this.selectedGroup$.subscribe(
              (groupe: Group) => {
                if (groupe.storesGroup !== undefined) {
                  /* This part is for Dashboard call(display) */
                  if (this.allStoreObservableCount < 1) {
                    groupe.storesGroup.map((storeGroup) => {
                      this.stores.push(storeGroup.store);
                    });

                    from(this.stores).pipe(
                      groupBy((store: Store) => store.name),
                      mergeMap((group) => group.pipe(toArray()))
                    ).subscribe(
                      storesArray => {
                        if (storesArray !== undefined) {
                          storesArray.map((store: Store) => {
                            let ordersValidatedCount = 0;
                            let ordersCanceledCount = 0;
                            this.storesName.push(store?.name);
                            if (store?.orders !== undefined){
                              this.allStoreCommandesValeurs.push(store?.orders?.length);
                              store?.orders?.map((order) => {
                                if (order.validatedAt != null) {
                                  ordersValidatedCount += 1;
                                }
                                if (order.declinedAt != null) {
                                  ordersCanceledCount += 1;
                                }
                              });

                              this.validateStoreCommandesValeurs.push(ordersValidatedCount);
                              this.declineStoreCommandesValeurs.push(ordersCanceledCount);
                            }
                          });
                        }
                      }
                    );
                    this.allStoreObservableCount++;
                  }
                  this.barChartMethod();
                }
              }
            )
          );

          this.subscription.add(
            this.selectedGroup$.subscribe(group => {
              group.storesGroup.map((storeGroup) => {
                storeGroup?.store?.orders?.length > 0 ? this.notifyService.soundNotification() : false;
              });

            })
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onChange(event): void {
    this.groupService.updateEtat(this.uidGroup, event.currentValue).then(
      () => {
        this.toastTitle = 'Success';
        this.toastMessage = 'Le Groupe a maintenant changer de disponibilité !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {
        this.toastTitle = 'Error';
        this.toastMessage = 'Le changement de disponibilité a échoué !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  barChartMethod(): void {
    this.barChart = new Chart(this.barCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: this.storesName,
        datasets: [{
          label: 'Commandes',
          data: this.allStoreCommandesValeurs,
          backgroundColor: [
            'rgba(75,192,192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(75,192,192,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
    this.barChart = new Chart(this.barCanvas2.nativeElement, {
      type: 'bar',
      data: {
        labels: this.storesName,
        datasets: [{
          label: 'Validates Commandes',
          data: this.validateStoreCommandesValeurs,
          backgroundColor: [
            'rgba(96,192,75,0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(96,192,75,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
    this.barChart = new Chart(this.barCanvas3.nativeElement, {
      type: 'bar',
      data: {
        labels: this.storesName,
        datasets: [{
          label: 'Denied Commandes',
          data: this.declineStoreCommandesValeurs,
          backgroundColor: [
            'rgba(192,75,75, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(192,75,75,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  removeStore(store: Store): void {
    if (confirm('Etes-vous sure de vouloir Supprimer ce Marchand ?')) {
      this.storeService.deleteStoreWithSupabase(store.uid).then(
        () => {
          this.notifyService.showSuccess('Suppression du Marchand fait avec succès !', 'Notification Faite');
        }
      ).catch(
        error => {
          console.log('>>>', error);
          this.notifyService.showError(`Echec de la Suppression du Marchand => ${error}`, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Blocage du Marchand Annulé`, 'Echec');
    }
  }

  lockStore(store: Store): void {
    if (confirm('Etes-vous sure de vouloir Bloquer ce Marchand ?')) {
      this.storeService.lockStoreWithSupabase(store.uid).then(
        () => {
          this.notifyService.showSuccess('Blocage du Marchand fait avec succès !', 'Notification Faite');
        }
      ).catch(
        error => {
          this.notifyService.showError(`Echec du Blocage de Marchand => ${error}`, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Blocage du Marchand Annulé`, 'Echec');
    }
  }

  restoreStore(store: Store): void {
    if (confirm('Etes-vous sure de vouloir Restorer ce Marchand ?')) {
      this.storeService.restoreStoreWithSupabase(store.uid).then(
        () => {
          this.notifyService.showSuccess('Le Marchand a été restauré avec succès !', 'Notification Faite');
        }
      ).catch(
        error => {
          this.notifyService.showError(`Echec, le Marchand n'a pas été restauré=> ${error}`, 'Echec');
        }
      );
    } else {
      this.notifyService.showInfo(`Restauration du Marchand Annulée`, 'Echec');
    }
  }


}
