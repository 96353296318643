import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {catchError, Observable, shareReplay, combineLatest, BehaviorSubject} from 'rxjs';
import {Deliver} from '../models/deliver.model';
import {Invoice} from '../models/invoice.model';
import {Order} from '../models/order.model';
import {OrderService} from './order.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeliverService {

  handleError: any = '';
  delivers$: Observable<Deliver[]> = this.firestoreService.col$('delivers')
    .pipe(
      shareReplay(1),
      catchError(this.handleError)
    );
  deliversWithOrders$ = combineLatest(
    this.delivers$,
    this.orderService.orders$
  ).pipe(
    map(([delivers, orders]) => {
      return delivers.map(deliver => ({
        ...deliver,
        orders: orders.filter(order => deliver.uid === order.uidDeliver)
      } as Deliver));
    })
  );

  private deliverSelectedAction = new BehaviorSubject<string>(null);
  deliverSelectedAction$ = this.deliverSelectedAction.asObservable();

  selectedDeliverWithOrders$ =  combineLatest(
    this.deliverSelectedAction$,
    this.deliversWithOrders$
  ).pipe(
    map(([selectedDeliverUid, delivers]) => {
      return delivers.find(deliver => deliver.uid === selectedDeliverUid);
    })
  );

  constructor(private firestoreService: FirestoreService,
              private orderService: OrderService) {
  }

  private changeSelectedDeliver(selectedDeliverUid: string | null): void {
    this.deliverSelectedAction.next(selectedDeliverUid);
  }
  onSelect(deliverUid: string): void {
    this.changeSelectedDeliver(deliverUid);
  }

  getDelivers(): Observable<Deliver[]> {
    return this.firestoreService.col$(`delivers`);
  }

  getDeliver(uidDeliver: string): Observable<Deliver> {
    return this.firestoreService.doc$(`delivers/${uidDeliver}`);
  }

  getDeliverRatings(uidDeliver: string): Observable<any[]> {
    return this.firestoreService.col$(`delivers/${uidDeliver}/rating-delivers/`);
  }

  getDeliverPaiements(uidDeliver: string): Observable<Invoice[]> {
    return this.firestoreService.col$(`delivers/${uidDeliver}/payments`);
  }

  getDeliverCommands(uidDeliver: string): Observable<Order[]> {
    return this.firestoreService.col$(`delivers/${uidDeliver}/orders`);
  }

  updateDeliver(deliver: Deliver): Promise<any> {
    return this.firestoreService.update(`delivers/${deliver.uid}`, deliver);
  }

  addDeliver(deliver: Deliver): Promise<any> {
    return this.firestoreService.set(`delivers/${deliver.uid}`, deliver);
  }

  affectOrderTo(uidDeliver: string, data): Promise<any> {
    return this.firestoreService.set(`delivers/${uidDeliver}/orders/${data.uid}`, data);
  }

  deleteDeliver(data): Promise<any> {
    return this.firestoreService.update2(
      `delivers/${data.uid}/`, {
        deletedAt: Date.now()
      }
    );
  }

  restoreDeliver(data): Promise<any> {
    return this.firestoreService.update2(`delivers/${data.uid}/`, {deletedAt: null});
  }

  deleteA(data): Promise<any> {
    return this.firestoreService.delete(`delivers/${data.uid}`);
  }


}
