<table datatable class="table table-striped table-bordered table-hover" *ngIf="promos?.length !== 0">
  <thead>
  <tr>
    <th>Code</th>
    <th>Debut</th>
    <th>Fin</th>
    <th>Pourcentage</th>
    <th>Produits</th>
    <th>Etat</th>
    <th>Recurrent</th>
    <th>Date de Creation</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let promotion of promos" class="w-100">
    <td><span class="pie">{{promotion.code}}</span></td>
    <td>{{promotion.startedAt | date: 'dd-MM-yyyy, hh:mm aa'}}</td>
    <td>{{promotion.endedAt | date: 'dd-MM-yyyy, hh:mm aa'}}</td>
    <td>{{promotion.percent | number}}</td>
    <td>
      <p *ngIf="promotion.productPromoCode.length === 0" class="text-center">-</p>
      <p *ngFor="let product of promotion.productPromoCode">
        <a [routerLink]="['/products', product.product]" data-toggle="tooltip" data-placement="bottom"
           title="Voir Detail du produit">{{product.product.name}}</a>
      </p>
    </td>
    <td [ngSwitch]="promotion.valid">
      <span *ngSwitchCase="true" class="badge badge-primary">Active</span>
      <span *ngSwitchCase="false" class="badge badge-danger">Disable</span>
    </td>
    <td [ngSwitch]="promotion.recurrent">
      <span *ngSwitchCase="true" class="badge badge-primary">Reccurent</span>
      <span *ngSwitchCase="false" class="badge badge-inverse">Non reccurent</span>
    </td>
    <td>{{promotion.createdAt | date: 'dd-MM-yyyy, hh:mm'}}</td>
    <td>
      <a  [routerLink]="['/groups',idGroup,'stores', promotion.productPromoCode[0].product.uidStore,'/promotions', promotion?.uid, 'edit']" data-toggle="tooltip" data-placement="bottom"
         title="Modifier le produit" class="btn btn-success btn-xs"><i class="fa fa-pencil pr-1"></i></a>
      <a  (click)="deletePromotion(promotion)" data-toggle="tooltip" data-placement="bottom"
         title="Supprimer le produit" class="ml-2"><i class="fa fa-trash text-danger"></i></a>
    </td>
  </tr>
  </tbody>
</table>
<table datatable class="table table-striped table-bordered table-hover" *ngIf="promos?.length === 0">
  <thead>
  <tr>
    <th>Code</th>
    <th>Debut</th>
    <th>Fin</th>
    <th>Pourcentage</th>
    <th>Produits</th>
    <th>Etat</th>
    <th>Recurrent</th>
    <th>Date de Creation</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  </tbody>
</table>
