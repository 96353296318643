import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Order} from '../models/order.model';
import {catchError, from, Observable, of, Subscription} from 'rxjs';
import {Chart} from 'chart.js';
import {StoreService} from '../services/store.service';
import {OrderService} from '../services/order.service';
import {GroupsService} from '../services/groups.service';
import {Group} from '../models/groups.model';
import {groupBy, mergeMap, toArray} from 'rxjs/operators';
import {UtilsService} from '../services/utils.service';
import {Store} from '../models/store.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChild('barCanvas') barCanvas;
  @ViewChild('barCanvas2') barCanvas2;
  @ViewChild('barCanvas3') barCanvas3;
  @ViewChild('barCanvas4') barCanvas4;

  groups: Group[] = [];
  stores: Store[] = [];
  totalStores = 0;
  allOrders: Order[] = [];
  allValidateOrdersTotal = 0;
  allDeclinedOrdersTotal = 0;
  subscriptions: Subscription = new Subscription();
  barChart: any;
  storesName: string[] = [];
  allStoreOrdersValeurs: number[] = [];
  validateStoreOrdersValeurs: number[] = [];
  declineStoreOrdersValeurs: number[] = [];
  allStoreObservableCount = 0;
  ordersTableDisplayed = false;
  errorMessage = '';
  groups$: Observable<Group[]>;

  amountOrdersValidated = 0;
  amountOrdersDelivered = 0;
  amountOrdersCanceled = 0;

  constructor(private groupeService: GroupsService,
              private storeService: StoreService,
              private orderService: OrderService,
              private utilsServices: UtilsService) {
    void this.groupeService.getGroupsWithSupabase();
    this.groups$ = groupeService.sGroups$;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.groups$.subscribe(
        groups => {
          if (groups !== null) {
            this.groups = groups;
            groups.forEach(
              group => {
                if (group.storesGroup !== undefined && this.allStoreObservableCount < group.storesGroup.length){
                  this.totalStores += group.storesGroup.length;
                  group.storesGroup.map((storeGroup) => {
                    this.stores.push(storeGroup.store);
                    storeGroup.store.orders?.map((order: Order) => {
                      // TODO: Need to come BACK HERE
                      this.allOrders.push(order);
                      if (order.validatedAt !== null) {
                        let totalAmount = 0;
                        order.productOrder.map((product) => {
                          totalAmount += product.price;
                        });
                        this.allValidateOrdersTotal += totalAmount;
                      }
                      if (order.declinedAt !== null) {
                        let totalAmount = 0;
                        order.productOrder.map((product) => {
                          totalAmount += product.price;
                        });
                        this.allDeclinedOrdersTotal += totalAmount;
                      }
                    });
                  });

                  from(this.stores).pipe(
                    groupBy(store => store.name),
                    mergeMap((groupe) => groupe.pipe(toArray()))
                  ).subscribe(
                    store => {
                      this.storesName.push(store[0].name);
                      this.allStoreOrdersValeurs.push(store[0].orders?.length);

                      // TODO: Must Check if it works correctly here
                      let ordersValidatedCount = 0;
                      let ordersDeliveredCount = 0;
                      let ordersDeclinedCount = 0;
                      store[0].orders?.map((order) => {
                        if (order.validatedAt != null){
                          ordersValidatedCount += 1;
                          this.amountOrdersValidated += order.totalAmount;
                        }
                        if (order.deliveredAt != null){
                          ordersDeliveredCount += 1;
                          this.amountOrdersDelivered += order.totalAmount;
                        }
                        if (order.declinedAt != null){
                          ordersDeclinedCount += 1;
                          this.amountOrdersCanceled += order.totalAmount;
                        }
                      });
                      this.validateStoreOrdersValeurs.push(ordersValidatedCount);
                      this.declineStoreOrdersValeurs.push(ordersDeliveredCount);
                    }
                  );

                  this.barChartMethod();

                  this.allStoreObservableCount++;
                }
                // this.subscriptions.add(
                //   this.storeService.getStores(group.uid).subscribe(
                //     stores => {
                //       if (this.allStoreObservableCount < groups.length){
                //         if (stores !== null) {
                //           // TODO: Need to come BACK HERE
                //           // @ts-ignore
                //           // this.totalStores += this.utilsServices.getFilteredStores(stores).length;
                //
                //           // TODO: Need to come BACK HERE
                //           // @ts-ignore
                //           // this.utilsServices.getFilteredStores(stores).forEach(
                //           //   store => {
                //           //     this.subscriptions.add(
                //           //       this.orderService.getOrderCommands(group.uid, store.uid).subscribe(
                //           //         orders => {
                //           //           orders.map((order: Order) => {
                //           //             // TODO: Need to come BACK HERE
                //           //             // @ts-ignore
                //           //             order = ({
                //           //               ...order,
                //           //               store
                //           //             } as Order);
                //           //             this.allOrders.push(order);
                //           //             if (order.validatedAt !== null) {
                //           //               let totalAmount = 0;
                //           //               order.productOrder.map((product) => {
                //           //                 totalAmount += product.price;
                //           //               });
                //           //               this.allValidateOrdersTotal += totalAmount;
                //           //             }
                //           //             if (order.declinedAt !== null) {
                //           //               let totalAmount = 0;
                //           //               order.productOrder.map((product) => {
                //           //                 totalAmount += product.price;
                //           //               });
                //           //               this.allDeclinedOrdersTotal += totalAmount;
                //           //             }
                //           //           });
                //           //         }
                //           //       )
                //           //     );
                //           //   }
                //           // );
                //
                //           // TODO: Need to come BACK HERE
                //           // @ts-ignore
                //           from(this.utilsServices.getFilteredStores(stores)).pipe(
                //             groupBy(store => store.name),
                //             mergeMap((groupe) => groupe.pipe(toArray()))
                //           ).subscribe(
                //             store => {
                //               this.storesName.push(store[0].name);
                //               this.allStoreOrdersValeurs.push(store[0].orders.length);
                //
                //               // TODO: Must Check if it works correctly here
                //               let ordersValidatedCount = 0;
                //               let ordersDeliveredCount = 0;
                //               store[0].orders.map((order) => {
                //                 if (order.validatedAt != null){
                //                   ordersValidatedCount += 1;
                //                 }
                //                 if (order.deliveredAt != null){
                //                   ordersDeliveredCount += 1;
                //                 }
                //               });
                //               this.validateStoreOrdersValeurs.push(ordersValidatedCount);
                //               this.declineStoreOrdersValeurs.push(ordersDeliveredCount);
                //             }
                //           );
                //
                //           this.barChartMethod();
                //         }
                //         this.allStoreObservableCount++;
                //       }
                //     }
                //   )
                // );
              }
            );
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  barChartMethod(): void {
    this.barChart = new Chart(this.barCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: this.storesName,
        datasets: [{
          label: 'Orders',
          data: this.allStoreOrdersValeurs,
          backgroundColor: [
            'rgba(75,192,192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(75,192,192,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
    this.barChart = new Chart(this.barCanvas2.nativeElement, {
      type: 'bar',
      data: {
        labels: this.storesName,
        datasets: [{
          label: 'Validates Orders',
          data: this.validateStoreOrdersValeurs,
          backgroundColor: [
            'rgba(96,192,75,0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(96,192,75,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
    this.barChart = new Chart(this.barCanvas3.nativeElement, {
      type: 'bar',
      data: {
        labels: this.storesName,
        datasets: [{
          label: 'Denied Orders',
          data: this.declineStoreOrdersValeurs,
          backgroundColor: [
            'rgba(192,75,75, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(192,75,75,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  toggleTable(): void {
    this.ordersTableDisplayed = !this.ordersTableDisplayed;
  }
}
