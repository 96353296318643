import {Component, OnInit} from '@angular/core';
import {StoreService} from '../../services/store.service';
import {Store} from '../../models/store.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../services/utils.service';

@Component({
  selector: 'app-agents-restorers',
  templateUrl: './agents-restorers.component.html',
  styleUrls: ['./agents-restorers.component.css']
})
export class AgentsRestorersComponent implements OnInit {

  stores: Store[] = [];
  uidGroup: string;
  subscription: Subscription = new Subscription();

  constructor(private storeService: StoreService,
              private route: ActivatedRoute,
              private utilsServices: UtilsService) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidGroup = token.get('idgroup');

          this.subscription.add(
            this.storeService.getStores(this.uidGroup).subscribe(
              stores => {
                if (stores !== null) {
                  // TODO: need to come BACK here
                  // @ts-ignore
                  this.stores = this.utilsServices.getFilteredStores(stores);
                }
              }
            )
          );
        }
      )
    );
  }

}
