import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AppSecondComponent} from './app-second.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {LoginComponent} from './login/login.component';
import {StoreaddComponent} from './groups/stores/storeadd.component';
import {StoreeditComponent} from './groups/stores/storeedit.component';
import {DeliversComponent} from './delivers/delivers.component';
import {DeliveraddComponent} from './delivers/deliveradd.component';
import {DelivereditComponent} from './delivers/deliveredit.component';
import {DeliverComponent} from './delivers/deliver.component';
import {DeliverSingleComponent} from './delivers/deliver-single.component';
import {StoreSingleComponent} from './groups/stores/store-single.component';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireModule} from '@angular/fire/compat';
import {DataTablesModule} from 'angular-datatables';
import {CategoryComponent} from './groups/categories/category.component';
import {CategoryAddComponent} from './groups/categories/category-add.component';
import {CategoryEditComponent} from './groups/categories/category-edit.component';
import {PaiementsComponent} from './groups/paiements/paiements.component';
import {PaiementComponent} from './groups/paiements/paiement.component';
import {CustomDatePipe} from './pipes/custom-date.pipe';
import {MakePaiementComponent} from './groups/paiements/make-paiement.component';
import {JwBootstrapSwitchNg2Module} from 'jw-bootstrap-switch-ng2';
import {OrdersComponent} from './groups/stores/orders/orders.component';
import {OrdersServiesComponent} from './groups/stores/orders/orders-servies.component';
import {OrderComponent} from './groups/stores/orders/order.component';
import {ProductsComponent} from './groups/stores/products/products.component';
import {ProductComponent} from './groups/stores/products/product.component';
import {ApiBackendInterceptor} from './services/api-backend.interceptor';
import {CommonModule} from '@angular/common';
import {QRCodeModule} from 'angularx-qrcode';
import {AgentsComponent} from './groups/agents/agents.component';
import {AgentAddComponent} from './groups/agents/agent-add.component';
import {AgentEditComponent} from './groups/agents/agent-edit.component';
import {AdminsComponent} from './admins/admins.component';
import {AdminAddComponent} from './admins/admin-add.component';
import {AdminEditComponent} from './admins/admin-edit.component';
import { OrderSingleComponent } from './groups/stores/orders/order-single.component';
import { GroupsComponent } from './groups/groups.component';
import { GroupSingleComponent } from './groups/group-single.component';
import { GroupAddComponent } from './groups/group-add.component';
import { GroupEditComponent } from './groups/group-edit.component';
import {NgxSoundsModule} from 'ngx-sounds';
import { AgentsRestorersComponent } from './groups/agents/agents-restorers.component';
import { CategoriesComponent } from './groups/categories/categories.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import { RefundComponent } from './groups/stores/orders/refund/refund.component';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './users/user.component';
import { UserSingleComponent } from './users/user-single.component';
import { PromosComponent } from './groups/stores/promos/promos.component';
import { PromoComponent } from './groups/stores/promos/promo.component';
import { PromoaddComponent } from './groups/stores/promos/promoadd.component';
import { PromoeditComponent } from './groups/stores/promos/promoedit.component';
import { PromoSingleComponent } from './groups/stores/promos/promo-single.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationaddComponent } from './notifications/notificationadd.component';
import { NotificationComponent } from './notifications/notification.component';
import { NotificationeditComponent } from './notifications/notificationedit.component';
import { NotificationSingleComponent } from './notifications/notification-single.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { AddPaymentMethodComponent } from './payment-methods/add-payment-method.component';
import { EditPaymentMethodComponent } from './payment-methods/edit-payment-method.component';
import { ProductaddComponent } from './groups/stores/products/productadd.component';
import { ProductSingleComponent } from './groups/stores/products/product-single.component';
import { ProducteditComponent } from './groups/stores/products/productedit.component';
import { AddPromotionComponent } from './groups/stores/products/add-promotion.component';
import { EditPromotionComponent } from './groups/stores/products/edit-promotion.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {ProductCategoriesComponent} from './groups/stores/products/categories/product-categories.component';
import {ProductCategoryComponent} from './groups/stores/products/categories/product-category.component';
import {ProductCategorySingleComponent} from './groups/stores/products/categories/product-category-single.component';
import {ProductCategoryaddComponent} from './groups/stores/products/categories/product-categoryadd.component';
import {ProductCategoryeditComponent} from './groups/stores/products/categories/product-categoryedit.component';
import {NgxImageCompressService} from 'ngx-image-compress';
import {environment} from '../environments/environment';
import { IsGrantedDirective } from './services/permissions/is-granted.directive';
import { ProductTagsComponent } from './product-tags/product-tags.component';
import { ProductTagComponent } from './product-tags/product-tag.component';
import { AddProductTagComponent } from './product-tags/add-product-tag.component';
import { EditProductTagComponent } from './product-tags/edit-product-tag.component';
import { BannersComponent } from './banners/banners.component';
import { BannerComponent } from './banners/banner.component';
import { BannerAddComponent } from './banners/banner-add.component';
import { BannerEditComponent } from './banners/banner-edit.component';
import { BannerSingleComponent } from './banners/banner-single.component';
import { ProductTypesComponent } from './groups/product-types/product-types.component';
import { AddProductTypeComponent } from './groups/product-types/addProductType.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    AppSecondComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    StoreaddComponent,
    StoreeditComponent,
    DeliversComponent,
    DeliveraddComponent,
    DelivereditComponent,
    DeliverComponent,
    DeliverSingleComponent,
    StoreSingleComponent,
    CategoryComponent,
    CategoryAddComponent,
    CategoryEditComponent,
    PaiementsComponent,
    PaiementComponent,
    CustomDatePipe,
    MakePaiementComponent,
    OrdersComponent,
    OrdersServiesComponent,
    OrderComponent,
    ProductsComponent,
    ProductComponent,
    AgentsComponent,
    AgentAddComponent,
    AgentEditComponent,
    AdminsComponent,
    AdminAddComponent,
    AdminEditComponent,
    OrderSingleComponent,
    GroupsComponent,
    GroupSingleComponent,
    GroupAddComponent,
    GroupEditComponent,
    AgentsRestorersComponent,
    CategoriesComponent,
    RefundComponent,
    UsersComponent,
    UserComponent,
    UserSingleComponent,
    PromosComponent,
    PromoComponent,
    PromoaddComponent,
    PromoeditComponent,
    PromoSingleComponent,
    NotificationsComponent,
    NotificationaddComponent,
    NotificationComponent,
    NotificationeditComponent,
    NotificationSingleComponent,
    PaymentMethodsComponent,
    AddPaymentMethodComponent,
    EditPaymentMethodComponent,
    ProductaddComponent,
    ProductSingleComponent,
    ProducteditComponent,
    AddPromotionComponent,
    EditPromotionComponent,
    ProductCategoriesComponent,
    ProductCategoryComponent,
    ProductCategorySingleComponent,
    ProductCategoryaddComponent,
    ProductCategoryeditComponent,
    IsGrantedDirective,
    ProductTagsComponent,
    ProductTagComponent,
    AddProductTagComponent,
    EditProductTagComponent,
    BannersComponent,
    BannerComponent,
    BannerAddComponent,
    BannerEditComponent,
    BannerSingleComponent,
    ProductTypesComponent,
    AddProductTypeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    DataTablesModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseTest),
    AngularFirestoreModule,
    JwBootstrapSwitchNg2Module,
    QRCodeModule,
    NgxSoundsModule,
    GooglePlaceModule,
    SlickCarouselModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ApiBackendInterceptor, multi: true},
    NgxImageCompressService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
