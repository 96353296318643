import {PermissionBase} from './permission-base';
import {PermissionType} from '../models/permission-type.enum';

export class AgentPermission extends PermissionBase{
  constructor() {
    super();
    this.permissions = [
      PermissionType.CREATE, PermissionType.READ
    ];
  }
}
