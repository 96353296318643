<table datatable class="table table-striped table-bordered table-hover dataTables-example" *ngIf="banners?.length !== 0">
  <thead>
  <tr>
    <th>image </th>
    <th>End At </th>
    <th>Link</th>
    <th>Status</th>
    <th>Creation Date</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let banner of banners" class="w-100">
    <td><img src="{{banner?.imageUrl || 'https://ssl.gstatic.com/accounts/ui/avatar_2x.png'}}" class="img-thumbnail" alt="banner_image" style="max-height: 80px;"></td>
    <td>{{banner?.endAt?.toDate() | date: 'yyyy-MMM-dd, hh:mm a'}}</td>
    <td>{{banner?.link || '-'}}</td>
    <td [ngSwitch]="banner.available" (click)="changeState(banner)" style="cursor: pointer">
      <span class="label label-primary" *ngSwitchCase="true">Enable</span>
      <span class="label label-danger" *ngSwitchCase="false">Disabled</span>
    </td>
    <td>{{banner?.createdAt?.toDate() | date: 'yyyy-MMM-dd, hh:mm a'}}</td>
    <td>
      <a [routerLink]="['/banners',banner?.uid]" class="btn btn-primary btn-xs font-weight-bold mr-2 mb-1"><i class="fa fa-eye pr-1"></i> Voir</a>
      <span >
          <a [routerLink]="['/banners',banner?.uid, 'edit']" class="btn btn-success btn-xs m-0">
            <i class="fa fa-pencil pr-1"></i> Edit</a>
      </span>
      <a  (click)="deleteBanner(banner)" class="m-1"><i class="fa fa-trash text-danger"></i></a>
    </td>
  </tr>
  </tbody>
</table>
<table datatable class="table table-striped table-bordered table-hover dataTables-example" *ngIf="banners?.length === 0">
  <thead>
  <tr>
    <th>image </th>
    <th>End At </th>
    <th>Link</th>
    <th>Status</th>
    <th>Creation Date</th>
    <th>Action</th>
  </tr>
  </thead>
</table>
