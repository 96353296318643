import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User} from '../models/user.model';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Order} from '../models/order.model';
import {Subscription} from 'rxjs';
import {Address} from '../models/address.model';
import {ActivatedRoute} from '@angular/router';
import {UploadService} from '../services/upload.service';
import {NotificationsService} from '../services/notifications.service';
import {AddressService} from '../services/address.service';
import {UserService} from '../services/user.service';
import {Address as gAddress} from 'ngx-google-places-autocomplete/objects/address';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';

@Component({
  selector: 'app-user-single',
  templateUrl: './user-single.component.html',
  styleUrls: ['./user-single.component.css']
})
export class UserSingleComponent implements OnInit, OnDestroy {

  @ViewChild('placesRef', {static: false}) placesRef: GooglePlaceDirective;
  user: User;
  idUser: string;
  profilForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  orders: Order[] = [];
  pourcentage = 0;
  refImage: string;
  fieldTextType: boolean;
  subscriptions: Subscription = new Subscription();
  upload = false;
  userAddress: Address;

  constructor(private route: ActivatedRoute,
              private uploadService: UploadService,
              private notifyService: NotificationsService,
              private addressService: AddressService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.idUser = this.route.snapshot.paramMap.get('id');
    this.userService.getUser(this.idUser);
    this.subscriptions.add(
      this.userService.currentUser$.subscribe(
        user => {
          if (user !== null) {
            this.user = user;
            this.profilForm.patchValue({
              name: user.name,
              adresse: `${user.userAddress[0].address?.street}, ${user.userAddress[0].address?.city}, ${user.userAddress[0].address?.country}`,
              phone: user.phoneNumber
            });
          }
        }
      )
    );

    this.subscriptions.add(
      this.userService.getUserOrders(this.idUser).subscribe(
        orders => {
          if (orders !== null) {
            this.orders = orders;
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.profilForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      adresse: new UntypedFormControl(),
      image: new UntypedFormControl(),
      phone: new UntypedFormControl(),
      email: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    if (this.profilForm.get('image').value !== null) {
      this.user.imageUrl = this.uploadService.images['user-images'][0];
      this.updateValues();
    } else {
      this.updateValues();
    }
  }

  updateValues(): void {
    this.user.name = this.profilForm.get('name').value;
    this.user.phoneNumber = this.profilForm.get('phone').value;
    this.user.userAddress[0].address = this.userAddress;
    this.user.updatedAt = new Date();
    this.userService.updateUser(this.user).then(
      () => {
        this.toastTitle = 'Modification Faite';
        this.toastMessage = 'Modification de l\'utilisateur faite avec succès !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {
        this.toastTitle = 'Modification Echouée';
        this.toastMessage = 'Modification de l\'utilisateur échouée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `users/${this.idUser}/${this.idUser}`, 'user-images');
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );

    this.refImage = this.uploadService.images['user-images'][0];
  }

  public handleAddressChange(address: gAddress): void {
    this.userAddress = this.addressService.handleAddressChange(address);
  }

  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }

}
