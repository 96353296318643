<div class="row">
  <div class="col-lg-12">
    <div class="ibox ">
      <div class="ibox-title">
        <h5>Liste des Commandes Servies</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <app-order [orders]="orders"></app-order>
        </div>

      </div>
    </div>
  </div>

</div>
