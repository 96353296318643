import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Category} from '../../../../models/category.model';
import {CategoryProductService} from '../../../../services/category-product.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-product-category-single',
  templateUrl: './product-category-single.component.html',
  styleUrls: ['./product-category-single.component.css']
})
export class ProductCategorySingleComponent implements OnInit {

  idCategory: string;
  category: Category;
  subscriptions: Subscription = new Subscription();
  idStore: string;
  idGroup: string;

  constructor(private route: ActivatedRoute,
              private categoryService: CategoryProductService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          if (token !== null) {
            this.idStore = token.get('idStore');
            this.idGroup = token.get('idgroup');
            this.idCategory = token.get('id');

            this.categoryService.getCategoryProduct(this.idGroup, this.idStore, this.idCategory).subscribe(
              category => {
                if (category !== null) {
                  this.category = category;
                }
              }
            );
          }
        }
      )
    );
  }

}
