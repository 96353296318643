<table datatable class="table table-striped table-bordered table-hover" *ngIf="orders?.length !==0">
  <thead>
  <tr>
    <th style="width: 15%">Date</th>
    <th>Short-Code</th>
    <th>QR Code</th>
    <th>Adresse</th>
    <th>Nombre des plats</th>
    <th>Prix Total</th>
    <th>Store</th>
    <th style="width: 15%"><i class="fa fa-envelope"></i></th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let order of orders" class="w-100">
    <td>{{this?.order?.orderedAt?.toDate() | date: 'yyyy-MMM-dd, hh:mm a'}}</td>
    <td>{{order?.orderShortCode || '-'}}</td>
    <td>
      <qrcode [qrdata]="order.uid" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
    </td>
    <td>{{order?.address?.street || '...'}}, {{order?.address?.city || '...'}}, {{order?.address?.country || '...'}}</td>
    <td>{{order?.productOrder.length}}</td>
    <td>{{order?.totalAmount | currency: 'USD'}}</td>
    <td>{{order?.store.name || '-' | titlecase}}</td>
    <td *ngIf="order.productOrder[0].product.productType !== 'BUS_TICKET' && order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt === null">
      <span class="text-black-50">Commande en Attente</span>
    </td>
    <td *ngIf="order.productOrder[0].product.productType !== 'BUS_TICKET' && order?.messageOfRejection !== '' && order?.declinedAt !== null">
      <span class="text-danger">{{order?.messageOfRejection || '-'}}</span>
    </td>
    <td
      *ngIf="order.productOrder[0].product.productType !== 'BUS_TICKET' && order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt === null">
      <span class="text-navy">Commande Validée</span>
    </td>
    <td
      *ngIf="order.productOrder[0].product.productType !== 'BUS_TICKET' && order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt !== null && order.giveToDeliverAt === null">
      <span class="text-warning"> Prêt pour la livraison à {{this?.order?.availableAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}</span>
    </td>
    <td
      *ngIf="order.productOrder[0].product.productType !== 'BUS_TICKET' && order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt !== null && order.giveToDeliverAt !== null && order.deliveredAt === null">
      <span class="text-navy"> Remis au livreur à{{this?.order?.giveToDeliverAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}</span>
    </td>
    <td
      *ngIf="order.productOrder[0].product.productType !== 'BUS_TICKET' && order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt !== null && order.giveToDeliverAt !== null && order.deliveredAt !== null">
      <span class="text-navy"> Livré à {{this?.order?.deliveredAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}</span>
    </td>
    <td
      *ngIf="order.productOrder[0].product.productType === 'BUS_TICKET' && order.deliveredAt !== null">
      <span class="text-navy"> Livré à {{this?.order?.deliveredAt?.toDate() | date: 'hh:mm a, dd-MM-yyyy'}}</span>
    </td>
    <td>
      <a [routerLink]="['/groups', order?.store.storeGroup[0].uidGroup, 'stores', order?.uidStore,order?.uid]" data-toggle="tooltip" data-placement="bottom"
         title="Voir Details de la commande" class="btn btn-primary btn-xs text-white mr-1"><i
        class="fa fa-eye lar text-white-50 pr-1"></i> Voir</a>
      <a (click)="validateCommande(order)" *ngIf="order.productOrder[0].product.productType !== 'BUS_TICKET' && order.validatedAt === null && order.declinedAt === null"
         data-toggle="tooltip" data-placement="bottom" title="Valider la Commande"
         class="btn btn-primary btn-xs text-white mr-1"><i class="fa fa-check text-white pr-1"></i> Accept Order</a>
<!--      <a *ngIf="order.validatedAt === null && order.declinedAt === null" data-placement="bottom"-->
<!--         title="Refuser la commande" [routerLink]="['/groups', this.idGroup, 'stores', order?.uidStore, order.uid, 'decline']" data-toggle="tooltip">-->
<!--        <i class="fa fa-trash text-danger"></i></a>-->
      <a (click)="readyForShipping(order)" *ngIf="order.productOrder[0].product.productType !== 'BUS_TICKET' && order.validatedAt !== null && order.availableAt === null"
         data-toggle="tooltip" data-placement="bottom" class="btn btn-default btn-xs text-black-50 mr-1"
         title="Order Ready"><i class="fa fa-thumbs-up text-black-50 pr-1"></i> Make Ready</a>
      <a (click)="orderDelivered(order)" *ngIf="order.productOrder[0].product.productType !== 'BUS_TICKET' && order?.messageOfRejection === '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt !== null && order.giveToDeliverAt !== null && order.deliveredAt === null"
         data-toggle="tooltip" data-placement="bottom" class="btn btn-primary btn-xs text-white m-1"
         title="Order Ready"><i class="fa fa-thumbs-up text-white-50 pr-1"></i> Mark Delivered</a>
      <a (click)="giveToDeliver(order)" *ngIf="order.productOrder[0].product.productType !== 'BUS_TICKET' && order.validatedAt !== null && order.availableAt !== null && order.uidDeliver !== '' && order.giveToDeliverAt === null"
         data-toggle="tooltip" data-placement="bottom" class="btn btn-default btn-xs text-black-50 mr-1"
         title="Give to Deliver"><i class="fa fa-handshake-o text-black-50 pr-1"></i> Give to Deliver</a>
      <span >
        <a (click)="deleteOrder(order)" *ngIf="order.deletedAt === null && uidStore !== null" data-toggle="tooltip"
           data-placement="bottom" title="Supprimer la commande" class="btn btn-danger btn-xs text-white m-1">
        <i class="fa fa-trash text-white pl-1"></i> Remove</a>
      </span>

      <span >
        <a [routerLink]="['/orders', order?.uidStore, order?.uid, 'refund']" *ngIf="order?.refund === null"
           data-toggle="tooltip" data-placement="bottom"
           title="Refund" class="btn btn-warning btn-xs text-white m-1"><i
          class="fa fa-refresh text-white pl-1"></i> Refund</a>
      </span>

      <span class="badge badge-warning" *ngIf="order?.refund !== null"> Commande Remboursée</span>
    </td>
  </tr>
  </tbody>
</table>
<table datatable class="table table-striped table-bordered table-hover" *ngIf="orders?.length === 0">
  <thead>
  <tr>
    <th>Date</th>
    <th>Code</th>
    <th>QR Code</th>
    <th>Adresse</th>
    <th>Nombre des plats</th>
    <th>Prix Total</th>
    <th>Store</th>
    <th style="width: 15%"><i class="fa fa-envelope"></i></th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  </tbody>
</table>
