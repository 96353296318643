<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>Admin</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/paymentMethods']">Payment Methods</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Ajout Payment Methods</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2"></div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/paymentMethods']" class="btn btn-sm btn-primary text-white"><i class="fa fa-chevron-left"></i> Retour</a>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Ajout <small>Admin.</small></h5>
        </div>
        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" [formGroup]="pMethodForm">
            <div class="form-group row"><label class="col-sm-2 col-form-label">Name</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                  </div>
                  <input type="text" placeholder="name" class="form-control" id="name" formControlName="name"
                         required="required" minlength="4"
                         [ngClass]="{'is-invalid' : (pMethodForm.get('name').touched || pMethodForm.get('name').dirty) && !pMethodForm.get('name').valid}">
                  <div class="invalid-feedback">
                    <span *ngIf="pMethodForm.get('name').errors?.required">Payment Method Name is required !</span>
                    <span
                      *ngIf="pMethodForm.get('name').errors?.minlength">Valid Payment Method Name must be longer than 4 caracters !</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"><label class="col-sm-2 col-form-label">Logo</label>
              <div class="col-sm-10">
                <div class="input-group m-b">
                  <div class="input-group-prepend">
                    <span class="input-group-addon"><i class="fa fa-image"></i></span>
                  </div>
                  <input type="file" accept="image/*" placeholder="Avatar" class="form-control" id="imageUrl"
                         formControlName="imageUrl" required="required" (change)="onUpload($event)"
                         [ngClass]="{'is-invalid' : (pMethodForm.get('imageUrl').touched || pMethodForm.get('imageUrl').dirty) && !pMethodForm.get('imageUrl').valid}">

                  <div class="invalid-feedback">
                    <span *ngIf="pMethodForm.get('imageUrl').errors?.required">Payment Method Logo is required !</span>
                  </div>
                </div>
                <div class="progress">
                  <div class="progress-bar" [style.width.%]="pourcentage" role="progressbar"
                       [attr.aria-valuenow]="pourcentage" aria-valuemin="0" aria-valuemax="100">
                    <span class="text-center" *ngIf="pourcentage > 0">{{pourcentage | number}} %</span>
                  </div>
                </div>
                <div class="offset-6" *ngIf="upload">
                  <div class="lds-ring" *ngIf="pourcentage < 100">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="hr-line-dashed"></div>

            <div class="form-group row">
              <div class="col-sm-4 col-sm-offset-2">
                <a [routerLink]="['/paymentMethods']" class="btn btn-white btn-sm" type="submit">Cancel</a>
                <button  class="btn btn-primary btn-sm" type="submit"
                        [title]="pMethodForm.valid ? 'Ajouter Payment Method' : 'Disable until valid form data'"
                        [disabled]="!pMethodForm.valid || pourcentage < 100">Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
