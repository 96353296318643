<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Livreurs</h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item active">
          <strong>Livreurs</strong>
        </li>
      </ol>
    </div>
    <div class="col-lg-2">

    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight" *ngIf="delivers$ | async as delivers">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox ">
          <div class="ibox-title">
            <h5>Liste des Livreurs </h5>
            <div *appIsGranted="'CREATE'" class="ibox-tools">
              <a class="btn btn-sm btn-success text-white" [routerLink]="['/delivers/add']">
                <span> Ajouter Livreur</span>
              </a>
            </div>
          </div>
          <div class="ibox-content">
            <div class="table-responsive">
              <app-deliver [delivers]="delivers"></app-deliver>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
