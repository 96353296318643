import { Component, OnInit } from '@angular/core';
import {PromoCode} from '../../../models/promo-code.model';

@Component({
  selector: 'app-promo-single',
  templateUrl: './promo-single.component.html',
  styleUrls: ['./promo-single.component.css']
})
export class PromoSingleComponent implements OnInit {

  promotion: PromoCode;
  constructor() { }

  ngOnInit(): void {
  }

}
