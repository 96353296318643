import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {supabase} from './supabase';
import {GroupProductType} from '../models/group-product-type.model';

@Injectable({
  providedIn: 'root'
})
export class GroupProductTypeService {

  private GPRODUCTTYPE_TABLE = 'GroupProductType';
  private state = new BehaviorSubject<any>({groupProductTypes: []});
  groupProductTypes$: Observable<GroupProductType[]> = this.state
    .asObservable()
    .pipe(map((state) => state.groupProductTypes));

  constructor() {
  }

  async getGroupProductTypes(uidGroup: string): Promise<void> {
    const {data} = await supabase
      .from(this.GPRODUCTTYPE_TABLE)
      .select('*')
      .eq('uidGroup', uidGroup);

    this.state.next({
      groupProductTypes: data?.map((groupProductType: GroupProductType) => (groupProductType)) ?? []
    });
  }

  async addGroupProductType(groupProductType: GroupProductType): Promise<any> {
    const {error} = await supabase
      .from(this.GPRODUCTTYPE_TABLE)
      .insert(groupProductType).single();

    return error;
  }

  async deleteGroupProductType(uidGroupProductType: string): Promise<any> {
    const {data} = await supabase
      .from(this.GPRODUCTTYPE_TABLE).
      delete()
      .match({uid: uidGroupProductType});

    return data;
  }
}
