import {Injectable, OnDestroy} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {BehaviorSubject, catchError, Observable, shareReplay, Subscription, combineLatest} from 'rxjs';
import {Group} from '../models/groups.model';
import {StoreService} from './store.service';
import {map} from 'rxjs/operators';
import {OrderService} from './order.service';
import {supabase} from './supabase';
import {GroupImage} from '../models/group-image.model';

@Injectable({
  providedIn: 'root'
})
export class GroupsService implements OnDestroy {

  subscriptions: Subscription = new Subscription();
  handlerError: any = '';
  groups$: Observable<Group[]> = this.firestoreService.col$('groups/')
    .pipe(
      shareReplay(1),
      catchError(this.handlerError)
    );
  groupsWithStores$ = combineLatest(
    this.groups$,
    this.storeServices.stores$,
    this.orderServices.allCurrentOrder$
  ).pipe(
    map(([groups, stores, orders]) => {
      return groups.map(group => ({
        ...group,
        stores: stores.filter(store => group.uid === store.storeGroup[0].uidGroup),
        orders: orders.filter(order => group.uid === order.store.storeGroup[0].uidGroup)
      } as Group));
    })
  );

  private groupSelectedAction = new BehaviorSubject<any>({group: null});
  selectedGroup$: Observable<Group> = this.groupSelectedAction
    .asObservable().pipe((uState) => uState);

  private GROUP_TABLE = 'Group';
  private GROUPIMAGE_TABLE = 'GroupImage';

  private state = new BehaviorSubject<any>({groups: []});
  sGroups$: Observable<Group[]> = this.state
    .asObservable()
    .pipe(map((state) => state.groups));

  constructor(private firestoreService: FirestoreService,
              private storeServices: StoreService,
              private orderServices: OrderService) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // private changeSelectedGroup(selectedGroupUid: string | null): void {
  //   this.groupSelectedAction.next(selectedGroupUid);
  // }
  // onSelect(groupUid: string): void {
  //   this.changeSelectedGroup(groupUid);
  // }
  getGroups(): Observable<Group[]> {
    return this.firestoreService.col$(`groups`);
  }

  getGroup(uidGroup: string): Observable<Group> {
    return this.firestoreService.doc$(`groups/${uidGroup}`);
  }

  addGroup(group: Group): Promise<any> {
    return this.firestoreService.set(`groups/${group.uid}`, group);
  }

  updateGroup(group: Group): Promise<any> {
    return this.firestoreService.update(`groups/${group.uid}`, group);
  }

  updateEtat(uidGroup: string, state): Promise<any> {
    return this.firestoreService.update2(`groups/${uidGroup}/`, {available: state});
  }

  deleteGroup(uidGroup: string): Promise<any> {
    return this.firestoreService.delete(`groups/${uidGroup}`);
  }

/**
 * SUPABASE
 */

  async getGroupsWithSupabase(): Promise<void> {
    const {data} = await supabase
      .from(this.GROUP_TABLE)
      .select(`
        *,
        groupImage:GroupImage(image:Image(url, available)),
        storesGroup:StoreGroup(store:Store(*))`)
      .is('deletedAt', null)
      .order('uid', {ascending: false});

    this.state.next({
      groups:
        data?.map((group: Group) => (group)) ?? [],
    });
  }

  async getGroupWithSupabase(uidGroup: string): Promise<void> {
    const {data} = await supabase
      .from(this.GROUP_TABLE)
      .select(`
        *,
        groupImage:GroupImage(image:Image(url, available)),
        storesGroup:StoreGroup(store:Store(*,
          storeImage:StoreImage(*, image:Image(*)),
          storeAddress: StoreAddress(*, address: Address(*)),
          schedule:StoreSchedule(*)))
        `)
      .eq('uid', uidGroup).single();
    this.groupSelectedAction.next(data ?? null);
  }

  async addGroupWithSupabase(group: Group): Promise<any> {
    const {error} = await supabase
      .from(this.GROUP_TABLE)
      .insert(group).single();
    return {error};
  }

  async addGroupImageWithSupabase(groupImage: GroupImage): Promise<any> {
    const {error} = await supabase
      .from(this.GROUPIMAGE_TABLE)
      .insert(groupImage).single();
    return {error};
  }

  async updateGroupWithSupabase(group: Group): Promise<any> {
    const {data, error} = await supabase
      .from(this.GROUP_TABLE)
      .update(group)
      .match({uid: group.uid});

    return {data, error};
  }

  async updateGroupImageWithSupabase(groupImage: GroupImage): Promise<any> {
    const {error} = await supabase
      .from(this.GROUPIMAGE_TABLE)
      .update(groupImage)
      .match({uid: groupImage.uid});
    return {error};
  }

  async deleteGroupWithSupabase(uidGroup: string): Promise<any> {
    const {data} = await supabase
      .from(this.GROUP_TABLE)
      .update({deletedAt: new Date()})
      .match({uid: uidGroup});


    return data;
  }

  async deleteGroupImageWithSupabase(uidGroupImage: string): Promise<any> {
    const {data} = await supabase
      .from(this.GROUPIMAGE_TABLE)
      .delete()
      .match({uid: uidGroupImage});

    return data;
  }

}
