<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>Commande</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/groups', this.idGroup, 'stores', order?.uidStore]">Store</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Commande</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2 mt-4">
    <button (click)="generatePDF(order)" class="btn btn-sm btn-info">Generate PDF</button>
  </div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/groups', this.idGroup, 'stores', order?.uidStore]" class="btn btn-sm btn-primary"><i class="fa fa-chevron-left"></i> Retour</a>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row m-b-lg m-t-lg">
    <div class="col-md-6">
      <div class="profile-info">
        <div class="">
          <div>
            <h2 class="no-margins">
              <strong>{{order?.orderShortCode}}</strong>
            </h2>
            <small><strong>Date: </strong>{{order?.orderedAt?.toDate() | date: 'dd-MMMM-yyyy'}}</small><br>
            <small>
              <strong>Nombre des produits: </strong>{{order?.productOrder.length}}
            </small><br>
            <small>
              <strong>Payment Phone Number: </strong>{{order?.phoneNumber}}
            </small><br>
            <small>
              <strong>Adresse de Livraison: </strong>{{order?.address?.street}}, {{order?.address?.city}} {{order?.address?.state}}, {{order?.address?.country}}
            </small><br>
            <small>
              <strong>Reference: </strong>{{order?.referencePlace}}
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h5>Liste des produits</h5>
      <table class="table m-b-xs">
        <thead>
        <tr><strong>Qte</strong> - Nom</tr>
        </thead>
        <tbody>
        <tr *ngFor="let product of products">
          <td>
            <strong>{{product?.quantity}}</strong> - {{product?.product?.name || product?.name | titlecase}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-2">
      <small>Total de la order</small>
      <h2 class="no-margins">{{order?.totalAmount | currency: 'USD'}}</h2>
      <div id="sparkline1"></div>
    </div>
  </div>
  <div class="row">
<!--    <div class="col-md-4">-->
<!--      <p class="alert alert-warning"-->
<!--         *ngIf="order.orderCategory !== 'BUS_TICKET' && order?.declinedAt === null && order?.validatedAt === null">Commande en attente de Validation</p>-->
<!--      <p class="alert alert-danger" *ngIf="order.orderCategory !== 'BUS_TICKET' && order?.validatedAt === null && order?.declinedAt !== null">Commande Rejetée</p>-->
<!--      <p class="alert alert-success"-->
<!--         *ngIf="order.orderCategory !== 'BUS_TICKET' && order?.validatedAt !== null && order?.availableAt === null">Commande Validée mais pas encore prête</p>-->
<!--      <p class="alert alert-primary"-->
<!--         *ngIf="order.orderCategory !== 'BUS_TICKET' && order?.validatedAt !== null && order?.availableAt !== null && order?.giveToDeliverAt === null">-->
<!--        Commande prête et attente du livreur</p>-->
<!--      <p class="alert alert-secondary"-->
<!--         *ngIf="order.orderCategory !== 'BUS_TICKET' && order?.validatedAt !== null && order?.availableAt !== null && order?.giveToDeliverAt !== null && order?.deliveredAt == null">-->
<!--        Commande en cours de livraison</p>-->
<!--      <p class="alert alert-success"-->
<!--         *ngIf="order.orderCategory !== 'BUS_TICKET' && order?.validatedAt !== null && order?.availableAt !== null && order?.giveToDeliverAt !== null && order?.deliveredAt != null">-->
<!--        Commande livrée</p>-->
<!--      <p class="alert alert-success"-->
<!--         *ngIf="order.orderCategory === 'BUS_TICKET' && order?.deliveredAt != null">-->
<!--        Commande livrée</p>-->
<!--    </div>-->
<!--    <div class="col-md-4 offset-2">-->
<!--      <a (click)="validateCommande(order)" class="btn btn-primary btn-sm text-white" data-placement="bottom" title="Valider la Commande" data-toggle="tooltip"-->
<!--         *ngIf="order.orderCategory !== 'BUS_TICKET' && order?.declinedAt === null && order?.validatedAt === null" ><i class="fa fa-check text-white pr-1"></i> Accepter Commande-->
<!--      </a>-->
<!--      <a (click)="readyForShipping(order)" class="btn btn-default btn-sm text-black-50" data-placement="bottom" title="Commande prête ?" data-toggle="tooltip"-->
<!--         *ngIf="order.orderCategory !== 'BUS_TICKET' && order?.validatedAt !== null && order?.availableAt === null"><i class="fa fa-thumbs-up text-black-50 pr-1"></i> Commande prête-->
<!--      </a>-->
<!--      <a (click)="orderDelivered(order)" *ngIf="order.orderCategory !== 'BUS_TICKET' && order?.messageOfRejection == '' && order?.declinedAt === null && order.validatedAt !== null && order.availableAt !== null && order.giveToDeliverAt !== null && order.deliveredAt === null"-->
<!--         data-toggle="tooltip" data-placement="bottom" class="btn btn-primary btn-lg text-white m-1"-->
<!--         title="Order Ready"><i class="fa fa-thumbs-up text-white pr-1"></i> Mark Delivered</a>-->
<!--      <a (click)="giveToDeliver(order)" class="btn btn-default btn-sm text-black-50" data-placement="bottom" title=" Donner la order au livreur" data-toggle="tooltip"-->
<!--         *ngIf="order.orderCategory !== 'BUS_TICKET' && order?.validatedAt !== null && order?.availableAt !== null && order?.uidDeliver !== '' && order?.giveToDeliverAt === null">-->
<!--        <i class="fa fa-handshake-o text-black-50 pr-1"></i> Donner la Commande au livreur-->
<!--      </a>-->
<!--      <a (click)="deleteOrder(order)" *ngIf="order?.deletedAt === null" data-toggle="tooltip"-->
<!--         data-placement="bottom" title="Supprimer la commande" class="btn btn-danger btn-xs text-white m-1">-->
<!--        <i class="fa fa-trash text-white pl-1"></i> Remove</a>-->
<!--      <span >-->
<!--        <a [routerLink]="['/orders', order?.uidStore, order?.uid, 'refund']" *ngIf="order?.refund === null"-->
<!--           data-toggle="tooltip" data-placement="bottom"-->
<!--           title="Refund" class="btn btn-warning btn-sm text-white m-1"><i-->
<!--          class="fa fa-refresh text-white pl-1"></i> Refund</a>-->
<!--      </span>-->

<!--      <p class="alert alert-danger" *ngIf="order?.declinedAt !== null && order?.validatedAt === null">Message: {{order.messageOfRejection | titlecase}}</p>-->
<!--      <span class="badge badge-warning" *ngIf="order?.refund !== null"> Commande Remboursée au client</span>-->
<!--      <div class="form-group row flex flex-column" *ngIf="order?.validatedAt !== null && order.availableAt !== null && order.uidDeliver === ''">-->
<!--        <form (ngSubmit)="selectDeliver()" [formGroup]="selectForm">-->
<!--          <label class="col-sm-12 col-form-label" for="deliver">Select the order deliverer</label>-->
<!--          <select class="form-control" name="deliver" id="deliver" required="required" formControlName="deliver"-->
<!--                  [ngClass]="{'is-invalid' : (selectForm.get('deliver').touched || selectForm.get('deliver').dirty) && !selectForm.get('deliver').valid}">-->
<!--            <option [ngValue]="null" disabled selected> Select Deliver</option>-->
<!--            <option *ngFor="let deliver of delivers" [value]="deliver.uid">{{deliver.name | titlecase}}</option>-->
<!--          </select>-->
<!--          <div class="invalid-feedback">-->
<!--            <span *ngIf="selectForm.get('deliver').errors?.required">You must select a Deliver !</span>-->
<!--          </div>-->
<!--          <div class="form-group mt-2">-->
<!--            <button class="btn btn-outline-success btn-sm" type="submit"-->
<!--                    [disabled]="!selectForm.valid"-->
<!--                    [title]="selectForm.valid ? 'Select Deliver': 'Disable until valid form data'">Assign</button>-->
<!--          </div>-->
<!--        </form>-->
<!--      </div>-->
<!--    </div>-->
  </div>

  <div *ngIf="order?.uidDeliver !== ''">
    <h1>Informations du livreur</h1>
    <hr>
    <div class="row m-b-lg m-t-lg">
      <div class="col-md-6">
        <div class="profile-image">
          <img src="{{this?.deliver?.imageUrl || 'https://ssl.gstatic.com/accounts/ui/avatar_2x.png'}}"
               class="img-thumbnail m-b-md" alt="Deliver Image">
        </div>
        <div class="profile-info">
          <div class="">
            <div>
              <h2 class="no-margins">
                Name: {{deliver?.name || '-' | titlecase}}
              </h2>
              <h4> Description : {{deliver?.description || '-' | titlecase}}</h4>
              <small>
                Phone: <strong>{{deliver?.phoneNumber || '-'}}</strong>
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</div>
