import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  admin: any;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.authService.user$.subscribe(
        user => {
          if (user !== null) {
            this.admin = user;
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
