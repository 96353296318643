import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Store} from '../../models/store.model';
import {NotificationsService} from '../../services/notifications.service';
import {UploadService} from '../../services/upload.service';
import {AgentsService} from '../../services/agents.service';
import {StoreService} from '../../services/store.service';
import {ActivatedRoute} from '@angular/router';
import {Agent} from '../../models/agent.model';
import {AddressService} from '../../services/address.service';
import {Address} from '../../models/address.model';
import {Address as gAddress} from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-agent-edit',
  templateUrl: './agent-edit.component.html',
  styleUrls: ['./agent-edit.component.css']
})
export class AgentEditComponent implements OnInit, OnDestroy {

  agentForm: UntypedFormGroup;
  agentPwdForm: UntypedFormGroup;
  agentEmailForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  agent: Agent;
  uidAgent: string;
  uidStore: string;
  uidGroup: string;
  subscription: Subscription = new Subscription();
  image: string;
  pourcentage = 0;
  fieldTextType: boolean;
  restorers: Store[] = [];
  userAddress: Address;
  upload = false;

  constructor(private notifyService: NotificationsService,
              private uploadService: UploadService,
              private agentsService: AgentsService,
              private storeService: StoreService,
              private route: ActivatedRoute,
              private addressService: AddressService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.subscription.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidAgent = token.get('id');
          this.uidStore = token.get('idStore');
          this.uidGroup = token.get('idgroup');

          this.subscription.add(
            this.storeService.getStores(this.uidGroup).subscribe(
              restorers => {
                if (restorers !== null) {
                  this.restorers = restorers;
                }
              }
            )
          );
          this.agentsService.getAgent(this.uidGroup, this.uidAgent, this.uidStore);
          this.subscription.add(
            this.agentsService.currentAgent$.subscribe(
              agent => {
                if (agent !== null) {
                  this.agent = agent;

                  this.agentEmailForm.patchValue({
                    email: agent.emailAddress
                  });

                  this.agentPwdForm.patchValue({
                    password: agent.password,
                  });

                  this.agentForm.patchValue({
                    name: agent.name,
                    phone: agent.phoneNumber,
                    adresse: `${agent.userAddress[0]?.address.street}, ${agent.userAddress[0]?.address.city}, ${agent.userAddress[0]?.address.country}`,
                    uidStore: agent.uidStore
                  });
                }
              }
            )
          );
          this.subscription.add(
            this.storeService.getStores(this.uidGroup).subscribe(
              restorers => {
                if (restorers !== null) {
                  this.restorers = restorers;
                }
              }
            )
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initForm(): void {
    this.agentForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      phone: new UntypedFormControl(),
      avatar: new UntypedFormControl(),
      adresse: new UntypedFormControl(),
      uidStore: new UntypedFormControl()
    });
    this.agentPwdForm = new UntypedFormGroup({
      password: new UntypedFormControl('', Validators.required),
    });
    this.agentEmailForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        Validators.required
      ]),
    });
  }


  onSubmit(): void {
    if (this.agentForm.get('avatar').value !== null) {
      this.agent.imageUrl = this.uploadService.images['agent-images'][0];
      this.updateValues();
    } else {
      this.updateValues();
    }
  }

  updateValues(): void {
    this.agent.name = this.agentForm.get('name').value;
    this.agent.phoneNumber = this.agentForm.get('phone').value;
    // this.agent.address[0] = this.userAddress;
    this.agent.uidStore = this.agentForm.get('uidStore').value;

    this.agentsService.updateAgent(this.uidGroup, this.agent).then(
      (result) => {
        if (result !== undefined && (result['code'] >= 400 && result['code'] < 500)) {
          this.toastTitle = 'Modification Echouée';
          this.toastMessage = `Modification du Deliver échouée: ${result['message']}`;
          this.notifyService.showError(this.toastMessage, this.toastTitle);
        } else {
          this.toastTitle = 'Modification Faite';
          this.toastMessage = `Modification de l'Agent faite avec succès !`;
          this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
        }
      }
    ).catch(
      () => {
        this.toastTitle = 'Modification Echouée';
        this.toastMessage = 'Modification du Deliver échouée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );

  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `groups/${this.uidGroup}/agents/${this.uidAgent}`, 'agent-images');
    this.subscription.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

  public handleAddressChange(address: gAddress): void {
    this.userAddress = this.addressService.handleAddressChange(address);
  }

  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }

  modifyPassword(): void {
    if (this.agentPwdForm.valid) {
      this.agent.password = this.agentPwdForm.get('password').value;
      this.agent.updatedAt =  new Date();
      this.agentsService.updateAgent(this.uidGroup, this.agent).then(
        () => {
          this.notifyService.showSuccess('Modification du password de l\'Agent fait avec succès !', 'Notification Faite');
        }
      ).catch(
        error => {
          this.notifyService.showError(`Echec de la modification du password de l\'Admin => ${error}`, 'Echec');
        }
      );
    }
  }


  modifyEmail(): void {
    if (this.agentEmailForm.valid) {
      this.agent.emailAddress = this.agentPwdForm.get('email').value;
      this.agent.updatedAt =  new Date();
      this.agentsService.updateAgent(this.uidGroup, this.agent).then(
        (result) => {
          if (result !== undefined && (result['code'] >= 400 && result['code'] < 500)) {
            this.notifyService.showError(`Echec de la modification de l\'email de l\'Agent: ${result['message']}`, 'Echec');
          } else {
            this.notifyService.showSuccess('Modification email de l\'Agent fait avec succès !', 'Notification Faite');
          }
        }
      ).catch(
        () => {
          this.notifyService.showError(`Echec de la modification de l\'email de l\'Agent}`, 'Echec');
        }
      );
    }
  }
}
