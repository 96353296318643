import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../services/notifications.service';
import {UploadService} from '../services/upload.service';
import {DeliverService} from '../services/deliver.service';
import {ActivatedRoute} from '@angular/router';
import {Deliver} from '../models/deliver.model';
import {Subscription} from 'rxjs';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {Address} from '../models/address.model';
import {AddressService} from '../services/address.service';
import {Address as gAddress} from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-deliveredit',
  templateUrl: './deliveredit.component.html',
  styleUrls: ['./deliveredit.component.css']
})
export class DelivereditComponent implements OnInit, OnDestroy {
  @ViewChild('placesRef', {static: false}) placesRef: GooglePlaceDirective;
  deliverForm: UntypedFormGroup;
  deliver: Deliver;
  toastTitle: string;
  toastMessage: string;
  uidStore: string;
  uid;
  image: string;
  pourcentage = 0;
  subscriptions: Subscription = new Subscription();
  upload = false;
  deliverAddress: Address;

  constructor(private notifyService: NotificationsService,
              private uploadService: UploadService,
              private deliverService: DeliverService,
              private route: ActivatedRoute,
              private addressService: AddressService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.add(
      this.deliverService.getDeliver(this.route.snapshot.paramMap.get('id')).subscribe(
        deliver => {
          if (deliver !== null){
            this.deliver = deliver;
            this.deliverForm.patchValue(
              {
                name: deliver.user.name,
                adresse: `${deliver.user.userAddress[0]?.address.street}, ${deliver.user.userAddress[0]?.address.city}, ${deliver.user.userAddress[0]?.address.country}`,
                email: deliver.user.emailAddress,
                phone: deliver.user.phoneNumber,
                pourcentage: deliver.paymentPercent,
                description: deliver.description
              }
            );
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.deliverForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      adresse: new UntypedFormControl(),
      email: new UntypedFormControl('', [
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        Validators.required
      ]),
      phone: new UntypedFormControl(),
      pourcentage: new UntypedFormControl(),
      description: new UntypedFormControl(),
      image: new UntypedFormControl(),
      password: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    if (this.deliverForm.get('image').value !== null) {
      this.deliver.user.imageUrl = this.uploadService.images['deliver-images'][0];
      this.updateValues();
    } else {
      this.updateValues();
    }
  }

  updateValues(): void {
    this.deliver.user.name = this.deliverForm.get('name').value;
    this.deliver.user.userAddress[0].address = this.deliverAddress;
    this.deliver.description = this.deliverForm.get('description').value;
    this.deliver.user.emailAddress = this.deliverForm.get('email').value;
    this.deliver.user.phoneNumber = this.deliverForm.get('phone').value;
    this.deliver.paymentPercent = this.deliverForm.get('pourcentage').value;

    this.deliverService.updateDeliver(this.deliver).then(
      () => {
        this.toastTitle = 'Modification Faite';
        this.toastMessage = 'Modification du Deliver faite avec succès !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {
        this.toastTitle = 'Modification Echouée';
        this.toastMessage = 'Modification du Deliver échouée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `delivers/${this.uid}/${this.uid}`, 'deliver-images');
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

  public handleAddressChange(address: gAddress): void {
    this.deliverAddress = this.addressService.handleAddressChange(address);
  }

}
