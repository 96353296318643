import {Component, Input, OnDestroy} from '@angular/core';
import {Banner} from '../models/banner.model';
import {Subscription} from 'rxjs';
import {BannersService} from '../services/banners.service';
import {NotificationsService} from '../services/notifications.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnDestroy {

  @Input() banners: Banner[];
  toastTitle: string;
  toastMessage: string;
  subscription: Subscription = new Subscription();

  constructor(private bannerService: BannersService,
              private notifyService: NotificationsService) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  deleteBanner(banner: Banner): void {
    if (confirm('Voulez vous supprimer cette bannière')) {
      this.bannerService.deleteBanner(banner.uid).then(
        () => {
          this.toastTitle = 'Success';
          this.toastMessage = 'Supression de la bannière réussie !';
          this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
        }
      ).catch(
        () => {
          this.toastTitle = 'Success';
          this.toastMessage = 'Supression de la bannière échouée !';
          this.notifyService.showError(this.toastMessage, this.toastTitle);
        }
      );
    }
  }

  changeState(banner: Banner): void {
    if (confirm('Voulez vous changer le status de cette bannière')) {
      this.bannerService.changeBannerState(banner.uid, !banner.available).then(
        () => {
          this.toastTitle = 'Success';
          this.toastMessage = 'Status de la bannière changé !';
          this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
        }
      ).catch(
        () => {
          this.toastTitle = 'Success';
          this.toastMessage = 'Supression de la bannière échouée !';
          this.notifyService.showError(this.toastMessage, this.toastTitle);
        }
      );
    }
  }
}
