<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-6">
    <h2>Agents</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/groups', uidGroup]">Groupe</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Agents</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-6 text-center pt-2">
    <h1 class="font-weight-bold"> {{store?.name | titlecase}}</h1>
  </div>
</div>

<div class="row mt-1">
  <a [routerLink]="['/groups', uidGroup]" class="btn btn-sm btn-primary"><i class="fa fa-chevron-left"></i> Retour</a>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-md-4 pb-3" *ngFor="let agent of agents">
      <div class="ibox-content text-center shadow-sm" style="border-radius: 5px;">
        <h2 style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">{{agent.name | titlecase}}</h2>
        <div class="m-b-sm">
          <img alt="image" class="rounded-circle" src="{{agent.imageUrl || 'https://ssl.gstatic.com/accounts/ui/avatar_2x.png'}}" style="height: 96px; width: 96px">
        </div>
        <p class="font-bold" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">{{agent.emailAddress}}</p>

        <div class="text-center">
          <span >
            <a [routerLink]="['/agents', agent.uidStore, agent.uid, 'edit']" class="btn btn-xs btn-white"
             data-toggle="tooltip" data-placement="bottom" title="Modifier Agent    "><i
            class="fa fa-pencil"></i> Modifier </a>
            <a (click)="lockAgent(agent)" *ngIf="agent.deletedAt === null" class="btn btn-warning ml-2 text-white" data-toggle="tooltip" data-placement="bottom"
               title="Bloquer Agent"><i class="fa fa-lock text-white"></i> Bloquer</a>
            <a (click)="restoreAgent(agent)" *ngIf="agent.deletedAt !== null" class="btn btn-warning ml-2 text-white" data-toggle="tooltip" data-placement="bottom"
             title="Restaurer Agent"><i class="fa fa-refresh text-white"></i> Restaurateur</a>
          </span>

          <a  (click)="removeAgent(agent)" class="ml-2" data-toggle="tooltip" data-placement="bottom"
             title="Supprimer Agent"><i class="fa fa-trash text-danger"></i></a>
        </div>
      </div>

    </div>
  </div>
  <div class="middle-box text-center animated fadeInRightBig" *ngIf="agents.length === 0">
    <h3 class="font-bold">Pas d'Agents</h3>
    <div class="error-desc">
      Désolé Aucun Agent disponible pour ce store !
      <br/><a [routerLink]="['/dashboard']" class="btn btn-primary m-t">Dashboard</a>
    </div>
  </div>
</div>

