import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {Invoice} from '../models/invoice.model';
import {Observable} from 'rxjs';
import {Store} from '../models/store.model';
import {User} from '../models/user.model';
import {Order} from '../models/order.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {ProductOrder} from '../models/product-order.model';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private firestoreService: FirestoreService) {
  }

  createPaiement(uidGroup: string, paiement: Invoice, uidStore: string): Promise<any> {
    this.firestoreService.set(
      `stores/${uidStore}/payments/${paiement.uid}`, paiement
    ).then();
    return this.firestoreService.set(
      `groups/${uidGroup}/stores/${uidStore}/payments/${paiement.uid}`, paiement
    );
  }

  getStorePaiements(uidGroup: string, uidStore: string): Observable<Invoice[]> {
    return this.firestoreService.col$(`groups/${uidGroup}/stores/${uidStore}/payments`);
  }


  /**
   * Generate Order Ticket
   * @param order The order
   * @param store The current merchant where the order was made
   * @param user The user who ordered
   * @param products The Products in order
   */
  generateTicketInvoice(
    order: Order, store: Store, user: User, products: Array<ProductOrder>): void {

    const docDefinition = {
      info: {
        title: `receipt-${order.orderShortCode}`,
        author: 'Be Served',
        subject: 'Receipt',
        keywords: 'receipt',
      },
      pageSize: 'A4',
      pageMargins: [ 40, 60, 40, 60 ],
      header: [
        {
          margin: [40, 10, 40, 10],
          columns: [
            [
              {
                text: store.name,
                fontSize: 14,
                color: '#0E1D2D',
                bold: true
              },
              { text: `${store?.storeAddress[0].address?.street || '-'}, ${store?.storeAddress[0].address?.city || '-'}, ${store?.storeAddress[0].address?.country || '-'}` },
            ],
          ],
        },
      ],
      watermark: { text: 'Original', color: '#9CCDC1', opacity: 0.1, bold: true, italics: false },
      content: [
        {
          text: 'FACTURE',
          fontSize: 20,
          bold: true,
          alignment: 'center',
          decoration: 'underline',
          color: '#229C81'
        },
        {
          text: 'Customer Details',
          style: 'sectionHeader'
        },
        {
          columns: [
            [
              {
                text: user.name || '-',
                bold: true
              },
              { text: `${order?.address?.street || user.userAddress[0].address.street || '-'}, ${order?.address?.city || user.userAddress[0]?.address.city || '-'}, ${order?.address?.country || user.userAddress[0].address?.country || '-'}`},
              { text: user.emailAddress || '-'},
              { text: user.phoneNumber || '-'}
            ],
            [
              {
                table: {
                  headerRows: 1,
                  widths: [100, 'auto'],
                  body: [
                    [
                      {
                        text: 'Date',
                        bold: true,
                        style: 'tableHeader',
                        border: [false, false, false, false]
                      },
                      {
                        // @ts-ignore
                        text: new Date(order.createdAt.seconds * 1000).toLocaleString().toString(),
                        border: [true, true, true, true]
                      }
                    ],
                    [{
                      text: 'Invoice N°',
                      bold: true,
                      style: 'tableHeader',
                      border: [false, false, false, false]},
                      {text: order.orderShortCode, border: [true, true, true, true]}
                    ],
                    [
                      {
                        text: 'Customer',
                        bold: true,
                        style: 'tableHeader',
                        border: [false, false, false, false]
                      },
                      {text: user.name, italics: true, border: [true, true, true, true]}
                    ],
                    [
                      {
                        text: 'Due Date',
                        bold: true,
                        style: 'tableHeader',
                        border: [false, false, false, false]
                      },
                      {
                        // @ts-ignore
                        text: new Date(order.orderedAt.seconds * 1000).toLocaleString().toString(),
                        italics: true,
                        border: [true, true, true, true]
                      }
                    ],
                  ]
                }
              }
            ]
          ]
        },
        {
          text: 'Order Details',
          style: 'sectionHeader'
        },
        {
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: [
              [{text: 'Products', bold: true, style: 'tableHeader'},
                {text: 'Passenger', bold: true, style: 'tableHeader'},
                {text: 'Seat', bold: true, style: 'tableHeader'},
                {text: 'Price', bold: true, style: 'tableHeader'},
                {text: 'Quantity', bold: true, style: 'tableHeader'},
                {text: 'Amount', bold: true, style: 'tableHeader'}
              ],
              ...products.map(
                (product: ProductOrder) => (
                  [
                    product.product.name,
                    product.order.user.name,
                    product.product.metaDatas[0].metaData.metaDataValues[0].value,
                    product.price,
                    product.quantity,
                    (product.price * product.quantity).toFixed(2)
                  ]
                )
              ),
              [
                {text: 'Total Amount', bold: true, style: 'tableHeader', colSpan: 5},
                {}, {}, {}, {},
                {
                  text: products.reduce((sum, product) =>
                    sum + (product.quantity * product.price), 0).toFixed(2),
                  bold: true,
                  fillColor: '#229C81',
                  color: '#fff',
                }],
            ],
          },
          layout: {
            fillColor: (rowIndex: number) => {
              return (rowIndex % 2 === 1) ? '#f5faf8' : null;
            }
          }
        },
        {
          text: 'Additional Details',
          style: 'sectionHeader'
        },
        {
          text: order.comment,
          margin: [0, 0 , 0, 15]
        },
        {
          columns: [
            [
              {
                qr: `ShortCode: ${order.uid}`,
                fit: 100, foreground: '#229C81'
              }],
            [{
              text: 'Terms and Conditions',
              style: 'sectionHeader'
            },
              {
                ul: [
                  'Order can be return in max 10 days.',
                  'Warrenty of the product will be subject to the manufacturer terms and conditions.',
                  'This is system generated invoice.',
                ],
              }],
          ]
        },
      ],
      footer: [
        {
          margin: [40, 10, 40, 10],
          columns: [
            [
              {
                text: 'Be Served',
                fontSize: 16,
                color: '#229C81',
                bold: true
              },
            ],
            [
              {
                text: '117b, Ave. Congo-Motors, Lubumbashi, DR Congo',
                fontSize: 10,
                bold: true
              }
            ],
            [
              {
                text: 'Email: info@beserved.cd',
                fontSize: 10,
                bold: true
              },
              {
                text: 'Phone: +243 976 623 199',
                fontSize: 10,
                bold: true
              },
              {
                text: 'Web: www.beserved.cd',
                fontSize: 10,
                bold: true
              }
            ]
          ]
        }
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        }
      }
    };

    pdfMake.createPdf(docDefinition).open();
  }
}
