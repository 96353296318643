import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {catchError, Observable, of, shareReplay, Subscription} from 'rxjs';
import {NotificationsService} from '../services/notifications.service';
import {UploadService} from '../services/upload.service';
import {ActivatedRoute} from '@angular/router';
import {PaymentMethod} from '../models/payment-method.model';
import {PaymentMethodsService} from '../services/payment-methods.service';

@Component({
  selector: 'app-edit-payment-method',
  templateUrl: './edit-payment-method.component.html',
  styleUrls: ['./edit-payment-method.component.css']
})
export class EditPaymentMethodComponent implements OnInit, OnDestroy {

  paymentMethod: PaymentMethod;
  uidPaymentMethod: string;
  pMethodForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  subscription: Subscription = new Subscription();
  image: string;
  pourcentage = 0;
  upload = false;

  errorMessage = '';
  selectedPaymentMethod$: Observable<PaymentMethod> = this.paymentMethodServices.selectedPaymentMethod$
    .pipe(
      shareReplay(1),
      catchError((error) => {
        console.log('**** ERRROR=>', error);
        this.errorMessage = error;
        return of(null);
      })
    );

  constructor(private notifyService: NotificationsService,
              private uploadService: UploadService,
              private route: ActivatedRoute,
              private paymentMethodServices: PaymentMethodsService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.subscription.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidPaymentMethod = token.get('id');
          void this.paymentMethodServices.getPaymentMethodWithSupabase(this.uidPaymentMethod)
            .then(() => {
              this.selectedPaymentMethod$.subscribe((payment) => {
                this.paymentMethod = payment;
                this.pMethodForm.patchValue({
                  name: payment.name
                });
              });
            });
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initForm(): void {
    this.pMethodForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      imageUrl: new UntypedFormControl()
    });
  }


  onSubmit(): void {
    if (this.pMethodForm.get('imageUrl').value !== null) {
      this.paymentMethod.imageUrl = this.uploadService.images['payment-images'][0];
      this.updateValues();
    } else {
      this.updateValues();
    }
  }

  updateValues(): void {
    this.paymentMethod.name = this.pMethodForm.get('name').value;
    this.paymentMethod.updatedAt = new Date();

    this.paymentMethodServices.updatePaymentMethodWithSupabase(this.paymentMethod)
      .then((result) => {
          if (result.error !== null) {
            this.toastTitle = 'Modification Echouée';
            this.toastMessage = `Modification de la methode de Paiement échouée: ${result.error}`;
            this.notifyService.showError(this.toastMessage, this.toastTitle);
          } else {
            this.toastTitle = 'Modification Faite';
            this.toastMessage = `Modification de la methode de Paiement faite avec succès !`;
            this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
          }
        }
      ).catch(
      () => {
        this.toastTitle = 'Modification Echouée';
        this.toastMessage = 'Modification de l\'Admin échouée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );

  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `supabase/payment-methods/${this.uidPaymentMethod}`, 'payment-images');
    this.subscription.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

}
