<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>{{uidGroup | titlecase}}: Agent</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/groups', uidGroup]">Groupe</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Modifier Agent</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2"></div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/groupes',uidGroup]" class="btn btn-sm btn-primary text-white"><i class="fa fa-chevron-left"></i> Retour</a>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="tabs-container">
        <ul class="nav nav-tabs">
          <li><a class="nav-link active" data-toggle="tab" href="#tab-1"><i class="fa fa-user-secret"></i> Agent Info
            Accounts</a>
          <li><a class="nav-link" data-toggle="tab" href="#tab-2"><i class="fa fa-at"></i> Email</a>
          <li><a class="nav-link" data-toggle="tab" href="#tab-3"><i class="fa fa-asterisk"></i> Password</a>
          </li>
        </ul>
        <div class="tab-content">
          <div id="tab-1" class="tab-pane active">
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox ">
                  <div class="ibox-title">
                    <h5>{{uidGroup | titlecase}}: Modifier <small>Agent Info.</small></h5>
                  </div>
                  <div class="ibox-content">
                    <form (ngSubmit)="onSubmit()" [formGroup]="agentForm">
                      <div class="form-group row"><label for="name" class="col-sm-2 col-form-label">Name</label>
                        <div class="col-sm-10">
                          <div class="input-group m-b">
                            <div class="input-group-prepend">
                              <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                            </div>
                            <input type="text" placeholder="name" class="form-control" id="name" formControlName="name"
                                   required="required" minlength="6"
                                   [ngClass]="{'is-invalid' : (agentForm.get('name').touched || agentForm.get('name').dirty) && !agentForm.get('name').valid}">
                            <div class="invalid-feedback">
                              <span *ngIf="agentForm.get('name').errors?.required">Agent Name is required !</span>
                              <span
                                *ngIf="agentForm.get('name').errors?.minlength">Valid Agent Name must be longer than 6 caracters !</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row"><label for="phone" class="col-sm-2 col-form-label">Phone</label>
                        <div class="col-sm-10">
                          <div class="input-group m-b">
                            <div class="input-group-prepend">
                              <span class="input-group-addon"><i class="fa fa-phone"></i></span>
                            </div>
                            <input type="text" placeholder="Phone" class="form-control"
                                   formControlName="phone" id="phone" required="required"
                                   [ngClass]="{'is-invalid' : (agentForm.get('phone').touched || agentForm.get('phone').dirty) && !agentForm.get('phone').valid}">
                            <div class="invalid-feedback">
                              <span *ngIf="agentForm.get('phone').errors?.required">Agent phone number is required !</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row"><label for="uidStore" class="col-sm-2 col-form-label">Store</label>
                        <div class="col-sm-10">
                          <div class="input-group m-b">
                            <div class="input-group-prepend">
                              <span class="input-group-addon"><i class="fa fa-random"></i></span>
                            </div>
                            <select class="form-control m-b" name="uidStore" formControlName="uidStore"
                                    id="uidStore" required="required"
                                    [ngClass]="{'is-invalid' : (agentForm.get('uidStore').touched || agentForm.get('uidStore').dirty) && !agentForm.get('uidStore').valid}">
                              <option [ngValue]="null" disabled>Store</option>
                              <option *ngFor="let store of restorers" [value]="store.uid">{{store.name}}</option>
                            </select>
                            <div class="invalid-feedback">
                              <span *ngIf="agentForm.get('uidStore').errors?.required">Agent Store is required !</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row"><label class="col-sm-2 col-form-label">Avatar</label>
                        <div class="col-sm-10">
                          <div class="input-group m-b">
                            <div class="input-group-prepend">
                              <span class="input-group-addon"><i class="fa fa-image"></i></span>
                            </div>
                            <input type="file" accept="image/*" placeholder="Avatar" class="form-control" id="avatar"
                                   formControlName="avatar" (change)="onUpload($event)"
                                   [ngClass]="{'is-invalid' : (agentForm.get('avatar').touched || agentForm.get('avatar').dirty) && !agentForm.get('avatar').valid}">

                            <div class="invalid-feedback">
                              <span *ngIf="agentForm.get('avatar').errors?.required">Agent avatar is required !</span>
                            </div>
                          </div>
                          <div class="progress">
                            <div class="progress-bar" [style.width.%]="pourcentage" role="progressbar"
                                 [attr.aria-valuenow]="pourcentage" aria-valuemin="0" aria-valuemax="100">
                              <span class="text-center" *ngIf="pourcentage > 0">{{pourcentage | number}} %</span>
                            </div>
                          </div>
                          <div class="offset-6" *ngIf="upload">
                            <div class="lds-ring" *ngIf="pourcentage < 100">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row"><label class="col-sm-2 col-form-label">Address</label>
                        <div class="col-sm-10">
                          <div class="input-group m-b">
                            <div class="input-group-prepend">
                              <span class="input-group-addon"><i class="fa fa-home"></i></span>
                            </div>
                            <input ngx-google-places-autocomplete #placesRef="ngx-places"
                                   type="text" placeholder="Address" class="form-control"
                                   (onAddressChange)="handleAddressChange($event)"
                                   minlength="10" formControlName="adresse" id="adresse" required="required"
                                   [ngClass]="{'is-invalid' : (agentForm.get('adresse').touched || agentForm.get('adresse').dirty) && !agentForm.get('adresse').valid}">
                            <div class="invalid-feedback">
                                <span
                                  *ngIf="agentForm.get('adresse').errors?.required">Store Address is required !</span>
                              <span
                                *ngIf="agentForm.get('adresse').errors?.minlength">Valid Store Address must be longer than 10 caracters !</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="hr-line-dashed"></div>

                      <div class="form-group row">
                        <div class="col-sm-4 col-sm-offset-2">
                          <a [routerLink]="['/groups', uidGroup]" class="btn btn-white btn-sm" type="submit">Cancel</a>
                          <button class="btn btn-primary btn-sm" type="submit"
                                  [title]="agentForm.valid ? 'Modifier Agent' : 'Disable until valid form data'"
                                  [disabled]="!agentForm.valid">Modifier
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-2" class="tab-pane">
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox ">
                  <div class="ibox-title">
                    <h5>Modifier <small>Email.</small></h5>
                  </div>
                  <div class="ibox-content">
                    <form (ngSubmit)="modifyEmail()" [formGroup]="agentEmailForm">
                      <div class="form-group row"><label for="email" class="col-sm-2 col-form-label">Email</label>
                        <div class="col-sm-10">
                          <div class="input-group m-b">
                            <div class="input-group-prepend">
                              <span class="input-group-addon"><i class="fa fa-at"></i></span>
                            </div>
                            <input type="email" placeholder="Email" class="form-control" id="email"
                                   formControlName="email" required="required"
                                   [ngClass]="{'is-invalid' : (agentEmailForm.get('email').touched || agentEmailForm.get('email').dirty) && !agentEmailForm.get('email').valid}">
                            <div class="invalid-feedback">
                              <span *ngIf="agentEmailForm.get('email').errors?.required">Agent's Email is required !</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="hr-line-dashed"></div>

                      <div class="form-group row">
                        <div class="col-sm-4 col-sm-offset-2">
                          <a [routerLink]="['/groups', uidGroup]" class="btn btn-white btn-sm" type="submit">Cancel</a>
                          <button  class="btn btn-primary btn-sm" type="submit"
                                  [title]="agentEmailForm.valid ? 'Modifier Email' : 'Disable until valid form data'"
                                  [disabled]="!agentEmailForm.valid">Modifier Email
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-3" class="tab-pane">
            <div class="row">
              <div class="col-lg-12">
                <div class="ibox ">
                  <div class="ibox-title">
                    <h5>Modifier <small>Email.</small></h5>
                  </div>
                  <div class="ibox-content">
                    <form (ngSubmit)="modifyPassword()" [formGroup]="agentPwdForm">
                      <div class="form-group row"><label for="password" class="col-sm-2 col-form-label">New Password</label>
                        <div class="col-sm-10">
                          <div class="input-group m-b">
                            <div class="input-group-prepend">
                              <span class="input-group-addon"><i class="fa fa-asterisk"></i></span>
                            </div>
                            <input type="password" [type]="fieldTextType ? 'text' : 'password'" placeholder="New Password" class="form-control" id="password"
                                   formControlName="password" required="required"
                                   [ngClass]="{'is-invalid' : (agentPwdForm.get('password').touched || agentPwdForm.get('password').dirty) && !agentPwdForm.get('password').valid}">
                            <div class="input-group-append">
                              <span class="input-group-text">
                                <i class="fa" [ngClass]="{'fa-eye': !fieldTextType,'fa-eye-slash': fieldTextType}" (click)="toggleFieldTextType()"></i></span>
                            </div>
                            <div class="invalid-feedback">
                              <span *ngIf="agentPwdForm.get('password').errors?.required">Agent's Password is required !</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="hr-line-dashed"></div>

                      <div class="form-group row">
                        <div class="col-sm-4 col-sm-offset-2">
                          <a [routerLink]="['/groups', uidGroup]" class="btn btn-white btn-sm" type="submit">Cancel</a>
                          <button class="btn btn-primary btn-sm" type="submit"
                                  [title]="agentPwdForm.valid ? 'Modifier Password' : 'Disable until valid form data'"
                                  [disabled]="!agentPwdForm.valid">Modifier Password
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
