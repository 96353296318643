import { Injectable } from '@angular/core';
import {PermissionBase} from '../../permissions-rules/permission-base';
import {PermissionType} from '../../models/permission-type.enum';
import {PermissionsFactory} from '../../permissions-rules/permissions-factory';
import {Roles} from '../../models/roles.enum';

@Injectable({
  providedIn: 'root'
})
export class PermissionManagerService {

  private permissions: PermissionBase;
  constructor() { }

  isGranted(permission: PermissionType): boolean {
    if (PermissionsFactory.getInstance()){
      const permissions = PermissionsFactory.getInstance().permissions;
      for (const perm of permissions) {
        if (perm === permission) {
          return true;
        }
      }
    }
    return false;
  }

  authAs(role: Roles): void{
    localStorage.setItem('role', (role === null) ? Roles.AGENT : role);
    this.permissions = PermissionsFactory.getInstance();
  }
}
