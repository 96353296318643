import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {PromoCode} from '../models/promo-code.model';
import {map} from 'rxjs/operators';
import {supabase} from './supabase';
import {ProductPromoCode} from '../models/product-promo-code.model';

@Injectable({
  providedIn: 'root'
})
export class PromoService {

  private currentPromo: BehaviorSubject<PromoCode> = new BehaviorSubject<PromoCode>(null);
  currentPromo$ = this.currentPromo.asObservable();
  subscriptions: Subscription = new Subscription();

  private PROMOCODE_TABLE = 'PromoCode';
  private PRODUCTPROMOCODE_TABLE = 'ProductPromoCode';

  private state = new BehaviorSubject<any>({promoCodes: []});
  promoCodes$: Observable<PromoCode[]> = this.state
    .asObservable()
    .pipe(map((state) => state.promoCodes));

  private promoCodeSelectedAction = new BehaviorSubject<any>({promoCode: null});
  selectedPromoCode$: Observable<PromoCode> = this.promoCodeSelectedAction
    .asObservable().pipe((uState) => uState);

  constructor(private firestoreService: FirestoreService) {
  }

  getPromotions(uidGroup: string, uidStore: string): Observable<PromoCode[]> {
    return this.firestoreService.col$(`groups/${uidGroup}/stores/${uidStore}/code-promo`);
  }

  getPromotion(uidGroup: string, uidStore: string, uidPromo: string): void {
    this.subscriptions.add(
      this.firestoreService
        .doc$(`groups/${uidGroup}/stores/${uidStore}/code-promo/${uidPromo}`).subscribe(
        (promo: PromoCode) => {
          this.currentPromo.next(promo);
        }
      )
    );

  }

  addPromotion(uidGroup: string, promotion: PromoCode): Promise<any> {
    void this.firestoreService.set(`stores/${promotion.uidUser}/code-promo/${promotion.uid}`, promotion).then();
    return this.firestoreService.set(`groups/${uidGroup}/stores/${promotion.uidUser}/code-promo/${promotion.uid}`, promotion);
  }

  editPromotion(uidGroup: string, promotion: PromoCode): Promise<any> {
    void this.firestoreService.update(`stores/${promotion.uidUser}/code-promo/${promotion.uid}`, promotion).then();
    return this.firestoreService.update(`groups/${uidGroup}/stores/${promotion.uidUser}/code-promo/${promotion.uid}`, promotion);
  }

  disablePromotion(uidGroup: string, promotion: PromoCode): Promise<any> {
    void this.firestoreService.update2(`stores/${promotion.uidUser}/code-promo/${promotion.uid}`, {
      valid: false
    }).then();
    return this.firestoreService.update2(`groups/${uidGroup}/stores/${promotion.uidUser}/code-promo/${promotion.uid}`, {
      valid: false
    });
  }

  deletePromotion(uidGroup: string, promo: PromoCode): Promise<any> {
    void this.firestoreService.delete(`stores/${promo.uidUser}/code-promo/${promo.uid}`).then();
    return this.firestoreService.delete(`groups/${uidGroup}/stores/${promo.uidUser}/code-promo/${promo.uid}`);
  }

  /**
   * SUPABASE
   */

  async getPromoCodesWithSupabase(): Promise<void> {
    const {data} = await supabase
      .from(this.PROMOCODE_TABLE)
      .select('*')
      .order('uid', {ascending: false});

    this.state.next({
      promoCodes:
        data?.map((promoCode: PromoCode) => (promoCode)) ?? [],
    });
  }

  async getPromoCodeWithSupabase(uidPromoCode: string): Promise<void> {
    const {data} = await supabase
      .from(this.PROMOCODE_TABLE)
      .select('*')
      .eq('uid', uidPromoCode).single();

    this.promoCodeSelectedAction.next(data ?? null);
  }

  async addPromoCodeWithSupabase(promoCode: PromoCode): Promise<any> {
    const {error} = await supabase
      .from(this.PROMOCODE_TABLE)
      .insert(promoCode).single();

    return {error};
  }

  async addProductPromoCodeWithSupabase(produtPromoCode: ProductPromoCode): Promise<any> {
    const {error} = await supabase
      .from(this.PRODUCTPROMOCODE_TABLE)
      .insert(produtPromoCode).single();

    return {error};
  }

  async updatePromoCodeWithSupabase(promoCode: PromoCode): Promise<any> {
    const {error} = await supabase
      .from(this.PROMOCODE_TABLE)
      .update(promoCode)
      .match({uid: promoCode.uid});

    return {error};
  }

  async updatePromoCodeStateWithSupabase(uidPromoCode: string, state: boolean): Promise<any> {
    const {data} = await supabase
      .from(this.PROMOCODE_TABLE)
      .update({valid: state})
      .match({uid: uidPromoCode});

    return data;
  }

}
