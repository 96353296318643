import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {BehaviorSubject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {NgxImageCompressService} from 'ngx-image-compress';
import uid from 'uid';
import {supabase} from './supabase';
import {Image} from '../models/image.model';
import {GroupImage} from '../models/group-image.model';
import {GroupsService} from './groups.service';
import {NotificationsService} from './notifications.service';
import {StoreImage} from '../models/store-image.model';
import {StoreService} from './store.service';
import {ImageProduct} from '../models/image-product.model';
import {ProductService} from './product.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private uploadPercent = new BehaviorSubject<number>(0);
  public uploadPercent$ = this.uploadPercent.asObservable();
  public images: {[key: string]: string[]} = {};
  localUrl: any;
  uid: string;

  private IMAGE_TABLE = 'Image';
  toastTitle: string;
  toastMessage: string;

  constructor(private angularFireStorage: AngularFireStorage,
              private imageCompress: NgxImageCompressService,
              private notifyService: NotificationsService,
              private groupeService: GroupsService,
              private storeService: StoreService,
              private productService: ProductService) {
  }

  uploadFile(uploadedImage, dossier, index: string, type: string): void {

    void this.selectFile(uploadedImage).then(
      file => {
        const fileRef = this.angularFireStorage.ref(`${dossier}-${index}`);
        const task = this.angularFireStorage.upload(`${dossier}-${index}`, file);

        task.percentageChanges().subscribe(
          valeur => this.uploadPercent.next(valeur)
        );

        task.snapshotChanges().pipe(
          finalize(() => {
            if (this.images[type] === undefined){
              this.images[type] = [];
            }
            fileRef.getDownloadURL().subscribe(
              image => {
                this.images[type] = [
                  ...this.images[type],
                  image
                ];
              }
            );
          })
        ).subscribe();
      }
    );
  }

  uploadFiles(event, dossier, type: string): void {
    let i = 1;
    for (const uploadedImage of event.target.files) {
      this.uid = uid(32);
      this.uploadFile(uploadedImage, dossier, this.uid, type);
      i++;
    }
  }

  selectFile(imageFile: any): Promise<any> {
    return new Promise(
      (resolve, reject) => {
        if (imageFile) {
          const reader = new FileReader();
          reader.onload = (event2: any) => {
            this.localUrl = event2.target.result;
            this.compressFile(this.localUrl).then(
              image => {
                resolve(image);
              }
            );

          };
          reader.readAsDataURL(imageFile);
        } else {
          reject('Image Undefined');
        }
      }
    );
  }

  compressFile(image): Promise<any> {
    return this.imageCompress.compressFile(image, -1, 50, 50).then(
      result => {
        return this.dataURItoBlob(result.split(',')[1]);
      }
    );
  }

  dataURItoBlob(dataURI): Blob {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array], {type: 'image/jpeg'});
  }

  dropImageList(): void {
    this.images = {};
  }

  async addImageWithSupabase(image: Image): Promise<any> {
    const {error} = await supabase
      .from(this.IMAGE_TABLE)
      .insert(image).single();
    return {error};
  }

  async updateImageWithSupabase(image: Image): Promise<any> {
    const {data, error} = await supabase
      .from(this.IMAGE_TABLE)
      .update(image)
      .match({uid: image.uid});

    return {data, error};
  }

  async deleteImageWithSupabase(uidImage: string): Promise<any> {
    const {data} = await supabase
      .from(this.IMAGE_TABLE)
      .delete()
      .match({uid: uidImage});

    return data;
  }

  async getImageWithSupabase(uidGroup: string = null, uidStore: string = null, uidProduct: string = null): Promise<any> {
    if (uidGroup !== null){
      const {data} = await supabase
        .from('GroupImage')
        .select(`*,image:Image(uid, url, available)`)
        .eq('uidGroup', uidGroup).single();

      return data;
    }  else if (uidStore !== null){
      const {data} = await supabase
        .from('StoreImage')
        .select(`*,image:Image(uid, url, available)`)
        .eq('uidGroup', uidGroup).single();

      return data;
    } else if (uidProduct !== null){
      const {data} = await supabase
        .from('ImageProduct')
        .select(`*,image:Image(uid, url, available)`)
        .eq('uidGroup', uidProduct).single();

      return data;
    }
  }

  public saveImage(uploadedImage: string, uidGroup: string = null, uidStore: string = null, uidProduct: string = null): void {

    const image: Image = {
      uid: uid(32),
      url: uploadedImage,
      available: true,
      createdAt: new Date(),
      updatedAt: null
    };

    void this.addImageWithSupabase(image).then((result: any) => {
      if (result.error === null) {
        if (uidGroup !== null){
          const imgGroup: GroupImage = {
            uid: uid(32),
            uidGroup,
            uidImage: image.uid
          };
          void this.groupeService.addGroupImageWithSupabase(imgGroup).then((response: any) => {
            if (response.error === null) {
              console.log('Creation GroupImage Succeed');
              this.toastTitle = 'Ajout Fait';
              this.toastMessage = 'Ajout du Groupe Image fait avec succès !';
              this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
            } else {
              console.log('Creation GroupImage Error =>', response.error);
            }
          });
        } else if (uidStore !== null){
          const imgStore: StoreImage = {
            uid: uid(32),
            uidImage: image.uid,
            uidStore
          };
          void this.storeService.addStoreImageWithSupabase(imgStore)
            .then((response: any) => {
              if (response.error === null) {
                console.log('Creation StoreImage Succeed');
                this.toastTitle = 'Ajout Fait';
                this.toastMessage = 'Ajout du Store image fait avec succès !';
                this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
              } else {
                console.log('Creation StoreImage Error =>', response.error);
              }
            });
        } else if (uidProduct !== null){
          const imgProduct: ImageProduct = {
            uid: uid(32),
            uidImage: image.uid,
            uidProduct
          };
          void this.productService.addProductImageWithSupabase(imgProduct)
            .then((res: any) => {
              if (res.error === null) {
                console.log('Creation StoreImage Succeed');
                this.toastTitle = 'Ajout Fait';
                this.toastMessage = 'Ajout du Store image fait avec succès !';
                this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
              } else {
                console.log('Creation StoreImage Error =>', res.error);
              }
            });
        }

      }
    });
  }

  public editStoreImage(uploadedImage: string, uidStore: string): void {
    void this.getImageWithSupabase(null, uidStore)
      .then((storeImage: StoreImage) => {
        if (storeImage !== null) {
          const image: Image = storeImage.image;
          image.url = uploadedImage;
          image.updatedAt = new Date();

          void this.updateImageWithSupabase(image)
            .then((response) => {
              if (response.error === null) {
                this.toastTitle = 'Mis à jour Faite';
                this.toastMessage = `Modification de l'image faite avec succès !`;
                this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
              } else {
                this.toastTitle = 'Echec de la Mis à jour';
                this.toastMessage = `Modification de l'image faite Echouée > ${response.error}!`;
                this.notifyService.showError(this.toastMessage, this.toastTitle);
              }
            });
        } else {
          this.saveImage(uploadedImage, null, uidStore);
        }
      });
  }

}
