import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Category} from '../models/category.model';
import {supabase} from './supabase';
import uid from 'uid';
import {map} from 'rxjs/operators';
import {StoreCategory} from '../models/store-category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryStoreService {

  private CATEGORY_TABLE = 'Category';
  private CATEGORYSTORE_TABLE = 'StoreCategory';

  private stateStoreCategories = new BehaviorSubject<any>({categories: []});
  storeCategories$: Observable<Category[]> = this.stateStoreCategories
    .asObservable()
    .pipe(map((value) => value.categories));

  private categorySelectedAction = new BehaviorSubject<any>({category: null});
  selectedCategory$: Observable<Category> = this.categorySelectedAction
    .asObservable().pipe((uState) => uState);

  constructor(private firestoreService: FirestoreService) {
  }

  getCategoriesStore(uidGroupe: string): Observable<Category[]> {
    return this.firestoreService.col$(`groups/${uidGroupe}/category-stores`);
  }

  getCategoryStore(uidGroupe: string, uidCategoryStore: string): Observable<Category> {
    return this.firestoreService.doc$(`groups/${uidGroupe}/category-stores/${uidCategoryStore}`);
  }

  updateCategoryStore(uidGroupe: string, categoryStore: Category): Promise<any> {
    return this.firestoreService.update(`groups/${uidGroupe}/category-stores/${categoryStore.uid}`, categoryStore);
  }

  addCategoryStore(uidGroupe: string, categoryStore: Category): Promise<any> {
    return this.firestoreService.set(`groups/${uidGroupe}/category-stores/${categoryStore.uid}`, categoryStore);
  }

  deleteCategory(category: Category, uidGroupe: string): Promise<any> {
    return this.firestoreService.delete(`groups/${uidGroupe}/category-stores/${category.uid}`);
  }

  /**
   * SUPABASE
   */

  async getCategoriesStoreWithSupabase(): Promise<void> {
    const {data} =  await supabase
      .from(this.CATEGORY_TABLE)
      .select('*')
      .is('deletedAt', null)
      .order('uid', {ascending: false});

    this.stateStoreCategories.next({
      categories: data.map((category) => (category)) ?? []
    });
  }

  async getCategoryStoreWithSupabase(uidCategory: string): Promise<void> {
    const {data} =  await supabase
      .from(this.CATEGORY_TABLE)
      .select('*')
      .eq('uid', uidCategory).single();

    this.categorySelectedAction.next(data ?? null);
  }

  async addCategoryWithSupabase(category: Category): Promise<any> {
    const {error} = await supabase
      .from(this.CATEGORY_TABLE)
      .insert(category).single();

    return {error};
  }

  async updateCategoryWithSupabase(category: Category): Promise<any> {
    const {data, error} = await supabase
      .from(this.CATEGORY_TABLE)
      .update(category)
      .match({uid: category.uid});

    return {data, error};
  }

  async addStoreCategoryWithSupabase(storeCategory: StoreCategory): Promise<any> {
    const {error} = await supabase
      .from(this.CATEGORYSTORE_TABLE)
      .insert(storeCategory).single();

    return {error};
  }

  async updateStoreCategoryWithSupabase(storeCategory: StoreCategory): Promise<any> {
    const {data, error} = await supabase
      .from(this.CATEGORYSTORE_TABLE)
      .update(storeCategory)
      .match({uid: storeCategory.uid});

    return {data, error};
  }

  async deleteCategoryStoreWithSupabase(uidCategory: string): Promise<any> {
    const {data} = await supabase
      .from(this.CATEGORY_TABLE)
      .update({deletedAt: new Date()})
      .match({uid: uidCategory});

    return data;
  }
}
