import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {Order} from '../../../models/order.model';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit, OnDestroy {


  ordersNoValidate: Order[] = [];
  ordersValidate: Order[] = [];
  ordersDecline: Order[] = [];
  idStore: string;
  idGroup: string;
  subscriptions: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,
              private orderService: OrderService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.idStore = token.get('idStore');
          this.idGroup = token.get('idgroup');

          this.subscriptions.add(
            this.orderService.getCurrentOrders(this.idGroup, this.idStore).subscribe(
              orders => {
                if (orders !== null) {
                  this.ordersNoValidate = orders;
                }
              }
            )
          );

          this.subscriptions.add(
            this.orderService.getPreviousOrders(this.idGroup, this.idStore).subscribe(
              orders => {
                if (orders !== null) {
                  this.ordersValidate = orders;
                }
              }
            )
          );
          this.subscriptions.add(
            this.orderService.getDeclinedOrders(this.idGroup, this.idStore).subscribe(
              orders => {
                if (orders !== null) {
                  this.ordersDecline = orders;
                }
              }
            )
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
