import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DeliverService} from '../services/deliver.service';
import {Deliver} from '../models/deliver.model';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {UploadService} from '../services/upload.service';
import {NotificationsService} from '../services/notifications.service';
import {Invoice} from '../models/invoice.model';
import {Order} from '../models/order.model';
import {catchError, of, Subscription} from 'rxjs';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {Address} from '../models/address.model';
import {Address as gAddress} from 'ngx-google-places-autocomplete/objects/address';
import {AddressService} from '../services/address.service';
import {Rating} from '../models/rating.model';
import {OrderService} from '../services/order.service';
import {UtilsService} from '../services/utils.service';

@Component({
  selector: 'app-deliver-single',
  templateUrl: './deliver-single.component.html',
  styleUrls: ['./deliver-single.component.css']
})
export class DeliverSingleComponent implements OnInit, OnDestroy {
  @ViewChild('placesRef', {static: false}) placesRef: GooglePlaceDirective;
  idDeliver: string;
  deliver: Deliver;

  profilForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;
  emailForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  ratings: Rating[] = [];
  paiements: Invoice[] = [];
  orders: Order[] = [];
  pourcentage = 0;
  refImage: string;
  fieldTextType: boolean;
  subscriptions: Subscription = new Subscription();
  upload = false;
  deliverAddress: Address;
  allValidatedOrders: Order[] = [];

  errorMessage = '';
  selectedDeliver$ = this.deliverService.selectedDeliverWithOrders$
    .pipe(
      catchError((error) => {
        this.errorMessage = error;
        return of(null);
      })
    );
  // allValidatedOrders$ = this.orderService.allValidatedOrders$
  //   .pipe(
  //     catchError((error) => {
  //       this.errorMessage = error;
  //       return of(null);
  //     })
  //   );

  constructor(private route: ActivatedRoute,
              private deliverService: DeliverService,
              private utilsServices: UtilsService,
              private uploadService: UploadService,
              private notifyService: NotificationsService,
              private addressService: AddressService,
              private orderService: OrderService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.idDeliver = this.route.snapshot.paramMap.get('id');
    this.deliverService.onSelect(this.idDeliver);

    this.subscriptions.add(
      this.deliverService.getDeliver(this.idDeliver).subscribe(
        deliver => {
          if (deliver !== null) {
            this.deliver = deliver;
            this.profilForm.patchValue({
              name: deliver.user.name,
              description: deliver.description,
              adresse: `${deliver.user.userAddress[0]?.address.street}, ${deliver.user.userAddress[0]?.address.city}, ${deliver.user.userAddress[0]?.address.country}`,
            });

            this.passwordForm.patchValue({
              newPassword: deliver.user.password
            });
            this.emailForm.patchValue({
              newEmail: deliver.user.emailAddress
            });
          }
        }
      )
    );
    this.subscriptions.add(
      this.deliverService.getDeliverRatings(this.idDeliver).subscribe(
        ratings => {
          if (ratings !== null){
            this.ratings = ratings;
          }
        }
      )
    );
    this.subscriptions.add(
      this.deliverService.getDeliverPaiements(this.idDeliver).subscribe(
        paiements => this.paiements = paiements
      )
    );

    this.subscriptions.add(
      this.deliverService.getDeliverCommands(this.idDeliver).subscribe(
        orders => this.orders = orders
      )
    );

    this.subscriptions.add(
      this.deliverService.getDeliverCommands(this.idDeliver).subscribe(
        orders => {
          this.allValidatedOrders = orders;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.profilForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      description: new UntypedFormControl(),
      adresse: new UntypedFormControl(),
      image: new UntypedFormControl(),
      email: new UntypedFormControl()
    });
    this.passwordForm = new UntypedFormGroup({
      newPassword: new UntypedFormControl()
    });
    this.emailForm = new UntypedFormGroup({
      newEmail: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    if (this.profilForm.get('image').value !== null) {
      this.deliver.user.imageUrl = this.uploadService.images['deliver-images'][0];
      this.updateValues();
    } else {
      this.updateValues();
    }
  }

  updateValues(): void {
    this.deliver.user.name = (this.profilForm.get('name').value).trim();
    this.deliver.description = (this.profilForm.get('description').value).trim();
    this.deliver.user.userAddress[0].address = this.deliverAddress;
    this.deliver.user.updatedAt = new Date();
    this.deliverService.updateDeliver(this.deliver).then(
      () => {
        this.toastTitle = 'Modification Faite';
        this.toastMessage = 'Modification du Livreur faite avec succès !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {
        this.toastTitle = 'Modification Echouée';
        this.toastMessage = 'Modification du Livreur échouée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `delivers/${this.idDeliver}/${this.idDeliver}`, 'deliver-images');
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );

    this.refImage = this.uploadService.images['deliver-images'][0];
  }

  public handleAddressChange(address: gAddress): void {
    this.deliverAddress = this.addressService.handleAddressChange(address);
  }

  resetPassword(): void {
    this.deliver.user.password = this.passwordForm.get('newPassword').value;
    this.deliver.user.updatedAt = new Date();
    this.deliverService.updateDeliver(this.deliver).then(
      () => {
        this.toastTitle = 'Modification Faite';
        this.toastMessage = 'Modification du Password du Livreur fait avec succès !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {

        this.toastTitle = 'Echec';
        this.toastMessage = 'Echec de la modification du Password Livreur';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  resetEmail(): void {
    this.deliver.user.emailAddress = this.emailForm.get('newEmail').value;
    this.deliver.user.updatedAt = new Date();
    this.deliverService.updateDeliver(this.deliver).then(
      () => {
        this.toastTitle = 'Modification Faite';
        this.toastMessage = 'Modification du Mail du Livreur fait avec succès !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
      }
    ).catch(
      () => {
        this.toastTitle = 'Echec';
        this.toastMessage = 'Echec de la modification du Mail Livreur';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );
  }

  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }

  takeOrder(order: Order): void {
    if (confirm('Etes-vous sure de vouloir Prendre en charge cette Commande ?')){
      this.utilsServices.affectOrder(order, this.idDeliver);
    }
  }

}
