<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{idGroupe | titlecase}}: Modify Store</h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/groups']">Groupes</a>
        </li>
        <li class="breadcrumb-item active">
          <strong>Modify Store</strong>
        </li>
      </ol>
    </div>
    <div class="col-lg-2"></div>
  </div>
  <div class="row mt-1">
    <a [routerLink]="['/groups', idGroupe]" class="btn btn-sm btn-primary text-white"><i class="fa fa-chevron-left"></i> Back</a>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox ">
          <div class="ibox-title">
            <h5>{{idGroupe | titlecase}}: Modify <small>Store.</small></h5>
          </div>
          <div class="ibox-content">
            <form (ngSubmit)="onSubmit()" [formGroup]="storeForm">
              <div class="form-group row"><label class="col-sm-2 col-form-label">Name</label>
                <div class="col-sm-10">
                  <div class="input-group m-b">
                    <div class="input-group-prepend">
                      <span class="input-group-addon"><i class="fa fa-tint"></i></span>
                    </div>
                    <input type="text" placeholder="name" class="form-control" id="name" formControlName="name"
                           required="required" minlength="3"
                           [ngClass]="{'is-invalid' : (storeForm.get('name').touched || storeForm.get('name').dirty) && !storeForm.get('name').valid}">
                    <div class="invalid-feedback">
                      <span *ngIf="storeForm.get('name').errors?.required">Store Name is required !</span>
                      <span
                        *ngIf="storeForm.get('name').errors?.minlength">Valid Store Name must be longer than 3 characters !</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row"><label class="col-sm-2 col-form-label">Description</label>
                <div class="col-sm-10">
                  <div class="input-group m-b">
                    <div class="input-group-prepend">
                      <span class="input-group-addon"><i class="fa fa-italic"></i></span>
                    </div>
                    <input type="text" placeholder="Description" class="form-control" minlength="10"
                           formControlName="description" id="description"
                           [ngClass]="{'is-invalid' : (storeForm.get('description').touched || storeForm.get('description').dirty) && !storeForm.get('description').valid}">
                    <div class="invalid-feedback">
                      <span
                        *ngIf="storeForm.get('description').errors?.minlength">Valid Store description must be longer than 10 caracters !</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row"><label class="col-sm-2 col-form-label">Adresse</label>
                <div class="col-sm-10">
                  <div class="input-group m-b">
                    <div class="input-group-prepend">
                      <span class="input-group-addon"><i class="fa fa-home"></i></span>
                    </div>
                    <input ngx-google-places-autocomplete #placesRef="ngx-places" type="text" placeholder="Adresse"
                           class="form-control" minlength="10" (onAddressChange)="handleAddressChange($event)"
                           formControlName="adresse" id="adresse" required="required"
                           [ngClass]="{'is-invalid' : (storeForm.get('adresse').touched || storeForm.get('adresse').dirty) && !storeForm.get('adresse').valid}">
                    <div class="invalid-feedback">
                        <span *ngIf="storeForm.get('adresse').errors?.required">Store adresse is required !</span>
                      <span
                        *ngIf="storeForm.get('adresse').errors?.minlength">Valid Store description must be longer than 10 caracters !</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row"><label class="col-sm-2 col-form-label">Category</label>
                <div class="col-sm-10">
                  <div class="input-group m-b">
                    <div class="input-group-prepend">
                      <span class="input-group-addon"><i class="fa fa-random"></i></span>
                    </div>
                    <select class="form-control m-b" name="categoryStore" formControlName="categoryStore" id="categoryStore"
                            required="required"
                            [ngClass]="{'is-invalid' : (storeForm.get('categoryStore').touched || storeForm.get('categoryStore').dirty) && !storeForm.get('categoryStore').valid}">
                      <option [ngValue]="null" disabled>Category</option>
                      <option *ngFor="let category of categories" [value]="category.uid">{{category.name}}</option>
                    </select>
                    <div class="invalid-feedback">
                      <span *ngIf="storeForm.get('categoryStore').errors?.required">Store Category price is required !</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row"><label class="col-sm-2 col-form-label">Image</label>
                <div class="col-sm-10">
                  <div class="input-group m-b">
                    <div class="input-group-prepend">
                      <span class="input-group-addon"><i class="fa fa-image"></i></span>
                    </div>
                    <input type="file" accept="image/*" placeholder="Image" class="form-control" id="image"
                           formControlName="image"
                           (change)="onUpload($event)"
                           [ngClass]="{'is-invalid' : (storeForm.get('image').touched || storeForm.get('image').dirty) && !storeForm.get('image').valid}">

                    <div class="invalid-feedback">
                      <span *ngIf="storeForm.get('image').errors?.required">Store image is required !</span>
                    </div>
                  </div>
                  <div class="progress">
                    <div class="progress-bar" [style.width.%]="pourcentage" role="progressbar"
                         [attr.aria-valuenow]="pourcentage" aria-valuemin="0" aria-valuemax="100">
                      <span class="text-center" *ngIf="pourcentage > 0">{{pourcentage | number}} %</span>
                    </div>
                  </div>
                  <div class="offset-6" *ngIf="upload">
                    <div class="lds-ring" *ngIf="pourcentage < 100">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hr-line-dashed"></div>

              <div class="form-group row">
                <div class="col-sm-4 col-sm-offset-2">
                  <button class="btn btn-white btn-sm" type="submit">Cancel</button>
                  <button class="btn btn-primary btn-sm" type="submit"
                          [title]="storeForm.valid ? 'Modifier Store' : 'Disable until valid form data'"
                          [disabled]="!storeForm.valid">Edit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
