import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '../../../../models/store.model';
import {Subscription} from 'rxjs';
import {Order} from '../../../../models/order.model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderService} from '../../../../services/order.service';
import {NotificationsService} from '../../../../services/notifications.service';
import {StoreService} from '../../../../services/store.service';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})
export class RefundComponent implements OnInit, OnDestroy {

  store: Store;
  subscriptions: Subscription = new Subscription();
  toastTitle: string;
  toastMessage: string;
  idOrder: string;
  idStore: string;
  idGroup: string;
  order: Order;
  refundForm: UntypedFormGroup;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private orderService: OrderService,
              private notifyService: NotificationsService,
              private storeService: StoreService) {
  }

  ngOnInit(): void {
    this.initForm();

    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          if (token !== null) {
            this.idOrder = token.get('idOrder');
            this.idStore = token.get('idStore');
            this.idGroup = token.get('idgroup');

            this.storeService.getStore(this.idGroup, this.idStore);
            this.subscriptions.add(
              this.storeService.currentStore$.subscribe(
                store => {
                  if (store !== null) {
                    this.store = store;
                  }
                }
              )
            );
            this.subscriptions.add(
              this.orderService.getOrder(this.idOrder, this.idStore, this.idGroup).subscribe(
                order => {
                  if (order !== null) {
                    this.order = order;
                  }
                }
              )
            );

          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.refundForm = new UntypedFormGroup({
      date: new UntypedFormControl('', [Validators.required]),
      amount: new UntypedFormControl('', [Validators.required]),
      reason: new UntypedFormControl('', [Validators.required])
    });
  }

  refundCommand(): void {
    // this.order.refund.date = this.refundForm.get('date').value;
    // this.order.refund.amount = this.refundForm.get('amount').value;
    // this.order.refund.reason = this.refundForm.get('reason').value;
    //
    // this.store.amountAllOrders -= this.refundForm.get('amount').value;
    // this.store.updatedAt = new Date();
    //
    // this.storeService.updateStore(this.store).then(
    //   () => {
    //     this.orderService.updateOrder(this.order, this.idGroup).then(
    //       () => {
    //         this.refundForm.reset();
    //         this.router.navigate(['/stores', this.order.uidStore]).then(
    //           () => {
    //             this.toastTitle = 'Modification Faite';
    //             this.toastMessage = 'Remboursement fait avec succès !';
    //             this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
    //           }
    //         );
    //       }
    //     );
    //   }
    // ).catch(
    //   (err) => {
    //     console.log(err);
    //     this.toastTitle = 'Modification Echouée';
    //     this.toastMessage = 'Remboursement échouée !';
    //     this.notifyService.showError(this.toastMessage, this.toastTitle);
    //   }
    // );
  }

}
