<div class="ibox ">
  <div class="ibox-title">
    <h5>Liste des {{uidGroup | titlecase}} </h5>
    <div  class="ibox-tools">
      <a class="btn btn-sm btn-success text-white" [routerLink]="['/groups', uidGroup, 'agents', 'add']">
        <span> Ajouter Agent</span>
      </a>
    </div>
  </div>
  <div class="ibox-content">
    <div class="table-responsive">
      <table datatable class="table table-striped table-bordered table-hover dataTables-example"
             *ngIf="stores.length != 0">
        <thead>
        <tr>
          <th>image</th>
          <th>Nom</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let store of stores">
          <td>
            <img src="{{store.images[0]}}" class="img-thumbnail" alt="restorer_image" style="max-height: 50px;">
          </td>
          <td>{{store.name | titlecase}}</td>
          <td>
            <a [routerLink]="['/groups', uidGroup, 'agents', store?.uid]"><i class="fa fa-eye text-navy pr-1"></i></a>
          </td>
        </tr>
        </tbody>
      </table>
      <table datatable class="table table-striped table-bordered table-hover dataTables-example"
             *ngIf="stores.length == 0">
        <thead>
        <tr>
          <th>image</th>
          <th>Nom</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
      </table>

    </div>
  </div>
</div>
