import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Agent} from '../models/agent.model';
import {HttpClient} from '@angular/common/http';
import {FirestoreService} from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {

  private currentAgent: BehaviorSubject<Agent> = new BehaviorSubject<Agent>(null);
  currentAgent$ = this.currentAgent.asObservable();

  constructor(private httpClient: HttpClient, private firestoreService: FirestoreService) {
  }

  getAgents(uidGroup: string, uidStore: string): Observable<Agent[]> {
    return this.firestoreService.col$(`groups/${uidGroup}/stores/${uidStore}/agents`);
  }

  getAgent(uidGroup: string, uidAgent: string, uidStore: string): void {
    this.firestoreService.doc$(`groups/${uidGroup}/stores/${uidStore}/agents/${uidAgent}`).subscribe(
      (agent: Agent) => {
        this.currentAgent.next(agent);
      }
    );
  }

  getAgentUser(uidGroup: string, uidAgent: string, uidStore: string): Observable<any> {
    return this.firestoreService.doc$(`groups/${uidGroup}/stores/${uidStore}/agents/${uidAgent}`);
  }

  addAgent(agent: Agent): Promise<any> {
    this.firestoreService.set(`stores/${agent.uidStore}/agents/${agent.uid}`, agent).then();
    return this.firestoreService.set(`groups/${agent.uidGroup}/stores/${agent.uidStore}/agents/${agent.uid}`, agent);
  }

  updateAgent(uidGroup: string, agent: Agent): Promise<any> {
    this.firestoreService.update(`stores/${agent.uidStore}/agents/${agent.uid}`, agent).then();
    return this.firestoreService.update(`groups/${uidGroup}/stores/${agent.uidStore}/agents/${agent.uid}`, agent);
  }

  deleteAgent(agent: Agent): Promise<any> {
    this.firestoreService.update2(
      `stores/${agent.uidStore}/agents/${agent.uid}`, {
        deletedAt: new Date()
      }
    ).then();
    return this.firestoreService.update2(
      `groups/${agent.uidGroup}/stores/${agent.uidStore}/agents/${agent.uid}`, {
        deletedAt: new Date()
      }
    );
  }

  restoreAgent(agent: Agent): Promise<any> {
    this.firestoreService.update2(`stores/${agent.uidStore}/agents/${agent.uid}`, {
      deletedAt: null}).then();
    return this.firestoreService.update2(`groups/${agent.uidGroup}/stores/${agent.uidStore}/agents/${agent.uid}`, {
      deletedAt: null});
  }

  deleteA(agent): Promise<any> {
    this.firestoreService.delete(`stores/${agent.uidStore}/agents/${agent.uid}`).then();
    return this.firestoreService.delete(`groups/${agent.uidGroup}/stores/${agent.uidStore}/agents/${agent.uid}`);
  }
}
