<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>Notification</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/notifications']">Notifications</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Modifier Notification</strong>
      </li>
    </ol>
  </div>
  <div class="col-lg-2"></div>
</div>
<div class="row mt-1">
  <a [routerLink]="['/notifications']" class="btn btn-sm btn-primary text-white"><i class="fa fa-chevron-left"></i> Back</a>
</div>
