import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {NotificationsService} from '../services/notifications.service';
import {UploadService} from '../services/upload.service';
import {ActivatedRoute} from '@angular/router';
import {AdminsService} from '../services/admins.service';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {Address} from '../models/address.model';
import {AddressService} from '../services/address.service';
import {Address as gAddress} from 'ngx-google-places-autocomplete/objects/address';
import {User} from '../models/user.model';

@Component({
  selector: 'app-admin-edit',
  templateUrl: './admin-edit.component.html',
  styleUrls: ['./admin-edit.component.css']
})
export class AdminEditComponent implements OnInit, OnDestroy {
  @ViewChild('placesRef', {static: false}) placesRef: GooglePlaceDirective;
  adminForm: UntypedFormGroup;
  adminPwdForm: UntypedFormGroup;
  adminEmailForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  admin: User;
  uidAdmin: string;
  subscription: Subscription = new Subscription();
  image: string;
  pourcentage = 0;
  fieldTextType: boolean;
  upload = false;
  adminAddress: Address;

  constructor(private notifyService: NotificationsService,
              private uploadService: UploadService,
              private adminsService: AdminsService,
              private route: ActivatedRoute,
              private addressService: AddressService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.subscription.add(
      this.route.paramMap.subscribe(
        idAdmin => {
          this.uidAdmin = idAdmin.get('id');

          this.adminsService.getAdmin(this.uidAdmin);
          this.subscription.add(
            this.adminsService.currentAdmin$.subscribe(
              admin => {
                if (admin !== null) {
                  this.admin = admin;

                  this.adminEmailForm.patchValue({
                    email: admin.emailAddress
                  });

                  this.adminPwdForm.patchValue({
                    password: admin.password,
                  });

                  this.adminForm.patchValue({
                    name: admin.name,
                    phone: admin.phoneNumber,
                    adresse: `${admin.userAddress[0].address?.street}, ${admin.userAddress[0].address?.city}, ${admin.userAddress[0].address?.country}`,
                  });
                }
              }
            )
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initForm(): void {
    this.adminForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      phone: new UntypedFormControl(),
      avatar: new UntypedFormControl(),
      adresse: new UntypedFormControl()
    });
    this.adminPwdForm = new UntypedFormGroup({
      password: new UntypedFormControl('', Validators.required),
    });
    this.adminEmailForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        Validators.required
      ]),
    });
  }


  onSubmit(): void {
    if (this.adminForm.get('avatar').value !== null) {
      this.admin.imageUrl = this.uploadService.images['admin-images'][0];
      this.updateValues();
    } else {
      this.updateValues();
    }
  }

  updateValues(): void {
    this.admin.name = this.adminForm.get('name').value;
    this.admin.phoneNumber = this.adminForm.get('phone').value;
    this.admin.userAddress[0].address = this.adminAddress;
    this.admin.updatedAt = new Date();

    this.adminsService.updateAdmin(this.admin).then(
      (result) => {
        if (result !== undefined && (result['code'] >= 400 && result['code'] < 500)) {
          this.toastTitle = 'Modification Echouée';
          this.toastMessage = `Modification de l\'Admin échouée: ${result['message']}`;
          this.notifyService.showError(this.toastMessage, this.toastTitle);
        } else {
          this.toastTitle = 'Modification Faite';
          this.toastMessage = `Modification de l'Admin faite avec succès !`;
          this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
        }
      }
    ).catch(
      () => {
        this.toastTitle = 'Modification Echouée';
        this.toastMessage = 'Modification de l\'Admin échouée !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      }
    );

  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event, `admins/${this.uidAdmin}`, 'admin-images');
    this.subscription.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }

  public handleAddressChange(address: gAddress): void {
    this.adminAddress = this.addressService.handleAddressChange(address);
  }

  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }

  modifyPassword(): void {
    if (this.adminPwdForm.valid) {
      this.admin.password = this.adminPwdForm.get('password').value;
      this.admin.updatedAt = new Date();

      this.adminsService.updateAdmin(this.admin).then(
        () => {
          this.notifyService.showSuccess('Modification du password de l\'Admin fait avec succès !', 'Notification Faite');
        }
      ).catch(
        error => {
          this.notifyService.showError(`Echec de la modification du password de l\\'Admin => ${error}`, 'Echec');
        }
      );
    }
  }


  modifyEmail(): void {
    if (this.adminEmailForm.valid) {
      this.admin.emailAddress = this.adminEmailForm.get('email').value;
      this.admin.updatedAt = new Date();

      this.adminsService.updateAdmin(this.admin).then(
        (result) => {
          if (result !== undefined && (result['code'] >= 400 && result['code'] < 500)) {
            this.notifyService.showError(`Echec de la modification de l\\'email de l\\'Admin: ${result['message']}`, 'Echec');
          } else {
            this.notifyService.showSuccess('Modification email de l\'Admin fait avec succès !', 'Notification Faite');
          }
        }
      ).catch(
        () => {
          this.notifyService.showError(`Echec de la modification de l\\'email de l\\'Admin}`, 'Echec');
        }
      );
    }
  }
}
