import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';
import {Observable} from 'rxjs';
import {Category} from '../models/category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryProductService {

  constructor(private firestoreService: FirestoreService) {
  }

  getCategoriesProduct(uidGroup: string, uidStore: string): Observable<Category[]> {
    return this.firestoreService.col$(`groups/${uidGroup}/stores/${uidStore}/category-products/`);
  }

  getCategoryProduct(uidGroup: string, uidStore: string, uidProduct: string): Observable<Category> {
    return this.firestoreService.doc$(`groups/${uidGroup}/stores/${uidStore}/category-products/${uidProduct}`);
  }

  createCategoryProduct(categoryProduct: Category, uidStore: string, uidGroup: string): Promise<any> {
    this.firestoreService.set(`stores/${uidStore}/category-products/${categoryProduct.uid}`, categoryProduct).then();
    return this.firestoreService.set(`groups/${uidGroup}/stores/${uidStore}/category-products/${categoryProduct.uid}`, categoryProduct);
  }

  updateCategoryProduct(categoryProduct: Category, uidStore: string, uidGroup: string): Promise<any> {
    this.firestoreService.update(`stores/${uidStore}/category-products/${categoryProduct.uid}`, categoryProduct).then();
    return this.firestoreService.update(`groups/${uidGroup}/stores/${uidStore}/category-products/${categoryProduct.uid}`, categoryProduct);
  }

  deleteCategoryProduct(uidGroup: string, uidStore: string, categoryProduct: Category): Promise<any> {
    this.firestoreService.delete(`stores/${uidStore}/category-products/${categoryProduct.uid}`).then();
    return this.firestoreService.delete(`groups/${uidGroup}/stores/${uidStore}/category-products/${categoryProduct.uid}`);
  }
}
