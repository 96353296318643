<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-xs-6">
    <h2>Payment Methods</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Payment Methods</strong>
      </li>
    </ol>
  </div>
  <div class="col-xs-6">
    <div class="ibox-tools pt-4">
      <a class="btn btn-sm btn-success text-white" [routerLink]="['/paymentMethods/add']"
         data-toggle="tooltip" data-placement="bottom" title="Ajouter une Methode de Paiement">
        <span><i class="fa fa-plus"></i> Ajouter Payment Method</span>
      </a>
    </div>
  </div>
</div>

<div class="wrapper wrapper-content animated fadeInRight" *ngIf="paymentMethods$ | async as paymentMethods">
  <div class="row">
    <div class="col-md-4" *ngFor="let paymentMethod of paymentMethods">
      <div class="payment-card">
        <img src="{{paymentMethod?.imageUrl || 'logo.ico'}}"
             class="img-thumbnail payment-image-big" alt="payment-method-image">
        <h2>
          <strong>{{paymentMethod.name | titlecase}}</strong>
        </h2>
        <div class="row">
          <div class="col-sm-6">
            <small>
              <strong>Creation date:</strong> {{paymentMethod.createdAt | date: 'MM/yy'}}
            </small>
          </div>
          <div class="col-sm-6 text-right" [ngSwitch]="paymentMethod.available">
            <small *ngSwitchCase="true">
              <strong>Status:</strong><span class="text-navy"> Active</span>
            </small>
            <small *ngSwitchCase="false">
              <strong>Status:</strong><span class="text-danger"> Disable</span>
            </small>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="row">

    <div class="col-lg-12">

      <div class="ibox">
        <div class="ibox-title">
          Payment method
        </div>
        <div class="ibox-content">

          <div class="panel-group payments-method" id="accordion">
            <div class="panel panel-default" *ngFor="let paymentMethod of paymentMethods">
              <div class="panel-heading">
                <div  class="float-right">
                  <bSwitch [(ngModel)]="paymentMethod.available" [switch-size]="'small'"
                           [switch-label-text]="'Disponibilité'" [switch-on-color]="'success'"
                           [switch-off-color]="'danger'" [switch-off-text]="'Indisponible'"
                           [switch-on-text]="'Disponible'" [switch-base-class]="'bootstrap-switch'"
                           [switch-wrapper-class]="'bootstrap-switch'"
                           (changeState)="onChange($event, paymentMethod.uid)">
                  </bSwitch>
                  <img src="{{paymentMethod?.imageUrl || 'logo.ico'}}"
                       class="img-thumbnail ml-3" alt="payment-method-image" style="height: 30px;">
                </div>
                <h5 class="panel-title">
                  <a data-toggle="collapse" data-parent="#accordion" href="#{{paymentMethod.uid}}">{{paymentMethod.name | titlecase}}</a>
                </h5>
              </div>
              <div id="{{paymentMethod.uid}}" class="panel-collapse collapse">
                <div class="panel-body">

                  <div class="row">
                    <div class="col-md-10">
                      <h2>{{paymentMethod.name | titlecase}}</h2>
                      <strong>Creation Date:</strong> {{paymentMethod.createdAt | date: 'MM/yy'}} <br/>
                      <div [ngSwitch]="paymentMethod.available">
                        <div *ngSwitchCase="true">
                          <strong>Status:</strong><span class="text-navy"> Active</span>
                        </div>
                        <div *ngSwitchCase="false">
                          <strong>Status:</strong><span class="text-danger"> Disable</span>
                        </div>
                      </div>

                      <div class="col-lg-10 p-1 m-t align-content-center">
                        <div [ngSwitch]="paymentMethod.available">
                          <h4 *ngSwitchCase="true" class="text-navy">
                            <strong>La methode de paiement est disponible</strong></h4>
                          <h4 *ngSwitchCase="false" class="text-danger">
                            <strong>La methode de paiement est indisponible</strong></h4>
                        </div>
                      </div>

                      <a  class="btn btn-success text-white pull-left"
                         [routerLink]="['/paymentMethods', paymentMethod.uid, 'edit']" data-toggle="tooltip"
                         data-placement="bottom" title="Modifier la Methode de Paiement">
                        <strong><i class="fa fa-pencil-square-o"></i> Modify Payment method</strong>
                      </a>
                      <a  class="btn btn-danger text-white pull-right" (click)="removePayementMethod(paymentMethod)"
                         data-toggle="tooltip" data-placement="bottom" title="Supprimer la Methode de Paiement">
                        <strong><i class="fa fa-trash"></i> Remove Payment method</strong>
                      </a>
                    </div>

                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>

  </div>
</div>
