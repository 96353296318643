import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import uid from 'uid';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Category} from '../../../../models/category.model';
import {UploadService} from '../../../../services/upload.service';
import {CategoryProductService} from '../../../../services/category-product.service';
import {NotificationsService} from '../../../../services/notifications.service';

@Component({
  selector: 'app-product-categoryadd',
  templateUrl: './product-categoryadd.component.html',
  styleUrls: ['./product-categoryadd.component.css']
})
export class ProductCategoryaddComponent implements OnInit, OnDestroy {

  categoryForm: UntypedFormGroup;
  toastTitle: string;
  toastMessage: string;
  uidStore: string;
  uidGroup: string;
  uidCategory;
  image: string;
  pourcentage = 0;
  group: string;
  upload = false;
  subscriptions: Subscription = new Subscription();

  constructor(
    private notifyService: NotificationsService,
    private categoryProductService: CategoryProductService,
    private uploadService: UploadService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          if (token !== null) {
            this.uidStore = token.get('idStore');
            this.uidGroup = token.get('idgroup');
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.categoryForm = new UntypedFormGroup({
      title: new UntypedFormControl(),
      description: new UntypedFormControl(),
      image: new UntypedFormControl()
    });
  }

  onSubmit(): void {

    const category: Category = {
      uid: uid(32),
      image: this.uploadService.images['category-images'][0],
      name: this.categoryForm.get('title').value,
      description: this.categoryForm.get('description').value,
      available: true,
      priority: 0,
      createdAt: new Date(),
      updatedAt: null,
      deletedAt: null
    };

    this.categoryProductService.createCategoryProduct(category, this.uidStore, this.uidGroup).then(
      () => {
        this.toastTitle = 'Ajout Fait';
        this.toastMessage = 'Ajout de la category faite avec success !';
        this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
        this.categoryForm.reset();
        this.uploadService.dropImageList();
        this.uidCategory = uid(32);
      }
    ).catch(
      () => {
        this.toastTitle = 'Echec';
        this.toastMessage = 'Ajout de la Categorie échoué !';
        this.notifyService.showError(this.toastMessage, this.toastTitle);
      });

  }

  onUpload(event): void {
    this.upload = true;
    this.uploadService.uploadFiles(event,
      `groups/${this.uidGroup}/stores/${this.uidStore}/category-products/${this.uidCategory}/${this.uidCategory}`, 'category-images');
    this.subscriptions.add(
      this.uploadService.uploadPercent$.subscribe(
        valeur => {
          this.pourcentage = valeur;
        }
      )
    );
  }
}
