import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationsService} from '../../services/notifications.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GroupProductTypeService} from '../../services/group-product-type.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {GroupProductType} from '../../models/group-product-type.model';
import uid from 'uid';
import {ProductType} from '../../models/product-type.model';

@Component({
  selector: 'app-addproducttype',
  templateUrl: './addProductType.component.html',
  styleUrls: ['./addProductType.component.css']
})
export class AddProductTypeComponent implements OnInit, OnDestroy {

  toastTitle: string;
  toastMessage: string;
  gProductTypeForm: UntypedFormGroup;
  uidGroup: string;
  subscriptions: Subscription = new Subscription();
  productTypes: ProductType[] = [
    ProductType.AIRPLANE_TICKET,
    ProductType.BUS_TICKET,
    ProductType.FOOD,
    ProductType.GOOD,
    ProductType.SERVICE,
    ProductType.EVENT_TICKET
  ];
  gProductTypes: string[] = [];

  constructor(private notifyService: NotificationsService,
              private gProductTypeServices: GroupProductTypeService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.add(
      this.route.paramMap.subscribe(
        token => {
          this.uidGroup = token.get('idgroup');
          void this.gProductTypeServices.getGroupProductTypes(this.uidGroup);
          this.subscriptions.add(
            this.gProductTypeServices.groupProductTypes$.subscribe((gProductTypes: GroupProductType[]) => {
              gProductTypes.map((gProductType) => {
                this.gProductTypes.push(gProductType.productType);
              });
              this.productTypes = this.productTypes.filter((element) => !this.gProductTypes.includes(element));
            })
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    this.gProductTypeForm = new UntypedFormGroup({
      productType: new UntypedFormControl()
    });
  }

  onSubmit(): void {
    const gProductType: GroupProductType = {
      uid: uid(32),
      productType: this.gProductTypeForm.get('productType').value,
      uidGroup: this.uidGroup
    };

    this.gProductTypeServices.addGroupProductType(gProductType)
      .then((response: any) => {
        if (response === null) {
          this.gProductTypeForm.reset();
          void this.router.navigate([`groups/${this.uidGroup}`]).then(
            () => {
              this.toastTitle = 'Ajout Fait';
              this.toastMessage = `Ajout du Type de Produit fait avec succès !`;
              this.notifyService.showSuccess(this.toastMessage, this.toastTitle);
            }
          );
        } else if (response.code === '23505'){
          this.toastTitle = 'Echec de l\'ajout';
          this.toastMessage = `Ce Type de Produit existe déjà pour ce Groupe!`;
          this.notifyService.showWarning(this.toastMessage, this.toastTitle);
        } else {
          this.toastTitle = 'Echec de l\'ajout';
          this.toastMessage = `Echec de l'ajout du Type de Produit!: ${response.message}`;
          this.notifyService.showError(this.toastMessage, this.toastTitle);
        }
      });
  }

}
