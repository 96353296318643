<div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Liste des Commandes En Attente de Validation</h5>
        </div>
        <div class="ibox-content">
          <div class="table-responsive">
            <app-order [orders]="ordersNoValidate"></app-order>
          </div>

        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Liste des Commandes Validées</h5>
        </div>
        <div class="ibox-content">
          <div class="table-responsive">
            <app-order [orders]="ordersValidate"></app-order>
          </div>

        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Liste des Commandes Refusées</h5>
        </div>
        <div class="ibox-content">
          <div class="table-responsive">
            <app-order [orders]="ordersDecline"></app-order>
          </div>

        </div>
      </div>
    </div>

  </div>
